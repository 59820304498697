import React from "react";
import Link from "next/link";
import Head from "next/head";

import Main from "../Main";
import styles from "../../styles/error.scss";

const FallbackComponent = ({ error }: { error: Error; info: React.ErrorInfo; clearError: () => void }) => {
  return (
    <Main>
      <div className="rotoql-content-error__container">
        <Head>
          <title>Error</title>
          <meta name="robots" content="noindex,follow" />
          <meta name="googlebot" content="noindex,follow" />
        </Head>
        <div className="rotoql-content-error__text-container">
          {error && error.message ? <h1 className="rotoql-content-error__title">{error.message}</h1> : <h1>Error</h1>}
          <Link href="/" legacyBehavior>
            <a className="rotoql-content-error__link">Go Home</a>
          </Link>
        </div>
        <style jsx global>
          {styles}
        </style>
      </div>
    </Main>
  );
};

export default FallbackComponent;
