import React from "react";
import { Button, Form, Label, Input, Row, Col, Alert } from "reactstrap";
import { useMutation } from "@apollo/client";
import { getCxd } from "../../../services/CellxpertService";
import Dropdown, { DropdownItem } from "../../Dropdown";
import { parsePhoneNumber, getAsYouType } from "awesome-phonenumber";
import Router from "next/router";

import { CREATE_USER_MUTATION } from "../../../gql/mutations";
import { STATES, SPORT_KEYS, WEB_SPORTS_NAME_LIST } from "../../../constants";
import styles from "./styles.scss";
import { SubscriptionContext } from "src/services/SubscriptionService";
import SportSelector from "src/components/SportSelector";
import { useContext } from "react";
import AnalyticsService from "src/services/AnalyticsService";

export interface RegisterProps {
  hidePhone?: boolean;
  showSports?: boolean;
}

const Register = ({ showSports, hidePhone }: RegisterProps) => {
  const ctx = useContext(SubscriptionContext);
  const [createUser, { loading, error }] = useMutation(CREATE_USER_MUTATION);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [displayName, setDisplayName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [formErrorList, setFormErrorList] = React.useState<string[]>([]);
  const [selectedStateIndex, setSelectedStateIndex] = React.useState(0);
  const [sportList, setSportList] = React.useState([]);
  const [signupTerms, setSignupTerms] = React.useState(false);

  const validate = () => {
    const errorMessageList: string[] = [];
    const usernameRE = /^[A-Z0-9_]{4,16}$/;
    if (displayName && (/\s/g.test(displayName.toUpperCase()) || !usernameRE.test(displayName.toUpperCase()))) {
      errorMessageList.push("Username must be letters, numbers, underscores and 4-16 characters");
    }
    if (!password || password.length < 6) {
      errorMessageList.push("Password must be at least six characters.");
    }

    if (!email || email === "") {
      errorMessageList.push("Please enter a valid email address.");
    } else if (email) {
      const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/;
      if (!re.test(email.toUpperCase())) {
        errorMessageList.push("Please enter a valid email address.");
      }
    }

    if (phone) {
      const phoneNumber = parsePhoneNumber(phone, { regionCode: "US" });
      if (!phoneNumber || (phoneNumber && !phoneNumber.valid)) {
        errorMessageList.push("Please enter a valid phone number.");
      }
    }

    if (showSports && !sportList.length) {
      errorMessageList.push("Sport not specified");
    }

    return errorMessageList;
  };

  const renderApolloErrorMessages = () => {
    const errorMessageList: string[] = [];
    const errors: any = error?.graphQLErrors || [];

    for (let i = 0; i < errors.length; i++) {
      errorMessageList.push(errors[i].message);
    }

    // Alerting
    return renderErrorMessages(errorMessageList);
  };

  const renderErrorMessages = (errors: Array<string>) => {
    const errorMessageList: any[] = [];

    for (let i = 0; i < errors.length; i++) {
      errorMessageList.push(<div key={i}>{errors[i]}</div>);
    }

    // Alerting
    return <Alert color="danger">{errorMessageList}</Alert>;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const errorList = validate();
    if (errorList.length === 0) {
      try {
        const phoneNumber = parsePhoneNumber(phone, { regionCode: "US" });
        const res = await createUser({
          variables: {
            cxd: getCxd(),
            email,
            password,
            displayName,
            state: STATES[selectedStateIndex].value,
            phoneNumber: phoneNumber?.number?.e164 ?? undefined,
          },
        });
        await ctx.onSignIn(res.data.createUser, false, "email", window.location.href);
        if (showSports) {
          const url = WEB_SPORTS_NAME_LIST[SPORT_KEYS.indexOf(sportList[0])]; // prettier-ignore
          Router.push(url);
        }
      } catch {
        AnalyticsService.track("Signup Failure", {
          source: "email",
        });
      }
    } else {
      setFormErrorList(errorList);
    }
  };

  return (
    <Form className="rotoql-register__form" onSubmit={handleSubmit}>
      <style jsx>{styles}</style>
      {error ? (
        <Row>
          <Col sm={12}>{renderApolloErrorMessages()}</Col>
        </Row>
      ) : null}
      {formErrorList && formErrorList.length > 0 ? (
        <Row>
          <Col sm={12}>{renderErrorMessages(formErrorList)}</Col>
        </Row>
      ) : null}
      <Row>
        <Col />
      </Row>
      <div className="rotoql-register__dropdown-container">
        <Label for="displayName" className="rotoql-register__label">
          What state are you in?
        </Label>
        <Dropdown
          className="rotoql-register__state-dropdown"
          toggleStyle={{
            width: "100%",
          }}
          menuStyle={{
            width: "100%",
          }}
          onSelectItem={(_: DropdownItem, idx: number) => {
            setSelectedStateIndex(idx);
          }}
          selectedIndex={selectedStateIndex}
          items={STATES}
        />
      </div>
      <div className="rotoql-register__input-container">
        <Label for="displayName" className="rotoql-register__label">
          Username
        </Label>
        <Input
          className="rotoql-register__input"
          type="text"
          name="displayName"
          value={displayName}
          placeholder="Enter username"
          onChange={(e) => {
            setDisplayName(e.target.value);
            setFormErrorList([]);
          }}
        />
      </div>
      <div className="rotoql-register__input-container">
        <Label for="email" className="rotoql-register__label">
          E-mail Address
        </Label>
        <Input
          className="rotoql-register__input"
          type="email"
          name="email"
          value={email}
          placeholder="Enter e-mail address"
          onChange={(e) => {
            setEmail(e.target.value);
            setFormErrorList([]);
          }}
        />
      </div>
      <div className="rotoql-register__input-container">
        <Label for="password" className="rotoql-register__label">
          Password
        </Label>
        <Input
          className="rotoql-register__input"
          type="password"
          name="password"
          value={password}
          placeholder="Enter password"
          onChange={(e) => {
            setPassword(e.target.value);
            setFormErrorList([]);
          }}
        />
      </div>
      {hidePhone ? null : (
        <div className="rotoql-register__input-container">
          <Label for="phone" className="rotoql-register__label">
            Phone Number (optional)
          </Label>
          <Input
            className="rotoql-register__input"
            type="tel"
            name="phone"
            value={phone}
            placeholder="Enter phone number (optional)"
            onChange={(e) => {
              setPhone(getAsYouType("US").reset(e.target.value.replace(/[^+0-9]/g, "")));
              setFormErrorList([]);
            }}
          />
        </div>
      )}
      <div className="rotoql-register__accept-terms">
        <input
          className="rotoql-register__checkbox"
          type="checkbox"
          checked={signupTerms}
          onChange={(e) => setSignupTerms(e.target.checked)}
        />
        By checking this box I acknowledge that I have reviewed, understand, and accept the
        <a href="https://betql.co/terms-and-conditions" target="_blank">
          &nbsp;BetQL Terms and Conditions of Service&nbsp;
        </a>
        and
        <a href="https://audacyinc.com/privacy-policy/" target="_blank">
          &nbsp;Privacy Policy
        </a>{" "}
        and confirm that I am at least 18 years of age.
      </div>
      {showSports ? (
        <div className="rotoql-register__input-container">
          <label className="landing-page-modal__label">Select your sport</label>
          <SportSelector
            tier="premium"
            sportList={sportList}
            setSportList={(_sportList: any) => {
              setSportList(_sportList);
            }}
            titleStyle={{ display: "none" }}
            style={{ padding: 0 }}
          />
        </div>
      ) : null}
      <div className="rotoql-register__button-container">
        <Button
          color="primary"
          disabled={loading || !signupTerms}
          type="submit"
          className="rotoql-register__register-button"
        >
          Sign Up
        </Button>
      </div>
    </Form>
  );
};

export default Register;
