import { StatConfig, SummaryStatConfig } from ".";

export enum NbaPlayerStatKeys {
  Points = "points",
  Rebounds = "rebounds",
  Assists = "assists",
  PointsAssists = "point_assists",
  PointsRebound = "point_rebounds",
  AssistsRebounds = "assists_rebounds",
  PointsAssistsRebounds = "point_assists_rebounds",
  ThreePointsMade = "three_points_made",
  DoubleDouble = "double_double",
  TripleDouple = "triple_double",
  GamesPlayed = "games_played",
  GamesStarted = "games_started",
  Minutes = "minutes",
  // BetQLState Keys
  PercentPointsOver = "percent_points_over",
  PercentAssistsOver = "percent_assists_over",
  PercentReboundsOver = "percent_rebounds_over",
  PercentPRAOver = "percent_pra_over",
}

export enum NbaPlayerPropKeys {
  Points = "Points",
  Rebounds = "Rebounds",
  Assists = "Assists",
  PointsAssists = "Points & Assists",
  PointsAssistsRebounds = "Points, Rebounds & Assists",
}

export const nbaDefaultSummaryStatConfig: StatConfig = {
  [NbaPlayerStatKeys.Points]: {
    shortLabel: "PTS",
    longLabel: "Points",
    sortOrder: 1,
  },
  [NbaPlayerStatKeys.Assists]: {
    shortLabel: "AST",
    longLabel: "Assists",
    sortOrder: 2,
  },
  [NbaPlayerStatKeys.Rebounds]: {
    shortLabel: "REB",
    longLabel: "Rebounds",
    sortOrder: 3,
  },
  [NbaPlayerStatKeys.PointsAssistsRebounds]: {
    shortLabel: "P+R+A",
    longLabel: "P+R+A",
    sortOrder: 4,
  },
};

export function getNbaStatsMap(): Map<NbaPlayerStatKeys, NbaPlayerPropKeys> {
  return new Map<NbaPlayerStatKeys, NbaPlayerPropKeys>([
    [NbaPlayerStatKeys.PercentPointsOver, NbaPlayerPropKeys.Points],
    [NbaPlayerStatKeys.PercentAssistsOver, NbaPlayerPropKeys.Assists],
    [NbaPlayerStatKeys.PercentReboundsOver, NbaPlayerPropKeys.Rebounds],
    [NbaPlayerStatKeys.PercentPRAOver, NbaPlayerPropKeys.PointsAssistsRebounds],
  ]);
}

export function getNbaOverSummaryStatConfig(): StatConfig {
  return {
    [NbaPlayerStatKeys.PercentPointsOver]: {
      ...nbaDefaultSummaryStatConfig[NbaPlayerStatKeys.Points],
    },
    [NbaPlayerStatKeys.PercentAssistsOver]: {
      ...nbaDefaultSummaryStatConfig[NbaPlayerStatKeys.Assists],
    },
    [NbaPlayerStatKeys.PercentReboundsOver]: {
      ...nbaDefaultSummaryStatConfig[NbaPlayerStatKeys.Rebounds],
    },
    [NbaPlayerStatKeys.PercentPRAOver]: {
      ...nbaDefaultSummaryStatConfig[NbaPlayerStatKeys.PointsAssistsRebounds],
    },
  };
}

export function getNbaSummaryConfig(): SummaryStatConfig {
  const overSummaryStatConfig = getNbaOverSummaryStatConfig();

  return {
    all: {
      ...nbaDefaultSummaryStatConfig,
      ...overSummaryStatConfig,
    },
    default: nbaDefaultSummaryStatConfig,
    over: overSummaryStatConfig,
  };
}

export const getNbaOverPropertyKeys = () => [
  NbaPlayerStatKeys.PercentPointsOver,
  NbaPlayerStatKeys.PercentAssistsOver,
  NbaPlayerStatKeys.PercentReboundsOver,
  NbaPlayerStatKeys.PercentPRAOver,
];

export const getNbaStandardPropertyKeys = () => [
  NbaPlayerStatKeys.Points,
  NbaPlayerStatKeys.Assists,
  NbaPlayerStatKeys.Rebounds,
  NbaPlayerStatKeys.PointsAssistsRebounds,
];
