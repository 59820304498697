import { StatConfig, SummaryStatConfig } from ".";

export enum NflPlayerStatKeys {
  // Passing
  Completions = "completions",
  CompletionPercent = "cmp_pct",
  CompletionPercentOver = "cmp_pct_over",
  AverageCompletions = "avg_completions",
  AverageCompletionsOver = "avg_completions_over",
  Sacks = "sacks",
  SackYards = "sack_yards",
  Interceptions = "interceptions",
  AverageInterceptions = "average_interceptions",
  Rating = "rating",
  AirYards = "air_yards",
  GrossYards = "gross_yards",
  FirstDowns = "first_downs",
  IntTouchdowns = "int_touchdowns",
  ThrowAways = "throw_aways",
  PoorThrows = "poor_throws",
  DefendedPasses = "defended_passes",
  Spikes = "spikes",
  Blitzes = "blitzes",
  Hurries = "hurries",
  Knockdowns = "Knockdowns",
  PocketTime = "pocket_time",
  AvgPocketTime = "avg_pocket_time",
  NetYards = "net_yards",
  BattedPasses = "batted_passes",
  OnTargetThrows = "on_target_throws",
  PassingYards = "passing_yards",
  PassingAttempts = "passing_attempts",
  AveragePassingAttempts = "average_passing_attemps",
  AveragePassingAttemptsOver = "average_passing_attemps_over",
  AverageYardsPassing = "passing_avg_yards",
  AverageYardsPassingOver = "passing_avg_yards_over",
  LongestPassing = "passing_longest",
  LongestTochdownPassing = "passing_longest_touchdown",
  PassingTouchdowns = "passing_touchdowns",
  PassingDroppedPasses = "passing_dropped_passes",
  AveragePassingTouchdowns = "average_passing_touchdown",

  // Rushing
  Tlost = "tlost",
  TlostYards = "tlost_yards",
  FirstDown = "first_down",
  KneelDowns = "kneel_downs",
  Scrambles = "scrambles",
  RushingYards = "rushing_yards",
  BrokenTacklesRushing = "rushing_broken_tackles",
  RushingAttempts = "rushing_attempts",
  AverageRushingAttempts = "average_rushing_attemps",
  AverageRushingAttemptsOver = "average_rushing_attemps_over",
  AverageYardsRushing = "rushing_avg_yards",
  AverageYardsRushingOver = "rushing_avg_yards_over",
  LongestRushing = "rushing_longest",
  LongestTouchdownRushing = "rushing_longest_touchdown",
  RushingTouchdowns = "rushing_touchdowns",
  AverageRushingTouchdowns = "average_rushing_touchdowns",
  AverageRushingTouchdownsOver = "average_rushing_touchdowns_over",
  RushingRedzoneAttempts = "rushing_redzone_attempts",
  RusingYardsAfterContact = "rushing_yards_after_contact",
  AverageRushingReceivingYards = "avg_rushing_receiving_yards",
  AverageRushingReceivingYardsOver = "avg_rushing_receiving_yards_over",

  // Receiving
  Receptions = "receptions",
  AverageReceptions = "average_receptions",
  AverageReceptionsOver = "average_receptions",
  Targets = "targets",
  YardsAfterCatch = "yards_after_catch",
  CatchablePasses = "catchable_passes",
  ReceivingYards = "receiving_yards",
  BrokenTacklesReceiving = "receiving_broken_tackles",
  AverageYardsReceiving = "receiving_avg_yards",
  LongestReceiving = "receiving_longest",
  LongestTouchdownReceiving = "receiving_longest_touchdown",
  ReceivingTouchdowns = "receiving_touchdowns",
  AverageReceivingTouchdowns = "average_receiving_touchdowns",
  AverageReceivingTouchdownsOver = "average_receiving_touchdowns_over",
  ReceivingRedzoneAttempts = "receiving_redzone_attempts",
  ReceivingDroppedPasses = "receiving_dropped_passes",
  ReceivingYardsAfterContact = "receiving_yards_after_contact",

  // Receiving & Receiving
  RushingReceivingYards = "rushing_receiving_yards",
  RushingReceivingTouchdowns = "rushing_receiving_touchdowns",
  RushingReceivingTouchdownsOver = "rushing_receiving_touchdowns_over",
  AverageRushingReceivingTouchdowns = "avg_rushing_receiving_touchdowns",
  AverageRushingReceivingTouchdownsOver = "avg_rushing_receiving_touchdowns_over",

  GamesPlayed = "games_played",
  GamesStarted = "games_started",

  AverageYardsReceivingOver = "average_yards_receiving_over",
  AveragePassingTouchdownsOver = "average_passing_touchdowns_over",
  AverageInterceptionsOver = "average_interceptions_over",

  CompletionsOver = "completions_over",
  PassingAttempsOver = "passing_attempts_over",
  PassingYardsOver = "passing_yards_over",
  PassingTouchdownsOver = "passing_touchdowns_over",
  InterceptionsOver = "interceptions_over",
  RushingYardsOver = "rushing_yards_over",
  RushingAttemptsOver = "rushing_attempts_over",
  ReceptionsOver = "receptions_over",
  RushingReceivingYardsOver = "rushing_receiving_yards_over",
  RushingTouchdownsOver = "rushing_touchdowns_over",
  ReceivingTouchdownsOver = "receiving_touchdowns_over",
  ReceivingYardsOver = "receiving_yards_over",
  PassingCompletionsOver = "completions_over",
}

export enum NflPlayerPropKeys {
  Passes = "Passes",
  PassingYards = "Passing Yards",
  PassingTouchdowns = "Passing Touchdowns",
  PassingInterceptions = "Passing Interceptions",
  PassingCompletions = "Passing Completions",
  RushingYards = "Rushing Yards",
  RushingTouchdowns = "Rushing Touchdowns",
  Rushes = "Rushes",
  RushingReceivingYards = "Rushing + Receiving Yards",
  Receptions = "Receptions",
  ReceivingYards = "Receiving Yards",
  ReceivingTouchdowns = "Receiving Touchdowns",
}

export const nflQBDefaultSummaryStatConfig: StatConfig = {
  [NflPlayerStatKeys.Completions]: {
    shortLabel: "Comp",
    longLabel: "Comp",
    sortOrder: 1,
  },
  [NflPlayerStatKeys.PassingAttempts]: {
    shortLabel: "Att",
    longLabel: "Att",
    sortOrder: 2,
  },
  [NflPlayerStatKeys.PassingYards]: {
    shortLabel: "PaYD",
    longLabel: "PaYD",
    sortOrder: 3,
  },
  [NflPlayerStatKeys.RushingAttempts]: {
    shortLabel: "Rush",
    longLabel: "Rush",
    sortOrder: 4,
  },
  [NflPlayerStatKeys.RushingYards]: {
    shortLabel: "RuYD",
    longLabel: "RuYD",
    sortOrder: 5,
  },
  [NflPlayerStatKeys.PassingTouchdowns]: {
    shortLabel: "PaTD",
    longLabel: "PaTD",
    sortOrder: 6,
  },
  [NflPlayerStatKeys.Interceptions]: {
    shortLabel: "INT",
    longLabel: "INT",
    sortOrder: 7,
  },
};

export const nflQBAvgSummaryStatConfig: StatConfig = {
  [NflPlayerStatKeys.Completions]: {
    shortLabel: "Comp",
    longLabel: "Comp",
    sortOrder: 1,
  },
  [NflPlayerStatKeys.PassingAttempts]: {
    shortLabel: "Att",
    longLabel: "Att",
    sortOrder: 2,
  },
  [NflPlayerStatKeys.PassingYards]: {
    shortLabel: "PaYD",
    longLabel: "PaYD",
    sortOrder: 3,
  },
  [NflPlayerStatKeys.RushingAttempts]: {
    shortLabel: "Rush",
    longLabel: "Rush",
    sortOrder: 4,
  },
  [NflPlayerStatKeys.RushingYards]: {
    shortLabel: "RuYD",
    longLabel: "RuYD",
    sortOrder: 5,
  },
  [NflPlayerStatKeys.PassingTouchdowns]: {
    shortLabel: "PaTD",
    longLabel: "PaTD",
    sortOrder: 6,
  },
  [NflPlayerStatKeys.Interceptions]: {
    shortLabel: "INT",
    longLabel: "INT",
    sortOrder: 7,
  },
};

export const nflRBDefaultSummaryStatConfig: StatConfig = {
  [NflPlayerStatKeys.RushingAttempts]: {
    shortLabel: "Rush",
    longLabel: "Rush",
    sortOrder: 1,
  },
  [NflPlayerStatKeys.RushingYards]: {
    shortLabel: "RushYD",
    longLabel: "RushYD",
    sortOrder: 2,
  },
  [NflPlayerStatKeys.Receptions]: {
    shortLabel: "Rec",
    longLabel: "Rec",
    sortOrder: 3,
  },
  [NflPlayerStatKeys.ReceivingYards]: {
    shortLabel: "RecYD",
    longLabel: "RecYD",
    sortOrder: 4,
  },
  [NflPlayerStatKeys.RushingReceivingYards]: {
    shortLabel: "RuYD+ReYD",
    longLabel: "RuYD+ReYD",
    sortOrder: 5,
  },
  [NflPlayerStatKeys.RushingTouchdowns]: {
    shortLabel: "TD",
    longLabel: "TD",
    sortOrder: 6,
  },
};

export const nflRBAvgSummaryStatConfig: StatConfig = {
  [NflPlayerStatKeys.RushingAttempts]: {
    shortLabel: "Rush",
    longLabel: "Rush",
    sortOrder: 1,
  },
  [NflPlayerStatKeys.RushingYards]: {
    shortLabel: "RushYD",
    longLabel: "RushYD",
    sortOrder: 2,
  },
  [NflPlayerStatKeys.Receptions]: {
    shortLabel: "Rec",
    longLabel: "Rec",
    sortOrder: 3,
  },
  [NflPlayerStatKeys.ReceivingYards]: {
    shortLabel: "RecYD",
    longLabel: "RecYD",
    sortOrder: 4,
  },
  [NflPlayerStatKeys.RushingReceivingYards]: {
    shortLabel: "RuYD+ReYD",
    longLabel: "RuYD+ReYD",
    sortOrder: 5,
  },
  [NflPlayerStatKeys.RushingTouchdowns]: {
    shortLabel: "TD",
    longLabel: "TD",
    sortOrder: 6,
  },
};

export const nflWRDefaultSummaryStatConfig: StatConfig = {
  [NflPlayerStatKeys.Receptions]: {
    shortLabel: "Rec",
    longLabel: "Rec",
    sortOrder: 1,
  },
  [NflPlayerStatKeys.ReceivingYards]: {
    shortLabel: "RecYD",
    longLabel: "RecYD",
    sortOrder: 2,
  },
  [NflPlayerStatKeys.ReceivingTouchdowns]: {
    shortLabel: "TD",
    longLabel: "TD",
    sortOrder: 3,
  },
};

export const nflWRAvgSummaryStatConfig: StatConfig = {
  [NflPlayerStatKeys.Receptions]: {
    shortLabel: "Rec",
    longLabel: "Rec",
    sortOrder: 1,
  },
  [NflPlayerStatKeys.ReceivingYards]: {
    shortLabel: "ReYD",
    longLabel: "ReYD",
    sortOrder: 2,
  },
  [NflPlayerStatKeys.ReceivingTouchdowns]: {
    shortLabel: "TD",
    longLabel: "TD",
    sortOrder: 3,
  },
};

export function getNflDefaultSummaryStatConfig(postion: string): StatConfig {
  let config = {};
  switch (postion) {
    case "QB":
      config = nflQBDefaultSummaryStatConfig;
      break;
    case "RB":
    case "FB":
      config = nflRBDefaultSummaryStatConfig;
      break;
    case "WR":
    case "TE":
      config = nflWRDefaultSummaryStatConfig;
      break;
  }

  return config;
}

export function getNflStatsMap(postion: string): Map<NflPlayerStatKeys, NflPlayerPropKeys> {
  let map = new Map();
  switch (postion) {
    case "QB":
      map = new Map<NflPlayerStatKeys, NflPlayerPropKeys>([
        [NflPlayerStatKeys.PassingCompletionsOver, NflPlayerPropKeys.PassingCompletions],
        [NflPlayerStatKeys.PassingAttempsOver, NflPlayerPropKeys.Passes],
        [NflPlayerStatKeys.PassingYardsOver, NflPlayerPropKeys.PassingYards],
        [NflPlayerStatKeys.RushingAttemptsOver, NflPlayerPropKeys.Rushes],
        [NflPlayerStatKeys.RushingYardsOver, NflPlayerPropKeys.RushingYards],
        [NflPlayerStatKeys.PassingTouchdownsOver, NflPlayerPropKeys.PassingTouchdowns],
        [NflPlayerStatKeys.InterceptionsOver, NflPlayerPropKeys.PassingInterceptions],
      ]);
      break;
    case "RB":
    case "FB":
      map = new Map<NflPlayerStatKeys, NflPlayerPropKeys>([
        [NflPlayerStatKeys.RushingAttemptsOver, NflPlayerPropKeys.Rushes],
        [NflPlayerStatKeys.RushingYardsOver, NflPlayerPropKeys.RushingYards],
        [NflPlayerStatKeys.ReceptionsOver, NflPlayerPropKeys.Receptions],
        [NflPlayerStatKeys.ReceivingYardsOver, NflPlayerPropKeys.ReceivingYards],
        [NflPlayerStatKeys.RushingReceivingYardsOver, NflPlayerPropKeys.RushingReceivingYards],
      ]);
      break;
    case "WR":
    case "TE":
      map = new Map<NflPlayerStatKeys, NflPlayerPropKeys>([
        [NflPlayerStatKeys.ReceptionsOver, NflPlayerPropKeys.Receptions],
        [NflPlayerStatKeys.ReceivingYardsOver, NflPlayerPropKeys.ReceivingYards],
      ]);
      break;
  }

  return map;
}

export function getNflOverSummaryStatConfig(postion: string): StatConfig {
  let config = {};
  switch (postion) {
    case "QB":
      config = {
        [NflPlayerStatKeys.CompletionsOver]: {
          ...nflQBAvgSummaryStatConfig[NflPlayerStatKeys.Completions],
        },
        [NflPlayerStatKeys.PassingAttempsOver]: {
          ...nflQBAvgSummaryStatConfig[NflPlayerStatKeys.PassingAttempts],
        },
        [NflPlayerStatKeys.PassingYardsOver]: {
          ...nflQBAvgSummaryStatConfig[NflPlayerStatKeys.PassingYards],
        },
        [NflPlayerStatKeys.RushingAttemptsOver]: {
          ...nflQBAvgSummaryStatConfig[NflPlayerStatKeys.RushingAttempts],
        },
        [NflPlayerStatKeys.RushingYardsOver]: {
          ...nflQBAvgSummaryStatConfig[NflPlayerStatKeys.RushingYards],
        },
        [NflPlayerStatKeys.PassingTouchdownsOver]: {
          ...nflQBAvgSummaryStatConfig[NflPlayerStatKeys.PassingTouchdowns],
        },
        [NflPlayerStatKeys.InterceptionsOver]: {
          ...nflQBAvgSummaryStatConfig[NflPlayerStatKeys.Interceptions],
        },
      };
      break;
    case "RB":
    case "FB":
      config = {
        [NflPlayerStatKeys.RushingAttemptsOver]: {
          ...nflRBAvgSummaryStatConfig[NflPlayerStatKeys.RushingAttempts],
        },
        [NflPlayerStatKeys.RushingYardsOver]: {
          ...nflRBAvgSummaryStatConfig[NflPlayerStatKeys.RushingYards],
        },
        [NflPlayerStatKeys.ReceptionsOver]: {
          ...nflRBAvgSummaryStatConfig[NflPlayerStatKeys.Receptions],
        },
        [NflPlayerStatKeys.ReceivingYardsOver]: {
          ...nflRBAvgSummaryStatConfig[NflPlayerStatKeys.ReceivingYards],
        },
        [NflPlayerStatKeys.RushingReceivingYardsOver]: {
          ...nflRBAvgSummaryStatConfig[NflPlayerStatKeys.RushingReceivingYards],
        },
      };
      break;
    case "WR":
    case "TE":
      config = {
        [NflPlayerStatKeys.ReceptionsOver]: {
          ...nflWRAvgSummaryStatConfig[NflPlayerStatKeys.Receptions],
        },
        [NflPlayerStatKeys.ReceivingYardsOver]: {
          ...nflWRAvgSummaryStatConfig[NflPlayerStatKeys.ReceivingYards],
        },
      };
      break;
  }

  return config;
}

export function getNflSummaryConfig(postion: string): SummaryStatConfig {
  const defaultSummaryStatConfig = getNflDefaultSummaryStatConfig(postion);
  const overSummaryStatConfig = getNflOverSummaryStatConfig(postion);

  return {
    all: {
      ...defaultSummaryStatConfig,
      ...overSummaryStatConfig,
    },
    default: defaultSummaryStatConfig,
    over: overSummaryStatConfig,
  };
}

export function getNflOverPropertyKeys(postion: string): string[] {
  let list: string[] = [];
  switch (postion) {
    case "QB":
      list = [
        NflPlayerStatKeys.CompletionsOver,
        NflPlayerStatKeys.PassingAttempsOver,
        NflPlayerStatKeys.PassingYardsOver,
        NflPlayerStatKeys.RushingAttemptsOver,
        NflPlayerStatKeys.RushingYardsOver,
        NflPlayerStatKeys.PassingTouchdownsOver,
        NflPlayerStatKeys.InterceptionsOver,
      ];
      break;
    case "RB":
    case "FB":
      list = [
        NflPlayerStatKeys.RushingAttemptsOver,
        NflPlayerStatKeys.RushingYardsOver,
        NflPlayerStatKeys.ReceptionsOver,
        NflPlayerStatKeys.ReceivingYardsOver,
        NflPlayerStatKeys.RushingReceivingYardsOver,
      ];
      break;
    case "WR":
    case "TE":
      list = [NflPlayerStatKeys.ReceptionsOver, NflPlayerStatKeys.ReceivingYardsOver];
      break;
  }

  return list;
}

export function getNflStandardPropertyKeys(postion: string): string[] {
  let list: string[] = [];
  switch (postion) {
    case "QB":
      list = [
        NflPlayerStatKeys.Completions,
        NflPlayerStatKeys.PassingAttempts,
        NflPlayerStatKeys.PassingYards,
        NflPlayerStatKeys.RushingAttempts,
        NflPlayerStatKeys.RushingYards,
        NflPlayerStatKeys.PassingTouchdowns,
        NflPlayerStatKeys.Interceptions,
      ];
      break;
    case "RB":
    case "FB":
      list = [
        NflPlayerStatKeys.RushingAttempts,
        NflPlayerStatKeys.RushingYards,
        NflPlayerStatKeys.Receptions,
        NflPlayerStatKeys.ReceivingYards,
        NflPlayerStatKeys.RushingReceivingYards,
        NflPlayerStatKeys.RushingTouchdowns,
      ];
      break;
    case "WR":
    case "TE":
      list = [NflPlayerStatKeys.Receptions, NflPlayerStatKeys.ReceivingYards, NflPlayerStatKeys.ReceivingTouchdowns];
      break;
  }

  return list;
}
