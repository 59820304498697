import { getApps, initializeApp } from "firebase/app";
import { Analytics, getAnalytics, logEvent, setUserId } from "firebase/analytics";
import { IS_CLIENT } from "src/constants";

const firebaseConfig = {
  apiKey: "AIzaSyDP0CSgJtE3u5ZlfCjpeAr1gvX1isaRhhU",
  authDomain: "arched-hybrid-112221.firebaseapp.com",
  projectId: "arched-hybrid-112221",
  storageBucket: "arched-hybrid-112221.appspot.com",
  messagingSenderId: "974171577195",
  appId: "1:974171577195:web:4bdbb83a1a1d32c9",
  measurementId: "G-4HP4Z8HG1N",
};

let analytics: Analytics;

if (!getApps().length) {
  const app = initializeApp(firebaseConfig);
  if (IS_CLIENT) {
    analytics = getAnalytics(app);
  }
}

export { analytics, logEvent, setUserId };
