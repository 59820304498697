import React from "react";
import styles from "./styles.scss";

function FocusedTextLink({ children, ...other }: { children: any; [key: string]: any }) {
  return (
    <>
      <button className={`button ${other?.className ?? ""}`} {...other}>
        {children}
      </button>
      <style jsx>{styles}</style>
    </>
  );
}

export default FocusedTextLink;
