function appendImgElement(url: string) {
  const cacheBust = Math.random() * 10000000000000;
  const image = document.createElement("img");
  image.src = url + cacheBust;
  image.alt = "";
  image.width = 1;
  image.height = 1;
  image.border = "0";
  document.body.appendChild(image);
}

export default {
  trackPurchase: () => {
    appendImgElement("https://trkn.us/pixel/conv/ppt=10312;g=purchase;gid=31583;ord=");
  },
  trackEmailCapture: () => {
    appendImgElement("https://trkn.us/pixel/conv/ppt=10312;g=email_capture;gid=31584;ord=");
  },
};
