"use client";
import { Dispatch, FC, PropsWithChildren, SetStateAction, createContext, useContext, useEffect, useState } from "react";

import { userGetId, userGetToken } from "./UserService";
import { isProduction } from "./GAService";

type BrazeContext = {
  isBrazeSetup: boolean;
  setIsBrazeSetup: Dispatch<SetStateAction<boolean>>;
  safelyUseBraze: (callback: () => void) => void;
};
const BrazeContext = createContext<BrazeContext>({
  isBrazeSetup: false,
  setIsBrazeSetup: () => {},
  safelyUseBraze: () => {},
});

const BRAZE_KEY = process.env.NEXT_PUBLIC_BRAZE_KEY ?? "";
if (!BRAZE_KEY) {
  console.error("Braze key is missing", process.env.NEXT_PUBLIC_BRAZE_KEY);
}

export const useBrazeService = () => useContext(BrazeContext);
export const BrazeServiceProvider: FC<PropsWithChildren<PropsWithChildren<BrazeServiceProviderProps>>> = ({
  signedIn,
  children,
}) => {
  const [isBrazeSetup, setIsBrazeSetup] = useState(false);

  // Initial setup for Braze.
  useEffect(() => {
    import(
      /* webpackExports: ["initialize", "automaticallyShowInAppMessages", "logCustomEvent"] */
      "@braze/web-sdk"
    ).then(({ initialize, automaticallyShowInAppMessages, logCustomEvent, logPurchase }) => {
      const isSuccessful = initialize(BRAZE_KEY, {
        baseUrl: "sdk.iad-03.braze.com",
        enableLogging: !isProduction,
      });
      if (isSuccessful) {
        automaticallyShowInAppMessages();
        window.logBrazeCustomEvent = logCustomEvent;
        window.logPurchase = logPurchase;
        setIsBrazeSetup(true);
      } else {
        setIsBrazeSetup(false);
        console.error("Braze initialization failed");
      }
    });
  }, []);

  useEffect(() => {
    if (!isBrazeSetup) {
      return;
    }
    import(
      /* webpackExports: ["getUser", "changeUser", "openSession"] */
      "@braze/web-sdk"
    ).then(({ changeUser, getUser, openSession }) => {
      if (signedIn) {
        const userId = userGetId(userGetToken());
        if (!userId) {
          // this should probs be an error thrown
          console.error("[Change] User ID not found when trying to change user on Braze");
          throw new Error("User ID not found when trying to change user on Braze");
        }
        const brazeUser = getUser();
        if (brazeUser && brazeUser.getUserId() === userId) {
          // User is already being tracked by Braze, no action needed
        } else {
          changeUser(userId);
        }
      }
      openSession();
    });
  }, [isBrazeSetup, signedIn]);

  const safelyUseBraze = (callback: () => void) => {
    if (isBrazeSetup) {
      callback();
    } else {
      // This might need to be a thrown error
      console.error("Braze is not setup");
    }
  };

  return (
    <BrazeContext.Provider
      value={{
        isBrazeSetup,
        setIsBrazeSetup,
        safelyUseBraze,
      }}
    >
      {children}
    </BrazeContext.Provider>
  );
};

type BrazeServiceProviderProps = {
  signedIn: boolean;
};
