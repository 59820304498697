import { useQuery } from "@apollo/client";
import Bugsnag from "@bugsnag/js";
import { BetQLService, BetQLSportItem, DEFAULT_BOOK_ID } from "@rotoql/common-services";
import _ from "lodash";
import moment, { Moment } from "moment-timezone";
import { useContext, useEffect, useMemo, useState } from "react";
import { DEFAULT_SPORT, WebSportItem, WEB_SPORTS_LIST, DEFAULT_TIMEZONE } from "src/constants";
import { EVENT_BY_SLUG_QUERY } from "src/gql/queries";
import { capitalize, printPlus } from "./DisplayService";
import { SubscriptionContext } from "./SubscriptionService";
import { rfc3986EncodeURIComponent } from "./UtilService";

const { getOurPick } = BetQLService;

// Periods we have ratings for / care about
// Keys are enums for GraphQL, values are for everything else
export const PERIODS = {
  FULLGAME: "FG",
  FIRSTHALF: "1H",
  SECONDHALF: "2H",
  // Not used
  // FIRSTQUARTER: "1Q",
  // SECONDQUARTER: "2Q",
  // THIRDQUARTER: "3Q",
  // FOURTHQUARTER: "4Q",
  FIRSTPERIOD: "1P",
  SECONDPERIOD: "2P",
  THIRDPERIOD: "3P",
} as const;

// This being imported by queries.ts causes a cyclic dependency. In test, this is shown as `undefined` if logged.
// Moving this line to this file fixes the error, but not the root issue.
export const PERIODS_FRAGMENT = `[${Object.keys(PERIODS).join(",")}]`;

export type Period = (typeof PERIODS)[keyof typeof PERIODS];

export const BET_TYPES = ["moneyline", "spread", "total"] as const;

export type BetType = "moneyline" | "spread" | "total";

export type Pick = {
  betType: BetType;
  period: Period;
  odds: number | null;
  outcome: "home" | "draw" | "away" | "over" | "push" | "under";
  stars: number;
};

interface IGenerateEventDisplayProps {
  event: { [key: string]: any };
  currentSport: BetQLSportItem;
  currentBookId: string;
  filterKey?: string;
}

export const generateEventDisplayProps = ({
  event,
  currentSport,
  currentBookId,
  filterKey,
}: IGenerateEventDisplayProps) => {
  // We are going to poop the values in teamStats into the team object and we
  // don't want the team object's ID to get overwritten. We also want to have
  // the teamStats object get cached.
  // NOTE Not quite sure I understand the note that was here, but now we can't modify the responses anymore.

  event = _.cloneDeep(event);

  if (event.awayTeam?.teamStats) {
    delete event.awayTeam.teamStats.id;
  }

  if (event.homeTeam?.teamStats) {
    delete event.homeTeam.teamStats.id;
  }

  const currentSportLookupKey = currentSport.lookupKey;

  let tempEvent: { [key: string]: any } = {
    ...event,
    ...BetQLService.getRatingDisplayValues(
      currentSportLookupKey,
      event.spRating,
      event.mlRating,
      event.ouRating,
      event.sp1HRating,
      event.ml1HRating,
      event.ou1HRating,
      event.sp1PRating,
      event.ml1PRating,
      event.ou1PRating
    ),
    eventTrends: event.eventTrends === null ? [] : event.eventTrends,
    awayTeam: {
      ...event.awayTeam,
      ...event.awayTeam?.teamStats,
    },
    homeTeam: {
      ...event.homeTeam,
      ...event.homeTeam?.teamStats,
    },
    ...BetQLService.getTeamStats(event),
    ...BetQLService.getSharpDisplayValues(event, currentSportLookupKey),
    ...BetQLService.getOpenCurrentLines(event, currentSportLookupKey, currentBookId),
  };

  tempEvent = {
    ...tempEvent,
    // ...userPicks[tempEvent.id],
    ...BetQLService.getValueDisplayValues(tempEvent, currentSportLookupKey),
    homeScore: tempEvent.homeScore === null ? "--" : tempEvent.homeScore,
    awayScore: tempEvent.awayScore === null ? "--" : tempEvent.awayScore,
  };

  if (filterKey) {
    const periodFilter =
      filterKey === "spread" || filterKey === "moneyline" || filterKey === "total" ? `FG${filterKey}` : filterKey;
    const communityPicksMap = _.keyBy(tempEvent.communityStats, "betType");

    let awayCount = 0,
      homeCount = 0,
      drawCount = 0;

    const ourPick = getOurPick(tempEvent, "value", filterKey, currentSport);
    const currentLine = tempEvent[`current${periodFilter}${capitalize(ourPick)}Display`];

    const trendsPick = {
      betqlPick: currentLine && currentLine !== "--" ? ourPick : null,
      communityPick: getCommunityPick(communityPicksMap[filterKey]),
      fadeThePublicPick: getFadeThePublicPick(tempEvent[`sharp${periodFilter}TicketIsHome`]),
      proSharpPick: getProSharpPick(tempEvent[`sharp${periodFilter}IsHome`]),
    };

    Object.values(trendsPick).forEach((pick) => {
      if (pick === null) {
        return;
      }

      pick === "away" && awayCount++;
      pick === "home" && homeCount++;
      pick === "draw" && drawCount++;
    });

    const modelPicks: TrendPick = {
      ...trendsPick,
      homeCount,
      awayCount,
      drawCount,
      favoredTeam: getFavoredPickByCount({ awayCount, homeCount, drawCount }),
    };

    tempEvent = {
      ...tempEvent,
      trendsPick: modelPicks,
    };
  }

  return tempEvent;
};

export type TrendPick = {
  betqlPick: TrendsPickType;
  communityPick: TrendsPickType;
  fadeThePublicPick: TrendsPickType;
  proSharpPick: TrendsPickType;
  homeCount: number;
  awayCount: number;
  drawCount: number;
  favoredTeam: string | null;
};

type ModelCount = {
  awayCount: number;
  homeCount: number;
  drawCount: number;
};

export const getFavoredPickByCount = ({ awayCount, homeCount, drawCount }: ModelCount) => {
  if (drawCount > homeCount && drawCount > awayCount) {
    return "draw";
  }

  if (homeCount > awayCount && homeCount > drawCount) {
    return "home";
  }

  if (homeCount < awayCount && awayCount > drawCount) {
    return "away";
  }

  return null;
};

export const generateEventCardDisplayProps = (event: any, currentSport: string, currentBookId: string) => {
  event = _.cloneDeep(event);

  let tempEvent = {
    ...event,
    ...BetQLService.getRatingDisplayValues(
      currentSport,
      event.spRating,
      event.mlRating,
      event.ouRating,
      event.sp1HRating,
      event.ml1HRating,
      event.ou1HRating
    ),
    ...BetQLService.getTeamStats(event),
    ...BetQLService.getSharpDisplayValues(event, currentSport),
    ...BetQLService.getOpenCurrentLines(event, currentSport, currentBookId),
  };
  return {
    ...tempEvent,
    ...BetQLService.getValueDisplayValues(tempEvent, currentSport),
  };
};

/**
 * Select the most relevant games in the order of: Upcoming games and fill up the
 * rest with completed games if they
 *
 * @param events The list of events to choose from
 * @param min The minimum games required
 */
export const selectGames = (now: Moment, events: Array<any>, min: number) => {
  const upcomingGames: Array<any> = events.filter((event: any) => moment.utc(event.startDate).isAfter(now));

  const completedGames: Array<any> = events.filter((event: any) => moment.utc(event.startDate).isBefore(now));

  // How many more do we need to fill up the pages?
  const required: number = Math.max(min - upcomingGames.length, 0);

  let requiredArray: any = [];
  for (let i: number = 0; i < completedGames.length && i < required; i++) {
    requiredArray.push(completedGames[i]);
  }

  return requiredArray.concat(upcomingGames);
};

/**
 * Get human readable date string for live game card
 */
const _getGameDateTime = (game: any, timezone: string = DEFAULT_TIMEZONE) => {
  const time = moment.tz(game.startDate, timezone);

  const today = moment.tz(timezone);
  if (time.isSame(today, "day")) {
    return `Today, ${time.format("h:mm A")}`;
  }

  let tomorrow = moment.tz(timezone).add(1, "d");
  if (time.isSame(tomorrow, "day")) {
    return `Tomorrow, ${time.format("h:mm A")}`;
  }

  let yesterday = moment.tz(timezone).subtract(1, "d");
  if (time.isSame(yesterday, "day")) {
    return `Yesterday, ${time.format("h:mm A")}`;
  }

  return time.format("ddd M/D, h:mm A");
};

/**
 * Get current state of game ie start time, postponed, period + TV Channel
 */
const _getGameProgressAndChannel = (game: any, timezone?: string) => {
  const channelString = game.channel ? ` on ${game.channel}` : "";

  if (game.eventState === "FINAL") {
    return "Final";
  }

  if (game.eventState === "POSTPONED") {
    return "Postponed";
  }

  if (game.eventState === "STARTED") {
    const readablePeriod = _.startCase(game.period === "TIME" ? "HALF" : game.period); // prettier-ignore
    return readablePeriod + channelString;
  }

  return _getGameDateTime(game, timezone) + channelString;
};

export const useGameProgressAndChannel = (game: any) => {
  const [timezone, setTimezone] = useState(DEFAULT_TIMEZONE);
  useEffect(() => {
    setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, []);
  return _getGameProgressAndChannel(game, timezone);
};

const getGameLine = ({ game, period = "FG" }: { game: any; period?: Period }) => {
  const line = game.lines?.find((x: any) => x.period === period && x.lineType === "current") || null; // prettier-ignore

  // Can be null if loading or no lines yet
  const odds = {
    moneyline: {
      home: line?.homeMoney as number | null,
      draw: line?.drawMoney as number | null,
      away: line?.awayMoney as number | null,
    },
    spread: {
      home: line?.homeSpread as number | null,
      draw: line?.drawSpread as number | null,
      away: line?.awaySpread as number | null,
    },
    total: line?.total as number | null,
  };

  return odds;
};

export const getGamePick = ({
  game,
  betType: _betType,
  period = "FG",
}: {
  game: any;
  period?: Period;
  betType?: BetType;
}): Pick => {
  const league = getGameLeague(game);
  const betType = _betType || (league.favoriteLine as BetType);

  // Get line
  const line = getGameLine({ game, period });

  // Get key for game.xxRating
  const ratingKeyParts: { [key in BetType]: string } = {
    spread: "sp",
    moneyline: "ml",
    total: "ou",
  };
  const ratingKey = `${ratingKeyParts[betType]}${period === "FG" ? "" : period}Rating`; // prettier-ignore

  // Side preferred
  let sidePreferred: "home" | "draw" | "away" | null = null;
  if (betType !== "total") {
    const homePreferred = game[ratingKey]?.preferredTeamId === game.homeTeam.id;
    const drawPreferred = league.hasDraws && game[ratingKey]?.preferredTeamId === null; // prettier-ignore
    sidePreferred = homePreferred ? "home" : drawPreferred ? "draw" : "away";
  }

  // Total preferred
  let totalPreferred: "over" | "push" | "under" | null = null;
  if (betType === "total") {
    totalPreferred = game[ratingKey]?.preferredTotal || "under";
  }

  // Odds
  const odds = betType === "total" ? line.total : line[betType][sidePreferred!]; // prettier-ignore

  // Outcome
  const outcome = betType === "total" ? totalPreferred! : sidePreferred!;

  // Stars
  const stars: number = game[ratingKey]?.rating || 0;

  return {
    betType,
    period,
    odds,
    outcome,
    stars,
  };
};

export const useGameAccess = (game: any) => {
  const ctx = useContext(SubscriptionContext);
  if (!game) {
    return null;
  }

  const league = getGameLeague(game);
  return ctx.hasSubscriptionAccess(league.key) || game.freeGame;
};

export const getProjectedPeriodBetTypes = (league: WebSportItem) => {
  // We only want bet types and periods that have projections
  const filterKeys = Object.keys(league)
    .filter((x) => x.includes("PowerRating"))
    .filter((x) => league[x])
    .map((x) => x.match(/has(FG)?(.+)PowerRating/)?.[2]);
  const filters = league.filters.filter((x) => filterKeys.includes(x.key));

  return filters.map((filter) => {
    const betType = BET_TYPES.find((b) => filter.key.includes(b)) as BetType;
    const period = Object.values(PERIODS).find((p) => filter.key.includes(p)) || "FG"; // prettier-ignore
    return { period, betType };
  });
};

export const getProjectedPeriods = (league: WebSportItem) => {
  const betTypes = getProjectedPeriodBetTypes(league);
  return betTypes.map((x) => x.period);
};

export const getGamePicks = (game: any): Pick[] => {
  const league = getGameLeague(game);
  const periodBetTypes = getProjectedPeriodBetTypes(league);
  return periodBetTypes.map(({ period, betType }) => getGamePick({ game, period, betType }));
};

export const getBetTypeAlias = ({
  league,
  betType,
  period = "FG",
}: {
  league: WebSportItem;
  betType: BetType;
  period: Period;
}): string => {
  const filter = league.filters.find((f) => f.key === `${period === "FG" ? "" : period}${betType}`);

  // Should never have to fall back
  return filter?.name || betType;
};

export const formatGamePickAsText = ({ game, pick }: { game: any; pick: Pick }) => {
  const outcomeText = ["home", "away"].includes(pick.outcome)
    ? game[`${pick.outcome}Team`].preferredAbbreviation
    : _.capitalize(pick.outcome);

  if (pick.odds === null) {
    return `${outcomeText} --`;
  }

  const oddsText = pick.betType === "total" ? pick.odds : printPlus(pick.odds);

  return `${outcomeText} ${oddsText}`;
};

/**
 * Get the bet text for a live game card
 */
export const getGameOddsText = (game: any) => {
  const league = getGameLeague(game);
  const betType = league.favoriteLine as BetType;
  const line = getGameLine({ game });
  const pick = getGamePick({ game, betType });
  const outcome = pick.outcome as "home" | "draw" | "away";

  // Assemble string
  const teamAbbrev = outcome === "draw" ? "Draw" : game[`${outcome}Team`].preferredAbbreviation; // prettier-ignore
  const oddsText = pick.odds == null ? "--" : printPlus(pick.odds);
  const totalStr = line.total == null ? "--" : `O/U ${line.total}`;

  return `${teamAbbrev} ${oddsText}, ${totalStr}`;
};

export const getGameRound = (game: any): string => {
  const round = game.tournamentRound;

  if (!round) {
    return "";
  }

  const league = getGameLeague(game);
  if (league.sport.name !== "Tennis") {
    return `Round ${round}`;
  }

  // NOTE tournamentRound in tennis is actually the bracket size e.g. 128
  let roundText = "Round of " + round;
  if (round < 8) {
    if (round === 1) {
      roundText = "Final";
    } else if (round === 2) {
      roundText = "Semi Final";
    } else if (round === 4) {
      roundText = "Quarter Final";
    }
  }
  return roundText;
};

export const getGameLeague = (game: any): WebSportItem => {
  const league = WEB_SPORTS_LIST.find((x) => game?.league?.name === x.key);
  if (!league) {
    const error = new Error("Could not find league for game.");
    console.error(error);
    Bugsnag.notify(error);
  }
  return league || DEFAULT_SPORT; // Fallback to prevent crash
};

export const getGameLink = (game: any) => {
  const league = getGameLeague(game);
  return `/${league.url}/game-predictions/${game.slugId}`;
};

export const useGame = ({ slug, ssr = false }: { slug: string; ssr?: boolean }) => {
  const variables: any = {
    slugId: rfc3986EncodeURIComponent(decodeURIComponent(slug)),
  };

  const response = useQuery<{ event: { [key: string]: any } }>(EVENT_BY_SLUG_QUERY, {
    ssr,
    variables,
    errorPolicy: "all",
  });

  const game = useMemo(() => {
    if (!response.data?.event) {
      return null;
    }
    const league = getGameLeague(response.data.event);
    return generateEventDisplayProps({
      event: response.data.event,
      currentSport: league,
      currentBookId: DEFAULT_BOOK_ID,
    });
  }, [response.data?.event, slug]);

  return { game, response };
};

export const getGameProjections = (game: any) => {
  const p = game.projection || {};
  const tiData = JSON.parse(game.tiData);
  let awayConfidence: string | number = "--";
  let homeConfidence: string | number = "--";
  let awayRank: string | number = "--";
  let homeRank: string | number = "--";
  if (tiData) {
    if (tiData.Winner_Mt_Rat_Conf) {
      awayConfidence = parseFloat((parseFloat(tiData.Winner_Mt_Rat_Conf) * 100).toFixed(2));
    }
    if (tiData.Loser_Mt_Rat_Conf) {
      homeConfidence = parseFloat((parseFloat(tiData.Loser_Mt_Rat_Conf) * 100).toFixed(2));
    }
    if (tiData.Winner_Rank) {
      awayRank = parseInt(tiData.Winner_Rank, 10);
    }
    if (tiData.Loser_Rank) {
      homeRank = parseInt(tiData.Loser_Rank, 10);
    }
  }
  const projections = [];
  const league = getGameLeague(game);
  const projectedPeriods = getProjectedPeriods(league);

  // Soccer games used to be 0-100 now new ones are 0-1. Might do migration and not need this
  const winPercentFactor = p.projHomeWinPercent + p.projAwayWinPercent > 2 ? 1 : 100; // prettier-ignore
  const winPercentFormat = (x: number) => x && (x * winPercentFactor).toFixed(2) + "%"; // prettier-ignore

  if (league.sport.hasTeams) {
    projections.push({
      name: "Proj Full Score",
      home: p.projHomeScore,
      away: p.projAwayScore,
    });
  }

  if (league.sport.name === "Tennis") {
    projections.push({
      name: "Official Player Ranking",
      home: homeRank,
      away: awayRank,
      noColor: true,
    });
  }

  projections.push({
    name: "Proj Win %",
    home: winPercentFormat(p.projHomeWinPercent),
    away: winPercentFormat(p.projAwayWinPercent),
  });

  if (projectedPeriods.includes("1P")) {
    projections.push({
      name: "First Period Score",
      home: p.projHomeFirstHalfScore,
      away: p.projAwayFirstHalfScore,
    });
  }

  if (projectedPeriods.includes("1H")) {
    projections.push({
      name: "First Half Score",
      home: p.projHomeFirstHalfScore,
      away: p.projAwayFirstHalfScore,
    });
  }

  if (projectedPeriods.includes("1P")) {
    projections.push({
      name: "First Period Win %",
      home: winPercentFormat(p.projHomeFirstHalfWinPercent),
      away: winPercentFormat(p.projAwayFirstHalfWinPercent),
    });
  }

  if (projectedPeriods.includes("1H")) {
    projections.push({
      name: "First Half Win %",
      home: winPercentFormat(p.projHomeFirstHalfWinPercent),
      away: winPercentFormat(p.projAwayFirstHalfWinPercent),
    });
  }

  if (league.key === "NHL") {
    projections.push({
      name: "Starting Goalie",
      home: p.projHomeKeyPlayerGrade,
      away: p.projAwayKeyPlayerGrade,
    });
  }

  if (league.key === "NHL") {
    projections.push({
      name: "Starting Goalie Grade",
      home: p.projHomeKeyPlayerGrade,
      away: p.projAwayKeyPlayerGrade,
    });
  }

  if (league.sport.hasTeams) {
    projections.push({
      name: "Home Field Advantage",
      home: !p.neutral,
      away: false,
    });
  }

  if (league.sport.name === "Soccer") {
    projections.push({
      name: "Proj Shots",
      home: p.projHomeShots,
      away: p.projAwayShots,
    });
  }

  if (league.sport.name === "Soccer") {
    projections.push({
      name: "Proj Shots on Goal",
      home: p.projHomeShotsOnGoal,
      away: p.projAwayShotsOnGoal,
    });
  }

  if (["CFB", "NFL", "CBK", "MLB"].includes(league.key)) {
    projections.push({
      name: "Team Grade",
      home: p.projHomeTeamGrade,
      away: p.projAwayTeamGrade,
    });
  }

  if (!league.sport.hasTeams) {
    projections.push({
      name: "Player Grade",
      home: p.projHomeTeamGrade,
      away: p.projAwayTeamGrade,
    });
  }

  if (["NBA", "NHL"].includes(league.key)) {
    projections.push({
      name: "Matchup Grade",
      home: p.projHomeTeamGrade,
      away: p.projAwayTeamGrade,
    });
  }

  if (league.key === "CFB") {
    projections.push({
      name: "Skill Matchup Grade",
      home: p.projHomeKeyPlayerGrade,
      away: p.projAwayKeyPlayerGrade,
    });
  }

  if (league.key === "MLB") {
    projections.push({
      name: "Starting Pitcher Grade",
      home: p.projHomeKeyPlayerGrade,
      away: p.projAwayKeyPlayerGrade,
    });
  }

  if (league.key === "NBA") {
    projections.push({
      name: "Starters Matchup Grade",
      home: p.projHomeKeyPlayerGrade,
      away: p.projAwayKeyPlayerGrade,
    });
  }

  if (league.sport.name === "Tennis") {
    projections.push({
      name: "Matchup Grade",
      home: p.projHomeKeyPlayerGrade,
      away: p.projAwayKeyPlayerGrade,
    });
  }

  if (league.sport.hasTeams && league.sport.name !== "Soccer") {
    projections.push({
      name: "Offense Grade",
      home: p.projHomeOffenseGrade,
      away: p.projAwayOffenseGrade,
    });
  }

  if (league.sport.hasTeams && league.sport.name !== "Soccer") {
    projections.push({
      name: league.key === "MLB" ? "Pitching Grade" : "Defense Grade",
      home: p.projHomeDefenseGrade,
      away: p.projAwayDefenseGrade,
    });
  }

  if (league.key === "CBK") {
    projections.push({
      name: "Win Margin Grade",
      home: p.projHomeKeyPlayerGrade,
      away: p.projAwayKeyPlayerGrade,
    });
  }

  if (league.sport.name === "Tennis") {
    projections.push({
      name: "Matchup Grade Confidence",
      home: `${homeConfidence}%`,
      away: `${awayConfidence}%`,
    });
  }

  return projections;
};

export const getPeriodFilter = (filterKey: string) => {
  const fgKeys = ["total", "spread", "moneyline"];
  return fgKeys.includes(filterKey) ? `FG${filterKey}` : filterKey;
};

export type TrendsPickType = "home" | "away" | "draw" | null;

export type CommunityPick = {
  id: string;
  betType: string;
  awayCount: number;
  homeCount: number;
  drawCount: number;
};

export const getCommunityPick = (communityPick?: CommunityPick): TrendsPickType => {
  if (communityPick === undefined) {
    return null;
  }

  const { awayCount, homeCount, drawCount } = communityPick;

  return getFavoredPickByCount({ awayCount, homeCount, drawCount });
};

export const getFadeThePublicPick = (publicPick: undefined | boolean): TrendsPickType => {
  if (publicPick === undefined) {
    return null;
  }

  if (publicPick) {
    return "away";
  }

  return "home";
};

export const getProSharpPick = (sharpPick: undefined | boolean): TrendsPickType => {
  if (sharpPick === undefined) {
    return null;
  }

  if (sharpPick) {
    return "home";
  }

  return "away";
};
