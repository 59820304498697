import React, { useMemo } from "react";
import styles from "./styles.scss";
import { Player, PlayerGameStat, PlayerStat, PlayerStatSummary, PlayerStatType } from "src/models";
import {
  StatConfig,
  getOverPropertyKeys,
  getStatPropertyForPropName,
  getSummaryStatConfig,
  sortByConfig,
} from "src/services/PlayerCardService";
import Tooltip from "src/components/Tooltip";

type PlayerCardOverSummaryProps = {
  summary: PlayerStatSummary;
  league: string;
};

function format(number: number | string): number | string {
  if (number === "--") {
    return number;
  }

  return `${Math.round(Number(number))}`;
}

function percentage(number: number | string): number | string {
  const formatedValue = format(number);

  if (number === "--") {
    return number;
  }

  return `${formatedValue}%`;
}

function indicator(value: number | string): string {
  if (value === "--") {
    return "no-data";
  }

  return Number(value) < 50 ? "below" : "above";
}

function generateData(
  player: Player,
  league: string,
  stats: PlayerStat[],
  statType: PlayerStatType,
  config: StatConfig,
  todaysStats?: PlayerGameStat
): { header: string; value: number | string }[] {
  const data = (stats ?? []).filter((stat) => {
    return stat.statType === statType && config[stat.statProperty] && stat.player?.id === player.id;
  });

  const propWithProps = [...(todaysStats?.homePlayerProps ?? []), ...(todaysStats?.awayPlayerProps ?? [])].find(
    (p) => p.player.id === player.id
  );

  const props = propWithProps ? propWithProps.props : [];
  const keys = getOverPropertyKeys(league, player.position);
  keys.forEach((property) => {
    const hasProperty = data.findIndex((stat) => stat.statProperty === property) !== -1;

    if (!hasProperty) {
      data.push({
        statProperty: property,
        statType,
        side: "all",
        statCategory: "average",
        statValue: "--",
      });
    }
  });

  return data
    .sort((a, b) => sortByConfig(a, b, config))
    .map((stat) => {
      let shortLabel = config[stat.statProperty]?.shortLabel ?? "";
      let statValue = stat.statValue;

      if (props) {
        const name = getStatPropertyForPropName(stat.statProperty, league, player.position);
        const prop = props.find((p) => p.propName === name);

        shortLabel = prop ? `${prop.bookValue ?? ""} ${shortLabel}` : shortLabel;
        statValue = (prop && prop.bookValue) || statType === "SUMMARY-SEASON" ? statValue : "--";
      }

      return {
        header: shortLabel,
        value: statValue,
      };
    });
}

function OverSummaryCell({ header, value }: { header: string; value: number | string }) {
  return (
    <div className="roto-playercard-modal-summary__list-cell">
      <div className="roto-playercard-modal-summary__list-cell__header">{header}</div>
      <div className="roto-playercard-modal-summary__list-cell__content">{percentage(value)}</div>
      <div className={`roto-playercard-modal-summary__list-cell__indicator--avg-${indicator(value)}`} />
    </div>
  );
}

export function PlayerCardOverSummary({ summary: { player, stats, todaysStats }, league }: PlayerCardOverSummaryProps) {
  const { season, today } = useMemo(() => {
    const config = getSummaryStatConfig(league, player.position) ?? { over: {} };
    const overConfig = config.over;
    const averageStats = stats.filter((s) => s.statCategory === "average");
    const averageDailyStats = averageStats.filter((a) => a.statType === "SUMMARY-DAILY");
    const averageSeasonStats = averageStats.filter((a) => a.statType === "SUMMARY-SEASON");

    return {
      today: generateData(player, league, averageDailyStats, "SUMMARY-DAILY", overConfig, todaysStats),
      season: generateData(player, league, averageSeasonStats, "SUMMARY-SEASON", overConfig),
    };
  }, [stats, player, league]);

  return (
    <div className="roto-playercard-modal-summary__container">
      <div className="roto-playercard-modal-summary__content">
        <p className="roto-playercard-modal-summary__more">(Scroll to See More Stats)</p>
        <div className="roto-playercard-modal-summary__list-cell__header">
          <span>OVER SUMMARY VS. TODAY'S ODDS</span>
          <Tooltip title="OVER SUMMARY VS. TODAY'S ODDS">
            <p className="tooltip-text">
              The percentages indicate how often the player goes over the line shown. If a line is not displayed,
              multiple sportsbooks have not released it yet. Stats include regular and postseason.
            </p>
          </Tooltip>
        </div>
        <div className="roto-playercard-modal-summary__list first">
          {today.map((item, index) => (
            <OverSummaryCell key={index} {...item} />
          ))}
        </div>
      </div>
      <div className="roto-playercard-modal-summary__content">
        <span className="roto-playercard-modal-summary__list-cell__header">
          <span>OVER SEASON SUMMARY</span>
          <Tooltip title="OVER SEASON SUMMARY">
            <p className="tooltip-text">
              The percentages indicate how often the player has gone over the closing line for that stat in a game this
              season. If a line is not displayed, multiple sportsbooks have not released it yet. Stats include regular
              and postseason.
            </p>
          </Tooltip>
        </span>
        <div className="roto-playercard-modal-summary__list">
          {season.map((item, index) => (
            <OverSummaryCell key={index} {...item} />
          ))}
        </div>
      </div>
      <style jsx global>
        {styles}
      </style>
    </div>
  );
}
