import React, { useContext, useState } from "react";
import { useMutation, useReactiveVar } from "@apollo/client";
import styles from "./styles.scss";
import { sportCountMismatch } from "../../services/ApolloService";
import {
  getProductTier,
  getTierSportCount,
  ProductTier,
  SubscriptionContext,
} from "../../services/SubscriptionService";
import SportSelector from "../SportSelector";
import { UPDATE_USER_SPORT } from "src/gql/mutations";

const SportMismatchModal = () => {
  const sportMismatch = useReactiveVar(sportCountMismatch);
  const ctx = useContext(SubscriptionContext);
  const [sportList, setSportList] = useState([]);
  const [sportChangePending, setSportChangePending] = useState(false);
  const [updateUserSport] = useMutation(UPDATE_USER_SPORT);

  if (!sportMismatch) {
    return null;
  }

  const onCancel = () => sportCountMismatch(false);

  const onAccept = async () => {
    if (!validSelection || sportChangePending) {
      return;
    }

    setSportChangePending(true);

    try {
      const sportsRes = await updateUserSport({
        variables: { sports: sportList },
      });
      const { data, errors } = sportsRes;
      if (errors || !data?.updateUserSport) {
        throw new Error("Failed to change sports.");
      }
      const { token } = data.updateUserSport;
      await ctx.setSubscriptionLevel(token);
    } catch (e) {
      console.error("[updateSports] error: ", e);
    }

    setSportChangePending(false);

    sportCountMismatch(false);
  };

  const tier = getProductTier(ctx.subscriptionLevel) as ProductTier;
  const sportCount = getTierSportCount(tier);
  const validSelection = sportList.length === sportCount;

  return (
    <div className="sport-mismatch-modal">
      <div className="modal-card">
        <div className="modal-title">
          Select {sportCount} Sport{sportCount !== 1 ? "s" : ""}
        </div>
        <SportSelector tier={tier} sportList={sportList} setSportList={setSportList} />
        <div className="modal-options">
          <div className="modal-cancel" onClick={onCancel}>
            Cancel
          </div>
          <div className="modal-accept" onClick={onAccept} style={{ opacity: validSelection ? 1 : 0.5 }}>
            {sportChangePending ? "Saving..." : "Save Changes"}
          </div>
        </div>
      </div>
      <style jsx global>
        {styles}
      </style>
    </div>
  );
};

export default SportMismatchModal;
