import "intersection-observer"; // polyfill old iPhones blowing up Bugsnag
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import React, { useCallback, useEffect, useState } from "react";
import { AppProps, NextWebVitalsMetric } from "next/app";
import { ApolloProvider } from "@apollo/client";
import { useRouter } from "next/router";
import Head from "next/head";
import { Analytics } from "@vercel/analytics/react";
import useAsyncEffect from "use-async-effect";
import moment, { Moment } from "moment-timezone";

import GAService from "src/services/GAService";
import { userSetToken, userGetToken, userRefreshToken, userGetClaims, userDeleteToken } from "src/services/UserService";
import AnalyticsService from "src/services/AnalyticsService";
import Page from "src/components/Page";
import Bugsnag from "src/lib/bugsnag";
import FallbackComponent from "src/components/FallbackComponent";
import { PortalProvider } from "src/components/Portal";
import { currentSport, isHeadless, pricingModalDeepLink, sportCountMismatch } from "src/services/ApolloService";
import { useApollo } from "src/lib/apollo-client";
import { SPORTS_MAP } from "src/constants";
import {
  SubscriptionContext,
  getProductTier,
  getSubscriptionFromToken,
  getTierSportCount,
} from "src/services/SubscriptionService";
import { BrazeServiceProvider } from "src/services/BrazeService";

const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);

export function reportWebVitals({ id, name, label, value }: NextWebVitalsMetric) {
  AnalyticsService.track(`Next.js ${label} metric`, {
    eventAction: name,
    eventValue: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    eventLabel: id, // id unique to current page load
  });
  GAService.sendEvent({
    eventCategory: `Next.js ${label} metric`,
    eventAction: name,
    eventValue: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    eventLabel: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate.
  });
}

// Track client-side page views
const handleRouteChange = (url: string) => {
  GAService.set("page", url);
  GAService.sendPageView();
  AnalyticsService.page(window.location.href);
};

// Detect change and update currentSport
const handleRouteChangeStart = (url: string) => {
  const parts = url.split("/");
  currentSport(SPORTS_MAP[parts[1]]);
};

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const apolloClient = useApollo(pageProps);

  const [init, setInit] = useState(false);
  const [signedIn, setSignedIn] = useState(false);
  const [userLocale, setUserLocale] = useState("CA");
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [pricingModalVisible, setPricingModalVisible] = useState(false);
  const [loginTabIndex, setLoginTabIndex] = useState(0);
  // The cookie refresh will happen server side, so we can immediately
  // check the token for the latest subscription value when we load the
  // app.
  const [subscriptionLevel, setSubscriptionLevel] = useState<string | null>(null);
  const [subscriptionSports, setSubscriptionSports] = useState<string[]>([]);
  const [subscriptionSportsUpdateTime, setSubscriptionSportsUpdateTime] = useState<Moment>();
  const [subscriptionUpgradeSource, setSubscriptionUpgradeSource] = useState<string | null>(null);
  const [subscriptionDefaultTierIndex, setSubscriptionDefaultTierIndex] = useState<number | null>(null);
  const [subscriptionDefaultSports, setSubscriptionDefaultSports] = useState<string[] | null>(null);

  // Initialize headless
  useEffect(() => {
    if (typeof router.query?.headless === "string") {
      isHeadless(router.query.headless);
    }
  }, [router.query?.headless]);

  const initUserData = useCallback((token: string) => {
    // Initialize user state
    userSetToken(token);
    setSignedIn(true);
    const { sub: userId, email, locale, sports, sut } = userGetClaims(token);
    const subLvl = getSubscriptionFromToken(token);
    setSubscriptionLevel(subLvl);
    setSubscriptionSports(sports);
    setUserLocale(locale ?? "CA");
    if (sut) {
      setSubscriptionSportsUpdateTime(moment.utc(sut).local());
    }
    // Detect sport mismatch
    const properSportCount = getTierSportCount(getProductTier(subLvl));
    if (properSportCount && properSportCount !== sports.length) {
      sportCountMismatch(true);
    }
    apolloClient.resetStore().catch(console.error);
    GAService.set("userId", userId);
    AnalyticsService.identify(userId, {
      email,
    });
  }, []);

  useEffect(() => {
    // Set the token from URL query param is provided
    if (typeof router.query.token === "string") {
      const url = new URL(router.asPath, window.location.origin);
      url.searchParams.delete("token");
      router.replace(url.pathname, undefined, { shallow: true });
      initUserData(router.query.token);
    }
  }, [router.query.token]);

  // Initialize user specific data
  useAsyncEffect(async () => {
    // Refresh the token
    let token = await userRefreshToken(userGetToken());
    if (token) {
      initUserData(token);
    }
    router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("routeChangeStart", handleRouteChangeStart);
    // Let SubscriptionContext consumers know that we've initialized
    setInit(true);
    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  /**
   * a function that gets called when we close the login modal in the wrapper
   * This sets the user token through the user service and rerenders the state
   * @param userInfo {object} The user information that is parsed from the login call
   * @param isLogin {boolean} Whether or not this is a sign up or login
   */
  const onSignIn = async (userInfo?: any, isLogin?: boolean, source?: string, page?: string) => {
    if (userInfo?.token) {
      const { token } = userInfo;
      userSetToken(token);
      const { sub: userId, email, locale, sports, sut: sportsUpdateTime } = userGetClaims(token);
      // It is important to do reset store here because reset store will refire all queries and we want all queries to be refired now that they have logged in.
      await apolloClient.resetStore();
      // force getInitialProps to run again on the current page
      router.push(router.asPath);

      setSignedIn(true);
      // Set subscription values to the users subscription
      setSubscriptionLevel(getSubscriptionFromToken(token));
      setSubscriptionSports(sports);
      setSubscriptionSportsUpdateTime(sportsUpdateTime ? moment(sportsUpdateTime) : undefined);
      setUserLocale(locale);
      setLoginModalVisible(false);
      setLoginTabIndex(0);
      if (isLogin) {
        AnalyticsService.identify(userId, {
          email,
        });
        AnalyticsService.track("Login", {
          source,
        });
      } else {
        AnalyticsService.alias(userId);
        AnalyticsService.identify(userId, {
          email,
          page,
        });
        AnalyticsService.track("Signup", {
          email,
          source,
          page,
          state: userLocale,
        });
      }
      GAService.set("userId", userId);
    } else {
      setLoginModalVisible(false);
      setLoginTabIndex(0);
    }
  };

  /**
   * Set the users state locally to be used throughout the app
   */
  const setLocale = (token: string) => {
    const { locale } = userGetClaims(token);
    setUserLocale(locale ?? "CA");
  };

  /**
   * This function sets the subscription properties we need to determine
   * which features to gate and when they can update their sports
   */
  const setUserSubscriptionLevel = async (token: string) => {
    // Set token
    userSetToken(token);

    const claims = userGetClaims(token);

    // Update app state
    setSubscriptionLevel(getSubscriptionFromToken(token));
    setSubscriptionSports(claims.sports);
    setSubscriptionSportsUpdateTime(claims.sut ? moment(claims.sut) : undefined);

    // Refetch everything
    await apolloClient.resetStore();

    // force getInitialProps to run again on the current page
    router.push(router.asPath);
  };

  /**
   * Determine if the users subscription level gives them access to a feature
   * @param sportKey {string} The sport key to compare against the users list of approved sports
   * @param isSharpStat {boolean} The users email
   */
  const hasSubscriptionAccess = (sportKey: string, isSharpStat?: boolean) => {
    if (
      sportKey === "XFL" ||
      (signedIn &&
        subscriptionLevel &&
        (subscriptionLevel.includes("sharp") || // if the subscription is sharp, they get it all
          (!isSharpStat && // if its a sharp stat and they aren't sharp, goodbye
            (subscriptionLevel.includes("vip") || // if it's not a sharp stat and they are vip, pro or prem lets check sports
              subscriptionLevel.includes("pro") ||
              subscriptionLevel.includes("prem")) &&
            subscriptionSports?.includes(sportKey))))
    ) {
      return true;
    }
    return false;
  };

  /**
   * This function will redirect the user to the pricing page
   * @deprecated
   * @param trackingProperties properties to attach to the tracking event
   * @param subscriptionUpgradeSource
   */
  const redirectToPricing = (track: any, source: string, defaultTierIndex?: number, defaultSports?: string[]) => {
    togglePricingModal({
      track,
      source,
      defaultTierIndex,
      defaultSports,
    });
  };

  /**
   * This function opens the pricing modal for the user to upgrade or downgrade
   */
  const togglePricingModal = ({
    track,
    source = null,
    defaultTierIndex = null,
    defaultSports = null,
    deepLink,
  }: {
    track?: any;
    source?: string | null;
    defaultTierIndex?: number | null;
    defaultSports?: string[] | null;
    deepLink?: string;
  } = {}) => {
    if (deepLink) {
      pricingModalDeepLink(deepLink);
    }
    setPricingModalVisible(!pricingModalVisible);
    setSubscriptionUpgradeSource(source);
    setSubscriptionDefaultTierIndex(defaultTierIndex);
    setSubscriptionDefaultSports(defaultSports);
    AnalyticsService.track("Click Upgrade Modal", {
      page: window.location.href,
      sport: currentSport()?.name,
      source,
      ...track,
    });
  };

  /**
   * Set the which login modal tab (Login/Register) we show when opening the modal
   */
  const setTabIndex = (index: number) => setLoginTabIndex(index);

  const openLoginModal = (index: number) => {
    setLoginModalVisible(true);
    setLoginTabIndex(index);
  };

  /**
   * Log the user out
   * This removes the token from local storage
   * Then it clears the client store
   * Finally it calls set state and wipes the current state
   */
  const logoutUser = async () => {
    userDeleteToken();
    // It is important to do clear store here because reset store will refire all queries and the rerender from setState will also refire queries so they will conflict and throw errors.  Clear store is identical to reset store but queries are not refired.
    setSignedIn(false);
    setSubscriptionLevel(null);
    setSubscriptionSports([]);
    setSubscriptionSportsUpdateTime(undefined);
    apolloClient.resetStore();
    // force getInitialProps to run again on the current page
    router.push(router.asPath);
    AnalyticsService.reset();
    window.localStorage.clear();
  };

  return (
    <ErrorBoundary FallbackComponent={FallbackComponent}>
      <ApolloProvider client={apolloClient}>
        <BrazeServiceProvider signedIn={signedIn}>
          <SubscriptionContext.Provider
            value={{
              init,
              signedIn,
              onSignIn,
              userLocale,
              setLocale,
              setSubscriptionLevel: setUserSubscriptionLevel,
              hasSubscriptionAccess,
              subscriptionLevel,
              subscriptionSports,
              subscriptionSportsUpdateTime,
              subscriptionUpgradeSource,
              subscriptionDefaultTierIndex,
              subscriptionDefaultSports,
              pricingModalVisible,
              redirectToPricing,
              togglePricingModal,
              loginModalVisible,
              loginTabIndex,
              setLoginTabIndex: setTabIndex,
              openLoginModal,
              logoutUser,
            }}
          >
            <PortalProvider>
              <Page {...pageProps}>
                <Head>
                  <meta charSet="utf-8" />
                  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                  <meta name="theme-color" content="#00214d" />
                  <meta name="format-detection" content="telephone=no" />
                </Head>
                <Component {...pageProps} />
                <Analytics />
              </Page>
            </PortalProvider>
          </SubscriptionContext.Provider>
        </BrazeServiceProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export default MyApp;
