import { useMemo } from "react";
import nookies from "nookies";
import { ApolloClient, InMemoryCache, HttpLink, from, split, NormalizedCacheObject } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from "@apollo/client/link/context";
import merge from "deepmerge";
import { isEqual } from "lodash";
import { relayStylePagination } from "@apollo/client/utilities";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import type { GetStaticPropsResult } from "next";

import apolloBetSettings from "src/services/ApolloService";
import { LOCAL_STORAGE_TOKEN_KEY, userDeleteToken } from "src/services/UserService";
import { IS_CLIENT, IS_SERVER } from "src/constants";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient: ApolloClient<NormalizedCacheObject>;

function createApolloClient() {
  const apolloSettings = apolloBetSettings();
  const getToken = () => nookies.get({})[LOCAL_STORAGE_TOKEN_KEY];
  const getPreviewRef = () => nookies.get({})["io.prismic.preview"]; // eslint-disable-line

  const authLink = setContext((_, { headers = {} }) => {
    const token = getToken();
    const previewRef = getPreviewRef();
    return {
      headers: {
        ...headers,
        "Prismic-ref": previewRef || "", // This allows prismic preview functionality to work on our own server
        "x-token": token || "",
      },
    };
  });

  const retryLink = new RetryLink({
    attempts: {
      max: 2,
    },
  });

  const customErrorHandler = (errorResp: any) => {
    const { graphQLErrors, networkError } = errorResp;
    if (graphQLErrors) {
      console.log(`[GraphQL error]: ${JSON.stringify(graphQLErrors)}`);
      if (graphQLErrors[0].extensions.code === "UNAUTHENTICATED") {
        // Reload the page because we can't easily access the app state from here
        userDeleteToken();
        window.location.reload();
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  };
  const errorLink = onError((errorResp) => customErrorHandler(errorResp));

  const cache = new InMemoryCache({
    typePolicies: {
      PrismicSurvey: {
        keyFields: ["_meta", ["id"]],
      },
      Event: {
        keyFields: ["slugId"],
      },
      Rating: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
      Tournament: {
        keyFields: ["slugId"],
      },
      PrismicNews_basic: {
        keyFields: ["_meta", ["uid"]],
      },
      PrismicBetting_guide: {
        keyFields: ["_meta", ["uid"]],
      },
      PrismicLeague_config: {
        keyFields: ["_meta", ["id"]],
      },
      PrismicAuthor: {
        keyFields: ["_meta", ["id"]],
      },
      PrismicGames_table: {
        keyFields: ["_meta", ["uid"]],
      },
      PrismicGame: {
        keyFields: ["_meta", ["uid"]],
      },
      Query: {
        fields: {
          prismicAllNews_basics: relayStylePagination(["where"]), // InfiniteArticles feed
          event(_, { args, toReference }) {
            return toReference({
              __typename: "Event",
              slugId: args?.slugId,
            });
          },
          prismicNews_basic(_, { args, toReference }) {
            return toReference({
              __typename: "PrismicNews_basic",
              _meta: {
                uid: args?.uid,
              },
            });
          },
          prismicBetting_guide(_, { args, toReference }) {
            return toReference({
              __typename: "PrismicBetting_guide",
              _meta: {
                uid: args?.uid,
              },
            });
          },
          tournament(_, { args, toReference }) {
            return toReference({
              __typename: "Tournament",
              slugId: args?.slugId,
            });
          },
        },
      },
      User: {
        fields: {
          books: {
            merge: false,
          },
        },
      },
    },
  });

  const httpLink = new HttpLink({
    uri: apolloSettings.http, // Server URL (must be absolute)
    credentials: "same-origin", // Additional fetch() options like `credentials` or `headers`
    fetch, // Use fetch() polyfill on the server
  });

  const batchHttpLink = new BatchHttpLink({
    uri: apolloSettings.http, // Server URL (must be absolute)
    credentials: "same-origin", // Additional fetch() options like `credentials` or `headers`
    fetch, // Use fetch() polyfill on the server
    batchInterval: 10, // ms (default: 10)
    batchMax: 10, // (default: 10)
    batchDebounce: true, // delay another 10ms (batchInterval) if another request is queued (default: false)
  });
  const mergedHttpLink = split(
    (operation) => operation.getContext().debatch === true,
    httpLink, // if the test is true -- debatch
    batchHttpLink // otherwise, batching is fine
  );
  return new ApolloClient({
    name: "web",
    version: process.env.NEXT_PUBLIC_VERCEL_URL || "development",
    connectToDevTools: IS_CLIENT,
    ssrMode: IS_SERVER, // Disables forceFetch on the server (so queries are only run once)
    ssrForceFetchDelay: 100,
    link: from([retryLink, authLink, errorLink, mergedHttpLink]),
    cache,
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") {
    return _apolloClient;
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
}

export function addApolloState<TProps>(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: GetStaticPropsResult<TProps>
) {
  //@ts-expect-error this is stupid, GetStaticPropsResult has props, but is a union so you can't access it
  const props: Record<string, unknown> = pageProps?.props;
  if (props) {
    props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }
  return pageProps;
}

export function useApollo(pageProps: any) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
