import React, { Component } from "react";
import { Button, Form, Input, Row, Col, Alert } from "reactstrap";
import { Mutation } from "@apollo/client/react/components";
import { ApolloError } from "@apollo/client";
import { getCxd } from "../../../services/CellxpertService";

import { CREATE_USER_MUTATION } from "../../../gql/mutations";
import { SubscriptionContext } from "src/services/SubscriptionService";
import AnalyticsService from "src/services/AnalyticsService";

export interface RegisterFromSocialState {
  email: string;
  password: string;
  displayName: string;
  submitting: boolean;
  dropdownOpen: boolean;
  attributionValue: string;
  formErrorList: string[];
}

export default class RegisterFromSocial extends Component<{}, RegisterFromSocialState> {
  static contextType = SubscriptionContext;
  constructor(props: any) {
    super(props);
    this.state = {
      email: "",
      password: "",
      displayName: "",
      submitting: false,
      dropdownOpen: false,
      attributionValue: "Select One",
      formErrorList: [],
    };

    this.toggle = this.toggle.bind(this);
    this.onClick = this.onClick.bind(this);
    this._validate = this._validate.bind(this);
  }

  toggle() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  onClick(title: string) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
      attributionValue: title,
    });
  }

  _validate(email: string, password: string, username: string, socialToken: string) {
    if (socialToken) {
      return [];
    }

    const errorMessageList: string[] = [];
    const usernameRE = /^[A-Z0-9_]{4,16}$/;
    if (username && (/\s/g.test(username.toUpperCase()) || !usernameRE.test(username.toUpperCase()))) {
      errorMessageList.push("Username must be letters, numbers, underscores and 4-16 characters");
    }
    if (!password || password.length < 6) {
      errorMessageList.push("Password must be at least six characters.");
    }

    if (!email || email === "") {
      errorMessageList.push("Please enter a valid email address.");
    } else if (email) {
      const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/;
      if (!re.test(email.toUpperCase())) {
        errorMessageList.push("Please enter a valid email address.");
      }
    }

    return errorMessageList;
  }

  _renderApolloErrorMessages(error: ApolloError) {
    const errorMessageList: string[] = [];
    const errors = error.graphQLErrors;

    for (let i = 0; i < errors.length; i++) {
      errorMessageList.push(errors[i].message);
    }

    // Alerting
    return this._renderErrorMessages(errorMessageList);
  }

  _renderErrorMessages(errors: Array<string>) {
    const errorMessageList: any[] = [];

    for (let i = 0; i < errors.length; i++) {
      errorMessageList.push(<div key={i}>{errors[i]}</div>);
    }

    // Alerting
    return <Alert color="danger">{errorMessageList}</Alert>;
  }

  render() {
    const { email, password, displayName, attributionValue, formErrorList } = this.state;

    return (
      <Mutation mutation={CREATE_USER_MUTATION}>
        {(createUser: any, { loading, error }: any) => (
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              const errorList = this._validate(email, password, displayName, "");
              if (errorList.length === 0) {
                try {
                  const res = await createUser({
                    variables: {
                      cxd: getCxd(),
                      email,
                      password,
                      displayName,
                      attributionValue,
                    },
                  });
                  await this.context.onSignIn(res.data.createUser, false, "social", window.location.href);
                } catch {
                  AnalyticsService.track("Signup Failure", {
                    source: "social",
                  });
                }
              } else {
                this.setState({ formErrorList: errorList });
              }
            }}
          >
            {error ? (
              <Row>
                <Col sm={12}>{this._renderApolloErrorMessages(error)}</Col>
              </Row>
            ) : null}
            {formErrorList && formErrorList.length > 0 ? (
              <Row>
                <Col sm={12}>{this._renderErrorMessages(formErrorList)}</Col>
              </Row>
            ) : null}
            <Row>
              <Col />
            </Row>
            <div className="rotoql-social-login-modal__input-container">
              <Input
                className="rotoql-social-login-modal__input"
                type="text"
                name="displayName"
                value={displayName}
                placeholder="Username"
                onChange={(e) => {
                  this.setState({
                    displayName: e.target.value,
                    formErrorList: [],
                  });
                }}
              />
            </div>
            <div className="rotoql-social-login-modal__input-container">
              <Input
                className="rotoql-social-login-modal__input"
                type="email"
                name="email"
                value={email}
                placeholder="Email Address"
                onChange={(e) => {
                  this.setState({
                    email: e.target.value,
                    formErrorList: [],
                  });
                }}
              />
            </div>
            <div className="rotoql-social-login-modal__input-container">
              <Input
                className="rotoql-social-login-modal__input"
                type="password"
                name="password"
                value={password}
                placeholder="Password"
                onChange={(e) => {
                  this.setState({
                    password: e.target.value,
                    formErrorList: [],
                  });
                }}
              />
            </div>
            <div className="rotoql-social-login-modal__button-container">
              <Button
                color="primary"
                disabled={loading}
                type="submit"
                className="rotoql-social-login-modal__login-button"
              >
                Get Free Access
              </Button>
            </div>
          </Form>
        )}
      </Mutation>
    );
  }
}
