import React from "react";
import { SPORTS_LIST } from "@rotoql/common-services";
import { Player, PlayerStat } from "src/models";
import {
  NbaPlayerPropKeys as NbaPlayerPropKeys,
  NbaPlayerStatKeys,
  getNbaStatsMap,
  getNbaSummaryConfig,
  NflPlayerPropKeys,
  NflPlayerStatKeys,
  getNflStatsMap,
  getNflSummaryConfig,
  getNbaOverPropertyKeys,
  getNflOverPropertyKeys,
  getNbaStandardPropertyKeys,
  getNflStandardPropertyKeys,
} from ".";

export const PlayerCardContext = React.createContext<{
  openModal(_player: Player, league?: string): void;
}>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openModal: (_player: Player) => {},
});

type StatConfigValue = {
  shortLabel: string;
  longLabel: string;
  sortOrder: number;
};

export type PlayerStatKeys = string | NbaPlayerStatKeys | NflPlayerStatKeys;
export type PlayerPropKeys = NbaPlayerPropKeys | NflPlayerPropKeys;

export type StatConfig = { [key: string]: StatConfigValue };

export type SummaryStatConfig = { all: StatConfig; default: StatConfig; over: StatConfig };

export function getSummaryStatConfig(league: string, position?: string): SummaryStatConfig {
  let config = null;

  switch (league) {
    case SPORTS_LIST.nba.name:
      config = getNbaSummaryConfig();
      break;
    case SPORTS_LIST.nfl.name:
      config = getNflSummaryConfig(position ?? "");
      break;
    default:
      config = { all: {}, default: {}, over: {} };
  }
  return config;
}

export function sortByConfig(a: PlayerStat, b: PlayerStat, config: StatConfig) {
  const aConfig = config[a.statProperty];
  const bConfig = config[b.statProperty];
  const aSortOrder = aConfig?.sortOrder ?? 0;
  const bSortOrder = bConfig?.sortOrder ?? 0;

  return aSortOrder - bSortOrder;
}

export function getStatPropertyForPropName(
  key: PlayerStatKeys,
  league: string,
  position?: string
): PlayerPropKeys | null {
  let propKey = null;

  switch (league) {
    case SPORTS_LIST.nba.name:
      propKey = getNbaStatsMap().get(key as NbaPlayerStatKeys) ?? null;
      break;
    case SPORTS_LIST.nfl.name:
      propKey = getNflStatsMap(position ?? "").get(key as NflPlayerStatKeys) ?? null;
      break;
  }

  return propKey;
}

export function getStandardPropertyKeys(league: string, position?: string): string[] {
  let keys: string[] = [];

  switch (league) {
    case SPORTS_LIST.nba.name:
      keys = getNbaStandardPropertyKeys();
      break;
    case SPORTS_LIST.nfl.name:
      keys = getNflStandardPropertyKeys(position ?? "");
      break;
  }

  return keys;
}

export function getOverPropertyKeys(league: string, position?: string): string[] {
  let keys: string[] = [];

  switch (league) {
    case SPORTS_LIST.nba.name:
      keys = getNbaOverPropertyKeys();
      break;
    case SPORTS_LIST.nfl.name:
      keys = getNflOverPropertyKeys(position ?? "");
      break;
  }

  return keys;
}

export function getSplitColumnLayout(league: string, position?: string): string {
  let keys: string[] = getStandardPropertyKeys(league, position);

  return `${keys.length ?? 3}col`;
}
