import React from "react";
import { Row, Col } from "reactstrap";
import { renderRichText } from "src/services/PrismicService";
import { VariationOverride } from "src/services/VariationService";
import GLOBAL_DATA from "../../../global-data.json";

import styles from "./styles.scss";
const Logo = require("public/BetQL_logo_AUD_4.png?sizes[]=120,sizes[]=240,sizes[]=360");
const AppStore = require("public/app-store.png?sizes[]=128,sizes[]=256,sizes[]=384");
const GooglePlay = require("public/google-play.png?sizes[]=128,sizes[]=256,sizes[]=384");

export interface FooterLink {
  text: string;
  link: string;
}

export interface FooterSectionConfig {
  title: string;
  footerLinkList: FooterLink[];
  xs: number;
  sm: number;
}

export interface FooterConfig {
  iosAppLink: string;
  androidAppLink: string;
  footerSectionConfigList: FooterSectionConfig[];
  privacyLink: string;
  betqlTermsLink: string;
  termsLink: string;
  copyrightLink: string;
  dontSellMyInfoLink: string;
}

export interface WrapperFooterProps {
  footerConfig: FooterConfig;
}

/**
 * Build out the footer section according to the configuration object passed in. The job of this method
 * is to create the footer section with links/text with right container size for boostrap arrangement.
 *
 * @param footerSectionConfig Configuration
 */
const _buildFooterSection = (footerSectionConfig: FooterSectionConfig, key: number) => {
  return (
    <Col key={key} xs={6} sm={4} md={2} className="wrapper-footer__container">
      <div>
        <span className="wrapper-footer__title-text">{footerSectionConfig.title}</span>
        {footerSectionConfig.footerLinkList.map((link: any, index: number) => (
          <a key={index} href={link.link} className="wrapper-footer__link" target="_blank" rel="noopener">
            {link.text}
          </a>
        ))}
      </div>
    </Col>
  );
};

const Footer = (props: WrapperFooterProps) => {
  const { footerConfig } = props;

  const { links, additional_informations } = GLOBAL_DATA.prismicAllFooters.edges[0].node;

  return (
    <footer className="wrapper-footer">
      <div className="wrapper-footer__app-links-container">
        <span className="wrapper-footer__title-text">DOWNLOAD APP</span>
        <div className="wrapper-footer__app-links">
          <a href={footerConfig.iosAppLink} className="wrapper-footer__app-link">
            <img
              data-src={AppStore.src}
              data-srcset={AppStore.srcSet}
              className="lazyload"
              style={{ height: 48, maxWidth: 140 }}
              alt="App Store Image"
            />
          </a>
          <a href={footerConfig.androidAppLink} className="wrapper-footer__app-link">
            <img
              data-src={GooglePlay.src}
              data-srcset={GooglePlay.srcSet}
              className="lazyload"
              style={{ height: 48, maxWidth: 140 }}
              alt="Google Play Store Image"
            />
          </a>
        </div>
      </div>
      <Row className="wrapper-footer__top">
        {footerConfig.footerSectionConfigList.map((footerSectionConfig: FooterSectionConfig, index: number) => {
          return _buildFooterSection(footerSectionConfig, index);
        })}
      </Row>
      <div className="wrapper-footer__bottom">
        <a href="#">
          <img
            data-src={Logo.src}
            data-srcset={Logo.srcSet}
            className="lazyload"
            style={{ width: 120, marginBottom: 15 }}
            alt="Logo Image"
          />
        </a>

        <p className="wrapper-footer__text">
          © {new Date().getFullYear()} Audacy Inc., All Rights Reserved. Part of{" "}
          <a className="wrapper-footer__text-link" href="https://www.audacy.com/">
            Audacy.com
          </a>{" "}
          Sports.{" "}
        </p>

        <p className="wrapper-footer__text">
          {links?.map(({ name, url }) => (
            <span key={name}>
              <a className="wrapper-footer__text-link" href={url}>
                {name}
              </a>
              {" | "}
            </span>
          ))}
          {/* OneTrust Cookies Settings button start */}
          <span id="ot-sdk-btn" className="ot-sdk-show-settings">
            Cookies Settings
          </span>
        </p>

        {additional_informations?.map(({ text }, index) => (
          <div className="wrapper-footer__text" key={`footer-text-${index}`}>
            {renderRichText(text)}
          </div>
        ))}

        <VariationOverride className="wrapper-footer__text" style={{ marginBottom: 16 }} />
      </div>
      <style jsx>{styles}</style>
    </footer>
  );
};

Footer.defaultProps = {
  footerConfig: {
    iosAppLink: "https://itunes.apple.com/app/apple-store/id1334825645?pt=118678143&ct=BetQLWebapp&mt=8",
    androidAppLink:
      "https://play.google.com/store/apps/details?id=com.rotoql.betql&referrer=utm_source%3DBetQL%2520Webapp",
    footerSectionConfigList: [
      {
        title: "PRODUCT",
        footerLinkList: [
          {
            text: "RotoQL",
            link: "https://www.rotoql.com/product/",
          },
          {
            text: "RotoQL Mobile App",
            link: "https://www.rotoql.com/mobile/",
          },
          {
            text: "BetQL B2B",
            link: "/contact-us-b2b",
          },
          {
            text: "BetQL Affiliate Program",
            link: "/become-betql-affiliate",
          },
        ],
      },
      {
        title: "SPORTS BETTING",
        footerLinkList: [
          {
            text: "Lines and Odds",
            link: "/lines-odds-sitemap",
          },
          {
            text: "Public Betting Lines",
            link: "/public-betting-sitemap",
          },
          {
            text: "Sharp Picks",
            link: "/sharp-picks-sitemap",
          },
          {
            text: "Line Movement",
            link: "/line-movement-sitemap",
          },
        ],
      },
      {
        title: "HELP",
        footerLinkList: [
          {
            text: "Getting Started",
            link: "https://betql.zendesk.com/hc/en-us",
          },
          {
            text: "Feedback",
            link: "mailto:support@betql.co",
          },
          {
            text: "FAQ",
            link: "https://betql.zendesk.com/hc/en-us/categories/360003861073-FAQs",
          },
          {
            text: "Careers",
            link: "https://careers-audacy.icims.com/jobs/",
          },
          {
            text: "Contact",
            link: "mailto:support@betql.co",
          },
        ],
      },
      {
        title: "SOCIAL",
        footerLinkList: [
          {
            text: "Twitter",
            link: "https://twitter.com/betqlapp",
          },
          {
            text: "Facebook",
            link: "https://www.facebook.com/betql/",
          },
          {
            text: "LinkedIn",
            link: "https://www.linkedin.com/company/rotoql",
          },
          {
            text: "Instagram",
            link: "https://www.instagram.com/betqlapp/",
          },
        ],
      },
      {
        title: "Popular Pages",
        footerLinkList: [
          {
            text: "NFL Picks",
            link: "/nfl/sharp-picks",
          },
          {
            text: "NFL Odds",
            link: "/nfl/odds",
          },
          {
            text: "College Football Picks",
            link: "/ncaaf/sharp-picks",
          },
          {
            text: "College Football Odds",
            link: "/ncaaf/odds",
          },
        ],
      },
      {
        title: "Popular Pages",
        footerLinkList: [
          {
            text: "NBA Picks",
            link: "/nba/sharp-picks",
          },
          {
            text: "NBA Odds",
            link: "/nba/odds",
          },
          {
            text: "MLB Picks",
            link: "/mlb/sharp-picks",
          },
          {
            text: "MLB Odds",
            link: "/mlb/odds",
          },
        ],
      },
    ],
    privacyLink: "https://www.audacyinc.com/privacy-policy",
    betqlTermsLink: "https://betql.co/terms-and-conditions",
    termsLink: "https://audacyinc.com/terms-and-conditions/",
    copyrightLink:
      "http://www.entercom.com/wp-content/uploads/2016/01/Copyright_Notice2.pdf?_ga=2.196005480.572862595.1604587371-952221172.1603319789",
    dontSellMyInfoLink:
      "https://privacyportal.onetrust.com/webform/ef763e80-dba8-4bac-a6d7-97d736fd81e5/09ac4e3a-41ca-488c-b2b5-ba56d5df39cd",
  },
};

export default Footer;
