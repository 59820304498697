import React, { Component } from "react";
import { Button, Form, Label, Input, Row, Col, Alert } from "reactstrap";
import { Mutation } from "@apollo/client/react/components";
import { ApolloError } from "@apollo/client";

import { RESET_PASSWORD } from "../../../gql/mutations";
import styles from "./styles.scss";

export interface ResetPWProps {
  resetComplete(): void;
}

export interface ResetPWState {
  token: string;
  password: string;
  passwordConfirm: string;
  errors: string[];
}

export default class ResetPW extends Component<ResetPWProps, ResetPWState> {
  constructor(props: any) {
    super(props);
    this.state = { token: "", password: "", passwordConfirm: "", errors: [] };
  }

  renderErrorMessages(error: ApolloError) {
    if (!error) {
      return null;
    }
    const errorMessageList: any = [];
    const errors: any = error.graphQLErrors;
    if (!errors) {
      return null;
    }
    for (let i = 0; i < errors.length; i++) {
      const thisError = errors[i];
      errorMessageList.push(<div key={i}>{thisError.message}</div>);
    }
    return <Alert color="danger">{errorMessageList}</Alert>;
  }

  render() {
    const { resetComplete = () => {} } = this.props;
    const { token, password, passwordConfirm } = this.state;
    return (
      <Mutation mutation={RESET_PASSWORD}>
        {(mutate: any, { loading, error }: any) => (
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              await mutate({
                variables: {
                  token,
                  password,
                },
              });
              await resetComplete();
            }}
          >
            <style jsx>{styles}</style>
            {error ? (
              <Row>
                <Col md={12}>{this.renderErrorMessages(error)}</Col>
              </Row>
            ) : null}
            <div className="rotoql-reset__input-container">
              <Label for="email" className="rotoql-reset__label">
                Reset Token
              </Label>
              <Input
                id="token"
                name="token"
                value={token}
                placeholder="Enter reset token"
                onChange={(e) => {
                  this.setState({
                    token: e.target.value,
                    errors: [],
                  });
                }}
                className="rotoql-reset__input"
              />
            </div>
            <div className="rotoql-reset__input-container">
              <Label for="password" className="rotoql-reset__label">
                Password
              </Label>
              <Input
                className="rotoql-reset__input"
                type="password"
                name="password"
                value={password}
                placeholder="Enter password"
                onChange={(e) => {
                  this.setState({
                    password: e.target.value,
                    errors: [],
                  });
                }}
              />
            </div>
            <div className="rotoql-reset__input-container">
              <Label for="passwordConfirm" className="rotoql-reset__label">
                Confirm Password
              </Label>
              <Input
                className="rotoql-reset__input"
                type="password"
                name="password"
                value={passwordConfirm}
                placeholder="Re-enter password"
                onChange={(e) => {
                  this.setState({
                    passwordConfirm: e.target.value,
                    errors: [],
                  });
                }}
              />
            </div>
            <div className="rotoql-reset__button-container">
              <Button
                color="primary"
                disabled={loading || password === "" || password !== passwordConfirm}
                className="rotoql-reset__request-reset-button"
              >
                Reset Password
              </Button>
            </div>
          </Form>
        )}
      </Mutation>
    );
  }
}
