import React, { ReactNode, useContext, useEffect, useRef, useState } from "react";
import styles from "./styles.scss";
import { WEB_HEADER_SPORTS_LIST, WebSportItem, DEFAULT_SPORT, Sport, STATE_MAP } from "../../constants";
import { useReactiveVar } from "@apollo/client";
import Link from "next/link";
import { currentSport, navBarPosition, navBarLeague } from "src/services/ApolloService";
import Icon from "@mdi/react";
import { mdiCalendarText, mdiCash, mdiChevronDown, mdiMenu, mdiHome } from "@mdi/js";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouter } from "next/router";
import { SubscriptionContext } from "src/services/SubscriptionService";
import { useDisplayName } from "src/services/UserService";
import MobileDropdownControls from "src/components/GamesTable/ModifyStatsDropdown/MobileDropdownControls";
import BetQLLogo from "src/components/BetQLLogo";
import FocusedTextLink from "src/components/FocusedTextLink";
import Spinner from "src/components/Spinner";

// @ts-ignore
import GLOBAL_DATA from "../../../global-data.json";

const HomeIcon = require("public/icon/home.png");

const CUSTOM_PAGES: any = {
  "/": "Home",
  "/pricing/monthly": "Monthly Pricing",
  "/pricing/annual": "Annual Pricing",
  "/sportsbook-offers": "Offers",
  "/boosts": "Boosts",
  "/news": "News",
  "/newsletter": "Newsletter",
  "/free-picks": "Free Picks",
};

const useNavLinks = () => {
  const configs: any[] = GLOBAL_DATA.prismicAllLeague_configs.edges.map((x: any) => x.node) || [];
  const ctx = useContext(SubscriptionContext);
  let navLinksByLeagueUrl: { [key: string]: any } = {};
  configs.forEach((x) => {
    const links = x.nav_links.reduce((arr: any[], link: any) => {
      const legalized = STATE_MAP[ctx.userLocale]?.legal;
      if (!legalized && ["Compare Odds", "Future Odds", "Player Prop Odds"].includes(link.text)) {
        return arr;
      }
      arr.push(link);
      return arr;
    }, []);
    navLinksByLeagueUrl[x.league] = links;
  });

  const getNavLinksByLeague = (league: WebSportItem | null): any[] => {
    return navLinksByLeagueUrl[league?.url || ""]?.slice(0) || [];
  };

  return { getNavLinksByLeague };
};

const getSportLeagues = (sport: Sport | null) => WEB_HEADER_SPORTS_LIST.filter((x) => x.sport === sport);

const NUM_LEAGUES_TO_SHOW = 5;
const leagues = [...new Set(WEB_HEADER_SPORTS_LIST.map((e) => (e.sport.group ? e.sport : e)))].slice(
  0,
  NUM_LEAGUES_TO_SHOW
);

const leagueAlreadyShown = leagues.map((e) => e.name);
const otherLeagues = WEB_HEADER_SPORTS_LIST.filter(
  (e) => !leagueAlreadyShown.includes(e.sport.group ? e.sport.name : e.name)
);

interface CollapsibleProps {
  open: boolean;
  children: any;
  className?: string;
}

// A collapsible div
const Collapsible = ({ open, children, className }: CollapsibleProps) => {
  const ref = useRef<any>();
  const didMount = useRef<any>();

  // Initially closed on page load
  const [maxHeight, setMaxHeight] = useState<any>("0px");

  // Always open to proper height
  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    async function run() {
      // Animate
      await ref.current?.animate?.(
        [
          { maxHeight: open ? "0" : ref.current?.scrollHeight + "px" },
          { maxHeight: open ? ref.current?.scrollHeight + "px" : "0" },
        ],
        { duration: 200, easing: "ease-in-out" }
      ).finished;

      // Final state
      if (ref.current) {
        setMaxHeight(open ? "none" : "0px");
      }
    }
    run();
  }, [open]);

  return (
    <div className={`collapsible ${className}`} ref={ref} style={{ overflowY: "scroll", maxHeight }}>
      {children}
    </div>
  );
};

type MobileMenuProps = {
  position: "top" | "bottom";
};

// Mobile Sport Picker Dropdown Menu
const MobileMenu = ({ position }: MobileMenuProps) => {
  const navPosition = useReactiveVar(navBarPosition);
  const ctx = useContext(SubscriptionContext);
  return (
    <div className={`sport-picker-dropdown ${position}`}>
      <Collapsible open={navPosition === position}>
        {!ctx.signedIn && <MobileMenuAction onClick={() => ctx.openLoginModal(0)} text="Log In" />}
        {!ctx.signedIn && <MobileMenuAction onClick={() => ctx.openLoginModal(1)} text="Sign Up" />}
        <MobileMenuHome />
        {leagues.map((item: any, idx: number) => {
          if (item?.group) {
            return (
              <MobileMenuOtherLeagues
                key={idx}
                icon={item.icon}
                text={item.name}
                showPages={position === "top"}
                items={getSportLeagues(item)}
              />
            );
          }
          return <MobileMenuLeagueItem key={idx} league={item} showPages={position === "top"} />;
        })}

        <MobileMenuOtherLeagues text={"Other Sports"} showPages={position === "top"} items={otherLeagues} />

        <MobileMenuPageItem href="/account" text="Account" />
        <MobileMenuPageItem href="/pricing/monthly" text="Pricing" />
        <MobileMenuPageItem href="/free-picks" text="Free Picks" />
        <MobileMenuPageItem href="/newsletter" text="Newsletter" />
        <MobileMenuPageItem href="/sportsbook-offers" text="Sportsbook Offers" />
        <MobileMenuPageItem href="/casino-offers" text="Casino Offers" />
        {STATE_MAP[ctx.userLocale]?.legal ? <MobileMenuPageItem href="/boosts" text="Odds Boosts" /> : null}
        {ctx.signedIn && <MobileMenuAction onClick={() => ctx.logoutUser()} text="Log Out" />}
      </Collapsible>
    </div>
  );
};

// Mobile Sport Picker Sub-dropdown menu for non-season sports
const MobileMenuOtherLeagues = ({
  items,
  showPages = true,
  text,
  icon,
}: {
  items: WebSportItem[];
  showPages?: boolean;
  text: string;
  icon?: string;
}) => {
  const router = useRouter();
  const [open, setOpen] = useState(false);

  // On navigate, close menu
  useEffect(() => {
    setOpen(false);
  }, [router?.asPath]);

  return (
    <div className="nav-item">
      <div className="nav-link" onClick={() => setOpen(!open)}>
        {Boolean(icon) && <img className="nav-link-icon" src={icon} alt="icon" />}
        <div className="nav-link-text">{text}</div>
      </div>
      <Collapsible open={open} className="sub-dropdown">
        {items.map((leagueItem: any, idx2: number) => (
          <MobileMenuLeagueItem key={idx2} league={leagueItem} showPages={showPages} />
        ))}
      </Collapsible>
    </div>
  );
};

// Mobile League Picker Sub-dropdown menu for pages
const MobileMenuLeagueItem = ({ league, showPages = true }: { league: WebSportItem; showPages?: boolean }) => {
  const router = useRouter();
  const navLeague = useReactiveVar(navBarLeague);
  const { getNavLinksByLeague } = useNavLinks();

  // On navigate, close menu
  useEffect(() => {
    navBarLeague(null);
  }, [router?.asPath]);

  // Client side redirect for golf
  const href = league.url === "golf" ? "/golf/news" : `/${league.url}`;

  return (
    <div className="nav-item">
      {!showPages && (
        <Link href={href} legacyBehavior>
          <a className="nav-link">
            <img className="nav-link-icon" src={league.icon} alt={league?.name} />
            <div className="nav-link-text">{league.name}</div>
          </a>
        </Link>
      )}
      {showPages && (
        <div className="nav-link" onClick={() => navBarLeague(navLeague === league.key ? null : league.key)}>
          <img className="nav-link-icon" src={league.icon} alt={league?.name} />
          <div className="nav-link-text">{league.name}</div>
        </div>
      )}
      {showPages && (
        <Collapsible open={navLeague === league.key} className="sub-dropdown">
          {getNavLinksByLeague(league).map((link, idx2) => (
            <MobileMenuPageItem key={idx2} href={link.href} text={link.text} />
          ))}
        </Collapsible>
      )}
    </div>
  );
};

const MobileMenuPageItem = ({ href, text, icon }: { href: string; text: string; icon?: string }) => {
  if (!href || !text) {
    return null;
  }

  return (
    <div className="nav-item">
      <Link href={href} legacyBehavior>
        <a className="nav-link">
          {Boolean(icon) && <img className="nav-link-icon" src={icon} alt="icon" />}
          <div className="nav-link-text">{text}</div>
        </a>
      </Link>
    </div>
  );
};

const MobileMenuHome = () => {
  return (
    <div className="nav-item">
      <Link href="/" legacyBehavior>
        <a className="nav-link">
          <img className="nav-link-icon" src={HomeIcon} alt="Home" />
          <div className="nav-link-text">Home</div>
        </a>
      </Link>
    </div>
  );
};

const MobileMenuAction = ({ onClick, text }: { onClick: any; text: string }) => {
  return (
    <div className="nav-item">
      <div
        className="nav-link"
        onClick={() => {
          navBarPosition(null);
          onClick();
        }}
      >
        <div className="nav-link-text">{text}</div>
      </div>
    </div>
  );
};

const NavBarMobile = () => {
  const router = useRouter();
  const league = useReactiveVar(currentSport);
  const navPosition = useReactiveVar(navBarPosition);
  const [title, setTitle] = useState("");
  const [isHomePage, setIsHomePage] = useState(false);
  const [isGamesPage, setIsGamesPage] = useState(false);
  const [isOffersPage, setIsOffersPage] = useState(false);
  const [isGamesTable, setIsGamesTable] = useState(false);
  const [isTrendingBetsPage, setIsTrendingBetsPage] = useState(false);

  // On navigate, close menu
  useEffect(() => {
    navBarPosition(null);
    setTitle(league?.name || CUSTOM_PAGES[router?.asPath] || "");
    setIsHomePage(router?.asPath === "/");
    setIsGamesPage(router?.asPath.includes(`/${league?.url}/`));
    setIsOffersPage(["/sportsbook-offers", "/boosts"].includes(router?.asPath));
    setIsGamesTable(router?.route.includes("/games"));
    setIsTrendingBetsPage(router?.asPath.includes("best-bets"));
  }, [router]);

  return (
    <React.Fragment>
      <div style={{ position: "relative" }}>
        <div className="nav-bar-mobile">
          <Link href="/" legacyBehavior>
            <a className="nav-logo">
              <BetQLLogo color="white" />
            </a>
          </Link>
          <div className={`page-title-text ${isGamesTable ? "d-none d-sm-flex" : ""}`}>{title}</div>
          {isGamesTable && <MobileDropdownControls isTrendingBetsPage={isTrendingBetsPage} />}
          <div className="account-options">
            <div className="hamburger" onClick={() => navBarPosition(navPosition === "top" ? null : "top")}>
              <Icon path={mdiMenu} size="28px" />
            </div>
          </div>
        </div>
        <MobileMenu position="top" />
      </div>
      <div className="nav-bar-bottom">
        <MobileMenu position="bottom" />
        <div className="sport-selector">
          <div
            className="league-link-selected"
            onClick={() => navBarPosition(navPosition === "bottom" ? null : "bottom")}
          >
            <img className="league-icon-selected" src={league?.icon || HomeIcon} alt={league?.name ?? "Home"} />
            <div className="league-name-selected">{league?.name || "Home"}</div>
            <Icon className="dropdown-toggle-arrow" path={mdiChevronDown} size="24px" />
          </div>
        </div>
        <div className="tab-links">
          <Link href="/" legacyBehavior>
            <a className={`tab-link ${isHomePage ? "selected" : ""}`}>
              <Icon className="tab-icon" path={mdiHome} size="26px" />
              <div className="tab-name">Home</div>
            </a>
          </Link>
          <Link href={`/${(league || DEFAULT_SPORT).url}/odds`} legacyBehavior>
            <a className={`tab-link ${isGamesPage ? "selected" : ""}`}>
              <Icon className="tab-icon" path={mdiCalendarText} size="26px" />
              <div className="tab-name">Games</div>
            </a>
          </Link>
          <Link href="/sportsbook-offers" legacyBehavior>
            <a className={`tab-link ${isOffersPage ? "selected" : ""}`} style={{ position: "relative", bottom: 1 }}>
              <Icon className="tab-icon" path={mdiCash} size="32px" style={{ marginBottom: -2 }} />
              <div className="tab-name">Offers</div>
            </a>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export interface PageTree {
  href?: string;
  text: ReactNode;
  icon?: string;
  pages?: PageTree[];
}

const DesktopMenuPageItem = ({ href, text, icon, pages }: PageTree) => {
  // Client side redirect for golf
  if (href === "/golf") {
    href = "/golf/news";
  }

  if (!text) {
    return null;
  }

  const onClick = (event: any) => {
    if (["pen", "touch"].includes(event.nativeEvent.pointerType) && pages?.length) {
      // If touched and has children, open submenu instead
      event.preventDefault();
    } else {
      // Else navigate and blur
      (document.activeElement as any).blur();
    }
  };

  const linkMarkup = (
    <a className="nav-link" onClick={onClick}>
      {Boolean(icon) && <img className="nav-link-icon" src={icon} alt="icon" />}
      <div className="nav-link-text">{text}</div>
    </a>
  );

  return (
    <div className="nav-item">
      {href ? (
        <Link href={href} legacyBehavior>
          {linkMarkup}
        </Link>
      ) : (
        linkMarkup
      )}
      {pages?.length && (
        <div className="dropdown">
          <div className="dropdown-inner">
            {pages.map((page: any, idx3: number) => (
              <DesktopMenuPageItem key={idx3} href={page.href} text={page.text} icon={page.icon} pages={page.pages} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const NavBarDesktop = () => {
  const ctx = useContext(SubscriptionContext);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const displayName = useDisplayName();
  const { getNavLinksByLeague } = useNavLinks();
  const pages = [
    { href: "/sportsbook-offers", text: "Sportsbook Offers" },
    { href: "/casino-offers", text: "Casino Offers" },
  ];
  if (STATE_MAP[ctx.userLocale]?.legal) {
    pages.push({ href: "/boosts", text: "Odds Boosts" });
  }
  useEffect(() => {
    if (ctx.init) {
      setLoading(false);
    }
  }, [ctx.init]);
  return (
    <div className="nav-bar-desktop">
      <Link href="/" legacyBehavior>
        <a className="nav-logo">
          <BetQLLogo color="white" />
        </a>
      </Link>
      <div className="main-menu">
        {leagues.map((item: any, id: number) => {
          if (item?.group) {
            return (
              <DesktopMenuPageItem
                key={id}
                href={`/${getSportLeagues(item)[0].url}`}
                text={item.name}
                icon={item.icon}
                pages={getSportLeagues(item).map((league) => ({
                  href: `/${league.url}`,
                  text: league.name,
                  icon: league.icon,
                  pages: getNavLinksByLeague(league),
                }))}
              />
            );
          }
          return (
            <DesktopMenuPageItem
              key={id}
              href={`/${item.url}`}
              text={item.name}
              icon={item.icon}
              pages={getNavLinksByLeague(item)}
            />
          );
        })}
        <DesktopMenuPageItem
          text={
            <>
              Other Sports <FontAwesomeIcon icon={faCaretDown} />
            </>
          }
          pages={otherLeagues.map((league) => ({
            href: `/${league.url}`,
            text: league.name,
            icon: league.icon,
            pages: getNavLinksByLeague(league),
          }))}
        />
        <DesktopMenuPageItem
          href="/sportsbook-offers"
          text={
            <>
              Offers <FontAwesomeIcon icon={faCaretDown} />
            </>
          }
          pages={pages}
        />
        <DesktopMenuPageItem href="/free-picks" text="Free Picks" />
        <DesktopMenuPageItem href="/newsletter" text="Newsletter" />
        <DesktopMenuPageItem href="/pricing/monthly" text="Pricing" />
      </div>
      <div className="account-options">
        {loading ? (
          <Spinner size={25} color="#ebebeb" />
        ) : ctx.signedIn ? (
          <React.Fragment>
            <FocusedTextLink className="user-profile" onClick={() => setOpen(!open)}>
              <div className="user-pic">
                <span>{displayName.charAt(0).toUpperCase()}</span>
              </div>
              <Icon className="user-dropdown-arrow" path={mdiChevronDown} size="26px" />
            </FocusedTextLink>
            <div className="user-dropdown" style={{ display: open ? "flex" : "none" }} onClick={() => setOpen(!open)}>
              <DesktopMenuPageItem href="/account" text="Account" />
              <FocusedTextLink onClick={ctx.logoutUser}>Log Out</FocusedTextLink>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <FocusedTextLink className="login-button" onClick={() => ctx.openLoginModal(0)}>
              Log In
            </FocusedTextLink>
            <FocusedTextLink className="signup-button" onClick={() => ctx.openLoginModal(1)}>
              Sign Up
            </FocusedTextLink>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

const NavBar = () => {
  return (
    <div className="nav-bar">
      <NavBarDesktop />
      <NavBarMobile />
      <style jsx>{styles}</style>
    </div>
  );
};

export default NavBar;
