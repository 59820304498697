import React from "react";
import styles from "./styles.scss";

// From https://loading.io/css/
const Spinner = ({ size, color }: { size: number; color: string }) => {
  return (
    <div className="lds-ring" style={{ "--size": `${size}px`, "--color": color } as any}>
      <div />
      <div />
      <div />
      <div />
      <style jsx>{styles}</style>
    </div>
  );
};

export default Spinner;
