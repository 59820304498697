import React, { useEffect, useRef, useState } from "react";
import { mdiClose, mdiHelpCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import styles from "./styles.scss";
import { CSSTransition } from "react-transition-group";

export type TooltipDirection = "top" | "right" | "bottom" | "left";

export type TooltipProps = {
  children: any;
  title: string;
  iconSize?: number;
  tooltipWidth?: number;
  direction?: TooltipDirection;
  customIcon?: any;
};

export const reverseDirection = (direction: TooltipDirection) =>
  ({
    top: "bottom",
    bottom: "top",
    right: "left",
    left: "right",
  }[direction]);

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export const TOOLTIP_MARGIN = 10;

const Tooltip = ({
  title,
  children,
  iconSize = 16,
  tooltipWidth = 270,
  direction = "top",
  customIcon = null,
}: TooltipProps) => {
  const [directionOverride, setDirectionOverride] = useState(null as TooltipDirection | null);
  const [visible, setVisible] = useState(false);
  const [transformX, setTransformX] = useState(0);
  const [ww, wh] = useWindowSize();
  const iconRef = useRef<any>();
  const tooltipRef = useRef<any>();

  useEffect(() => {
    if (!tooltipRef.current || !iconRef.current) {
      return;
    }
    const iconRect = iconRef.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();

    // Very small screens tooltip away from edge
    const leftOverflow = -1 * tooltipRect.left + TOOLTIP_MARGIN;
    const rightOverflow = tooltipRect.right - ww + TOOLTIP_MARGIN;
    let overflowSide = transformX < 0 ? "right" : "left";
    if (rightOverflow > 0) {
      overflowSide = "right";
    }
    if (leftOverflow > 0) {
      overflowSide = "left";
    }
    if (leftOverflow + rightOverflow > -4) {
      // keep previous layout if both sides overflow
    } else if (overflowSide === "left") {
      setTransformX(Math.max(0, transformX + leftOverflow));
    } else if (overflowSide === "right") {
      setTransformX(Math.min(0, transformX - rightOverflow));
    }

    // If left or right go vertical if overflow.
    // This needs to not be based on tooltip rect or it'll jerk back and forth.
    if (direction === "left") {
      const overflow = iconRect.left - (2 * TOOLTIP_MARGIN + tooltipWidth) < 0;
      setDirectionOverride(overflow ? "top" : null);
    }
    if (direction === "right") {
      const overflow = iconRect.right + 2 * TOOLTIP_MARGIN + tooltipWidth > ww;
      setDirectionOverride(overflow ? "top" : null);
    }
  }, [visible, ww, wh, directionOverride]);

  return (
    <span className="tooltip-component">
      <span className="tooltip-button" onClick={() => setVisible(!visible)} ref={iconRef}>
        {customIcon ? customIcon : <Icon path={mdiHelpCircleOutline} size={`${iconSize}px`} />}
      </span>
      <CSSTransition in={visible} timeout={200} classNames="tooltip-anim">
        <span
          ref={tooltipRef}
          className="tooltip-modal"
          style={{
            [reverseDirection(directionOverride ?? direction)]: iconSize + TOOLTIP_MARGIN,
            transform: `translate(${transformX}px, 0px)`,
            width: `${tooltipWidth}px`,
          }}
        >
          <span className="tooltip-head">
            <span className="tooltip-title">{title}</span>
            <span className="tooltip-x" onClick={() => setVisible(false)}>
              <Icon path={mdiClose} size="16px" color="#888" />
            </span>
          </span>
          <span className="tooltip-body">{children}</span>
        </span>
      </CSSTransition>
      <style jsx>{styles}</style>
    </span>
  );
};

export default Tooltip;
