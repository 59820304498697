import React from "react";
import { userGetDisplayName } from "src/services/UserService";

const AppStore = require("public/app-store/apple@3x.png?sizes[]=130,sizes[]=260,sizes[]=390");
const GooglePlay = require("public/app-store/google@3x.png?sizes[]=130,sizes[]=260,sizes[]=390");
const Phone = require("public/HeroAd/phone.png?sizes[]=340,sizes[]=680,sizes[]=1020");
import styles from "./styles.scss";
import { TierItem } from "src/typings";

interface ThankYouViewProps {
  style?: any;
  className?: string;
  awarded: boolean;
  selectedTier: TierItem;
  invoiceId: string;
}

const ThankYouView = ({ className, style, awarded, selectedTier, invoiceId }: ThankYouViewProps) => {
  return (
    <div className={`rotoql-content-thankyouview ${className ? className : ""}`} style={style}>
      <style jsx>{styles}</style>
      <img
        src={`https://www.shareasale.com/sale.cfm?tracking=${invoiceId}&amount=${selectedTier.totalPrice}&merchantID=140230&transtype=sale`}
        width="1"
        height="1"
      />
      <div className="rotoql-content-thankyouview__title">
        {awarded ? "Congratulations!" : "Thank You For Your Purchase!"}
      </div>
      <div className="rotoql-content-thankyouview__body">
        {awarded
          ? `Your account, ${userGetDisplayName()}, has been credited 12 free months of BetQL (1 sport).`
          : "You now have access to your plan on both BetQL web app and mobile app!"}
      </div>
      <div className="rotoql-content-thankyouview__divider" />
      <div className="rotoql-content-thankyouview__mobile-app-container">
        <div className="rotoql-content-thankyouview__mobile-app-store-container">
          <div className="rotoql-content-thankyouview__app-text">Don’t forget to download BetQL mobile app</div>
          <div className="rotoql-content-thankyouview__app-store-container">
            <a
              className="rotoql-content-thankyouview__app-store-img-link"
              href="https://itunes.apple.com/us/app/betql-sports-betting/id1334825645?mt=8"
              target="_blank"
              rel="noopener"
            >
              <img
                className="rotoql-content-thankyouview__app-store-img"
                src={AppStore.src}
                srcSet={AppStore.srcSet}
                alt="App Store"
              />
            </a>
            <a
              className="rotoql-content-thankyouview__google-play-img-link"
              href="https://play.google.com/store/apps/details?id=com.rotoql.betql&hl=en_US"
              target="_blank"
              rel="noopener"
            >
              <img
                className="rotoql-content-thankyouview__google-play-img"
                src={GooglePlay.src}
                srcSet={GooglePlay.srcSet}
                alt="Google Play Store"
              />
            </a>
          </div>
        </div>
        <img
          className="rotoql-content-thankyouview__phone-img"
          src={Phone.src}
          srcSet={Phone.srcSet}
          alt="BetQL Mobile App"
        />
      </div>
    </div>
  );
};

export default ThankYouView;
