import React from "react";
import { Elements, RichText } from "prismic-reactjs";
import PrismicJS from "prismic-javascript";
import { getArticleAsUrl, getUrlWithSearchParams } from "src/services/UrlService";

// Use imgix api that comes with Prismic to force resize
export const imgixResize = (imageUrl: string, { width = 800, height }: { width?: number; height?: number } = {}) => {
  return getUrlWithSearchParams(imageUrl, {
    rect: undefined,
    fit: "crop",
    w: width,
    h: height,
  });
};

export const getMasterAPI = async (options = {}) =>
  await PrismicJS.api("https://betql-staging.prismic.io/api/v2", options); // eslint-disable-line

export const parseTags = (tags: string) => {
  const rawTagNames = !tags
    ? []
    : tags
        .trim() // no whitespace at start and end
        .replace(/#/g, "") // hash becomes space
        .replace(/,/g, "") // comma becomes space
        .replace(/\s+/g, " ") // remove multiple spaces
        .split(" "); // separate the tag names

  return rawTagNames.map((raw: string) => {
    // insert space before each word
    const pretty = raw.replace(/([A-Z][a-z0-9]+)/g, " $1").trim();
    return { raw, pretty };
  });
};

export const articleAsText = (doc: any) => {
  const textArr = doc.body?.map((slice: any) => {
    const isText = slice?.type === "text" && slice?.primary?.text;
    return isText ? RichText.asText(slice?.primary?.text) : slice?.primary?.text;
  }) || ["No preview available."];
  return textArr.join(" ");
};

export const renderRichText = (value: any) => RichText.render(value, getArticleAsUrl as any, null);

export const renderArticleRichText = (value: any) =>
  RichText.render(value, getArticleAsUrl as any, articleHtmlSerializer);

const propsWithUniqueKey = function (props: any, key: any) {
  return Object.assign(props || {}, { key });
};

const articleHtmlSerializer = function (type: any, element: any, _: any, children: any, key: any) {
  let props = {};
  switch (type) {
    case Elements.paragraph: // Paragraph
      props = {
        className: "rotoql-content-article__paragraph",
      };
      return React.createElement("p", propsWithUniqueKey(props, key), children);
    case Elements.hyperlink: // Link
      // Default link handling
      const targetAttr = element.data.target ? { target: element.data.target } : {};
      const relAttr = element.data.target ? { rel: "noopener" } : {};

      const url = new URL(element.data.url);
      let href = url.href;
      if (url?.host === "betql.co") {
        href = url.href.replace("https://betql.co", "");
      }

      props = Object.assign(
        {
          href,
        },
        targetAttr,
        relAttr
      );
      return React.createElement("a", propsWithUniqueKey(props, key), children);
    case Elements.embed: // Embed
      props = Object.assign(
        {
          className: "rotoql-content-article__video",
          "data-oembed": element.oembed.embed_url,
          "data-oembed-type": element.oembed.type,
          "data-oembed-provider": element.oembed.provider_name,
        },
        element.label ? { className: element.label } : {}
      );
      const embedHtml = React.createElement(
        "div",
        Object.assign({ style: { display: "flex" } }, { dangerouslySetInnerHTML: { __html: element.oembed.html } })
      );
      return React.createElement("div", propsWithUniqueKey(props, key), embedHtml);
    default:
      return null;
  }
};
