export default {
  description:
    "The App to Outsmart Vegas - Powerful analytics for sports betting. Get access to real-time line movement, value bets of the day and meaningful team trends.",
  facebook: {
    appId: "159390208060520",
  },
  openGraph: {
    type: "website",
    description:
      "The App to Outsmart Vegas - Powerful analytics for sports betting. Get access to real-time line movement, value bets of the day and meaningful team trends.",
    title: "BetQL",
    locale: "en_US",
    site_name: "BetQL",
    image: {
      url: "https://betql.co/ogimage.jpg",
      width: 1200,
      height: 630,
      alt: "BetQL",
    },
  },
  twitter: {
    handle: "@betqlapp",
    site: "@betqlapp",
    creator: "@betqlapp",
    cardType: "summary_large_image",
  },
};
