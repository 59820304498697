import { gql } from "@apollo/client";
import { DEFAULT_BOOK_ID } from "@rotoql/common-services";
import { DocumentNode, parse } from "graphql";

import { PERIODS_FRAGMENT } from "src/services/EventService";

export const USER_QUERY = gql`
  query user {
    me {
      id
      selectedBooksOn
      phoneNumber
    }
  }
`;

// Not cacheable

export const USER_SUBSCRIPTION_QUERY = gql`
  query userSubscriptionQuery {
    userSubscriptions(product: "bet") {
      id
      cancelAt
      expiration
      subscriptionLevel {
        id
        name
      }
      sports {
        id
        sport
      }
    }
  }
`;

// Not cacheable
export const STRIPE_COUPON_QUERY = gql`
  query stripeCouponQuery($couponId: String) {
    stripeCoupon(couponId: $couponId) {
      couponId
      amountOff
      percentOff
      valid
    }
  }
`;

// Not cacheable
export const CHANGE_USER_SUBSCRIPTION_PREVIEW = gql`
  query stripeChangeUserSubscriptionPreview($currentUserSubscriptionId: UUID!, $targetSubscriptionName: String) {
    stripeChangeUserSubscriptionPreview(
      currentUserSubscriptionId: $currentUserSubscriptionId
      targetSubscriptionName: $targetSubscriptionName
    ) {
      startingBalance
      changeCost
      totalDue
    }
  }
`;

// Cacheable
export const LIST_ALL_BOOKS_AND_CTA_EVENTS: DocumentNode = gql`
  query listAllBooks($keyName: String!, $league: LeagueEnum) {
    books {
      id
      name
      locales
      displayTitle
      rank
      afilliateUrl
    }
    paywall {
      id
      valueEvents
      sharpEvents
    }
    appMessaging(keyName: $keyName, league: $league) {
      message
    }
  }
`;

// Not cacheable
export const USER_UI_SETTINGS_AND_ALL_BOOKS: DocumentNode = gql`
  query userSettingsAndBooks($league: LeagueEnum, $page: UiGamePageSectionEnum) {
    bookSetting: userUiSetting(settingType: CHOSENBOOK, league: $league) {
      id
      parsedValue
      uiSection
      updateTs
    }
    columnOrder: userUiSetting(settingType: COLUMNDISPLAYORDER, league: $league, section: $page) {
      id
      parsedValue
      uiSection
      updateTs
    }
    columnOrderList: userUiSettings(settingType: COLUMNDISPLAYORDER, league: $league, section: $page) {
      id
      parsedValue
      uiSection
      updateTs
    }
    walkthroughComplete: userUiSetting(settingType: WEBWALKTHROUGHCOMPLETE) {
      id
      parsedValue
    }
    books {
      id
      name
      locales
      displayTitle
      rank
      afilliateUrl
    }
    paywall {
      id
      valueEvents
      sharpEvents
    }
    appMessaging(keyName: "cta_bet_performance", league: $league) {
      message
    }
  }
`;

// Cacheable
export const SPORT_INDEX_EVENTS = gql`
  query sportIndexEvents(
    $league: LeagueEnum!
    $before: DateTime
    $after: DateTime
    $bookId: UUID
    $conference: CFBConferenceEnum
  ) {
    events(
      league: $league
      before: $before
      after: $after
      eventType: TEAM
      limit: 6
      conference: $conference
      freeGamesFirst: true
    ) {
      id
      slugId
      tournamentRound
      freeGame
      channel
      startDate
      tiData
      awayTeamSeed
      homeTeamSeed
      league {
        id
        name
      }
      tournament {
        id
        slugId
        name
      }
      spRating(bookUuid: $bookId) {
        rating
        differential
        preferredLine
        preferredTeamId
      }
      mlRating(bookUuid: $bookId) {
        rating
        differential
        preferredLine
        preferredTeamId
        preferredWinPercent
        preferredMoneyline
      }
      homeTeam {
        id
        fullName
        lastName
        preferredAbbreviation
        country
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
          rank
        }
      }
      awayTeam {
        id
        fullName
        lastName
        preferredAbbreviation
        country
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
          rank
        }
      }
      lines(bookIds: [$bookId], period: FULLGAME, lineType: [CURRENT]) {
        id
        bookId
        time
        type
        lineType
        period
        awaySpread
        awayMoney
        awayPrice
        homeSpread
        homeMoney
        homePrice
        drawMoney
        total
        overPrice
        underPrice
      }
      eventStats(period: FULLGAME) {
        id
        period
        homeSpreadCount
        awaySpreadCount
        homeMoneyCount
        awayMoneyCount
        drawMoneyCount
        overCount
        underCount
        homeSharpSpreadPercent
        awaySharpSpreadPercent
        homeSharpMoneyPercent
        awaySharpMoneyPercent
        drawSharpMoneyPercent
        overSharpPercent
        underSharpPercent
        homeTicketSpreadPercent
        awayTicketSpreadPercent
        homeTicketMoneyPercent
        awayTicketMoneyPercent
        drawTicketMoneyPercent
        overTicketPercent
        underTicketPercent
        publicSpreadHome
        publicSpreadAway
        publicMoneyHome
        publicMoneyAway
        publicMoneyDraw
        publicTotalOver
        publicTotalUnder
      }
      eventTrends(bookUuid: $bookId, trendTypes: [ATS, ML, HTATS, OU]) {
        id
        teamId
        timePeriod
        description
        typeGames
        rating
        trendType
        reportType
        wins
        losses
        games
        units
      }
    }
  }
`;

// Cacheable. May be worth doing APQ on this.
export const INDEX_EVENTS = gql`
  query indexEvents(
    $nbaBefore: DateTime
    $nbaAfter: DateTime
    $nflBefore: DateTime
    $nflAfter: DateTime
    $cfbAfter: DateTime
    $cfbBefore: DateTime
    $nhlBefore: DateTime
    $nhlAfter: DateTime
    $xflAfter: DateTime
    $xflBefore: DateTime
    $debAfter: DateTime
    $debBefore: DateTime
    $eplBefore: DateTime
    $eplAfter: DateTime
    $espAfter: DateTime
    $espBefore: DateTime
    $itsaAfter: DateTime
    $itsaBefore: DateTime
    $uclBefore: DateTime
    $uclAfter: DateTime
    $mlsBefore: DateTime
    $mlsAfter: DateTime
    $before: DateTime
    $after: DateTime
    $bookId: UUID
  ) {
    nflEvents: events(
      league: NFL
      before: $nflBefore
      after: $nflAfter
      eventType: TEAM
      limit: 6
      freeGamesFirst: true
    ) {
      id
      slugId
      freeGame
      channel
      startDate
      league {
        id
        name
      }
      spRating(bookUuid: $bookId) {
        rating
        differential
        preferredLine
        preferredTeamId
      }
      homeTeam {
        id
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
        }
      }
      awayTeam {
        id
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
        }
      }
      lines(bookIds: [$bookId], period: FULLGAME, lineType: [CURRENT]) {
        id
        bookId
        time
        type
        lineType
        period
        awaySpread
        awayMoney
        awayPrice
        homeSpread
        homeMoney
        homePrice
        drawMoney
        total
        overPrice
        underPrice
      }
      eventStats(period: FULLGAME) {
        id
        period
        homeSpreadCount
        awaySpreadCount
        homeMoneyCount
        awayMoneyCount
        drawMoneyCount
        overCount
        underCount
        homeSharpSpreadPercent
        awaySharpSpreadPercent
        homeSharpMoneyPercent
        awaySharpMoneyPercent
        drawSharpMoneyPercent
        overSharpPercent
        underSharpPercent
        homeTicketSpreadPercent
        awayTicketSpreadPercent
        homeTicketMoneyPercent
        awayTicketMoneyPercent
        drawTicketMoneyPercent
        overTicketPercent
        underTicketPercent
        publicSpreadHome
        publicSpreadAway
        publicMoneyHome
        publicMoneyAway
        publicMoneyDraw
        publicTotalOver
        publicTotalUnder
      }
    }
    nbaEvents: events(
      league: NBA
      before: $nbaBefore
      after: $nbaAfter
      eventType: TEAM
      limit: 6
      freeGamesFirst: true
    ) {
      id
      slugId
      freeGame
      channel
      startDate
      league {
        id
        name
      }
      spRating(bookUuid: $bookId) {
        rating
        differential
        preferredLine
        preferredTeamId
      }
      homeTeam {
        id
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
        }
      }
      awayTeam {
        id
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
        }
      }
      lines(bookIds: [$bookId], period: FULLGAME, lineType: [CURRENT]) {
        id
        bookId
        time
        type
        lineType
        period
        awaySpread
        awayMoney
        awayPrice
        homeSpread
        homeMoney
        homePrice
        drawMoney
        total
        overPrice
        underPrice
      }
      eventStats(period: FULLGAME) {
        id
        period
        homeSpreadCount
        awaySpreadCount
        homeMoneyCount
        awayMoneyCount
        drawMoneyCount
        overCount
        underCount
        homeSharpSpreadPercent
        awaySharpSpreadPercent
        homeSharpMoneyPercent
        awaySharpMoneyPercent
        drawSharpMoneyPercent
        overSharpPercent
        underSharpPercent
        homeTicketSpreadPercent
        awayTicketSpreadPercent
        homeTicketMoneyPercent
        awayTicketMoneyPercent
        drawTicketMoneyPercent
        overTicketPercent
        underTicketPercent
        publicSpreadHome
        publicSpreadAway
        publicMoneyHome
        publicMoneyAway
        publicMoneyDraw
        publicTotalOver
        publicTotalUnder
      }
    }
    mlbEvents: events(league: MLB, before: $before, after: $after, eventType: TEAM, limit: 6, freeGamesFirst: true) {
      id
      slugId
      freeGame
      channel
      startDate
      league {
        id
        name
      }
      mlRating(bookUuid: $bookId) {
        rating
        differential
        preferredLine
        preferredTeamId
      }
      homeTeam {
        id
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
        }
      }
      awayTeam {
        id
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
        }
      }
      lines(bookIds: [$bookId], period: FULLGAME, lineType: [CURRENT]) {
        id
        bookId
        time
        type
        lineType
        period
        awaySpread
        awayMoney
        awayPrice
        homeSpread
        homeMoney
        homePrice
        drawMoney
        total
        overPrice
        underPrice
      }
      eventStats(period: FULLGAME) {
        id
        period
        homeSpreadCount
        awaySpreadCount
        homeMoneyCount
        awayMoneyCount
        drawMoneyCount
        overCount
        underCount
        homeSharpSpreadPercent
        awaySharpSpreadPercent
        homeSharpMoneyPercent
        awaySharpMoneyPercent
        drawSharpMoneyPercent
        overSharpPercent
        underSharpPercent
        homeTicketSpreadPercent
        awayTicketSpreadPercent
        homeTicketMoneyPercent
        awayTicketMoneyPercent
        drawTicketMoneyPercent
        overTicketPercent
        underTicketPercent
        publicSpreadHome
        publicSpreadAway
        publicMoneyHome
        publicMoneyAway
        publicMoneyDraw
        publicTotalOver
        publicTotalUnder
      }
    }
    ncaafEvents: events(
      league: CFB
      before: $cfbBefore
      after: $cfbAfter
      eventType: TEAM
      limit: 6
      freeGamesFirst: true
    ) {
      id
      slugId
      freeGame
      channel
      startDate
      awayTeamSeed
      homeTeamSeed
      league {
        id
        name
      }
      spRating(bookUuid: $bookId) {
        rating
        differential
        preferredLine
        preferredTeamId
      }
      homeTeam {
        id
        fullName
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
          rank
        }
      }
      awayTeam {
        id
        fullName
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
          rank
        }
      }
      lines(bookIds: [$bookId], period: FULLGAME, lineType: [CURRENT]) {
        id
        bookId
        time
        type
        lineType
        period
        awaySpread
        awayMoney
        awayPrice
        homeSpread
        homeMoney
        homePrice
        drawMoney
        total
        overPrice
        underPrice
      }
      eventStats(period: FULLGAME) {
        id
        period
        homeSpreadCount
        awaySpreadCount
        homeMoneyCount
        awayMoneyCount
        drawMoneyCount
        overCount
        underCount
        homeSharpSpreadPercent
        awaySharpSpreadPercent
        homeSharpMoneyPercent
        awaySharpMoneyPercent
        drawSharpMoneyPercent
        overSharpPercent
        underSharpPercent
        homeTicketSpreadPercent
        awayTicketSpreadPercent
        homeTicketMoneyPercent
        awayTicketMoneyPercent
        drawTicketMoneyPercent
        overTicketPercent
        underTicketPercent
        publicSpreadHome
        publicSpreadAway
        publicMoneyHome
        publicMoneyAway
        publicMoneyDraw
        publicTotalOver
        publicTotalUnder
      }
    }
    nhlEvents: events(
      league: NHL
      before: $nhlBefore
      after: $nhlAfter
      eventType: TEAM
      limit: 6
      freeGamesFirst: true
    ) {
      id
      slugId
      freeGame
      channel
      startDate
      league {
        id
        name
      }
      mlRating(bookUuid: $bookId) {
        rating
        differential
        preferredLine
        preferredTeamId
      }
      homeTeam {
        id
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
        }
      }
      awayTeam {
        id
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
        }
      }
      lines(bookIds: [$bookId], period: FULLGAME, lineType: [CURRENT]) {
        id
        bookId
        time
        type
        lineType
        period
        awaySpread
        awayMoney
        awayPrice
        homeSpread
        homeMoney
        homePrice
        drawMoney
        total
        overPrice
        underPrice
      }
      eventStats(period: FULLGAME) {
        id
        period
        homeSpreadCount
        awaySpreadCount
        homeMoneyCount
        awayMoneyCount
        drawMoneyCount
        overCount
        underCount
        homeSharpSpreadPercent
        awaySharpSpreadPercent
        homeSharpMoneyPercent
        awaySharpMoneyPercent
        drawSharpMoneyPercent
        overSharpPercent
        underSharpPercent
        homeTicketSpreadPercent
        awayTicketSpreadPercent
        homeTicketMoneyPercent
        awayTicketMoneyPercent
        drawTicketMoneyPercent
        overTicketPercent
        underTicketPercent
        publicSpreadHome
        publicSpreadAway
        publicMoneyHome
        publicMoneyAway
        publicMoneyDraw
        publicTotalOver
        publicTotalUnder
      }
    }
    ncaabEvents: events(league: CBK, before: $before, after: $after, eventType: TEAM, limit: 6, freeGamesFirst: true) {
      id
      slugId
      freeGame
      channel
      startDate
      awayTeamSeed
      homeTeamSeed
      league {
        id
        name
      }
      spRating(bookUuid: $bookId) {
        rating
        differential
        preferredLine
        preferredTeamId
      }
      homeTeam {
        id
        fullName
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
          rank
        }
      }
      awayTeam {
        id
        fullName
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
          rank
        }
      }
      lines(bookIds: [$bookId], period: FULLGAME, lineType: [CURRENT]) {
        id
        bookId
        time
        type
        lineType
        period
        awaySpread
        awayMoney
        awayPrice
        homeSpread
        homeMoney
        homePrice
        drawMoney
        total
        overPrice
        underPrice
      }
      eventStats(period: FULLGAME) {
        id
        period
        homeSpreadCount
        awaySpreadCount
        homeMoneyCount
        awayMoneyCount
        drawMoneyCount
        overCount
        underCount
        homeSharpSpreadPercent
        awaySharpSpreadPercent
        homeSharpMoneyPercent
        awaySharpMoneyPercent
        drawSharpMoneyPercent
        overSharpPercent
        underSharpPercent
        homeTicketSpreadPercent
        awayTicketSpreadPercent
        homeTicketMoneyPercent
        awayTicketMoneyPercent
        drawTicketMoneyPercent
        overTicketPercent
        underTicketPercent
        publicSpreadHome
        publicSpreadAway
        publicMoneyHome
        publicMoneyAway
        publicMoneyDraw
        publicTotalOver
        publicTotalUnder
      }
    }
    xflEvents: events(
      league: XFL
      before: $xflBefore
      after: $xflAfter
      eventType: TEAM
      limit: 6
      freeGamesFirst: true
    ) {
      id
      slugId
      freeGame
      channel
      startDate
      league {
        id
        name
      }
      spRating(bookUuid: $bookId) {
        rating
        differential
        preferredLine
        preferredTeamId
      }
      homeTeam {
        id
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
        }
      }
      awayTeam {
        id
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
        }
      }
      lines(bookIds: [$bookId], period: FULLGAME, lineType: [CURRENT]) {
        id
        bookId
        time
        type
        lineType
        period
        awaySpread
        awayMoney
        awayPrice
        homeSpread
        homeMoney
        homePrice
        drawMoney
        total
        overPrice
        underPrice
      }
      eventStats(period: FULLGAME) {
        id
        period
        homeSpreadCount
        awaySpreadCount
        homeMoneyCount
        awayMoneyCount
        drawMoneyCount
        overCount
        underCount
        homeSharpSpreadPercent
        awaySharpSpreadPercent
        homeSharpMoneyPercent
        awaySharpMoneyPercent
        drawSharpMoneyPercent
        overSharpPercent
        underSharpPercent
        homeTicketSpreadPercent
        awayTicketSpreadPercent
        homeTicketMoneyPercent
        awayTicketMoneyPercent
        drawTicketMoneyPercent
        overTicketPercent
        underTicketPercent
        publicSpreadHome
        publicSpreadAway
        publicMoneyHome
        publicMoneyAway
        publicMoneyDraw
        publicTotalOver
        publicTotalUnder
      }
    }
    premierLeagueEvents: events(
      league: EPL
      before: $eplBefore
      after: $eplAfter
      eventType: TEAM
      limit: 6
      freeGamesFirst: true
    ) {
      id
      slugId
      freeGame
      channel
      startDate
      league {
        id
        name
      }
      mlRating(bookUuid: $bookId) {
        rating
        differential
        preferredLine
        preferredTeamId
        preferredWinPercent
        preferredMoneyline
      }
      homeTeam {
        id
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
        }
      }
      awayTeam {
        id
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
        }
      }
      lines(bookIds: [$bookId], period: FULLGAME, lineType: [CURRENT]) {
        id
        bookId
        time
        type
        lineType
        period
        awaySpread
        awayMoney
        awayPrice
        homeSpread
        homeMoney
        homePrice
        drawMoney
        total
        overPrice
        underPrice
      }
      eventStats(period: FULLGAME) {
        id
        period
        homeSpreadCount
        awaySpreadCount
        homeMoneyCount
        awayMoneyCount
        drawMoneyCount
        overCount
        underCount
        homeSharpSpreadPercent
        awaySharpSpreadPercent
        homeSharpMoneyPercent
        awaySharpMoneyPercent
        drawSharpMoneyPercent
        overSharpPercent
        underSharpPercent
        homeTicketSpreadPercent
        awayTicketSpreadPercent
        homeTicketMoneyPercent
        awayTicketMoneyPercent
        drawTicketMoneyPercent
        overTicketPercent
        underTicketPercent
        publicSpreadHome
        publicSpreadAway
        publicMoneyHome
        publicMoneyAway
        publicMoneyDraw
        publicTotalOver
        publicTotalUnder
      }
    }
    bundesligaEvents: events(
      league: DEB
      before: $debBefore
      after: $debAfter
      eventType: TEAM
      limit: 6
      freeGamesFirst: true
    ) {
      id
      slugId
      freeGame
      channel
      startDate
      league {
        id
        name
      }
      mlRating(bookUuid: $bookId) {
        rating
        differential
        preferredLine
        preferredTeamId
        preferredWinPercent
        preferredMoneyline
      }
      homeTeam {
        id
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
        }
      }
      awayTeam {
        id
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
        }
      }
      lines(bookIds: [$bookId], period: FULLGAME, lineType: [CURRENT]) {
        id
        bookId
        time
        type
        lineType
        period
        awaySpread
        awayMoney
        awayPrice
        homeSpread
        homeMoney
        homePrice
        drawMoney
        total
        overPrice
        underPrice
      }
      eventStats(period: FULLGAME) {
        id
        period
        homeSpreadCount
        awaySpreadCount
        homeMoneyCount
        awayMoneyCount
        drawMoneyCount
        overCount
        underCount
        homeSharpSpreadPercent
        awaySharpSpreadPercent
        homeSharpMoneyPercent
        awaySharpMoneyPercent
        drawSharpMoneyPercent
        overSharpPercent
        underSharpPercent
        homeTicketSpreadPercent
        awayTicketSpreadPercent
        homeTicketMoneyPercent
        awayTicketMoneyPercent
        drawTicketMoneyPercent
        overTicketPercent
        underTicketPercent
        publicSpreadHome
        publicSpreadAway
        publicMoneyHome
        publicMoneyAway
        publicMoneyDraw
        publicTotalOver
        publicTotalUnder
      }
    }
    mlsEvents: events(
      league: MLS
      before: $mlsBefore
      after: $mlsAfter
      eventType: TEAM
      limit: 6
      freeGamesFirst: true
    ) {
      id
      slugId
      freeGame
      channel
      startDate
      league {
        id
        name
      }
      mlRating(bookUuid: $bookId) {
        rating
        differential
        preferredLine
        preferredTeamId
        preferredWinPercent
        preferredMoneyline
      }
      homeTeam {
        id
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
        }
      }
      awayTeam {
        id
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
        }
      }
      lines(bookIds: [$bookId], period: FULLGAME, lineType: [CURRENT]) {
        id
        bookId
        time
        type
        lineType
        period
        awaySpread
        awayMoney
        awayPrice
        homeSpread
        homeMoney
        homePrice
        drawMoney
        total
        overPrice
        underPrice
      }
      eventStats(period: FULLGAME) {
        id
        period
        homeSpreadCount
        awaySpreadCount
        homeMoneyCount
        awayMoneyCount
        drawMoneyCount
        overCount
        underCount
        homeSharpSpreadPercent
        awaySharpSpreadPercent
        homeSharpMoneyPercent
        awaySharpMoneyPercent
        drawSharpMoneyPercent
        overSharpPercent
        underSharpPercent
        homeTicketSpreadPercent
        awayTicketSpreadPercent
        homeTicketMoneyPercent
        awayTicketMoneyPercent
        drawTicketMoneyPercent
        overTicketPercent
        underTicketPercent
        publicSpreadHome
        publicSpreadAway
        publicMoneyHome
        publicMoneyAway
        publicMoneyDraw
        publicTotalOver
        publicTotalUnder
      }
    }
    laLigaEvents: events(
      league: ESP
      before: $espBefore
      after: $espAfter
      eventType: TEAM
      limit: 6
      freeGamesFirst: true
    ) {
      id
      slugId
      freeGame
      channel
      startDate
      league {
        id
        name
      }
      mlRating(bookUuid: $bookId) {
        rating
        differential
        preferredLine
        preferredTeamId
        preferredWinPercent
        preferredMoneyline
      }
      homeTeam {
        id
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
        }
      }
      awayTeam {
        id
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
        }
      }
      lines(bookIds: [$bookId], period: FULLGAME, lineType: [CURRENT]) {
        id
        bookId
        time
        type
        lineType
        period
        awaySpread
        awayMoney
        awayPrice
        homeSpread
        homeMoney
        homePrice
        drawMoney
        total
        overPrice
        underPrice
      }
      eventStats(period: FULLGAME) {
        id
        period
        homeSpreadCount
        awaySpreadCount
        homeMoneyCount
        awayMoneyCount
        drawMoneyCount
        overCount
        underCount
        homeSharpSpreadPercent
        awaySharpSpreadPercent
        homeSharpMoneyPercent
        awaySharpMoneyPercent
        drawSharpMoneyPercent
        overSharpPercent
        underSharpPercent
        homeTicketSpreadPercent
        awayTicketSpreadPercent
        homeTicketMoneyPercent
        awayTicketMoneyPercent
        drawTicketMoneyPercent
        overTicketPercent
        underTicketPercent
        publicSpreadHome
        publicSpreadAway
        publicMoneyHome
        publicMoneyAway
        publicMoneyDraw
        publicTotalOver
        publicTotalUnder
      }
    }
    serieAEvents: events(
      league: ITSA
      before: $itsaBefore
      after: $itsaAfter
      eventType: TEAM
      limit: 6
      freeGamesFirst: true
    ) {
      id
      slugId
      freeGame
      channel
      startDate
      league {
        id
        name
      }
      mlRating(bookUuid: $bookId) {
        rating
        differential
        preferredLine
        preferredTeamId
        preferredWinPercent
        preferredMoneyline
      }
      homeTeam {
        id
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
        }
      }
      awayTeam {
        id
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
        }
      }
      lines(bookIds: [$bookId], period: FULLGAME, lineType: [CURRENT]) {
        id
        bookId
        time
        type
        lineType
        period
        awaySpread
        awayMoney
        awayPrice
        homeSpread
        homeMoney
        homePrice
        drawMoney
        total
        overPrice
        underPrice
      }
      eventStats(period: FULLGAME) {
        id
        period
        homeSpreadCount
        awaySpreadCount
        homeMoneyCount
        awayMoneyCount
        drawMoneyCount
        overCount
        underCount
        homeSharpSpreadPercent
        awaySharpSpreadPercent
        homeSharpMoneyPercent
        awaySharpMoneyPercent
        drawSharpMoneyPercent
        overSharpPercent
        underSharpPercent
        homeTicketSpreadPercent
        awayTicketSpreadPercent
        homeTicketMoneyPercent
        awayTicketMoneyPercent
        drawTicketMoneyPercent
        overTicketPercent
        underTicketPercent
        publicSpreadHome
        publicSpreadAway
        publicMoneyHome
        publicMoneyAway
        publicMoneyDraw
        publicTotalOver
        publicTotalUnder
      }
    }
    championsLeagueEvents: events(
      league: UCL
      before: $uclBefore
      after: $uclAfter
      eventType: TEAM
      limit: 6
      freeGamesFirst: true
    ) {
      id
      slugId
      freeGame
      channel
      startDate
      league {
        id
        name
      }
      mlRating(bookUuid: $bookId) {
        rating
        differential
        preferredLine
        preferredTeamId
        preferredWinPercent
        preferredMoneyline
      }
      homeTeam {
        id
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
        }
      }
      awayTeam {
        id
        lastName
        preferredAbbreviation
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
        }
      }
      lines(bookIds: [$bookId], period: FULLGAME, lineType: [CURRENT]) {
        id
        bookId
        time
        type
        lineType
        period
        awaySpread
        awayMoney
        awayPrice
        homeSpread
        homeMoney
        homePrice
        drawMoney
        total
        overPrice
        underPrice
      }
      eventStats(period: FULLGAME) {
        id
        period
        homeSpreadCount
        awaySpreadCount
        homeMoneyCount
        awayMoneyCount
        drawMoneyCount
        overCount
        underCount
        homeSharpSpreadPercent
        awaySharpSpreadPercent
        homeSharpMoneyPercent
        awaySharpMoneyPercent
        drawSharpMoneyPercent
        overSharpPercent
        underSharpPercent
        homeTicketSpreadPercent
        awayTicketSpreadPercent
        homeTicketMoneyPercent
        awayTicketMoneyPercent
        drawTicketMoneyPercent
        overTicketPercent
        underTicketPercent
        publicSpreadHome
        publicSpreadAway
        publicMoneyHome
        publicMoneyAway
        publicMoneyDraw
        publicTotalOver
        publicTotalUnder
      }
    }
    wtaEvents: events(league: WTA, before: $before, after: $after, eventType: TEAM, limit: 6, freeGamesFirst: true) {
      id
      slugId
      tournamentRound
      freeGame
      channel
      startDate
      league {
        id
        name
      }
      tournament {
        id
        slugId
        name
      }
      mlRating(bookUuid: $bookId) {
        rating
        differential
        preferredLine
        preferredTeamId
        preferredWinPercent
        preferredMoneyline
      }
      homeTeam {
        id
        fullName
        lastName
        preferredAbbreviation
        country
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
          rank
        }
      }
      awayTeam {
        id
        fullName
        lastName
        preferredAbbreviation
        country
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
          rank
        }
      }
      lines(bookIds: [$bookId], period: FULLGAME, lineType: [CURRENT]) {
        id
        bookId
        time
        type
        lineType
        period
        awaySpread
        awayMoney
        awayPrice
        homeSpread
        homeMoney
        homePrice
        drawMoney
        total
        overPrice
        underPrice
      }
      eventStats(period: FULLGAME) {
        id
        period
        homeSpreadCount
        awaySpreadCount
        homeMoneyCount
        awayMoneyCount
        drawMoneyCount
        overCount
        underCount
        homeSharpSpreadPercent
        awaySharpSpreadPercent
        homeSharpMoneyPercent
        awaySharpMoneyPercent
        drawSharpMoneyPercent
        overSharpPercent
        underSharpPercent
        homeTicketSpreadPercent
        awayTicketSpreadPercent
        homeTicketMoneyPercent
        awayTicketMoneyPercent
        drawTicketMoneyPercent
        overTicketPercent
        underTicketPercent
        publicSpreadHome
        publicSpreadAway
        publicMoneyHome
        publicMoneyAway
        publicMoneyDraw
        publicTotalOver
        publicTotalUnder
      }
    }
    atpEvents: events(league: ATP, before: $before, after: $after, eventType: TEAM, limit: 6, freeGamesFirst: true) {
      id
      slugId
      tournamentRound
      freeGame
      channel
      startDate
      league {
        id
        name
      }
      tournament {
        id
        slugId
        name
      }
      mlRating(bookUuid: $bookId) {
        rating
        differential
        preferredLine
        preferredTeamId
        preferredWinPercent
        preferredMoneyline
      }
      homeTeam {
        id
        fullName
        lastName
        preferredAbbreviation
        country
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
          rank
        }
      }
      awayTeam {
        id
        fullName
        lastName
        preferredAbbreviation
        country
        teamStats {
          id
          wins
          losses
          suties
          atswins
          atslosses
          rank
        }
      }
      lines(bookIds: [$bookId], period: FULLGAME, lineType: [CURRENT]) {
        id
        bookId
        time
        type
        lineType
        period
        awaySpread
        awayMoney
        awayPrice
        homeSpread
        homeMoney
        homePrice
        drawMoney
        total
        overPrice
        underPrice
      }
      eventStats(period: FULLGAME) {
        id
        period
        homeSpreadCount
        awaySpreadCount
        homeMoneyCount
        awayMoneyCount
        drawMoneyCount
        overCount
        underCount
        homeSharpSpreadPercent
        awaySharpSpreadPercent
        homeSharpMoneyPercent
        awaySharpMoneyPercent
        drawSharpMoneyPercent
        overSharpPercent
        underSharpPercent
        homeTicketSpreadPercent
        awayTicketSpreadPercent
        homeTicketMoneyPercent
        awayTicketMoneyPercent
        drawTicketMoneyPercent
        overTicketPercent
        underTicketPercent
        publicSpreadHome
        publicSpreadAway
        publicMoneyHome
        publicMoneyAway
        publicMoneyDraw
        publicTotalOver
        publicTotalUnder
      }
    }
  }
`;

export const EVENT_PICKS_FRAGMENT = `#graphql
... on Event {
  id
  tiData
  freeGame
  sportsdataGameId
  channel
  slugId
  eventState
  startDate
  awayRot
  awayScore
  awayTeamSeed
  homeRot
  homeScore
  homeTeamSeed
  period
  statfoxGamecode
  awayTeamPowerRating
  homeTeamPowerRating
  neutral
  stadium
  location
  communityStats {
    id
    betType
    awayCount
    homeCount
    drawCount
  }
  league {
    id
    name
  }
  periodScores {
    id
    eventId
    period
    time
    homeScore
    awayScore
    order
  }
}`;

// Cacheable
export const EVENTS_PICKS_QUERY: DocumentNode = gql`
  query eventsQuery(
    $league: LeagueEnum!
    $before: DateTime
    $after: DateTime
    $period: [LinePeriodEnum]
    $books: [UUID]
    $book: UUID!
    $lineType: [LineTypeEnum]
    $conference: CFBConferenceEnum
    $hasTeamPowerProjectionGrades: Boolean!
    $hasFGspreadPowerRating: Boolean!
    $hasFGmoneylinePowerRating: Boolean!
    $hasFGtotalPowerRating: Boolean!
    $has1HspreadPowerRating: Boolean!
    $has1HmoneylinePowerRating: Boolean!
    $has1HtotalPowerRating: Boolean!
    $has1PspreadPowerRating: Boolean!
    $has1PmoneylinePowerRating: Boolean!
    $has1PtotalPowerRating: Boolean!
    $hasPitchers: Boolean!
    $hasInjuries: Boolean!
    $isNBA: Boolean!
  ) {
    events(
      league: $league
      before: $before
      after: $after
      eventType: TEAM
      conference: $conference
    ) {
      ${EVENT_PICKS_FRAGMENT}
      projection @include(if: $hasTeamPowerProjectionGrades) {
        projHomeScore
        projAwayScore
        projHomeWinPercent
        projAwayWinPercent
        projHomeFirstHalfScore
        projAwayFirstHalfScore
        projHomeFirstHalfWinPercent
        projAwayFirstHalfWinPercent
        projHomeKeyPlayerGradeScore
        projHomeKeyPlayerGrade
        projAwayKeyPlayerGradeScore
        projAwayKeyPlayerGrade
        projHomeOffenseGradeScore
        projHomeOffenseGrade
        projAwayOffenseGradeScore
        projAwayOffenseGrade
        projHomeDefenseGradeScore
        projHomeDefenseGrade
        projAwayDefenseGradeScore
        projAwayDefenseGrade
        projHomeTeamGrade
        projHomeTeamGradeScore
        projAwayTeamGrade
        projAwayTeamGradeScore
        projHomeGoalie
        projAwayGoalie
        projHomeShots
        projHomeShotsOnGoal
        projAwayShots
        projAwayShotsOnGoal
      }
      spRating(bookUuid: $book) @include(if: $hasFGspreadPowerRating) {
        rating
        differential
        preferredLine
        preferredTeamId
      }
      mlRating(bookUuid: $book) @include(if: $hasFGmoneylinePowerRating) {
        rating
        differential
        preferredLine
        preferredTeamId
        preferredWinPercent
        preferredMoneyline
      }
      ouRating(bookUuid: $book) @include(if: $hasFGtotalPowerRating) {
        rating
        differential
        preferredLine
        preferredTotal
        preferredWinPercent
      }
      sp1HRating(bookUuid: $book) @include(if: $has1HspreadPowerRating) {
        rating
        differential
        preferredLine
        preferredTeamId
      }
      ml1HRating(bookUuid: $book) @include(if: $has1HmoneylinePowerRating) {
        rating
        differential
        preferredLine
        preferredTeamId
      }
      ou1HRating(bookUuid: $book) @include(if: $has1HtotalPowerRating) {
        rating
        differential
        preferredLine
        preferredTotal
      }
      sp1PRating(bookUuid: $book) @include(if: $has1PspreadPowerRating) {
        rating
        differential
        preferredLine
        preferredTeamId
      }
      ml1PRating(bookUuid: $book) @include(if: $has1PmoneylinePowerRating) {
        rating
        differential
        preferredLine
        preferredTeamId
      }
      ou1PRating(bookUuid: $book) @include(if: $has1PtotalPowerRating) {
        rating
        differential
        preferredLine
        preferredTotal
      }
      awayTeamPitcher @include(if: $hasPitchers) {
        id
        fullName
        firstName
        lastName
        position
        imageUrl
        wins
        losses
        avgInnPitched
        earnedRunAvg
      }
      homeTeamPitcher @include(if: $hasPitchers) {
        id
        fullName
        firstName
        lastName
        position
        imageUrl
        wins
        losses
        avgInnPitched
        earnedRunAvg
      }
      homeTeam {
        id
        fullName
        lastName
        preferredAbbreviation
        country
        conference
        division
        injuredPlayers @include(if: $hasInjuries) {
          id
          fullName
          imageUrl
          injuryStatus
          active
          position
          depthOrder
          news(maxPriority: 3, injury: true) {
            id
            playerId
            title
            newsTime
          }
        }
        teamStats {
          id
          atsUnits
          atswins
          atslosses
          atsties
          atsWinPercent
          games
          netUnits
          overPercent
          overs
          pushes
          underPercent
          unders
          rank
          wins
          losses
          confWins
          confLosses
          pointsAgainst @include(if: $isNBA)
          pointsFor @include(if: $isNBA)
          homeSuWins
          awaySuWins
          homeSuLosses
          awaySuLosses
          homeSuTies
          awaySuTies
          homeAtsWins
          awayAtsWins
          homeAtsLosses
          awayAtsLosses
          homeAtsTies
          awayAtsTies
          homeOvers
          awayOvers
          homeUnders
          awayUnders
          homePushes
          awayPushes
        }
      }
      awayTeam {
        id
        fullName
        lastName
        preferredAbbreviation
        country
        conference
        division
        injuredPlayers @include(if: $hasInjuries) {
          id
          fullName
          imageUrl
          injuryStatus
          active
          position
          depthOrder
          news(maxPriority: 3, injury: true) {
            id
            playerId
            title
            newsTime
          }
        }
        teamStats {
          id
          atsUnits
          atswins
          atslosses
          atsties
          atsWinPercent
          games
          netUnits
          overPercent
          overs
          pushes
          underPercent
          unders
          rank
          wins
          losses
          confWins
          confLosses
          pointsAgainst @include(if: $isNBA)
          pointsFor @include(if: $isNBA)
          homeSuWins
          awaySuWins
          homeSuLosses
          awaySuLosses
          homeSuTies
          awaySuTies
          homeAtsWins
          awayAtsWins
          homeAtsLosses
          awayAtsLosses
          homeAtsTies
          awayAtsTies
          homeOvers
          awayOvers
          homeUnders
          awayUnders
          homePushes
          awayPushes
        }
      }
      lines(bookIds: $books, period: $period, lineType: $lineType) {
        bookId
        book {
          id
          name
        }
        id
        time
        type
        lineType
        period
        awaySpread
        awayMoney
        awayPrice
        homeSpread
        homeMoney
        homePrice
        drawMoney
        total
        overPrice
        underPrice
      }
      eventStats(period: $period) {
        id
        period
        homeSpreadCount
        awaySpreadCount
        homeMoneyCount
        awayMoneyCount
        drawMoneyCount
        overCount
        underCount
        homeSharpSpreadPercent
        awaySharpSpreadPercent
        homeSharpMoneyPercent
        awaySharpMoneyPercent
        drawSharpMoneyPercent
        overSharpPercent
        underSharpPercent
        homeTicketSpreadPercent
        awayTicketSpreadPercent
        homeTicketMoneyPercent
        awayTicketMoneyPercent
        drawTicketMoneyPercent
        overTicketPercent
        underTicketPercent
        publicSpreadHome
        publicSpreadAway
        publicMoneyHome
        publicMoneyAway
        publicMoneyDraw
        publicTotalOver
        publicTotalUnder
      }
      eventTrends(bookUuid: $book, trendTypes: [ATS, ML, HTATS, OU]) {
        id
        teamId
        timePeriod
        description
        typeGames
        rating
        trendType
        reportType
        wins
        losses
        games
        units
      }
    }
  }
`;

// Cacheable
// For non-paid, non-time-sensitive data
export const EVENT_STATIC_QUERY = gql`
  query eventStaticQuery($slugId: String!) {
    event(slugId: $slugId) {
      id
      tiData
      freeGame
      slugId
      sportsdataGameId
      channel
      tournamentRound
      eventState
      startDate
      awayRot
      awayScore
      awayTeamSeed
      homeRot
      homeScore
      homeTeamSeed
      period
      statfoxGamecode
      awayTeamPowerRating
      homeTeamPowerRating
      neutral
      stadium
      location
      analysis
      preferredTeamId
      league {
        id
        name
      }
      homeTeam {
        id
        fullName
        lastName
        preferredAbbreviation
        country
        teamStats {
          id
          rank
          wins
          losses
          suties
          confWins
          confLosses
        }
      }
      awayTeam {
        id
        fullName
        lastName
        preferredAbbreviation
        country
        teamStats {
          id
          rank
          wins
          losses
          suties
          confWins
          confLosses
        }
      }
    }
    prismicGame(lang: "en-us", uid: $slugId) {
      intro_paragraph
      home_team_preview
      away_team_preview
      full_game_prediction
      meta_title
      meta_description
      author {
        ... on PrismicAuthor {
          _meta {
            id
          }
          full_name
          summary
          photo
        }
      }
      _meta {
        uid
        lastPublicationDate
        firstPublicationDate
      }
    }
  }
`;

const CONSENSUS_UUID_FRAGMENT = `"${DEFAULT_BOOK_ID}"`;

// prettier-ignore
export const EVENT_FRAGMENT = `#graphql
... on Event {
  id
  tiData
  freeGame
  slugId
  sportsdataGameId
  channel
  tournamentRound
  eventState
  startDate
  awayRot
  awayScore
  awayTeamSeed
  homeRot
  homeScore
  homeTeamSeed
  period
  statfoxGamecode
  awayTeamPowerRating
  homeTeamPowerRating
  neutral
  stadium
  location
  analysis
  preferredTeamId
  league {
    id
    name
  }
  projection {
    projHomeScore
    projAwayScore
    projHomeWinPercent
    projAwayWinPercent
    projHomeFirstHalfScore
    projAwayFirstHalfScore
    projHomeFirstHalfWinPercent
    projAwayFirstHalfWinPercent
    projHomeKeyPlayerGradeScore
    projHomeKeyPlayerGrade
    projAwayKeyPlayerGradeScore
    projAwayKeyPlayerGrade
    projHomeOffenseGradeScore
    projHomeOffenseGrade
    projAwayOffenseGradeScore
    projAwayOffenseGrade
    projHomeDefenseGradeScore
    projHomeDefenseGrade
    projAwayDefenseGradeScore
    projAwayDefenseGrade
    projHomeTeamGrade
    projHomeTeamGradeScore
    projAwayTeamGrade
    projAwayTeamGradeScore
    projHomeGoalie
    projAwayGoalie
    projHomeShots
    projHomeShotsOnGoal
    projAwayShots
    projAwayShotsOnGoal
  }
  spRating(bookUuid: ${CONSENSUS_UUID_FRAGMENT}) {
    rating
    differential
    preferredLine
    preferredTeamId
  }
  mlRating(bookUuid: ${CONSENSUS_UUID_FRAGMENT}) {
    rating
    differential
    preferredLine
    preferredTeamId
    preferredWinPercent
    preferredMoneyline
  }
  ouRating(bookUuid: ${CONSENSUS_UUID_FRAGMENT}) {
    rating
    differential
    preferredLine
    preferredTotal
    preferredWinPercent
  }
  sp1HRating(bookUuid: ${CONSENSUS_UUID_FRAGMENT}) {
    rating
    differential
    preferredLine
    preferredTeamId
  }
  ml1HRating(bookUuid: ${CONSENSUS_UUID_FRAGMENT}) {
    rating
    differential
    preferredLine
    preferredTeamId
  }
  ou1HRating(bookUuid: ${CONSENSUS_UUID_FRAGMENT}) {
    rating
    differential
    preferredLine
    preferredTotal
  }
  sp1PRating(bookUuid: ${CONSENSUS_UUID_FRAGMENT}) {
    rating
    differential
    preferredLine
    preferredTeamId
  }
  ml1PRating(bookUuid: ${CONSENSUS_UUID_FRAGMENT}) {
    rating
    differential
    preferredLine
    preferredTeamId
  }
  ou1PRating(bookUuid: ${CONSENSUS_UUID_FRAGMENT}) {
    rating
    differential
    preferredLine
    preferredTotal
  }
  awayTeamPitcher {
    id
    fullName
    firstName
    lastName
    position
    imageUrl
    wins
    losses
    avgInnPitched
    earnedRunAvg
  }
  homeTeamPitcher {
    id
    fullName
    firstName
    lastName
    position
    imageUrl
    wins
    losses
    avgInnPitched
    earnedRunAvg
  }
  homeTeam {
    id
    fullName
    lastName
    preferredAbbreviation
    country
    conference
    division
    trends {
      id
      teamId
      trendCode
      timePeriod
      timePeriodRank
      trendType
      reportType
      description
      games
      typeGames
      wins
      losses
      rating
      line
      lineGames
      total
      totalGames
      units
    }
    teamStats {
      id
      atsUnits
      atswins
      atslosses
      atsties
      atsWinPercent
      games
      netUnits
      overPercent
      overs
      pushes
      underPercent
      unders
      rank
      wins
      losses
      suties
      games
      confWins
      confLosses
      atswins
      atslosses
      atsWinPercent
      overPercent
      byeWeek
      overtimeLosses
    }
    injuredPlayers {
      id
      fullName
      imageUrl
      injuryStatus
      active
      position
      depthOrder
      news(maxPriority:3, injury: true) {
        id
        title
        analysis
        priority
      }
    }
    news {
      id
      teamId
      title
      category
      description
      newsTime
    }
    schedule {
      id
      slugId
      startDate
      awayScore
      homeScore
      tiData
      week
      matchup {
        id
        bye
        seasonType
        weekId
      }
      homeTeam {
        id
        fullName
        lastName
        preferredAbbreviation
        country
        conference
        division
        teamStats {
          id
          rank
          byeWeek
        }
      }
      awayTeam {
        id
        fullName
        lastName
        preferredAbbreviation
        country
        conference
        division
        teamStats {
          id
          rank
          byeWeek
        }
      }
      line(bookId: ${CONSENSUS_UUID_FRAGMENT}, lineType: CURRENT, period: FULLGAME) {
        id
        awaySpread
        awayMoney
        homeSpread
        homeMoney
        total
        homeAtsWin
        awayAtsWin
        ouWin
      }
    }
  }
  homeLineup {
    id
    position
    orderRank
    player {
      id
      fullName
      imageUrl
      injuryStatus
      active
      position
      depthOrder
      extraData
      wins
      losses
      games
      news(maxPriority: 4) {
        id
        title
        analysis
        priority
      }
    }
  }
  awayTeam {
    id
    fullName
    lastName
    preferredAbbreviation
    country
    conference
    division
    trends {
      id
      teamId
      trendCode
      timePeriod
      timePeriodRank
      trendType
      reportType
      description
      games
      typeGames
      wins
      losses
      rating
      line
      lineGames
      total
      totalGames
      units
    }
    teamStats {
      id
      atsUnits
      atswins
      atslosses
      atsties
      atsWinPercent
      games
      netUnits
      overPercent
      overs
      pushes
      underPercent
      unders
      rank
      wins
      losses
      suties
      games
      confWins
      confLosses
      atswins
      atslosses
      overPercent
      atsWinPercent
      byeWeek
      overtimeLosses
    }
    injuredPlayers {
      id
      fullName
      imageUrl
      injuryStatus
      active
      position
      depthOrder
      news(maxPriority: 3, injury: true) {
        id
        title
        analysis
        priority
      }
    }
    news {
      id
      teamId
      title
      category
      description
      newsTime
    }
    schedule {
      id
      slugId
      startDate
      awayScore
      homeScore
      tiData
      week
      matchup {
        id
        bye
        seasonType
        weekId
      }
      homeTeam {
        id
        fullName
        lastName
        preferredAbbreviation
        country
        conference
        division
        teamStats {
          id
          rank
          byeWeek
        }
      }
      awayTeam {
        id
        fullName
        lastName
        preferredAbbreviation
        country
        conference
        division
        teamStats {
          id
          rank
          byeWeek
        }
      }
      line(bookId: ${CONSENSUS_UUID_FRAGMENT}, lineType: CURRENT, period: FULLGAME) {
        id
        awaySpread
        awayMoney
        homeSpread
        homeMoney
        total
        homeAtsWin
        awayAtsWin
        ouWin
      }
    }
  }
  awayLineup {
    id
    position
    orderRank
    player {
      id
      fullName
      imageUrl
      injuryStatus
      active
      position
      depthOrder
      news (maxPriority: 4) {
        id
        title
        analysis
        priority
      }
    }
  }
  lines(bookIds: [${CONSENSUS_UUID_FRAGMENT}], period: ${PERIODS_FRAGMENT}, lineType: [CURRENT, OPEN]) {
    id
    bookId
    id
    time
    type
    lineType
    period
    awaySpread
    awayMoney
    awayPrice
    homeSpread
    homeMoney
    homePrice
    drawMoney
    total
    overPrice
    underPrice
  }
  periodScores {
    id
    eventId
    period
    time
    homeScore
    awayScore
    order
  }
  eventStats(period: ${PERIODS_FRAGMENT}) {
    id
    period
    homeSpreadCount
    awaySpreadCount
    homeMoneyCount
    awayMoneyCount
    drawMoneyCount
    overCount
    underCount
    homeSharpSpreadPercent
    awaySharpSpreadPercent
    homeSharpMoneyPercent
    awaySharpMoneyPercent
    drawSharpMoneyPercent
    overSharpPercent
    underSharpPercent
    homeTicketSpreadPercent
    awayTicketSpreadPercent
    homeTicketMoneyPercent
    awayTicketMoneyPercent
    drawTicketMoneyPercent
    overTicketPercent
    underTicketPercent
    publicSpreadHome
    publicSpreadAway
    publicMoneyHome
    publicMoneyAway
    publicMoneyDraw
    publicTotalOver
    publicTotalUnder
  }
  eventTrends(bookUuid: ${CONSENSUS_UUID_FRAGMENT}, trendTypes: [ATS, ML, HTATS, OU]) {
    id
    teamId
    timePeriod
    description
    typeGames
    rating
    trendType
    reportType
    wins
    losses
    games
    units
  }
}
`;

// Cacheable
export const EVENT_BY_SLUG_QUERY: DocumentNode = gql`
  query eventQuery($slugId: String) {
    event(slugId: $slugId) {
      ${EVENT_FRAGMENT}
    }
  }
`;

export const FREE_EVENTS_QUERY: DocumentNode = gql`
  query freeEventsQuery {
    freeEvents {
      ${EVENT_FRAGMENT}
    }
  }
`;

export const ALL_EVENTS_SLUGS_QUERY = gql`
  query allEventsSlugsQuery {
    slugs(leagues: [NFL, NBA, NHL, MLB, CFB, CBK, XFL, EPL, DEB, MLS, ESP, ITSA, UCL, ATP, WTA]) {
      slugId
      league {
        name
      }
    }
  }
`;

export const EVENTS_SLUGS_QUERY = gql`
  query eventsSlugsQuery($leagues: [LeagueEnum!]) {
    slugs(leagues: $leagues) {
      slugId
      league {
        name
      }
    }
  }
`;

// Cacheable
export const PLAYER_PROPS_QUERY = gql`
  query playerPropsQuery($slugId: String!) {
    event(slugId: $slugId) {
      id
      slugId
      sportsdataGameId
      league {
        id
        name
      }
      homeTeam {
        id
        fullName
        lastName
        preferredAbbreviation
      }
      awayTeam {
        id
        fullName
        lastName
        preferredAbbreviation
      }
      homePlayerProps {
        id
        player {
          id
          fullName
          imageUrl
          position
          injuryStatus
          news(maxPriority: 4) {
            id
            analysis
            newsTime
          }
        }
        props {
          id
          money
          propName
          propAbbreviation
          propRow
          stars
          bookValue
          projectedValue
          direction
          explanation
          winOrLoss
          statValue
          book {
            id
            name
          }
        }
      }
      awayPlayerProps {
        id
        player {
          id
          fullName
          imageUrl
          position
          injuryStatus
          news(maxPriority: 4) {
            id
            analysis
            newsTime
          }
        }
        props {
          id
          money
          propName
          propAbbreviation
          propRow
          stars
          bookValue
          projectedValue
          direction
          explanation
          winOrLoss
          statValue
          book {
            id
            name
          }
        }
      }
    }
  }
`;
export const PLAYER_PROPS_EVENTS_QUERY = gql`
  query playerPropsEventQuery($league: LeagueEnum!, $before: DateTime, $after: DateTime) {
    events(league: $league, before: $before, after: $after, eventType: TEAM) {
      id
      slugId
      startDate
      league {
        id
        name
      }
      homeTeam {
        id
        preferredAbbreviation
        country
        teamStats {
          id
          wins
          losses
        }
      }
      awayTeam {
        id
        preferredAbbreviation
        country
        teamStats {
          id
          wins
          losses
        }
      }
    }
  }
`;

// Cacheable
export const LIVE_EVENTS_QUERY = gql`
  query liveEventsQuery($league: LeagueEnum!, $before: DateTime, $after: DateTime, $books: [UUID]) {
    events(league: $league, before: $before, after: $after, eventType: TEAM) {
      id
      slugId
      eventState
      startDate
      channel
      awayScore
      awayTeamSeed
      homeScore
      homeTeamSeed
      tiData
      period
      league {
        id
        name
      }
      homeTeam {
        id
        fullName
        lastName
        preferredAbbreviation
        country
        teamStats {
          id
          rank
        }
      }
      awayTeam {
        id
        fullName
        lastName
        preferredAbbreviation
        country
        teamStats {
          id
          rank
        }
      }
      lines(bookIds: $books, period: [FULLGAME], lineType: [CURRENT]) {
        id
        type
        period
        homeSpread
        awaySpread
        awayMoney
        homeMoney
        total
        lineType
        bookId
      }
    }
  }
`;

// Cacheable
export const TEAM_SUMMARY_QUERY = gql`
  query teamSummaryQuery($slugId: String!, $hasPitchers: Boolean!, $hasGoals: Boolean!) {
    event(slugId: $slugId) {
      id
      slugId
      league {
        id
        name
      }
      homeTeam {
        id
        fullName
        lastName
        preferredAbbreviation
        country
        teamStats {
          id
          rank
          wins
          losses
          confWins
          confLosses
          coachName
          tennisStats
          offReturningStarters
          defReturningStarters
          qbReturning
          ppg
          htPpg
          oppPpg
          atsWinPercent
          overPercent
          teamRunsGame @include(if: $hasPitchers)
          oppRunsGame @include(if: $hasPitchers)
          teamHr @include(if: $hasPitchers)
          oppHr @include(if: $hasPitchers)
          games @include(if: $hasGoals)
          goals @include(if: $hasGoals)
          shots @include(if: $hasGoals)
          shotsOnGoal @include(if: $hasGoals)
          form @include(if: $hasGoals)
        }
      }
      awayTeam {
        id
        fullName
        lastName
        preferredAbbreviation
        country
        teamStats {
          id
          rank
          wins
          losses
          confWins
          confLosses
          coachName
          tennisStats
          offReturningStarters
          defReturningStarters
          qbReturning
          ppg
          htPpg
          oppPpg
          atsWinPercent
          overPercent
          teamRunsGame @include(if: $hasPitchers)
          oppRunsGame @include(if: $hasPitchers)
          teamHr @include(if: $hasPitchers)
          oppHr @include(if: $hasPitchers)
          games @include(if: $hasGoals)
          goals @include(if: $hasGoals)
          shots @include(if: $hasGoals)
          shotsOnGoal @include(if: $hasGoals)
          form @include(if: $hasGoals)
        }
      }
    }
  }
`;

// Cacheable
export const TEAM_RANKINGS_QUERY = gql`
  query teamRankingsQuery(
    $after: DateTime!
    $before: DateTime!
    $league: LeagueEnum!
    $groupName: EventGroupEnum
    $includeIncomplete: Boolean
  ) {
    events(
      after: $after
      before: $before
      league: $league
      groupName: $groupName
      includeIncomplete: $includeIncomplete
    ) {
      id
      slugId
      tournamentRound
      awayTeamSeed
      homeTeamSeed
      awayTeam {
        id
        fullName
        preferredAbbreviation
        country
        conference
        teamStats {
          id
          rank
          wins
          losses
        }
      }
      homeTeam {
        id
        fullName
        preferredAbbreviation
        country
        conference
        teamStats {
          id
          rank
          wins
          losses
        }
      }
      projection {
        id
        projAwayOffenseGradeScore
        projHomeOffenseGradeScore
        projAwayDefenseGradeScore
        projHomeDefenseGradeScore
        projAwayKeyPlayerGradeScore
        projHomeKeyPlayerGradeScore
        projAwayTeamGradeScore
        projHomeTeamGradeScore
      }
    }
    paywall {
      valueEvents
      sharpEvents
    }
  }
`;

// Cacheable
export const BOOKS_QUERY = gql`
  query booksQuery($eventId: UUID!, $period: [LinePeriodEnum!]) {
    books {
      id
      name
      locales
      displayTitle
      rank
      afilliateUrl
      lines(eventId: $eventId, lineType: [CURRENT, OPEN, PREVIOUS], period: $period) {
        id
        bookId
        time
        type
        lineType
        period
        awaySpread
        awayMoney
        homeSpread
        homeMoney
        drawMoney
        total
      }
    }
  }
`;

// Cacheable
export const BEST_LINES_QUERY = gql`
  query bestLinesQuery($slugId: String!, $period: [LinePeriodEnum!], $locale: String) {
    books(locale: $locale) {
      id
      name
      locales
      displayTitle
      rank
      afilliateUrl
      lines(slugId: $slugId, lineType: [CURRENT], period: $period) {
        id
        bookId
        id
        time
        type
        lineType
        period
        awaySpread
        awayMoney
        awayPrice
        homeSpread
        homeMoney
        homePrice
        drawMoney
        total
        overPrice
        underPrice
      }
      prismicOffers(locale: $locale) {
        sportsbook_name
        web_link
        promo_code
        how_it_works
      }
    }
  }
`;

// Cacheable
export const RECOMMENDED_BETS_QUERY = gql`
  query recommendedBets($league: LeagueEnum) {
    recommendedBets(league: $league) {
      id
      rank
      winPercent
      description
      eventId
      slugId
      eventState
      startDate
      title
      sportsdataGameId
      totalReturn
      bestLine {
        book {
          id
          afilliateUrl
          name
          prismicOffers {
            sportsbook_name
            web_link
            promo_code
            how_it_works
          }
        }
        homeValue
        awayValue
        homeText
        awayText
      }
      line {
        betType
        stars
        preferredTeam
        homeText
        awayText
      }
      homeTeam {
        id
        preferredAbbreviation
        lastName
      }
      awayTeam {
        id
        preferredAbbreviation
        lastName
      }
      league {
        id
        name
      }
    }
  }
`;

// Cacheable
export const POPULAR_EVENTS_QUERY = gql`
  query popularEvents($league: LeagueEnum) {
    popularEvents(league: $league) {
      id
      slugId
      channel
      startDate
      eventState
      visitorCount
      visitorTrend
      homeTeam {
        id
        preferredAbbreviation
      }
      awayTeam {
        id
        preferredAbbreviation
      }
      league {
        id
        name
      }
    }
  }
`;

export const POPULAR_PLAYERS_PROPS_QUERY = gql`
  query PlayerProps($league: LeagueEnum!, $after: DateTime, $before: DateTime) {
    playerPropsTonight(league: $league, after: $after, before: $before) {
      id
      bookValue
      propAbbreviation
      player {
        id
        firstName
        lastName
        fullName
        position
        imageUrl
      }
      team {
        id
        lastName
      }
      event {
        freeGame
        slugId
        channel
        startDate
        league {
          name
        }
        awayTeam {
          id
          lastName
        }
        homeTeam {
          id
          lastName
        }
      }
    }
  }
`;

// Not sure if cacheable
export const TOURNAMENT_QUERY = gql`
  query tournamentQuery($slugId: String, $bookId: UUID) {
    tournament(slugId: $slugId) {
      id
      slugId
      name
      startDate
      endDate
      leagueId
      startYear
      seasonStartTime
      playoffStartDate
      playoffEndDate
      league {
        id
        name
      }
      tiData
      events {
        id
        slugId
        tournamentRound
        freeGame
        startDate
        tiData
        league {
          id
          name
        }
        tournament {
          id
          slugId
          name
        }
        spRating(bookUuid: $bookId) {
          rating
          differential
          preferredLine
          preferredTeamId
        }
        mlRating(bookUuid: $bookId) {
          rating
          differential
          preferredLine
          preferredTeamId
          preferredWinPercent
          preferredMoneyline
        }
        homeTeam {
          id
          fullName
          lastName
          preferredAbbreviation
          country
          teamStats {
            id
            wins
            losses
            atswins
            atslosses
            rank
          }
        }
        awayTeam {
          id
          fullName
          lastName
          preferredAbbreviation
          country
          teamStats {
            id
            wins
            losses
            atswins
            atslosses
            rank
          }
        }
        lines(bookIds: [$bookId], period: FULLGAME, lineType: [CURRENT]) {
          id
          bookId
          time
          type
          lineType
          period
          awaySpread
          awayMoney
          awayPrice
          homeSpread
          homeMoney
          homePrice
          drawMoney
          total
          overPrice
          underPrice
        }
        eventStats(period: FULLGAME) {
          id
          period
          homeSpreadCount
          awaySpreadCount
          homeMoneyCount
          awayMoneyCount
          drawMoneyCount
          overCount
          underCount
          homeSharpSpreadPercent
          awaySharpSpreadPercent
          homeSharpMoneyPercent
          awaySharpMoneyPercent
          drawSharpMoneyPercent
          overSharpPercent
          underSharpPercent
          homeTicketSpreadPercent
          awayTicketSpreadPercent
          homeTicketMoneyPercent
          awayTicketMoneyPercent
          drawTicketMoneyPercent
          overTicketPercent
          underTicketPercent
          publicSpreadHome
          publicSpreadAway
          publicMoneyHome
          publicMoneyAway
          publicMoneyDraw
          publicTotalOver
          publicTotalUnder
        }
        eventTrends(bookUuid: $bookId, trendTypes: [ATS, ML, HTATS, OU]) {
          id
          teamId
          timePeriod
          description
          typeGames
          rating
          trendType
          reportType
          wins
          losses
          games
          units
        }
      }
    }
  }
`;

export type ArticleTableSlice = {
  type: "custom_table";
  primary: {
    /** Comma separated values */
    team_names: string | null;
    /** Colon separated values. The value after the colon is the code key
     * @example ATS Wins: atsWins
     */
    sort_field: string | null;
    sort_direction: "asc" | "desc" | null;
  };
  fields: Array<{
    __typename?: string;
    column_name: string;
    position?: number | null;
  }>;
};
// Keep web and mobile in sync to get more cache hits
const ARTICLE_INLINE_FRAGMENT = `#graphql
... on PrismicNews_basic {
  login_to_view
  is_premium
  hasAccess
  league
  sidebar
  app_ad
  title
  subtitle
  article_image
  seo_title
  description
  canonical_url
  noindex
  google_paid_search_only
  og_image
  tags
  author_link {
    ... on PrismicAuthor {
      full_name
      summary
      photo
      _meta {
        id
      }
    }
  }
  _meta {
    id
    uid
    type
    firstPublicationDate
    lastPublicationDate
  }
  body {
    ... on PrismicNews_basicBodyContest_widget {
      type
      primary {
        widget_id
      }
    }
    ... on PrismicNews_basicBodyCustom_table {
      type
      primary {
        team_names
        sort_field
        sort_direction
      }
      fields {
        column_name
        position
      }
    }

    ... on PrismicNews_basicBodyEmail_capture {
      type
    }

    ... on PrismicNews_basicBodyFree_sms_pick {
      type
    }
    ... on PrismicNews_basicBodyDailymotion_video {
      type
      primary {
        video_id
      }
    }
    ... on PrismicNews_basicBodyAnchor {
      type
      primary {
        anchor_id
      }
    }
    ... on PrismicNews_basicBodyAttach_sportsbook_offer {
      type
      primary {
        sportsbook_offer {
          ... on PrismicSportsbook_offer {
            prismic_title # id
            welcome_offer
            rating
            staff_pick
            web_link
            mobile_link
            how_it_works
            image
            sportsbook_name
          }
        }
      }
    }
    ... on PrismicNews_basicBodyAudio {
      type
      primary {
        audio_url
      }
    }
    ... on PrismicNews_basicBodySportsbook_offers {
      type
    }
    ... on PrismicNews_basicBodyText {
      type
      primary {
        text
      }
    }
    ... on PrismicNews_basicBodyHtml {
      type
      primary {
        html
      }
    }
    ... on PrismicNews_basicBodyGoogle_ad {
      type
      primary {
        ad_unit
        slot_id
        width
        height
      }
    }
    ... on PrismicNews_basicBodyImage {
      type
      primary {
        image
        hyperlink {
          ... on Prismic_ExternalLink {
            url
            target
            _linkType
          }
        }
        formatting
      }
    }
    ... on PrismicNews_basicBodyEmbed {
      type
      primary {
        embed_url
      }
    }
    ... on PrismicNews_basicBodyLeaderboard {
      type
      primary {
        link {
          ... on Prismic_ExternalLink {
            url
          }
        }
      }
    }
    ... on PrismicNews_basicBody2_column_table {
      type
      fields {
        column_1
        column_2
      }
    }
    ... on PrismicNews_basicBody2_column_table1 {
      type
      fields {
        column_1
        column_2
        column_3
      }
    }
    ... on PrismicNews_basicBody3_column_table {
      type
      fields {
        column_1
        column_2
        column_3
        column_4
      }
    }
    ... on PrismicNews_basicBody4_column_table {
      type
      fields {
        column_1
        column_2
        column_3
        column_4
        column_5
      }
    }
    ... on PrismicNews_basicBodyTeam_grades {
      type
      primary {
        game_slug
      }
    }
    ... on PrismicNews_basicBodyLines_and_offers {
      type
      primary {
        game_slug
      }
    }
    ... on PrismicNews_basicBodyPicks {
      type
      primary {
        game_slug
      }
    }
  }
}
`;

// Cacheable
// Needs to be computed at build time or we get infinite loop for some reason
export const ARTICLE_QUERY = parse(`#graphql
query article($uid: String!) {
  article: prismicNews_basic(uid: $uid, lang: "en-us") {
    ${ARTICLE_INLINE_FRAGMENT}
  }
}
`);

const OFFER_INLINE_FRAGMENT = `#graphql
... on PrismicSportsbook_offer {
  _meta {
    id
  }
  sportsbook_name
  welcome_offer
  rating
  staff_pick
  web_link
  promo_code
  how_it_works
  preferred_payment
  unpreferred_payment
  withdrawal_time
  minimum_deposit
  minimum_withdrawal
  recommended_payment
  review_content
  image
}
`;

// Cacheable
export const OFFERS_QUERY = parse(`#graphql
  query offersQuery {
    prismicAllStatess {
      edges {
        node {
          states {
            state {
              ... on PrismicState {
                name
                abbreviation
                offers_content
                offers_title
                offers_description
                sportsbook_offers {
                  sportsbook_offer {
                    ${OFFER_INLINE_FRAGMENT}
                  }
                }
                casino_offers {
                  sportsbook_offer {
                    ${OFFER_INLINE_FRAGMENT}
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);
// Cacheable
export const INFINITE_ARTICLES_QUERY = parse(`#graphql
  query infiniteArticles(
    $where: PrismicWhereNews_basic!
    $first: Int!
    $after: String
  ) {
    prismicAllNews_basics(
      where: $where
      sortBy: meta_firstPublicationDate_DESC
      first: $first
      after: $after
    ) {
      pageInfo {
      	endCursor
      	hasNextPage
      }
      edges {
        cursor
        node {
          ${ARTICLE_INLINE_FRAGMENT}
        }
      }
    }
  }
`);

// Cacheable only if signed out
// Keep web and mobile in sync to get more cache hits
export const LEAGUE_CONFIG_QUERY = parse(`#graphql
  query leagueConfig($league: String, $includeSurveyResponses: Boolean!) {
    prismicAllLeague_configs(where: { league: $league }) {
      edges {
        node {
          _meta {
            id
          }
          popular_tags
          featured_content {
            location
            content {
              ${ARTICLE_INLINE_FRAGMENT}
              ... on PrismicSurvey {
                _meta {
                  id
                  uid
                  type
                  firstPublicationDate
                  lastPublicationDate
                }
                question
                answers {
                  answer
                  count
                }
                response @include(if: $includeSurveyResponses) {
                  answer
                }
              }
            }
          }
        }
      }
    }
  }
`);

// Cacheable
export const POPULAR_AUTHORS_QUERY = parse(`#graphql
  query popularAuthors {
    prismicAllPopular_authorss {
      edges {
        node {
          authors {
            author {
              ... on PrismicAuthor {
                full_name
                role
                summary
                photo
                _meta {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`);

// Cacheable
export const PRISMIC_ALL_STATES = parse(`#graphql
  query allStates {
    prismicAllStates(lang: "en-us", first: 100) {
      edges {
        node {
          abbreviation
          sportsbook_offers {
            sportsbook_offer {
              ... on PrismicSportsbook_offer {
                prismic_title # id
                sportsbook_name
                welcome_offer
                rating
                staff_pick
                web_link
                promo_code
                how_it_works
                review_content
                image
                sportsbook {
                  ... on PrismicSportsbook {
                    name
                    internal_book
                    image
                  }
                }
              }
            }
          }
          sportsbooks {
            sportsbook {
              ... on PrismicSportsbook {
                name
                internal_book
                image
              }
            }
          }
        }
      }
    }
  }
`);

// Not cacheable
export const USER_SPORTSBOOKS = parse(`#graphql
  query userSportsbooks($locale: String!, $includeOffers: Boolean! = false) {
    me {
      id
      books {
        id
      }
    }
    prismicState(uid: $locale, lang: "en-us") {
      name
      sportsbooks {
        sportsbook {
          ... on PrismicSportsbook {
            name
            internal_book
            image
          }
        }
      }
      sportsbook_offers @include(if: $includeOffers) {
        sportsbook_offer {
          ... on PrismicSportsbook_offer {
            prismic_title # id
            sportsbook_name
            welcome_offer
            rating
            staff_pick
            web_link
            promo_code
            how_it_works
            review_content
            image
            sportsbook {
              ... on PrismicSportsbook {
                name
                internal_book
                image
              }
            }
          }
        }
      }
    }
    fallbackState: prismicState(uid: "all", lang: "en-us") {
      name
      sportsbooks {
        sportsbook {
          ... on PrismicSportsbook {
            name
            internal_book
            image
          }
        }
      }
      sportsbook_offers @include(if: $includeOffers) {
        sportsbook_offer {
          ... on PrismicSportsbook_offer {
            prismic_title # id
            sportsbook_name
            welcome_offer
            rating
            staff_pick
            web_link
            promo_code
            how_it_works
            review_content
            image
            sportsbook {
              ... on PrismicSportsbook {
                name
                internal_book
                image
              }
            }
          }
        }
      }
    }
  }
`);

export const RICH_LINK_FRAGMENT = `#graphql
... on PrismicNews_basic {
  title
  image:article_image
  _meta {
    uid
    type
  }
}
... on PrismicBetting_guide {
  title
  image:article_image
  _meta {
    uid
    type
  }
}
... on PrismicGames_table {
  title
  image
  _meta {
    uid
    type
  }
}
`;

export const EXTRAS_CONTENT_QUERY = gql`
  query extrasContent($uid: String!) {
    prismicExtras(uid: $uid, lang: "en-us") {
      content
      seo_description
      seo_h1
      seo_title
    }
  }
`;

export const GAMES_TABLE_SEO_QUERY = gql`
  query gamesTableSEOQuery($uid: String!) {
    prismicGames_table(uid: $uid, lang: "en-us") {
      seo_content {
        bet_type
        bet_category
        text
        seo_title
        h1
        headline
        description
      }
      _meta {
        uid
      }
    }
  }
`;

export const PAID_LANDING_PAGES_SEO_QUERY = gql`
  query paidLandingPagesSEOQuery($uid: String!) {
    prismicPaid_landing_pages(uid: $uid, lang: "en-us") {
      seo_content {
        bet_category
        bet_type
        description
        h1
        headline
        seo_title
        text
      }
    }
  }
`;

export const GAMES_TABLE_SIDEBAR_QUERY = parse(`#graphql
query gamesTableSidebarQuery($uid: String!) {
  prismicGames_table(uid: $uid, lang: "en-us") {
		see_more_from_betql {
      internal_link {
        ${RICH_LINK_FRAGMENT}
      }
      image
      title
      url {
        ... on Prismic_ExternalLink {
          url
        }
      }
    }
    read_more_from_betql {
      internal_link {
        ${RICH_LINK_FRAGMENT}
      }
      image
      title
      url {
        ... on Prismic_ExternalLink {
          url
        }
      }
    }
    _meta {
      uid
    }
  }
}
`);

export const BOOSTS_QUERY = gql`
  query boostsQuery($sportName: SportEnum) {
    oddsBoosts(sportName: $sportName) {
      id
      book {
        id
        name
      }
      sport {
        id
        name
      }
      name
      url
      boostedOdds
      unboostedOdds
      order
      expiresAt
    }
  }
`;

export const SPORTSBOOK_REVIEWS_QUERY = gql`
  query sportsbook_review($sportsbookName: String!) {
    prismicSportsbook_review(uid: $sportsbookName, lang: "en-us") {
      sportsbook_name
      rating
      staff_pick
      sportsbook_logo
      overview_text
      mockup_image
      pros
      cons
      deposit
      withdraw
      inDepthReviews {
        review_title
        review_body
      }
      _meta {
        uid
      }
    }
  }
`;

export const ALL_SPORTSBOOK_REVIEWS_QUERY = gql`
  query allSportsbookReviews {
    prismicAllSportsbook_reviews {
      edges {
        node {
          sportsbook_name
        }
      }
    }
  }
`;

export const PLAYER_STAT_SUMMARY_QUERY = gql`
  query PlayerStatSummary($playerId: UUID!) {
    playerStatSummary(playerId: $playerId) {
      player {
        id
        imageUrl
        fullName
        injuryStatus
        position
        teamId
      }
      team {
        id
        fullName
        preferredAbbreviation
      }
      stats {
        id
        statProperty
        statValue
        statType
        statCategory
        side
        eventId
        player {
          id
        }
      }
      latestStats {
        id
        name
        slugId
        groupName
        startDate
        awayTeam {
          id
          fullName
          preferredAbbreviation
        }
        homeTeam {
          id
          fullName
          preferredAbbreviation
        }
        playerStats {
          id
          statProperty
          statValue
          statType
          statCategory
          side
          player {
            id
          }
        }
      }
      todaysStats {
        id
        slugId
        homePlayerProps {
          player {
            id
          }
          props {
            id
            propName
            bookValue
            projectedValue
            event {
              id
              slugId
            }
          }
        }
        awayPlayerProps {
          player {
            id
          }
          props {
            id
            propName
            bookValue
            projectedValue
            event {
              id
              slugId
            }
          }
        }
      }
    }
  }
`;

export const HOST_SAVED_PICKS_BY_LEAGUE_QUERY = gql`
  query getHostSavedPicksByLeague($league: LeagueEnum, $cursor: String) {
    getHostSavedPicksByLeague(league: $league, after: $cursor) {
      edges {
        cursor
        node {
          notes
          analysis
          betType
          pick
          id
          league {
            id
            name
          }
          team {
            id
            preferredAbbreviation
            imageUrl
          }
          user {
            id
            displayName
            imageUrl
          }
          player {
            id
            fullName
            imageUrl
          }
          __typename
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const PLAYER_SPORTSBOOK_PROPS_PAGE_INFO_QUERY = gql`
  query playerSportsbookPropsPageInformationQuery($uid: String!) {
    prismicSportsbook_player_props_page_information(uid: $uid, lang: "en-us") {
      seo_title
      page_header
      paragraph_descriptions
    }
  }
`;

export type PrismicMetaImage = {
  dimensions: {
    width: number;
    height: number;
  };
  alt: null;
  copyright: null;
  url: string;
};
