import React, { CSSProperties, PureComponent } from "react";
import Icon from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";
import {
  Dropdown as BootStrapDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem as DropdownMenuItem,
} from "reactstrap";
import Link from "next/link";

import styles from "./styles.scss";
import LinkWithHandler from "../LinkWithHandler";

export interface DropdownItem {
  value?: string;
  name: string;
  key?: string;
  href?: string;
}

export interface DropdownProps {
  style?: CSSProperties;
  toggleStyle?: CSSProperties;
  toggleClassName?: string;
  menuStyle?: CSSProperties;
  menuItemStyle?: CSSProperties;
  className?: string;
  onSelectItem(item: DropdownItem, index: number): void;
  selectedIndex: number;
  items: DropdownItem[];
  scroll?: boolean;
}

export interface DropdownState {
  openDropdown: boolean;
}

class Dropdown extends PureComponent<DropdownProps, DropdownState> {
  static defaultProps = {
    onSelectItem: () => {},
    selectedIndex: 0,
    items: [],
    scroll: false,
  };
  constructor(props: DropdownProps) {
    super(props);
    this.state = {
      openDropdown: false,
    };
  }
  toggleDropdown = () => {
    this.setState({ openDropdown: !this.state.openDropdown });
  };
  render() {
    const { openDropdown } = this.state;
    const {
      toggleClassName = "",
      style,
      toggleStyle,
      menuStyle,
      menuItemStyle,
      className,
      onSelectItem,
      selectedIndex,
      items,
      scroll,
    } = this.props;
    const selectedItem = items[selectedIndex];
    return (
      <BootStrapDropdown
        isOpen={openDropdown}
        toggle={this.toggleDropdown}
        style={style}
        className={`rotoql-dropdown ${className}`}
      >
        <DropdownToggle block style={toggleStyle} className={`rotoql-dropdown__button width-lg ${toggleClassName}`}>
          <div className={"dropdown-text"}>{selectedItem?.name}</div>
          <Icon path={mdiChevronDown} size="26px" color="#00214D" />
        </DropdownToggle>
        <DropdownMenu style={menuStyle} className="rotoql-dropdown__menu">
          {items.map((item: DropdownItem, index: number) =>
            item.href ? (
              <Link key={item.key} href={item.href} passHref scroll={scroll} legacyBehavior>
                <LinkWithHandler
                  onCustomClick={() => {
                    onSelectItem(item, index);
                    this.toggleDropdown();
                  }}
                  className={`rotoql-dropdown__item ${index === selectedIndex ? "rotoql-dropdown__item--active" : ""}`}
                >
                  {item.name}
                </LinkWithHandler>
              </Link>
            ) : (
              <DropdownMenuItem
                key={index}
                style={menuItemStyle}
                className={`rotoql-dropdown__item ${index === selectedIndex ? "rotoql-dropdown__item--active" : ""}`}
                onClick={() => onSelectItem(item, index)}
              >
                {item.name}
              </DropdownMenuItem>
            )
          )}
        </DropdownMenu>
        <style jsx global>
          {styles}
        </style>
      </BootStrapDropdown>
    );
  }
}

export default Dropdown;
