const GA4_MEASUREMENT_ID = "G-31HP7JQ53M";
export const isProduction: boolean = process.env.NEXT_PUBLIC_VERCEL_ENV === "production";

let tracker: any;

type GoogleAnalyticObject = {
  hitType?: string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
  eventValue?: number;
  nonInteraction?: boolean;
};

export default {
  sendPageView: () => {
    if (!isProduction) {
      return;
    }
    try {
      // UA (legacy)
      if (tracker) {
        tracker.send("pageview");
      } else {
        tracker = window.ga.getAll()[0];
        tracker.send("pageview");
      }
    } catch (e: any) {
      // Adblock breaks this
      console.log("[GAService] sendPageView error: ", e.message);
    }
  },
  sendEvent: (fieldsObject: GoogleAnalyticObject) => {
    if (!isProduction) {
      return;
    }
    try {
      // UA (legacy)
      if (tracker) {
        tracker.send("event", fieldsObject);
      } else {
        tracker = window.ga.getAll()[0];
        tracker.send("event", fieldsObject);
      }
      // GA4
      window.gtag("event", fieldsObject.eventCategory, { ...fieldsObject, send_to: GA4_MEASUREMENT_ID });
    } catch (e: any) {
      // Adblock breaks this
      console.log("[GAService] sendEvent error: ", e.message, fieldsObject);
    }
  },
  set: (fieldName: string, fieldValue: string) => {
    if (!isProduction) {
      return;
    }
    try {
      // UA (legacy)
      if (tracker) {
        tracker.set(fieldName, fieldValue);
      } else {
        tracker = window.ga.getAll()[0];
        tracker.set(fieldName, fieldValue);
      }
      // GA4
      const fieldObject: { [key: string]: string } = { send_to: GA4_MEASUREMENT_ID };
      fieldObject[fieldName] = fieldValue;
      window.gtag("set", fieldObject);
    } catch (e: any) {
      // Adblock breaks this
      console.log("[GAService] set error: ", e.message, fieldName, fieldValue);
    }
  },
};
