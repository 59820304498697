import React, { ReactNode, forwardRef } from "react";

export interface LinkWithHandlerProps {
  children?: ReactNode;
  className?: string;
  onClick?(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
  onCustomClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
}

/**
 * This element should be used when you want to user a link that also needs to call
 * a custom onClick handler. next/link automatically adds an onClick listener to the
 * anchor tag, so this element will ensure that both the next/link function and our
 * custom function both get called and don't override one and other.
 */
const LinkWithHandler = forwardRef(
  ({ children, className, onClick, onCustomClick, ...props }: LinkWithHandlerProps, ref: any) => {
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (onClick) {
        onClick(event);
      }

      if (onCustomClick) {
        onCustomClick(event);
      }
    };
    return (
      <a ref={ref} {...props} className={className} onClick={handleClick}>
        {children}
      </a>
    );
  }
);

export default LinkWithHandler;
