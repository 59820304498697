import moment from "moment-timezone";
import React, { useState, useRef, useContext, useEffect } from "react";
import { ModalHeader, Modal } from "reactstrap";
import styles from "./styles.scss";
import BookSelector, { BookSelectorHandle } from "src/components/BookSelector";
import { customOfferModalOpen } from "src/services/ApolloService";
import AnalyticsService from "src/services/AnalyticsService";
import { SubscriptionContext } from "src/services/SubscriptionService";
import { STATE_MAP } from "src/constants";
import { useUser } from "src/services/UserService";

function CollectSportsbookModal() {
  const ctx = useContext(SubscriptionContext);
  const [visible, setVisible] = useState(false);
  const [bookIds, setBookIds] = useState<string[]>([]);
  const bookSelectorHandle = useRef<BookSelectorHandle>();
  const user = useUser();

  // Select books every 3 months
  // prettier-ignore
  useEffect(() => {
    if (user && moment().isAfter(moment(user.selectedBooksOn || 0).add(3, "months"))) {
      setVisible(true);
    }
  }, [user]);

  const onSubmit = async () => {
    // Redundant analytic requested by Mike
    if (!bookIds.length) {
      AnalyticsService.track("Sportsbook Modal - No Books", {});
    }
    await bookSelectorHandle.current?.submit();
    setVisible(false);
    if (STATE_MAP[ctx.userLocale]?.legal) {
      customOfferModalOpen(true);
    }
  };

  return (
    <>
      <Modal
        isOpen={visible}
        toggle={() => setVisible(!visible)}
        className="collecting-sportsbook-modal"
        backdrop="static"
      >
        <ModalHeader className="modal-header">
          <div className="modal-header-primary">Help us personalize your BetQL experience</div>
          <div className="modal-header-secondary">
            What sportsbooks and daily fantasy sites do you already have accounts with? Select all that apply.
          </div>
        </ModalHeader>
        <div className="modal-body">
          <BookSelector includeOffers ref={bookSelectorHandle} setBookIds={setBookIds} />
          <button className="modal-submit-button" onClick={onSubmit}>
            Submit
          </button>
          <button className="modal-cancel-button" onClick={onSubmit}>
            I don't have any sportsbooks
          </button>
        </div>
      </Modal>
      <style jsx>{styles}</style>
    </>
  );
}

export default CollectSportsbookModal;
