import React from "react";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import Link from "next/link";
import styles from "./styles.scss";
import BetQLLogo from "src/components/BetQLLogo";
import { useFreeTrialClaimed } from "src/services/UserService";
import { usePeristedVar } from "src/services/UtilService";
import { freeTrialDismissedVar } from "src/services/ApolloService";

const FreeTrialBanner = () => {
  const trialClaimed = useFreeTrialClaimed();
  const [trialDismissed, setDismissedTrial] = usePeristedVar(freeTrialDismissedVar, "freeTrialDismissed", false);

  if (trialClaimed || trialDismissed) {
    return null;
  }

  return (
    <div className="trial-banner">
      <div className="offer-logos">
        <BetQLLogo color="white" style={{ height: 18 }} />
      </div>
      <span style={{ width: 10 }} />
      <div className="offer-text">
        <span style={{ color: "#fbcc33" }}>NEW:</span>
        &nbsp;Get 3 Days Free of BetQL!
      </div>
      <span style={{ width: 16, height: 6 }} />
      <Link href="/pricing/monthly" legacyBehavior>
        <a className="white-button">Start Free Trial</a>
      </Link>
      <div className="close-button" onClick={() => setDismissedTrial(true)}>
        <Icon path={mdiClose} size="20px" color="white" />
      </div>
      <style jsx global>
        {styles}
      </style>
    </div>
  );
};

export default FreeTrialBanner;
