import React from "react";
import folderIconMobile from "public/icon/folder-icon-mobile.png";
import addIconMobile from "public/icon/add-icon-mobile.png";
import { gamesDashboardEvent, gamesModifyStatsEvent } from "src/services/ApolloService";
import styles from "./styles.scss";

const MobileDropdownControls = React.memo(({ isTrendingBetsPage }: { isTrendingBetsPage: boolean }) => {
  return (
    <div className="d-sm-none games-mobile-dropdown-controls">
      {!isTrendingBetsPage ? (
        <>
          <img
            className="toggle-button lazyload"
            src={folderIconMobile}
            alt="Folder Icon"
            onClick={(e) => gamesDashboardEvent(e)}
          />
          <img
            className="toggle-button lazyload"
            data-src={addIconMobile}
            alt="Add Icon"
            onClick={(e) => gamesModifyStatsEvent(e)}
          />
        </>
      ) : null}
      <style jsx>{styles}</style>
    </div>
  );
});

export default MobileDropdownControls;
