/**
 * This file is an attempt to administer all the ad zones from one central location
 */
import moment from "moment-timezone";

/**
 * This is the image that will be used within the interstitial modal
 */
import MODAL_IMAGE from "public/vip40-deal.png";
import { DEFAULT_TIMEZONE } from "src/constants";

/**
 * This is the key we used to check for the previous deal. We need to remove it
 * so that we don't keep adding to our localstorage size with each new ad
 */
const OLD_INTERSTITIAL_KEY = "bowlDeal";
/**
 * This is the new key that we will use to determine if the user has viewed the
 * the current interstitial deal
 */
const NEW_INTERSTITIAL_KEY = "vip40";

const DEFAULT_START_DATE = moment.tz("2020-01-07 00:00", DEFAULT_TIMEZONE);
const DEFAULT_END_DATE = moment.tz("2020-02-09 23:59", DEFAULT_TIMEZONE);

// Enable this if you want to display games table ad-zone
const GAMES_TABLE_AD_ZONE_PROMOTION = false;

/**
 * This is a list of all the current ad zones throughout the app
 */
export enum AdZoneEnum {
  INTERSTITIAL_MODAL = "INTERSTITIAL_MODAL",
  GAMES_TABLE_AD = "GAMES_TABLE_AD",
  CHECKOUT_MODAL_BANNER = "CHECKOUT_MODAL_BANNER",
  PRICING_MODAL_BANNER = "PRICING_MODAL_BANNER",
  CHECKOUT_MODAL_CHECKOUT_TEXT = "CHECKOUT_MODAL_CHECKOUT_TEXT",
  PRICING_MODAL_CHECKOUT_TEXT = "PRICING_MODAL_CHECKOUT_TEXT",
  SITE_TOP_BANNER = "SITE_TOP_BANNER",
  // Prem Buttons
  PRICING_MODAL_PREM_WEEKLY_BUTTON = "PRICING_MODAL_PREM_WEEKLY_BUTTON",
  PRICING_MODAL_PREM_MONTHLY_BUTTON = "PRICING_MODAL_PREM_MONTHLY_BUTTON",
  PRICING_MODAL_PREM_THREE_MONTH_BUTTON = "PRICING_MODAL_PREM_THREE_MONTH_BUTTON",
  PRICING_MODAL_PREM_ANNUAL_BUTTON = "PRICING_MODAL_PREM_ANNUAL_BUTTON",
  // Pro Buttons
  PRICING_MODAL_PRO_WEEKLY_BUTTON = "PRICING_MODAL_PRO_WEEKLY_BUTTON",
  PRICING_MODAL_PRO_MONTHLY_BUTTON = "PRICING_MODAL_PRO_MONTHLY_BUTTON",
  PRICING_MODAL_PRO_THREE_MONTH_BUTTON = "PRICING_MODAL_PRO_THREE_MONTH_BUTTON",
  PRICING_MODAL_PRO_ANNUAL_BUTTON = "PRICING_MODAL_PRO_ANNUAL_BUTTON",
  // VIP Buttons
  PRICING_MODAL_VIP_WEEKLY_BUTTON = "PRICING_MODAL_VIP_WEEKLY_BUTTON",
  PRICING_MODAL_VIP_MONTHLY_BUTTON = "PRICING_MODAL_VIP_MONTHLY_BUTTON",
  PRICING_MODAL_VIP_THREE_MONTH_BUTTON = "PRICING_MODAL_VIP_THREE_MONTH_BUTTON",
  PRICING_MODAL_VIP_ANNUAL_BUTTON = "PRICING_MODAL_VIP_ANNUAL_BUTTON",
  // Sharp Buttons
  PRICING_MODAL_SHARP_WEEKLY_BUTTON = "PRICING_MODAL_SHARP_WEEKLY_BUTTON",
  PRICING_MODAL_SHARP_MONTHLY_BUTTON = "PRICING_MODAL_SHARP_MONTHLY_BUTTON",
  PRICING_MODAL_SHARP_THREE_MONTH_BUTTON = "PRICING_MODAL_SHARP_THREE_MONTH_BUTTON",
  PRICING_MODAL_SHARP_ANNUAL_BUTTON = "PRICING_MODAL_SHARP_ANNUAL_BUTTON",
}

/**
 * Determine if we want to display the promotion for the sport the user is viewing
 *
 * @param zone the button ad zone to change the price of
 * @param sports the array of sport strings to determine the sport the user is viewing
 */
const showForSport = (zone: AdZoneEnum, sports: string[]) => {
  switch (zone) {
    case AdZoneEnum.INTERSTITIAL_MODAL:
    case AdZoneEnum.GAMES_TABLE_AD:
    case AdZoneEnum.CHECKOUT_MODAL_BANNER:
    case AdZoneEnum.PRICING_MODAL_BANNER:
    case AdZoneEnum.CHECKOUT_MODAL_CHECKOUT_TEXT:
    case AdZoneEnum.PRICING_MODAL_CHECKOUT_TEXT:
    case AdZoneEnum.SITE_TOP_BANNER:
    // Prem Buttons
    case AdZoneEnum.PRICING_MODAL_PREM_WEEKLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PREM_MONTHLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PREM_THREE_MONTH_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PREM_ANNUAL_BUTTON:
    // Pro Buttons
    case AdZoneEnum.PRICING_MODAL_PRO_WEEKLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PRO_MONTHLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PRO_THREE_MONTH_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PRO_ANNUAL_BUTTON:
    // VIP Buttons
    case AdZoneEnum.PRICING_MODAL_VIP_WEEKLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_VIP_MONTHLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_VIP_THREE_MONTH_BUTTON:
    case AdZoneEnum.PRICING_MODAL_VIP_ANNUAL_BUTTON:
    // Sharp Buttons
    case AdZoneEnum.PRICING_MODAL_SHARP_WEEKLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_SHARP_MONTHLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_SHARP_THREE_MONTH_BUTTON:
    case AdZoneEnum.PRICING_MODAL_SHARP_ANNUAL_BUTTON:
    default:
      // Possible Keys: NFL, NBA, CFB, CBK, NHL, MLB, XFL, EPL, DEB, MLS, ESP, ITSA, UCL
      return sports.length ? false : true; // set to true for all sports
  }
};

/**
 * Get the starting date of the promotion
 *
 * @param zone the button ad zone to change the price of
 */
const getStartDate = (zone: AdZoneEnum) => {
  switch (zone) {
    case AdZoneEnum.INTERSTITIAL_MODAL:
    case AdZoneEnum.GAMES_TABLE_AD:
    case AdZoneEnum.CHECKOUT_MODAL_BANNER:
    case AdZoneEnum.PRICING_MODAL_BANNER:
    case AdZoneEnum.CHECKOUT_MODAL_CHECKOUT_TEXT:
    case AdZoneEnum.PRICING_MODAL_CHECKOUT_TEXT:
    case AdZoneEnum.SITE_TOP_BANNER:
    // Prem Buttons
    case AdZoneEnum.PRICING_MODAL_PREM_WEEKLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PREM_MONTHLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PREM_THREE_MONTH_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PREM_ANNUAL_BUTTON:
    // Pro Buttons
    case AdZoneEnum.PRICING_MODAL_PRO_WEEKLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PRO_MONTHLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PRO_THREE_MONTH_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PRO_ANNUAL_BUTTON:
    // VIP Buttons
    case AdZoneEnum.PRICING_MODAL_VIP_WEEKLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_VIP_MONTHLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_VIP_THREE_MONTH_BUTTON:
    case AdZoneEnum.PRICING_MODAL_VIP_ANNUAL_BUTTON:
    // Sharp Buttons
    case AdZoneEnum.PRICING_MODAL_SHARP_WEEKLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_SHARP_MONTHLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_SHARP_THREE_MONTH_BUTTON:
    case AdZoneEnum.PRICING_MODAL_SHARP_ANNUAL_BUTTON:
    default:
      return DEFAULT_START_DATE;
  }
};

/**
 * Get the ending date of the promotion
 *
 * @param zone the button ad zone to change the price of
 */
const getEndDate = (zone: AdZoneEnum) => {
  switch (zone) {
    case AdZoneEnum.INTERSTITIAL_MODAL:
      return moment.tz("2020-02-05 00:00", DEFAULT_TIMEZONE);
    case AdZoneEnum.GAMES_TABLE_AD:
    case AdZoneEnum.CHECKOUT_MODAL_BANNER:
    case AdZoneEnum.PRICING_MODAL_BANNER:
    case AdZoneEnum.CHECKOUT_MODAL_CHECKOUT_TEXT:
    case AdZoneEnum.PRICING_MODAL_CHECKOUT_TEXT:
    case AdZoneEnum.SITE_TOP_BANNER:
    // Prem Buttons
    case AdZoneEnum.PRICING_MODAL_PREM_WEEKLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PREM_MONTHLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PREM_THREE_MONTH_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PREM_ANNUAL_BUTTON:
    // Pro Buttons
    case AdZoneEnum.PRICING_MODAL_PRO_WEEKLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PRO_MONTHLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PRO_THREE_MONTH_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PRO_ANNUAL_BUTTON:
    // VIP Buttons
    case AdZoneEnum.PRICING_MODAL_VIP_WEEKLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_VIP_MONTHLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_VIP_THREE_MONTH_BUTTON:
    case AdZoneEnum.PRICING_MODAL_VIP_ANNUAL_BUTTON:
    // Sharp Buttons
    case AdZoneEnum.PRICING_MODAL_SHARP_WEEKLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_SHARP_MONTHLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_SHARP_THREE_MONTH_BUTTON:
    case AdZoneEnum.PRICING_MODAL_SHARP_ANNUAL_BUTTON:
    default:
      return DEFAULT_END_DATE;
  }
};

/**
 * This will show a new price on the pricing button and strike through the old
 * price. Make sure to also set the new price text to describe the discount
 *
 * @param zone the button ad zone to change the price of
 * @param originalPrice this is the original price of the subscription tier
 */
const getNewPrice = (zone: AdZoneEnum) => {
  switch (zone) {
    // Prem Buttons
    case AdZoneEnum.PRICING_MODAL_PREM_WEEKLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PREM_MONTHLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PREM_THREE_MONTH_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PREM_ANNUAL_BUTTON:
    // Pro Buttons
    case AdZoneEnum.PRICING_MODAL_PRO_WEEKLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PRO_MONTHLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PRO_THREE_MONTH_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PRO_ANNUAL_BUTTON:
    // VIP Buttons
    case AdZoneEnum.PRICING_MODAL_VIP_WEEKLY_BUTTON:
      return "";
    case AdZoneEnum.PRICING_MODAL_VIP_THREE_MONTH_BUTTON:
      return "120";
    case AdZoneEnum.PRICING_MODAL_VIP_ANNUAL_BUTTON:
    // Sharp Buttons
    case AdZoneEnum.PRICING_MODAL_SHARP_WEEKLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_SHARP_MONTHLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_SHARP_THREE_MONTH_BUTTON:
    case AdZoneEnum.PRICING_MODAL_SHARP_ANNUAL_BUTTON:
    default:
      return "";
  }
};

/**
 * This is additional text on the button to describe the discount that the
 * customer will get
 *
 * @param zone the button ad zone to change the price of
 */
const getNewPriceText = (zone: AdZoneEnum) => {
  switch (zone) {
    // Prem Buttons
    case AdZoneEnum.PRICING_MODAL_PREM_WEEKLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PREM_MONTHLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PREM_THREE_MONTH_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PREM_ANNUAL_BUTTON:
    // Pro Buttons
    case AdZoneEnum.PRICING_MODAL_PRO_WEEKLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PRO_MONTHLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PRO_THREE_MONTH_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PRO_ANNUAL_BUTTON:
    // VIP Buttons
    case AdZoneEnum.PRICING_MODAL_VIP_WEEKLY_BUTTON:
      return "";
    case AdZoneEnum.PRICING_MODAL_VIP_THREE_MONTH_BUTTON:
      return "$40 / Month";
    case AdZoneEnum.PRICING_MODAL_VIP_ANNUAL_BUTTON:
    // Sharp Buttons
    case AdZoneEnum.PRICING_MODAL_SHARP_WEEKLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_SHARP_MONTHLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_SHARP_THREE_MONTH_BUTTON:
    case AdZoneEnum.PRICING_MODAL_SHARP_ANNUAL_BUTTON:
    default:
      return "";
  }
};

/**
 * This will set the coupon within the subscription config so the coupon is auto
 * applied at checkout.
 *
 * @param zone the button ad zone to set the coupon for
 */
const getTierCoupon = (zone: AdZoneEnum) => {
  switch (zone) {
    // Prem Buttons
    case AdZoneEnum.PRICING_MODAL_PREM_WEEKLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PREM_MONTHLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PREM_THREE_MONTH_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PREM_ANNUAL_BUTTON:
    // Pro Buttons
    case AdZoneEnum.PRICING_MODAL_PRO_WEEKLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PRO_MONTHLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PRO_THREE_MONTH_BUTTON:
    case AdZoneEnum.PRICING_MODAL_PRO_ANNUAL_BUTTON:
    // VIP Buttons
    case AdZoneEnum.PRICING_MODAL_VIP_WEEKLY_BUTTON:
      return null;
    case AdZoneEnum.PRICING_MODAL_VIP_THREE_MONTH_BUTTON:
      return "VIP40";
    case AdZoneEnum.PRICING_MODAL_VIP_MONTHLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_VIP_ANNUAL_BUTTON:
    // Sharp Buttons
    case AdZoneEnum.PRICING_MODAL_SHARP_WEEKLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_SHARP_MONTHLY_BUTTON:
    case AdZoneEnum.PRICING_MODAL_SHARP_THREE_MONTH_BUTTON:
    case AdZoneEnum.PRICING_MODAL_SHARP_ANNUAL_BUTTON:
    default:
      return null;
  }
};

/**
 * Persist that the user viewed the interstitial modal to localstorage
 * so we don't show it again
 */
const setInterstitialViewed = () => {
  try {
    const viewed = window.localStorage.getItem(NEW_INTERSTITIAL_KEY);
    if (viewed !== "true") {
      window.localStorage.setItem(NEW_INTERSTITIAL_KEY, "true");
    }
  } catch (e: any) {
    console.log(e.message);
  }
};

/**
 * Check persisted storage to see if the user has already viewed the interstitial
 */
const getInterstitialViewed = () => {
  // remove the old localstorage value if it exists before we
  // check for the new one
  try {
    window.localStorage.removeItem(OLD_INTERSTITIAL_KEY);
  } catch (e: any) {
    console.log(e.message);
  }
  return window.localStorage.getItem(NEW_INTERSTITIAL_KEY) === "true";
  return true;
};

/**
 * Get the values needed to display the interstitial modal
 */
const getInterstitialConfig = () => {
  return {
    image: MODAL_IMAGE,
    title: "Get NFL, NBA, and March Madness Best Bets for only $40/month",
    strikeText: "$75/month",
    coupon: "VIP40",
    finePrint: "New subscribers only. Discount applicable for first three months only.",
  };
};

/**
 * Get the values needed to display the games table ad
 */
const getGamesTableConfig = () => {
  return {
    title: "BetQL's Bowl Season Plays",
    subtitle: "40 Games for $30",
    buttonText: "Unlock Best Bets",
  };
};

/**
 * Get the values needed to display the games table ad
 */
const getPowerRankingTableConfig = () => {
  return {
    title: "BetQL's Bowl Season Plays",
    subtitle: "40 Games for $30",
    buttonText: "Unlock Best Bets",
  };
};

/**
 * Get the text for a specific banner around the site
 *
 * @param zone the button ad zone to change the price of
 */
const getBannerText = (zone: AdZoneEnum) => {
  switch (zone) {
    case AdZoneEnum.SITE_TOP_BANNER:
    case AdZoneEnum.CHECKOUT_MODAL_BANNER:
    case AdZoneEnum.PRICING_MODAL_BANNER:
    default:
      return "Get NFL, NBA, and March Madness Best Bets for only $40/month. Use Code VIP40 at Checkout.";
  }
};

const getPricingModalPage = (zone: AdZoneEnum) => {
  switch (zone) {
    case AdZoneEnum.INTERSTITIAL_MODAL:
    case AdZoneEnum.GAMES_TABLE_AD:
    case AdZoneEnum.SITE_TOP_BANNER:
    default:
      return 2;
  }
};

const getPricingModalSportList = (zone: AdZoneEnum) => {
  switch (zone) {
    case AdZoneEnum.INTERSTITIAL_MODAL:
    case AdZoneEnum.GAMES_TABLE_AD:
    case AdZoneEnum.SITE_TOP_BANNER:
    default:
      return [];
  }
};

export default {
  GAMES_TABLE_AD_ZONE_PROMOTION,
  getInterstitialConfig,
  getGamesTableConfig,
  getPowerRankingTableConfig,
  setInterstitialViewed,
  getInterstitialViewed,
  getStartDate,
  getEndDate,
  showForSport,
  getNewPrice,
  getNewPriceText,
  getBannerText,
  getTierCoupon,
  getPricingModalPage,
  getPricingModalSportList,
};
