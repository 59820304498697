import React, { Component } from "react";
import { Button, Form, Label, Input, Row, Col, Alert } from "reactstrap";
import { Mutation } from "@apollo/client/react/components";
import { ApolloError } from "@apollo/client";
import GAService from "../../../services/GAService";

import { LOGIN_MUTATION } from "../../../gql/mutations";
import styles from "./styles.scss";
import { SubscriptionContext } from "src/services/SubscriptionService";

export interface LoginProps {
  onError?(): void;
}

export interface LoginState {
  email: string;
  password: string;
  submitting: boolean;
  errors: string[];
}

export default class Login extends Component<LoginProps, LoginState> {
  static contextType = SubscriptionContext;
  constructor(props: any) {
    super(props);
    this.state = { email: "", password: "", submitting: false, errors: [] };
  }

  renderErrorMessages(error: ApolloError) {
    if (!error) {
      return null;
    }
    const errorMessageList: any = [];
    const errors: any = error.graphQLErrors;
    if (!errors) {
      return null;
    }
    for (let i = 0; i < errors.length; i++) {
      const currError = errors[i];
      errorMessageList.push(<div key={i}>{currError.message}</div>);
    }
    return <Alert color="danger">{errorMessageList}</Alert>;
  }

  render() {
    const { email, password } = this.state;
    return (
      <Mutation mutation={LOGIN_MUTATION}>
        {(login: any, { loading, error }: any) => (
          <Form
            className="rotoql-login__form"
            onSubmit={async (e) => {
              e.preventDefault();
              try {
                const res = await login({
                  variables: {
                    email,
                    password,
                  },
                });
                await this.context.onSignIn(res.data.login, true, "email");
                GAService.sendEvent({
                  hitType: "event",
                  eventCategory: "Login - Success",
                  eventAction: window.location.href,
                  eventLabel: "Source - Email",
                });
                window.fbq("trackCustom", "Login", { source: "email" });
              } catch {
                this.props.onError && this.props.onError();
                GAService.sendEvent({
                  hitType: "event",
                  eventCategory: "Login - Fail",
                  eventAction: window.location.href,
                  eventLabel: "Source - Email",
                });
              }
            }}
          >
            <style jsx>{styles}</style>
            {error ? (
              <Row>
                <Col md={12}>{this.renderErrorMessages(error)}</Col>
              </Row>
            ) : null}
            <div className="rotoql-login__input-container">
              <Label for="email" className="rotoql-login__label">
                E-mail Address
              </Label>
              <Input
                id="email"
                type="email"
                name="email"
                value={email}
                placeholder="Enter e-mail address"
                onChange={(e) => {
                  this.setState({
                    email: e.target.value,
                    errors: [],
                  });
                }}
                className="rotoql-login__input"
              />
            </div>
            <div className="rotoql-login__input-container">
              <Label for="examplePassword" className="rotoql-login__label">
                Password
              </Label>
              <Input
                className="rotoql-login__input"
                type="password"
                name="password"
                value={password}
                placeholder="Enter password"
                onChange={(e) => {
                  this.setState({
                    password: e.target.value,
                    errors: [],
                  });
                }}
              />
            </div>
            <div className="rotoql-login__button-container">
              <Button color="primary" disabled={loading} className="rotoql-login__login-button">
                Log In
              </Button>
            </div>
          </Form>
        )}
      </Mutation>
    );
  }
}
