import React from "react";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import Link from "next/link";
import styles from "./styles.scss";
import BetQLLogo from "src/components/BetQLLogo";
import { usePeristedVar } from "src/services/UtilService";
import { promoDismissedVar } from "src/services/ApolloService";

const PromotionBanner = () => {
  const [promoDismissed, setPromoDismissed] = usePeristedVar(promoDismissedVar, "promoDismissed", false);

  if (promoDismissed) {
    return <span />;
  }

  return (
    <span>
      <div className="promo-banner">
        <span className="offer-logos">
          <BetQLLogo color="white" style={{ height: 18 }} />
        </span>
        <span style={{ width: 10 }} />
        <div className="offer-text">
          <span style={{ color: "#fbcc33" }}>NEW:</span>
          &nbsp;Get Access to March Madness Premium Betting Advice
        </div>
        <span style={{ width: 16, height: 6 }} />
        <Link href="/march" passHref legacyBehavior>
          <a className="white-button">View Now</a>
        </Link>
        <div className="close-button" onClick={() => setPromoDismissed(true)}>
          <Icon path={mdiClose} size="20px" color="white" />
        </div>
        <style jsx global>
          {styles}
        </style>
      </div>
    </span>
  );
};

export default PromotionBanner;
