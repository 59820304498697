import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { SOCIAL_PROVIDER } from "src/constants";
import { SOCIAL_LOGIN_MUTATION } from "src/gql/mutations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { SubscriptionContext } from "src/services/SubscriptionService";

export interface SignInProps {
  toggleError(): void;
  isLogin: boolean;
}

export default function SignInWithFacebook(props: SignInProps) {
  const ctx = useContext(SubscriptionContext);
  const { toggleError, isLogin } = props;
  const [login] = useMutation(SOCIAL_LOGIN_MUTATION);

  const signInBetQL = async (facebookAuth: any) => {
    const { status, authResponse } = facebookAuth;
    if (status === "connected") {
      const {
        data: { login: loginData },
      } = await login({
        variables: {
          socialProvider: SOCIAL_PROVIDER.FB,
          socialToken: authResponse.accessToken,
        },
      });
      ctx.onSignIn(loginData, isLogin, "social");
    } else {
      throw new Error("Unable to sign in with Facebook");
    }
  };

  function getLoginStatus() {
    return new Promise((resolve) => window.FB.getLoginStatus((response: any) => resolve(response))).then(signInBetQL);
  }

  function loginWithFacebook() {
    return new Promise((resolve) =>
      window.FB.login((response: any) => resolve(response), {
        scope: "email",
        auth_type: "rerequest",
      })
    ).then(signInBetQL);
  }

  const signInWithFacebook = async () => {
    try {
      window.FB.init({
        appId: 159390208060520,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v9.0",
      });
      getLoginStatus()
        .catch(() => loginWithFacebook())
        .catch((err) => {
          console.error(err);
          toggleError();
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <button className="btn signin-btn" onClick={signInWithFacebook}>
      <FontAwesomeIcon icon={faFacebookF} style={{ marginRight: "5px" }} />
      Sign In with Facebook
      <style jsx>{`
        .signin-btn {
          background: #4267b2;
          border-radius: 8px;
          color: white;
          font-size: 15px;
          margin-bottom: 8px;
          height: 46px;
          width: 100%;
        }
        .signin-btn:hover {
          opacity: 0.9;
        }
      `}</style>
    </button>
  );
}
