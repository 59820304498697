import React from "react";
import { Moment } from "moment-timezone";
import Router from "next/router";
import { setCookie as _setCookie, parseCookies as _parseCookies, destroyCookie as _destroyCookie } from "nookies";

import { FeatureItem, DurationItem, TierItem } from "../typings/pricing";
import { userGetClaims } from "./UserService";
import AnalyticsService from "./AnalyticsService";
import AdZoneService, { AdZoneEnum } from "./AdZoneService";
import Bugsnag from "src/lib/bugsnag";
import { USER_SUBSCRIPTION_QUERY } from "src/gql/queries";
import { useQuery } from "@apollo/client";
import _ from "lodash";

export const productTiers = ["premium", "pro", "vip", "sharp"] as const;
export type ProductTier = (typeof productTiers)[number];

export const productDurations = ["month", "year"] as const;
export type ProductDuration = (typeof productDurations)[number];

export const productPurchaseModel = ["consumable", "subscription"] as const;
export type ProductPurchaseModel = (typeof productPurchaseModel)[number];

// This function signature helps prevent mistakes for free tier (ie subKey is null)
// https://artsy.github.io/blog/2018/11/21/conditional-types-in-typescript/
export function getProductDuration(subKey: string): ProductDuration;
export function getProductDuration(subKey: string | null): ProductDuration | null; // prettier-ignore
export function getProductDuration(subKey: any): any {
  if (subKey?.includes("month")) {
    return "month";
  }

  if (subKey?.includes("year")) {
    return "year";
  }

  if (subKey) {
    const error = new Error(`Can't find duration for product ${subKey}`);
    console.error(error);
    Bugsnag.notify(error);
    return "month";
  }

  return null;
}

export function getTierSportCount(tier: ProductTier): number;
export function getTierSportCount(tier: ProductTier | null): number | null;
export function getTierSportCount(tier: any): any {
  const sportCounts: any = {
    premium: 1,
    pro: 2,
    vip: 3,
    sharp: 0,
  };

  const sportCount = sportCounts[tier];

  if (Number.isInteger(sportCount)) {
    return sportCount;
  }

  if (tier) {
    const error = new Error(`Can't find sport count for tier ${tier}`);
    console.error(error);
    Bugsnag.notify(error);
    return 1;
  }

  return null;
}

export function getProductTier(subKey: string): ProductTier;
export function getProductTier(subKey: string | null): ProductTier | null;
export function getProductTier(subKey: any): any {
  if (subKey?.includes("prem")) {
    return "premium";
  }

  if (subKey?.includes("pro")) {
    return "pro";
  }

  if (subKey?.includes("vip")) {
    return "vip";
  }

  if (subKey?.includes("sharp")) {
    return "sharp";
  }

  if (subKey) {
    const error = new Error(`Can't find tier for product ${subKey}`);
    console.error(error);
    Bugsnag.notify(error);
    return "premium";
  }

  return null;
}

export function getProductPurchaseModel(subKey: string): ProductPurchaseModel;
export function getProductPurchaseModel(subKey: string | null): ProductPurchaseModel | null; // prettier-ignore
export function getProductPurchaseModel(subKey: any): any {
  if (subKey) {
    return "subscription";
  }

  return null;
}

export const useUserSubscriptions = ({ skip }: { skip?: boolean } = {}): any[] => {
  const { data } = useQuery(USER_SUBSCRIPTION_QUERY, {
    ssr: false,
    context: { debatch: true },
    skip,
  });
  const userSubscriptions = _.cloneDeep(data?.userSubscriptions || []);
  userSubscriptions.sort((userSubscriptionA: any, userSubscriptionB: any) =>
    sortSubscriptionLevels(userSubscriptionA.subscriptionLevel.name, userSubscriptionB.subscriptionLevel.name)
  );
  return userSubscriptions;
};

export const grantedSubscriptions = ["premium", "pro", "vip", "sharp"];

/**
 * This list is used to match a mobile subscription with its web counterpart for display
 * purposes on the pricing and account page. The user won't be able to take action from
 * web and will be instead directed to mobile to manage their subcription, but we still
 * want to display what tier they have.
 */
export const mobileSubscriptionLookup: { [key: string]: string | null } = {
  // Free Tier
  betweb_free: null,
  // Premium Tier
  betql_prem_week: "web_betql_premium_month",
  betql_prem_month: "web_betql_premium_month",
  betql_prem_three_months: "web_betql_premium_month",
  betql_prem_year: "web_betql_premium_year",
  // Pro Tier
  betql_pro_week: "web_betql_pro_month",
  betql_pro_month: "web_betql_pro_month",
  betql_pro_three_months: "web_betql_pro_month",
  betql_pro_six_months: "web_betql_pro_month",
  betql_pro_year: "web_betql_pro_year",
  // VIP Tier
  betql_vip_week: "web_betql_vip_month",
  betql_vip_month: "web_betql_vip_month",
  betql_vip_three_months: "web_betql_month",
  betql_vip_year: "web_betql_vip_year",
  // Sharp Tier - Mobile doesn't have Sharp yet
};

export const webSubscriptionLookup: { [key: string]: string } = {
  // Premium Tier
  premium: "premium",
  web_betql_premium_week: "web_betql_premium_month",
  web_betql_premium_month: "web_betql_premium_month",
  web_betql_premium_quarter: "web_betql_premium_month",
  web_betql_premium_year: "web_betql_premium_year",
  web_betql_premium_quarter_v2: "web_betql_premium_month",
  web_betql_premium_year_v2: "web_betql_premium_year",
  // Pro Teir
  pro: "pro",
  web_betql_pro_week: "web_betql_pro_month",
  web_betql_pro_month: "web_betql_pro_month",
  web_betql_pro_quarter: "web_betql_pro_month",
  web_betql_pro_year: "web_betql_pro_year",
  web_betql_pro_quarter_v2: "web_betql_pro_month",
  web_betql_pro_year_v2: "web_betql_pro_year",
  // VIP Tier
  vip: "vip",
  web_betql_vip_week: "web_betql_vip_month",
  web_betql_vip_month: "web_betql_vip_month",
  web_betql_vip_quarter: "web_betql_vip_month",
  web_betql_vip_year: "web_betql_vip_year",
  web_betql_vip_quarter_v2: "web_betql_vip_month",
  web_betql_vip_year_v2: "web_betql_vip_year",
  // Sharp Tier
  sharp: "sharp",
  web_betql_sharp_week: "web_betql_sharp_month",
  web_betql_sharp_month: "web_betql_sharp_month",
  web_betql_sharp_quarter: "web_betql_sharp_month",
  web_betql_sharp_year: "web_betql_sharp_year",
  web_betql_sharp_quarter_v2: "web_betql_sharp_month",
  web_betql_sharp_year_v2: "web_betql_sharp_year",
};

/**
 * Sort each subscription by it's tier. We should never have two of the
 * same tier subscription, so this should always give us the highest level
 */
export const subscriptionLevelSort: { [key: string]: number } = {
  // Free Tier
  betweb_free: 0,
  // Premium Tier
  premium: 1,
  // Web
  web_betql_premium_week: 1,
  web_betql_premium_month: 1,
  web_betql_premium_quarter: 1,
  web_betql_premium_year: 1,
  web_betql_premium_quarter_v2: 1,
  web_betql_premium_year_v2: 1,
  // Mobile
  betql_prem_week: 1,
  betql_prem_month: 1,
  betql_prem_three_months: 1,
  betql_prem_year: 1,
  // Pro Teir
  pro: 2,
  // Web
  web_betql_pro_week: 2,
  web_betql_pro_month: 2,
  web_betql_pro_quarter: 2,
  web_betql_pro_year: 2,
  web_betql_pro_quarter_v2: 2,
  web_betql_pro_year_v2: 2,
  // Mobile
  betql_pro_week: 2,
  betql_pro_month: 2,
  betql_pro_three_months: 2,
  betql_pro_six_months: 2,
  betql_pro_year: 2,
  // VIP Tier
  vip: 3,
  // Web
  web_betql_vip_week: 3,
  web_betql_vip_month: 3,
  web_betql_vip_quarter: 3,
  web_betql_vip_year: 3,
  web_betql_vip_quarter_v2: 3,
  web_betql_vip_year_v2: 3,
  // Mobile
  betql_vip_three_months: 3,
  betql_vip_month: 3,
  betql_vip_week: 3,
  betql_vip_year: 3,
  // Sharp Tier
  sharp: 4,
  // Web
  web_betql_sharp_week: 4,
  web_betql_sharp_month: 4,
  web_betql_sharp_quarter: 4,
  web_betql_sharp_year: 4,
  web_betql_sharp_quarter_v2: 4,
  web_betql_sharp_year_v2: 4,
};

/**
 * Sort the list of subscritions by their tier so that we can
 * always grab the highest level subscription at position at the
 * front of the list.
 */
export const sortSubscriptionLevels = (subscriptionLevelA: any, subscriptionLevelB: any) => {
  if (subscriptionLevelSort[subscriptionLevelA] > subscriptionLevelSort[subscriptionLevelB]) {
    return -1;
  }
  if (subscriptionLevelSort[subscriptionLevelA] < subscriptionLevelSort[subscriptionLevelB]) {
    return 1;
  }
  return 0;
};

/**
 * This function retrieves the subscriptionLevel from the JWT token
 */
export const getSubscriptionFromToken = (token?: string) => {
  let subscriptionLevel = null;
  const { lvl: subscriptionLevelsObj } = userGetClaims(token);
  if (subscriptionLevelsObj) {
    let subscriptionLevels = Object.keys(subscriptionLevelsObj);
    if (subscriptionLevels.length) {
      subscriptionLevels.sort(sortSubscriptionLevels);
      subscriptionLevel = subscriptionLevels[0];
    }
  }
  return subscriptionLevel;
};

/**
 * Create a React Context that has everything we need to provide a component
 * with the current access levels
 */
export const SubscriptionContext = React.createContext({
  init: false as boolean,
  signedIn: false as boolean,
  onSignIn: async (userInfo?: any, isLogin?: boolean, source?: string, page?: string): Promise<void> => {
    // do not delete this console log, it is a placeholder for when we set the
    // actually function in _app.tsx
    console.log(userInfo, isLogin, source, page);
    return new Promise(() => {});
  },
  userLocale: "CA",
  setLocale: (token: string) => {
    // do not delete this console log, it is a placeholder for when we set the
    // actually function in _app.tsx
    console.log(token);
  },
  setSubscriptionLevel: async (token: string) => {
    // do not delete this console log, it is a placeholder for when we set the
    // actually function in _app.tsx
    console.log(token);
  },
  hasSubscriptionAccess: (sportKey: string, isSharpStat?: boolean) => {
    // do not delete this console log, it is a placeholder for when we set the
    // actually function in _app.tsx
    console.log(sportKey, isSharpStat);
    return false as boolean;
  },
  subscriptionLevel: null as string | null,
  subscriptionSports: [] as string[],
  subscriptionSportsUpdateTime: undefined as Moment | undefined,
  subscriptionUpgradeSource: null as string | null,
  subscriptionDefaultTierIndex: null as number | null,
  subscriptionDefaultSports: null as string[] | null,
  pricingModalVisible: false,
  /**
   * @deprecated Use togglePricingModal
   */
  redirectToPricing: (track: any, source: string, defaultTierIndex?: number, defaultSports?: string[]) => {
    // do not delete this console log, it is a placeholder for when we set the
    // actually function in _app.tsx
    console.log(source, defaultTierIndex, defaultSports);
    Router.push("/pricing").then(() => window.scrollTo(0, 0));
    AnalyticsService.track("Click Upgrade", track);
  },
  togglePricingModal: ({
    track,
    source,
    defaultTierIndex,
    defaultSports,
    deepLink,
  }: {
    track?: any;
    source?: string;
    defaultTierIndex?: number;
    defaultSports?: string[];
    deepLink?: string;
  }) => {
    // do not delete this console log, it is a placeholder for when we set the
    // actually function in _app.tsx
    console.log(track, source, defaultTierIndex, defaultSports, deepLink);
  },
  loginModalVisible: false,
  loginTabIndex: 0,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setLoginTabIndex: (index: number) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openLoginModal: (loginTabIndex: number) => {},
  logoutUser: () => {},
});

export const parseCookies = () => _parseCookies({});
export const setCookie = (name: string, value: string, options?: any) => {
  _setCookie({}, name, value, {
    expires: new Date("2099"), // was session cookie by default
    ...options,
  });
};
export const destroyCookie = (name: string) => _destroyCookie({}, name);

/**
 * This feature list maps to a list of feature keys in the TierItems so that we can
 * easily display which features a subscription tier has access to on the pricing page
 */
export const FEATURE_LIST: FeatureItem[] = [
  {
    key: "premium-stats",
    title: "Access to Premium Stats",
    body: "Projected full game and 1st half scores, real-time public and sharp betting, automated injury updates and more",
  },
  {
    key: "trending-bets",
    title: "Trending Bets from BetQL Community",
    body: "See the Most Popular Consensus Bets Among Simulations, Sharps, Community, and More",
  },
  {
    key: "betting-report",
    title: "Sharp Money and Public Betting Report",
    body: "Follow the pro money with largest bankrolls and the average bettors to pick against in real-time",
  },
  {
    key: "best-picks",
    title: "BetQL Model Value Picks",
    body: "Access to the best mispriced lines of the day in real time based on BetQL’s proprietary model",
  },
  {
    key: "player-props",
    title: "Player Prop Analysis for Single Bets and Same Game Parlays",
    body: "Get player recommendations for starters and popular contributing players (NFL and NBA)",
  },
  {
    key: "push-notification",
    title: "Push Notifications for News, Injuries, and Start Times",
    body: "Opt into alerts on the BetQL mobile app to never miss an opportunity",
  },
  {
    key: "exclusive-content",
    title: "Exclusive Content for BetQL Team",
    body: "Preseason betting guides and subscriber exclusive analysis and trends during season",
  },
];

const FEATURE_LIST_KEYS = FEATURE_LIST.map(({ key }) => key);

/**
 * This is our subscription configuration object that controls what we
 * display and how that page functions. The most important things in this array
 * is that the tier keys match the name of their corresponding subscription.
 */
export const PRICING_DURATIONS: DurationItem[] = [
  {
    key: "week",
    text: "Weekly",
    subtext: "",
    tiers: [
      {
        key: "web_betql_premium_week",
        maxSports: 1,
        badge: null,
        title: "1 Sport",
        price: 12.99,
        totalPrice: 12.99,
        durationDisplay: "week",
        durationDisplayAbv: "wk",
        durationNumber: 1,
        durationUnit: "week",
        durationTitle: "Weekly",
        upsellText: null,
        description: "I just started betting",
        features: ["premium-stats", "best-picks", "all-bet-types", "mobile-app", "change-weekly"],
        autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_PREM_WEEKLY_BUTTON),
        active: false,
      },
      {
        key: "web_betql_pro_week",
        maxSports: 2,
        badge: {
          backgroundColor: "#02a16c",
          text: "POPULAR",
        },
        title: "2 Sport",
        price: 23.95,
        totalPrice: 23.95,
        durationDisplay: "week",
        durationDisplayAbv: "wk",
        durationNumber: 1,
        durationUnit: "week",
        durationTitle: "Weekly",
        upsellText: null,
        description: "For the bettor who is getting more experience",
        features: ["premium-stats", "best-picks", "all-bet-types", "mobile-app", "change-weekly"],
        autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_PRO_WEEKLY_BUTTON),
        active: false,
      },
      {
        key: "web_betql_vip_week",
        maxSports: 3,
        badge: null,
        title: "All Sports",
        price: 34.99,
        totalPrice: 34.99,
        durationDisplay: "week",
        durationDisplayAbv: "wk",
        durationNumber: 1,
        durationUnit: "week",
        durationTitle: "Weekly",
        upsellText: null,
        description: "For the experienced bettor who needs all sports",
        features: ["premium-stats", "best-picks", "all-bet-types", "mobile-app", "change-weekly", "all-sport"],
        autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_VIP_WEEKLY_BUTTON),
        active: false,
      },
      {
        key: "web_betql_sharp_week",
        maxSports: "all",
        badge: {
          backgroundColor: "#14F2B3",
          text: "MORE DATA",
        },
        title: "All Sports",
        price: 54.95,
        totalPrice: 54.95,
        durationDisplay: "week",
        durationDisplayAbv: "wk",
        durationNumber: 1,
        durationUnit: "week",
        durationTitle: "Weekly",
        upsellText: null,
        description: "For the aspiring pro bettors who want to build their own winning strategies",
        features: [
          "premium-stats",
          "best-picks",
          "all-bet-types",
          "mobile-app",
          "change-weekly",
          "all-sport",
          "sharp-stats",
          "custom-dash",
          "premium-content",
        ],
        autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_SHARP_WEEKLY_BUTTON),
        active: false,
      },
    ],
  },
  {
    key: "month",
    text: "Monthly",
    subtext: "",
    tiers: [
      {
        key: "web_betql_premium_month",
        maxSports: 1,
        badge: null,
        title: "1 Sport",
        price: 19.99,
        totalPrice: 19.99,
        durationDisplay: "month",
        durationDisplayAbv: "mo",
        durationNumber: 1,
        durationUnit: "month",
        durationTitle: "Monthly",
        upsellText: null,
        description: "I just started betting",
        features: FEATURE_LIST_KEYS,
        autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_PREM_MONTHLY_BUTTON),
        active: true,
      },
      {
        key: "web_betql_pro_month",
        maxSports: 2,
        badge: null,
        title: "2 Sport",
        price: 24.99,
        totalPrice: 24.99,
        durationDisplay: "month",
        durationDisplayAbv: "mo",
        durationNumber: 1,
        durationUnit: "month",
        durationTitle: "Monthly",
        upsellText: null,
        description: "For the bettor who is getting more experience",
        features: FEATURE_LIST_KEYS,
        autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_PRO_MONTHLY_BUTTON),
        active: true,
      },
      {
        key: "web_betql_vip_month",
        maxSports: 3,
        badge: null,
        title: "3 Sport",
        price: 29.99,
        totalPrice: 29.99,
        durationDisplay: "month",
        durationDisplayAbv: "mo",
        durationNumber: 1,
        durationUnit: "month",
        durationTitle: "Monthly",
        upsellText: null,
        description: "For the experienced bettor who needs all sports",
        features: FEATURE_LIST_KEYS,
        autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_VIP_MONTHLY_BUTTON),
        active: true,
      },
      {
        key: "web_betql_sharp_month",
        maxSports: "all",
        badge: null,
        title: "All Sports",
        price: 49.99,
        totalPrice: 49.99,
        durationDisplay: "month",
        durationDisplayAbv: "mo",
        durationNumber: 1,
        durationUnit: "month",
        durationTitle: "Monthly",
        upsellText: null,
        description: "For the aspiring pro bettors who want to build their own winning strategies",
        features: FEATURE_LIST_KEYS,
        autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_SHARP_MONTHLY_BUTTON),
        active: true,
      },
    ],
  },
  {
    key: "quarter",
    text: "3 Month",
    subtext: "",
    tiers: [
      {
        key: "web_betql_premium_quarter",
        maxSports: 1,
        badge: null,
        title: "1 Sport",
        price: 8.49,
        totalPrice: 99.99,
        durationDisplay: "week",
        durationDisplayAbv: "wk",
        durationNumber: 3,
        durationUnit: "months",
        durationTitle: "3 Months",
        upsellText: "40% OFF",
        description: "I just started betting",
        features: ["premium-stats", "best-picks", "all-bet-types", "mobile-app", "change-weekly"],
        autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_PREM_THREE_MONTH_BUTTON),
        active: false,
      },
      {
        key: "web_betql_pro_quarter",
        maxSports: 2,
        badge: {
          backgroundColor: "#02a16c",
          text: "POPULAR",
        },
        title: "2 Sport",
        price: 12.49,
        totalPrice: 149.99,
        durationDisplay: "week",
        durationDisplayAbv: "wk",
        durationNumber: 3,
        durationUnit: "months",
        durationTitle: "3 Months",
        upsellText: "50% OFF",
        description: "For the bettor who is getting more experience",
        features: ["premium-stats", "best-picks", "all-bet-types", "mobile-app", "change-weekly"],
        autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_PRO_THREE_MONTH_BUTTON),
        active: false,
      },
      {
        key: "web_betql_vip_quarter",
        maxSports: 3,
        badge: null,
        title: "All Sports",
        price: 16.49,
        totalPrice: 199.99,
        durationDisplay: "week",
        durationDisplayAbv: "wk",
        durationNumber: 3,
        durationUnit: "months",
        durationTitle: "3 Months",
        upsellText: "50% OFF",
        description: "For the experienced bettor who needs all sports",
        features: ["premium-stats", "best-picks", "all-bet-types", "mobile-app", "change-weekly", "all-sport"],
        autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_VIP_THREE_MONTH_BUTTON),
        active: false,
      },
      {
        key: "web_betql_sharp_quarter",
        maxSports: "all",
        badge: {
          backgroundColor: "#14F2B3",
          text: "MORE DATA",
        },
        title: "All Sports",
        price: 24.49,
        totalPrice: 299.99,
        durationDisplay: "week",
        durationDisplayAbv: "wk",
        durationNumber: 3,
        durationUnit: "months",
        durationTitle: "3 Months",
        upsellText: "55% OFF",
        description: "For the aspiring pro bettors who want to build their own winning strategies",
        features: [
          "premium-stats",
          "best-picks",
          "all-bet-types",
          "mobile-app",
          "change-weekly",
          "all-sport",
          "sharp-stats",
          "custom-dash",
          "premium-content",
        ],
        autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_SHARP_THREE_MONTH_BUTTON),
        active: false,
      },
    ],
  },
  {
    key: "year",
    text: "Annual",
    subtext: "Save up to 50%",
    tiers: [
      {
        key: "web_betql_premium_year",
        maxSports: 1,
        badge: null,
        title: "1 Sport",
        price: 59.99,
        totalPrice: 59.99,
        durationDisplay: "year",
        durationDisplayAbv: "yr",
        durationNumber: 1,
        durationUnit: "year",
        durationTitle: "Annual",
        upsellText: "50% OFF Monthly",
        description: "I just started betting",
        features: FEATURE_LIST_KEYS,
        autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_PREM_ANNUAL_BUTTON),
        active: true,
      },
      {
        key: "web_betql_pro_year",
        maxSports: 2,
        badge: null,
        title: "2 Sport",
        price: 99.99,
        totalPrice: 99.99,
        durationDisplay: "year",
        durationDisplayAbv: "yr",
        durationNumber: 1,
        durationUnit: "year",
        durationTitle: "Annual",
        upsellText: "50% OFF Monthly",
        description: "For the bettor who is getting more experience",
        features: FEATURE_LIST_KEYS,
        autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_PRO_ANNUAL_BUTTON),
        active: true,
      },
      {
        key: "web_betql_vip_year",
        maxSports: 3,
        badge: null,
        title: "3 Sport",
        price: 149.99,
        totalPrice: 149.99,
        durationDisplay: "year",
        durationDisplayAbv: "yr",
        durationNumber: 1,
        durationUnit: "year",
        durationTitle: "Annual",
        upsellText: "50% OFF Monthly",
        description: "For the experienced bettor who needs all sports",
        features: FEATURE_LIST_KEYS,
        autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_VIP_ANNUAL_BUTTON),
        active: true,
      },
      {
        key: "web_betql_sharp_year",
        maxSports: "all",
        badge: null,
        title: "All Sports",
        price: 299.99,
        totalPrice: 299.99,
        durationDisplay: "year",
        durationDisplayAbv: "yr",
        durationNumber: 1,
        durationUnit: "year",
        durationTitle: "Annual",
        upsellText: "50% OFF Monthly",
        description: "For the aspiring pro bettors who want to build their own winning strategies",
        features: FEATURE_LIST_KEYS,
        autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_SHARP_ANNUAL_BUTTON),
        active: true,
      },
    ],
  },
];

export const ALL_TIERS: { [key: string]: TierItem } = PRICING_DURATIONS.map((x) => x.tiers)
  .flat()
  .reduce((obj: any, cur: any) => {
    obj[cur.key] = cur;
    return obj;
  }, {});

export const ALT_TIERS: any = {
  premium: {
    key: "premium",
    maxSports: 1,
    title: "1 Sport",
  },
  pro: {
    key: "pro",
    maxSports: 2,
    title: "2 Sport",
  },
  vip: {
    key: "vip",
    maxSports: 3,
    title: "3 Sport",
  },
  sharp: {
    key: "sharp",
    maxSports: "all",
    title: "All Sports",
  },
};

export const ACTIVE_PRICING_DURATIONS = PRICING_DURATIONS.filter((duration) =>
  duration.tiers.some((tier) => tier.active)
).map((duration) => {
  return {
    ...duration,
    tiers: duration.tiers.filter((tier) => tier.active),
  };
});

/**
 * This is our subscription configuration object that controls what we
 * display and how that page functions. The most important things in this array
 * is that the tier keys match the name of their corresponding subscription.
 */

export const ACTIVE_MOBILE_PRODUCTS: TierItem[][] = [
  [
    {
      key: "web_betql_premium_month",
      maxSports: 1,
      badge: null,
      title: "1 Sport",
      price: 19.99,
      totalPrice: 19.99,
      durationDisplay: "month",
      durationDisplayAbv: "mo",
      durationNumber: 1,
      durationUnit: "month",
      durationTitle: "Monthly",
      upsellText: null,
      description: "I just started betting",
      features: ["best-picks", "all-bet-types", "mobile-app"],
      autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_PREM_MONTHLY_BUTTON),
      active: true,
    },
    {
      key: "web_betql_premium_year",
      maxSports: 1,
      badge: null,
      title: "1 Sport",
      price: 59.99,
      totalPrice: 59.99,
      durationDisplay: "year",
      durationDisplayAbv: "yr",
      durationNumber: 1,
      durationUnit: "year",
      durationTitle: "Annual",
      upsellText: "50% OFF Monthly",
      description: "I just started betting",
      features: ["best-picks", "all-bet-types", "mobile-app"],
      autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_PREM_ANNUAL_BUTTON),
      active: true,
    },
  ],
  [
    {
      key: "web_betql_pro_month",
      maxSports: 2,
      badge: {
        backgroundColor: "#02a16c",
        text: "POPULAR",
      },
      title: "2 Sport",
      price: 24.99,
      totalPrice: 24.99,
      durationDisplay: "month",
      durationDisplayAbv: "mo",
      durationNumber: 1,
      durationUnit: "month",
      durationTitle: "Monthly",
      upsellText: null,
      description: "For the bettor who is getting more experience",
      features: ["best-picks", "all-bet-types", "mobile-app"],
      autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_PRO_MONTHLY_BUTTON),
      active: true,
    },
    {
      key: "web_betql_pro_year",
      maxSports: 2,
      badge: {
        backgroundColor: "#02a16c",
        text: "POPULAR",
      },
      title: "2 Sport",
      price: 99.99,
      totalPrice: 99.99,
      durationDisplay: "year",
      durationDisplayAbv: "yr",
      durationNumber: 1,
      durationUnit: "year",
      durationTitle: "Annual",
      upsellText: "50% OFF Monthly",
      description: "For the bettor who is getting more experience",
      features: ["best-picks", "all-bet-types", "mobile-app"],
      autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_PRO_ANNUAL_BUTTON),
      active: true,
    },
  ],
  [
    {
      key: "web_betql_vip_month",
      maxSports: 3,
      badge: null,
      title: "3 Sport",
      price: 29.99,
      totalPrice: 29.99,
      durationDisplay: "month",
      durationDisplayAbv: "mo",
      durationNumber: 1,
      durationUnit: "month",
      durationTitle: "Monthly",
      upsellText: null,
      description: "For the experienced bettor who needs all sports",
      features: ["best-picks", "all-bet-types", "mobile-app"],
      autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_VIP_MONTHLY_BUTTON),
      active: true,
    },
    {
      key: "web_betql_vip_year",
      maxSports: 3,
      badge: null,
      title: "3 Sport",
      price: 149.99,
      totalPrice: 149.99,
      durationDisplay: "year",
      durationDisplayAbv: "yr",
      durationNumber: 1,
      durationUnit: "year",
      durationTitle: "Annual",
      upsellText: "50% OFF Monthly",
      description: "For the experienced bettor who needs all sports",
      features: ["best-picks", "all-bet-types", "mobile-app"],
      autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_VIP_ANNUAL_BUTTON),
      active: true,
    },
  ],
  [
    {
      key: "web_betql_sharp_month",
      maxSports: "all",
      badge: {
        backgroundColor: "#14F2B3",
        text: "MORE DATA",
      },
      title: "All Sports",
      price: 49.99,
      totalPrice: 49.99,
      durationDisplay: "month",
      durationDisplayAbv: "mo",
      durationNumber: 1,
      durationUnit: "month",
      durationTitle: "Monthly",
      upsellText: null,
      description: "For the aspiring pro bettors who want to build their own winning strategies",
      features: ["best-picks", "all-bet-types", "mobile-app", "all-sport"],
      autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_SHARP_MONTHLY_BUTTON),
      active: true,
    },
    {
      key: "web_betql_sharp_year",
      maxSports: "all",
      badge: {
        backgroundColor: "#14F2B3",
        text: "MORE DATA",
      },
      title: "All Sports",
      price: 299.99,
      totalPrice: 299.99,
      durationDisplay: "year",
      durationDisplayAbv: "yr",
      durationNumber: 1,
      durationUnit: "year",
      durationTitle: "Annual",
      upsellText: "50% OFF Monthly",
      description: "For the aspiring pro bettors who want to build their own winning strategies",
      features: ["best-picks", "all-bet-types", "mobile-app", "all-sport"],
      autoCoupon: AdZoneService.getTierCoupon(AdZoneEnum.PRICING_MODAL_SHARP_ANNUAL_BUTTON),
      active: true,
    },
  ],
];
