import React from "react";

import styles from "./styles.scss";

const Main = ({ children }: { children: any }) => (
  <main role="main" className="wrapper-container">
    {children}
    <style jsx global>
      {styles}
    </style>
  </main>
);

export default Main;
