import React from "react";
import { SPORTS_LIST, BetQLSportItem } from "@rotoql/common-services";
import { Step } from "react-joyride";
import heroImageFirstSlide from "public/hero-image.png";
import mobileHeroImageFirstSlide from "public/mobile-hero-image.png";
import moment from "moment-timezone";
import GLOBAL_DATA from "../global-data.json";

// TODO update this for NCAA Tournament in march
export const NCAA_TOURNAMENT_STARTED = moment().isAfter("2025-03-18T23:00:00Z");

export const DEFAULT_TIMEZONE: string = "America/New_York";

export const IS_SERVER = typeof window === "undefined";
export const IS_CLIENT = !IS_SERVER;

export interface Sport {
  name: string;
  icon: string;
  hasTeams: boolean;
  group: boolean;
}

export const SLUG_SPORTS_MAP: {
  [key: string]: string;
} = {
  NFL: "nfl",
  CFB: "ncaaf",
  NBA: "nba",
  CBK: "ncaab",
  MLB: "mlb",
  NHL: "nhl",
  UCL: "champions-league",
  EPL: "premier-league",
  DEB: "bundesliga",
  ESP: "la-liga",
  ITSA: "serie-a",
  MLS: "mls",
  XFL: "xfl",
  ATP: "atp",
  WTA: "wta",
  GOLF: "golf",
};

// Add properties here that 100% will always belong at the sport level
export const SPORTS = {
  Football: {
    name: "Football",
    icon: require("public/icon/nflIcon@3x.png"),
    hasTeams: true,
    group: false,
  } as Sport,
  Basketball: {
    name: "Basketball",
    icon: require("public/icon/basketballIcon@3x.png"),
    hasTeams: true,
    group: false,
  } as Sport,
  Hockey: {
    name: "Hockey",
    icon: require("public/icon/nhlIcon@3x.png"),
    hasTeams: true,
    group: false,
  } as Sport,
  Baseball: {
    name: "Baseball",
    icon: require("public/icon/baseballIcon@3x.png"),
    hasTeams: true,
    group: false,
  } as Sport,
  Soccer: {
    name: "Soccer",
    icon: require("public/icon/soccer@3x.png"),
    hasTeams: true,
    group: true,
  } as Sport,
  Tennis: {
    name: "Tennis",
    icon: require("public/icon/tennisIcon@3x.png"),
    hasTeams: false,
    group: true,
  } as Sport,
  // Other sports (used in /boosts)
  Boxing: {
    name: "Boxing",
    icon: require("public/icon/boxing.png"),
    hasTeams: false,
    group: false,
  } as Sport,
  Golf: {
    name: "Golf",
    icon: require("public/icon/golf@3x.png"),
    hasTeams: false,
    group: false,
  } as Sport,
  Motorsports: {
    name: "Motorsports",
    icon: require("public/icon/motorsports.png"),
    hasTeams: false,
    group: false,
  } as Sport,
  Other: {
    name: "Other",
    icon: require("public/icon/globe.png"),
    hasTeams: true,
    group: false,
  } as Sport,
};

export type SportName = keyof typeof SPORTS;

export interface WebSportItem extends BetQLSportItem {
  icon: any;
  activeIcon: any;
  whiteIcon: any;
  iconStyle: any;
  isSoccer?: boolean;
  isTennis?: boolean;
  hideInSelector?: boolean;
  sport: Sport;
}

export interface WebSports {
  [key: string]: WebSportItem;
  ncaaf: WebSportItem;
  nfl: WebSportItem;
  nba: WebSportItem;
  ncaab: WebSportItem;
  mlb: WebSportItem;
  nhl: WebSportItem;
  xfl: WebSportItem;
  "premier-league": WebSportItem;
  mls: WebSportItem;
  "champions-league": WebSportItem;
  "la-liga": WebSportItem;
  "serie-a": WebSportItem;
  bundesliga: WebSportItem;
  atp: WebSportItem;
  wta: WebSportItem;
}

export const SPORTS_MAP: WebSports = {
  nfl: {
    ...SPORTS_LIST.nfl,
    icon: require("public/icon/nflIcon@3x.png"),
    activeIcon: require("public/icon/nflIconActive@3x.png"),
    whiteIcon: require("public/icon/nflWhiteIcon@3x.png"),
    iconStyle: { width: 14, height: 15 },
    sport: SPORTS.Football,
    hasPlayerProps: true,
    hasPlayerCards: true,
  },
  ncaaf: {
    ...SPORTS_LIST.ncaaf,
    icon: require("public/icon/nflIcon@3x.png"),
    activeIcon: require("public/icon/nflIconActive@3x.png"),
    whiteIcon: require("public/icon/nflWhiteIcon@3x.png"),
    iconStyle: { width: 20, height: 15 },
    sport: SPORTS.Football,
  },
  nba: {
    ...SPORTS_LIST.nba,
    icon: require("public/icon/basketballIcon@3x.png"),
    activeIcon: require("public/icon/basketballIconActive@3x.png"),
    whiteIcon: require("public/icon/basketballWhiteIcon@3x.png"),
    iconStyle: { width: 15, height: 15 },
    sport: SPORTS.Basketball,
    hasPlayerProps: true,
    hasPlayerCards: true,
  },
  ncaab: {
    ...SPORTS_LIST.ncaab,
    conferenceList: NCAA_TOURNAMENT_STARTED
      ? SPORTS_LIST.ncaab.conferenceList
      : SPORTS_LIST.ncaab.conferenceList?.slice(4),
    icon: require("public/icon/basketballIcon@3x.png"),
    activeIcon: require("public/icon/basketballIconActive@3x.png"),
    whiteIcon: require("public/icon/basketballWhiteIcon@3x.png"),
    iconStyle: { width: 12, height: 15 },
    sport: SPORTS.Basketball,
  },
  nhl: {
    ...SPORTS_LIST.nhl,
    icon: require("public/icon/nhlIcon@3x.png"),
    activeIcon: require("public/icon/nhlIconActive@3x.png"),
    whiteIcon: require("public/icon/nhlWhiteIcon@3x.png"),
    iconStyle: { width: 14, height: 15 },
    sport: SPORTS.Hockey,
  },
  "champions-league": {
    ...SPORTS_LIST.ucl,
    icon: require("public/icon/soccer@3x.png"),
    activeIcon: require("public/icon/soccerActive@3x.png"),
    whiteIcon: require("public/icon/soccerWhite@3x.png"),
    iconStyle: { width: 15, height: 15 },
    isSoccer: true,
    sport: SPORTS.Soccer,
  },
  "premier-league": {
    ...SPORTS_LIST.epl,
    icon: require("public/icon/soccer@3x.png"),
    activeIcon: require("public/icon/soccerActive@3x.png"),
    whiteIcon: require("public/icon/soccerWhite@3x.png"),
    iconStyle: { width: 15, height: 15 },
    isSoccer: true,
    sport: SPORTS.Soccer,
  },
  bundesliga: {
    ...SPORTS_LIST.deb,
    icon: require("public/icon/soccer@3x.png"),
    activeIcon: require("public/icon/soccerActive@3x.png"),
    whiteIcon: require("public/icon/soccerWhite@3x.png"),
    iconStyle: { width: 15, height: 15 },
    isSoccer: true,
    sport: SPORTS.Soccer,
  },
  "la-liga": {
    ...SPORTS_LIST.esp,
    icon: require("public/icon/soccer@3x.png"),
    activeIcon: require("public/icon/soccerActive@3x.png"),
    whiteIcon: require("public/icon/soccerWhite@3x.png"),
    iconStyle: { width: 15, height: 15 },
    isSoccer: true,
    sport: SPORTS.Soccer,
  },
  "serie-a": {
    ...SPORTS_LIST.itsa,
    icon: require("public/icon/soccer@3x.png"),
    activeIcon: require("public/icon/soccerActive@3x.png"),
    whiteIcon: require("public/icon/soccerWhite@3x.png"),
    iconStyle: { width: 15, height: 15 },
    isSoccer: true,
    sport: SPORTS.Soccer,
  },
  mls: {
    ...SPORTS_LIST.mls,
    icon: require("public/icon/soccer@3x.png"),
    activeIcon: require("public/icon/soccerActive@3x.png"),
    whiteIcon: require("public/icon/soccerWhite@3x.png"),
    iconStyle: { width: 15, height: 15 },
    isSoccer: true,
    sport: SPORTS.Soccer,
  },
  golf: {
    ...SPORTS_LIST.golf,
    icon: require("public/icon/golf@3x.png"),
    activeIcon: require("public/icon/golfActive@3x.png"),
    whiteIcon: require("public/icon/golfWhite@3x.png"),
    iconStyle: { width: 15, height: 15 },
    sport: SPORTS.Golf,
    hideInSelector: true,
  },
  mlb: {
    ...SPORTS_LIST.mlb,
    icon: require("public/icon/baseballIcon@3x.png"),
    activeIcon: require("public/icon/baseballIconActive@3x.png"),
    whiteIcon: require("public/icon/baseballWhiteIcon@3x.png"),
    iconStyle: { width: 15, height: 15 },
    sport: SPORTS.Baseball,
  },
  atp: {
    ...SPORTS_LIST.atp,
    icon: require("public/icon/tennisIcon@3x.png"),
    activeIcon: require("public/icon/tennisIconActive@3x.png"),
    whiteIcon: require("public/icon/tennisWhiteIcon@3x.png"),
    iconStyle: { width: 15, height: 15 },
    isTennis: true,
    favoriteLine: "moneyline",
    sport: SPORTS.Tennis,
  },
  wta: {
    ...SPORTS_LIST.wta,
    icon: require("public/icon/tennisIcon@3x.png"),
    activeIcon: require("public/icon/tennisIconActive@3x.png"),
    whiteIcon: require("public/icon/tennisWhiteIcon@3x.png"),
    iconStyle: { width: 15, height: 15 },
    isTennis: true,
    sport: SPORTS.Tennis,
  },
  xfl: {
    ...SPORTS_LIST.xfl,
    icon: require("public/icon/nflIcon@3x.png"),
    activeIcon: require("public/icon/nflIconActive@3x.png"),
    whiteIcon: require("public/icon/nflWhiteIcon@3x.png"),
    iconStyle: { width: 14, height: 15 },
    sport: SPORTS.Football,
    hideInSelector: true,
  },
};

export const WEB_SPORTS_MAP: WebSports = Object.assign(
  {},
  ...GLOBAL_DATA.leagues.map((league: { id: string; name: string }) => {
    const sport = SPORTS_MAP[SLUG_SPORTS_MAP[league.name]];

    return { [sport.url]: sport };
  })
);

export const WEB_SPORTS_NAME_LIST: string[] = Object.keys(WEB_SPORTS_MAP);
export const WEB_SPORTS_LIST: WebSportItem[] = Object.values(WEB_SPORTS_MAP);
export const SPORT_KEYS = Object.values(WEB_SPORTS_MAP).map((sport) => sport.key); // prettier-ignore
// This list determines what we show in the header
const HEADER_SPORT_KEY_LIST: string[] = [
  "NFL",
  "NBA",
  "CFB",
  "CBK",
  "NHL",
  "MLB",
  "DEB",
  "EPL",
  "ESP",
  "ITSA",
  "MLS",
  "UCL",
  "ATP",
  "WTA",
  "GOLF",
];
export const WEB_HEADER_SPORTS_LIST: WebSportItem[] = Object.values(WEB_SPORTS_MAP).filter((sport) =>
  HEADER_SPORT_KEY_LIST.includes(sport.key)
);
export const WEB_HEADER_SPORTS_NAME_LIST: string[] = WEB_HEADER_SPORTS_LIST.map((sport) => sport.url);
export const DEFAULT_SPORT_KEY: string = WEB_HEADER_SPORTS_LIST[0].url;
export const DEFAULT_SPORT: WebSportItem = WEB_SPORTS_MAP[DEFAULT_SPORT_KEY];
export const DEFAULT_ONE_SPORT = SPORT_KEYS.slice(0, 1);
export const DEFAULT_TWO_SPORTS = SPORT_KEYS.slice(0, 2);
export const DEFAULT_THREE_SPORTS = SPORT_KEYS.slice(0, 3);

export const POPULAR_EVENTS_SELECTOR_CONFIG: Array<{
  key: string;
  name: string;
}> = WEB_SPORTS_LIST.filter((s) => s.hasPopularEvents).map((s) => ({
  key: s.key,
  name: s.name,
}));

export const RECOMMENDED_BETS_SELECTOR_CONFIG: Array<{
  key: string;
  name: string;
}> = WEB_SPORTS_LIST.filter((s) => s.hasRecommendedBets).map((s) => ({
  key: s.key,
  name: s.name,
}));

export const COLUMN_TEAMS = 0;
// export const COLUMN_DATE = 1;
export const COLUMN_CURRENT = 2;
export const COLUMN_OPEN = 3;
export const COLUMN_VALUE_RATING = 4;
export const COLUMN_PUBLIC_TICKET_PCT = 5;
export const COLUMN_PUBLIC_MONEY = 6;
export const COLUMN_LINE_MOVE_SPREAD = 7;
export const COLUMN_INJURIES = 8;
export const COLUMN_PRO_MONEY_EDGE = 9;
export const COLUMN_TOTAL_GAMES = 10;
export const COLUMN_LINE_MOVE_ML = 11;
export const COLUMN_LINE_MOVE_OU = 12;
export const COLUMN_UNDER_RECORD = 13;
export const COLUMN_SEASON_WIN_PCT = 14;
export const COLUMN_ATS_RECORD = 15;
export const COLUMN_ATS_WIN_PCT = 16;
export const COLUMN_OVER_RECORD = 17;
export const COLUMN_OVER_PCT = 18;
export const COLUMN_UNDER_PCT = 19;
export const COLUMN_ROT_NUMBER = 20;
export const COLUMN_CURRENT_SPREAD = 21;
export const COLUMN_OPEN_SPREAD = 22;
export const COLUMN_CURRENT_ML = 23;
export const COLUMN_OPEN_ML = 24;
export const COLUMN_CURRENT_OU = 25;
export const COLUMN_OPEN_OU = 26;
export const COLUMN_PUBLIC_TICKET_TOT_SPREAD = 27;
export const COLUMN_PUBLIC_TICKET_TOT_ML = 28;
export const COLUMN_PUBLIC_TICKET_TOT_OVER = 29;
export const COLUMN_PUBLIC_TICKET_TOT_UNDER = 30;
export const COLUMN_NET_UNITS = 31;
export const COLUMN_ATS_UNITS = 32;
export const COLUMN_PITCHERS = 33;
export const COLUMN_PTS_FOR = 34;
export const COLUMN_PTS_AGAINST = 35;
export const COLUMN_HOME_RECORD = 36;
export const COLUMN_ROAD_RECORD = 37;
export const COLUMN_HOME_ATS_RECORD = 38;
export const COLUMN_ROAD_ATS_RECORD = 39;
export const COLUMN_HOME_OU_RECORD = 40;
export const COLUMN_ROAD_OU_RECORD = 41;
export const COLUMN_LINE_MOVE = 42;
export const COLUMN_BETQL_MODEL = 43;
export const COLUMN_FADE_THE_PUBLIC = 44;
export const COLUMN_SHARP_BETTING = 45;
export const COLUMN_BETQL_COMMUNITY = 46;
export const COLUMN_CONSENSUS = 47;

export const TRENDING_BETS_PRESET_COLUMNS = [
  COLUMN_CURRENT, // 2
  COLUMN_CONSENSUS, // 47
  COLUMN_BETQL_MODEL, // 43
  COLUMN_BETQL_COMMUNITY, // 46
  COLUMN_SHARP_BETTING, // 45
  COLUMN_FADE_THE_PUBLIC, // 44
];

export const EUROPEAN_SOCCER_LEAGUES_KEYS = ["DEB", "UCL", "ESP", "EPL", "ITSA"];

export const PPCSportsList = ["nfl", "nba", "ncaaf", "ncaab", "mlb"];
export const PPCPagesList = ["sharp-picks-ppc", "best-bets-ppc", "odds-ppc", "totals-spread-ppc"];

export const PAGES: Record<string, number> = {
  odds: 0,
  "best-bets": 1,
  "public-betting": 2,
  "sharp-picks": 3,
  "line-movement": 4,
};

export interface TrackingProperties {
  page: string;
  betType: string;
  sport: string;
  book: string;
}

export interface Sportsbook {
  id: string;
  name: string;
  rank: number;
}

export const ContentZoneEnum: { [key: string]: string } = {
  WEB_RIGHT_RAIL: "WEB_RIGHT_RAIL",
  WEB_CAROUSEL: "WEB_CAROUSEL",
  WEB_FEATURED: "WEB_FEATURED",
  MOBILE_HOME: "MOBILE_HOME",
};

export interface State {
  value: string;
  name: string;
  legal: boolean;
}

export const STATES: State[] = GLOBAL_DATA.states;

export const STATE_MAP: { [key: string]: State } = {};
for (const s of STATES) {
  STATE_MAP[s.value] = s;
}

export const DESKTOP_STEPS: Step[] = [
  {
    target: "body",
    content: (
      <div>
        <img
          className="react-joyride__first-slide--hero-image lazyload"
          data-src={heroImageFirstSlide}
          alt="Hero Image"
        />
        <div className="react-joyride__first-slide">
          <div className="react-joyride__first-slide--title">Welcome to BetQL Web</div>
          <div className="react-joyride__first-slide--content">
            BetQL’s customizable dashboard allows you to control your own betting research with the factors you care
            about. Let us help you discover ways to find best bets for today’s games
          </div>
        </div>
      </div>
    ),
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
    placement: "center",
    showSkipButton: false,
    locale: {
      next: "Show Me Around",
    },
  },
  {
    target: ".games-view__page-dropdown",
    title: "Start with our Preset Dashboards",
    content: (
      <div className="react-joyride__content-style">
        See today’s games sorted by best bets, biggest public bets, sharp plays, and biggest line moves.
      </div>
    ),
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
    placement: "bottom",
  },
  {
    target: ".rotoql-large-dropdown__toggle-button",
    title: "Create your own Views by Adding or Removing Stats",
    content: (
      <div className="react-joyride__content-style">
        Add stats and betting trends you find useful to your dashboard here. Stats include team records vs. betting
        lines (spread, total) and number of total tickets to analyze with ticket and pro money percentages.
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    disableBeacon: true,
    placement: "bottom",
  },
  {
    target: ".games-table-header__team-column",
    title: "Organize the Dashboard",
    content: (
      <div className="react-joyride__content-style">
        Click on a column header to sort the table by any stat. Click and hold a column header to move it to a new place
        on the table.
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 0,
    disableBeacon: true,
    placement: "right",
  },
  {
    target: ".games-view__dashboard-setting",
    title: "Save your Custom Dashboard for Future Use",
    content: (
      <div className="react-joyride__content-style">
        You can create and save your customized dashboards. As a BetQL member these views will remain saved to your
        account.
      </div>
    ),
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
    placement: "bottom",
    styles: {
      options: {
        arrowColor: "#272a33",
        backgroundColor: "#272a33",
      },
    },
  },
];

export const MOBILE_STEPS: Step[] = [
  {
    target: "body",
    content: (
      <div>
        <img
          className="react-joyride__first-slide--hero-image lazyload"
          data-src={mobileHeroImageFirstSlide}
          alt="Hero Image"
        />
        <div className="react-joyride__first-slide">
          <div className="react-joyride__first-slide--title">Welcome to BetQL Web</div>
          <div className="react-joyride__first-slide--content">
            BetQL’s customizable dashboard allows you to control your own betting research with the factors you care
            about. Let us help you discover ways to find best bets for today’s games
          </div>
        </div>
      </div>
    ),
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
    placement: "center",
    showSkipButton: false,
    locale: {
      next: "Show Me Around",
    },
  },
  {
    target: ".games-view__mobile-page-dropdown-container",
    title: "Start with our Preset Dashboards",
    content: (
      <div className="react-joyride__content-style">
        See today’s games sorted by best bets, biggest public bets, sharp plays, and biggest line moves.
      </div>
    ),
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
    placement: "bottom",
  },
  {
    target: ".games-view__mobile-filter-container .rotoql-large-dropdown__mobile-toggle-icon",
    title: "Create your own Views by Adding or Removing Stats",
    content: (
      <div className="react-joyride__content-style">
        Add stats and betting trends you find useful to your dashboard here. Stats include team records vs. betting
        lines (spread, total) and number of total tickets to analyze with ticket and pro money percentages.
      </div>
    ),
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
    placement: "bottom",
  },
  {
    target: ".games-view__mobile-dashboard-setting-folder-icon",
    title: "Save your Custom Dashboard for Future Use",
    content: (
      <div className="react-joyride__content-style">
        You can create and save your customized dashboards. As a BetQL member these views will remain saved to your
        account.
      </div>
    ),
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
    placement: "bottom",
    styles: {
      options: {
        arrowColor: "#272a33",
        backgroundColor: "#272a33",
      },
    },
  },
];

export const SOCIAL_PROVIDER = {
  FB: "FB",
  TWITTER: "TWITTER",
  APPLE: "APPLE",
};

export const MARCH_MADNESS_WEEK: [string, string] = ["2021-03-08", "2021-03-16"];

export const METABET_DEFAULT_MARKET: {
  [key: string]: string;
} = {
  mlb: "to-win-world-series",
  nba: "to-win-nba-title",
  nfl: "to-win-super-bowl",
  nhl: "to-win-stanley-cup",
  ncaab: "to-win-national-championship",
  ncaaf: "to-win-national-championship",
};

export const HIDE_SPORTS_NEWS = [
  WEB_SPORTS_MAP.mls,
  WEB_SPORTS_MAP.atp,
  WEB_SPORTS_MAP.wta,
  WEB_SPORTS_MAP.xfl,
  WEB_SPORTS_MAP["serie-a"],
  WEB_SPORTS_MAP["la-liga"],
  WEB_SPORTS_MAP.bundesliga,
  WEB_SPORTS_MAP["champions-league"],
  WEB_SPORTS_MAP["premier-league"],
];

const TODAY = moment.tz(DEFAULT_TIMEZONE);
// TODO update this for NCAA Tournament in march
export const CURRENT_PROMO = {
  name: "March Madness",
  startDate: moment.tz("2024-03-18", DEFAULT_TIMEZONE).startOf("day"),
  endDate: moment.tz("2024-04-08", DEFAULT_TIMEZONE).endOf("day"),
};

export const VALID_PROMO = TODAY.isBetween(CURRENT_PROMO.startDate, CURRENT_PROMO.endDate, undefined, "[]");

export const TooltipDescriptions = {
  LiveLine: "This is the live consensus betting line.",
  DvP: "This is Defense vs Player. How well the opposing team plays defense against the player’s position.",
  OppTeamDefRank: "This is the opposing teams’ ranking, when it comes to defending the selected prop type.",
  LastGames: (gameCount: number) =>
    `This is the percentage the player has gone over the live line in the last ${gameCount} games.`,
};
