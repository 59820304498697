import React, { useEffect, useState } from "react";
import { SportIcon } from "../SportIcon";
import styles from "./styles.scss";
import { WEB_SPORTS_MAP, WEB_HEADER_SPORTS_LIST, WebSportItem } from "../../constants";
import { getTierSportCount, ProductTier } from "../../services/SubscriptionService";

interface SportSelectorProps {
  tier: ProductTier;
  sportList: string[];
  setSportList: any;
  className?: string;
  style?: any;
  titleStyle?: any;
  buttonContainerStyle?: any;
  buttonStyle?: any;
  showSuggestions?: boolean;
}

const SportSelector = ({
  tier,
  sportList,
  setSportList,
  className,
  style,
  titleStyle,
  buttonContainerStyle,
  buttonStyle,
  showSuggestions,
}: SportSelectorProps) => {
  const maxSports = getTierSportCount(tier);
  const [showError, setShowError] = useState(false);

  // Show error
  useEffect(() => {
    setShowError(maxSports > 0 && sportList.length !== maxSports);
  }, [sportList, maxSports]);

  const selectSport = (sportKey: string) => {
    // sharp
    if (getTierSportCount(tier) === 0) {
      return;
    }

    // is sport already in list
    const sportIdx = sportList.findIndex((sport: string) => sport === sportKey);

    // new sport list
    const newSportList: string[] = sportList.slice(0);

    // if not already selected, add to end
    if (sportIdx === -1) {
      newSportList.push(sportKey);
      // if maxed out, remove first item
      if (sportList.length >= getTierSportCount(tier)) {
        newSportList.splice(0, 1);
      }
    } else {
      // if already selected, remove it
      newSportList.splice(sportIdx, 1);
    }

    // Set state and show error
    setSportList(newSportList);
  };

  return (
    <div className={`rotoql-sport-selector ${className ? className : ""}`} style={style}>
      <style jsx>{styles}</style>
      {showSuggestions && (
        <div className="rotoql-sport-selector__num-sports-text" style={titleStyle}>
          {maxSports > 0 && sportList.length !== maxSports ? "Select " : null}
          {maxSports === 0 ? "All" : `${sportList.length} / ${maxSports}`}{" "}
          {maxSports === 0 || maxSports > 1 ? "Sports" : "Sport"}
          {showError ? (
            <React.Fragment>
              : <span className="rotoql-sport-selector__num-sports-text--red">Select another sport</span>
            </React.Fragment>
          ) : null}
        </div>
      )}
      <div className="rotoql-sport-selector__sport-button-container" style={buttonContainerStyle}>
        {WEB_HEADER_SPORTS_LIST.reduce((array: any[], sport: WebSportItem, index: number) => {
          if (!sport.hideInSelector) {
            const isActive = maxSports === 0 || sportList.includes(sport.key);
            array.push(
              <button
                type="button"
                key={index}
                onClick={() => selectSport(sport.key)}
                style={buttonStyle}
                className={`rotoql-sport-selector__sport-button ${
                  isActive ? "rotoql-sport-selector__sport-button--active" : ""
                }`}
              >
                <SportIcon
                  name={sport.key}
                  color={isActive ? "#02a16c" : "#5b5e66"}
                  style={{
                    width: WEB_SPORTS_MAP[sport.url].iconStyle.width + 5,
                    marginRight: "5px",
                  }}
                />
                <div className="rotoql-sport-selector__sport-button-text">{sport.tabName}</div>
              </button>
            );
          }
          return array;
        }, [])}
      </div>
    </div>
  );
};

export default SportSelector;
