/*
The User service deals with the token and any data contained
within.  It can access, set, or delete the token.  All token
reading, decoding, validation, or other access should go though
this service.
*/
import nookies from "nookies";
import JWTDecode from "jwt-decode";
import moment from "moment-timezone";
import { useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { USER_QUERY } from "src/gql/queries";
import { REFRESH_TOKEN_MUTATION } from "src/gql/mutations";
import { initializeApollo } from "src/lib/apollo-client";
import { SubscriptionContext } from "./SubscriptionService";

const domain = process.env.NEXT_PUBLIC_NODE_ENV === "production" ? ".betql.co" : undefined;
const secure = process.env.NEXT_PUBLIC_NODE_ENV === "production" ? true : undefined;

let _token: string | undefined;

// Defines the object to save in the cache
export interface UserUiSetting {
  __typename: string;
  id: string;
  settingValue: any;
}

export const LOCAL_STORAGE_TOKEN_KEY = "node_auth_token";

export const useUser = () => {
  const ctx = useContext(SubscriptionContext);
  const res = useQuery(USER_QUERY, {
    context: { debatch: true },
  });

  useEffect(() => {
    res.refetch();
  }, [ctx.signedIn]);

  if (!ctx.signedIn) {
    return null;
  }

  return res.data?.me || null;
};

/**
 * A helper function to get the claims from a token.
 * @param token {string} an authentication token that can optionally be passed in to avoid a call to local storage, will call local storage if token is not passed.
 */
export const userGetClaims = (token?: string) => {
  if (!token) {
    token = userGetToken();
  }
  let claims: any = {};
  if (token) {
    try {
      claims = JWTDecode(token);
    } catch {
      nookies.destroy({}, LOCAL_STORAGE_TOKEN_KEY, {
        domain,
        path: "/",
        secure,
        sameSite: "lax",
      });
    }
  }
  return claims;
};

/**
 * A setter function to set the token in local storage.
 * This call currently does not rerender or update the app and this must be done within the logic that calls this token set.
 * @param token {string} Set the new token in local storage.
 */
export const userSetToken = (token: string) => {
  _token = token;
  nookies.set({}, LOCAL_STORAGE_TOKEN_KEY, token, {
    expires: moment().add(14, "days").toDate(),
    domain,
    path: "/",
    secure,
    sameSite: "lax",
  });
};

/**
 * Get the vouchers this user has redeemed
 * @param token Optional token to use instead of localstorage
 */
export const userGetVouchers = (token?: string) => {
  const claims = userGetClaims(token);
  return claims.vouchers || [];
};

export const useVouchers = () => {
  const ctx = useContext(SubscriptionContext);
  const vouchers = useMemo(() => userGetVouchers(_token), [ctx.subscriptionLevel]);
  return vouchers;
};

export const getFreeTrialClaimed = (token?: string) => {
  const claims = userGetClaims(token);
  return claims.free_trial_claimed || false;
};

export const useFreeTrialClaimed = () => {
  const ctx = useContext(SubscriptionContext);
  const [claimed, setClaimed] = useState(true);
  useEffect(() => {
    setClaimed(getFreeTrialClaimed(_token));
  }, [ctx.subscriptionLevel]);
  return claimed;
};

export const userGetId = (token?: string) => {
  const claims = userGetClaims(token);
  return claims.sub;
};

export const userGetDisplayName = (token?: string) => {
  const claims = userGetClaims(token);
  return claims.preferred_username || claims.email || "";
};

export const useDisplayName = () => {
  const ctx = useContext(SubscriptionContext);
  const displayName = useMemo(() => userGetDisplayName(_token), [ctx.signedIn]);
  return displayName;
};

/**
 * A wrapper around getting a token from local storage.
 */
export const userGetToken = () => {
  if (_token) {
    return _token;
  }
  return nookies.get({})[LOCAL_STORAGE_TOKEN_KEY] || "";
};

/**
 * Check if a token is still valid.
 * @param token {string} An authentication token that can be optionally passed in to avoid another call to local storage, will call local storage if token is not passed.
 */
export const userTokenValidBefore = (token?: string, expirationCutoff?: moment.Moment) => {
  const claims = userGetClaims(token);
  if (!expirationCutoff) {
    expirationCutoff = moment();
  }
  if (claims && claims.exp) {
    return expirationCutoff.isBefore(moment.unix(claims.exp));
  }
  return false;
};

/**
 * A removal function to clean the token from local storage.
 * This call currently does not rerender or update the app and this must
 * be done within the logic that calls this token set.
 * This doesn't work on the server currently due to the server context not being passed.
 */
export const userDeleteToken = () => {
  _token = undefined;
  nookies.destroy({}, LOCAL_STORAGE_TOKEN_KEY, {
    domain,
    path: "/",
    secure,
    sameSite: "lax",
  });
};

export const userRefreshToken = async (token: string) => {
  if (!token) {
    return null;
  }
  const apollo = initializeApollo();
  try {
    const res = await apollo.mutate({
      mutation: REFRESH_TOKEN_MUTATION,
      variables: {
        token,
      },
      errorPolicy: "all",
    });
    const newToken = res?.data?.token?.token ?? null;
    if (newToken) {
      userSetToken(newToken);
      return newToken;
    } else {
      userDeleteToken();
    }
  } catch (e: any) {
    console.error(e);
  }
  return null;
};
