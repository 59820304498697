import React from "react";

const BetQLLogo = ({
  color = "#00214D",
  style = {},
  className = "",
}: {
  color?: string;
  style?: any;
  className?: string;
}) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 115 31.6"
    style={style}
    className={className}
  >
    <title>BetQL</title>
    <path
      style={{ fill: color }}
      d="M92.8,21.8c0.3-0.7,0.5-1.2,0.7-1.9c0.5-1.5,0.7-3.2,0.7-4.7c0-0.2,0-0.5,0-0.7c0-0.2,0-0.3,0-0.3
 c-0.2-2.5-0.7-4.7-1.7-6.6c0-0.2-0.2-0.3-0.2-0.5c-2.2-4-6.4-6.7-12.1-6.9c0,0,0,0-0.2,0c-0.2,0-0.5,0-0.7,0
 c-9.5,0-14.9,6.7-14.9,15.2s5.7,15.2,14.9,15.2c3.4,0,6.2-0.8,8.5-2.4l3.2,3l4.5-3.7L91.9,24C92.1,23.2,92.6,22.5,92.8,21.8z
  M69.6,15.3c0-5.2,3.9-9.6,9.5-9.6c5.9,0,9.5,4.2,9.5,9.6c0,1.7-0.3,3.2-1,4.5l-5.4-5.2l-4.5,3.7l5.9,5.7c-1.2,0.7-2.7,1-4.2,1
 C73.3,24.9,69.6,20.5,69.6,15.3z"
    />
    <path
      style={{ fill: color }}
      d="M19.1,15.1v-0.5c2.2-0.8,3.9-3,3.9-5.9c0-4.7-3.7-7.9-9.5-7.9H0.3v28.6h13.6c6,0,9.9-3,9.9-7.9
 C23.6,18.5,21.7,15.9,19.1,15.1z M6.7,6h5.7c2.5,0,4.2,1.3,4.2,3.4c0,2-1.7,3.4-4.2,3.4H6.7V6z M12.5,24.5H6.7v-7.2h5.9
 c2.7,0,4.7,1.5,4.7,3.5C17.2,23,15.2,24.5,12.5,24.5z"
    />
    <path
      style={{ fill: color }}
      d="M36,8.4c-6.2,0-10.7,4.4-10.7,10.8c0,6.6,4.4,11.1,10.7,11.1c4.7,0,8.4-2.2,9.7-5.9L40.8,23
 c-0.8,1.7-2.5,2.9-4.7,2.9c-2.8,0-4.7-1.9-5.2-4.7h15.2c0-0.5,0-1.3,0-1.9C46.4,12.7,42.2,8.4,36,8.4z M30.9,17.3
 c0.5-2.7,2.2-4.5,4.9-4.5s4.5,1.9,4.9,4.5H30.9z"
    />
    <path
      style={{ fill: color }}
      d="M56.9,22.7v-9.4h4.7V9h-4.7V2.5h-4.7L51.1,9h-3v4.4h2.8V23c0,4.4,2.7,7.2,6.9,7.2c1.2,0,2.8-0.2,4-0.7v-4.2
 c-0.7,0.2-1.5,0.3-2.2,0.3C57.9,25.5,56.9,24.4,56.9,22.7z"
    />
    <path style={{ fill: color }} d="M104.2,23.9V1h-6.4v28.6h16.9v-5.7H104.2z" />
  </svg>
);

export default BetQLLogo;
