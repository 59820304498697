import React, { Component } from "react";
import { Query } from "@apollo/client/react/components";
import { graphql } from "@apollo/client/react/hoc";
import _ from "lodash";
import Loader from "react-loaders";

import { Button } from "../../../Button";
import { TierItem } from "../../../../typings/pricing";
import { SubscriptionContext } from "../../../../services/SubscriptionService";
import { CHANGE_USER_SUBSCRIPTION_PREVIEW } from "../../../../gql/queries";
import { CHANGE_USER_SUBSCRIPTION, UPDATE_USER_SPORT } from "../../../../gql/mutations";
import styles from "./styles.scss";
import GAService from "../../../../services/GAService";
import AnalyticsService from "../../../../services/AnalyticsService";

const DownArrowIcon = require("public/icon/down-arrow@3x.png?sizes[]=50,sizes[]=100,sizes[]=150");
const CreditCardIcon = require("public/icon/credit-card-icon@3x.png?sizes[]=50,sizes[]=100,sizes[]=150");
const NotesIcon = require("public/icon/notes-icon@3x.png?sizes[]=50,sizes[]=100,sizes[]=150");

export interface DowngradeViewProps {
  userSubscription?: any;
  currentTier?: TierItem;
  selectedTier: TierItem;
  visible: boolean;
  toggleModal(): void;
  signedIn: boolean;
  changeUserSubscription(obj: any): Promise<void>;
  updateUserSport(obj: any): Promise<void>;
  sportList: string[];
}

export interface DowngradeViewState {
  loading: boolean;
  error?: string;
}

class DowngradeView extends Component<DowngradeViewProps, DowngradeViewState> {
  constructor(props: DowngradeViewProps) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidUpdate(prevProps: DowngradeViewProps) {
    if (prevProps.signedIn && !this.props.signedIn) {
      this.props.toggleModal();
    }
  }

  render() {
    const { sportList, toggleModal, selectedTier, currentTier: initialCurrentTier, userSubscription } = this.props;
    const { loading, error } = this.state;
    let currentTier = {
      title: "",
      durationTitle: "",
      price: 0,
    };
    if (initialCurrentTier) {
      currentTier = {
        title: initialCurrentTier.title,
        durationTitle: initialCurrentTier.durationTitle,
        price: initialCurrentTier.price,
      };
    }
    return (
      <SubscriptionContext.Consumer>
        {({ setSubscriptionLevel, subscriptionUpgradeSource }) => (
          <div className="rotoql-downgrade-view">
            <Query
              query={CHANGE_USER_SUBSCRIPTION_PREVIEW}
              variables={{
                currentUserSubscriptionId: userSubscription.id,
                targetSubscriptionName: selectedTier.key,
              }}
              notifyOnNetworkStatusChange
              errorPolicy="all"
            >
              {({ data }: any) => {
                const totalDue = data?.stripeChangeUserSubscriptionPreview?.totalDue || 0; // prettier-ignore
                const totalDueLoading = !data?.stripeChangeUserSubscriptionPreview; // prettier-ignore

                return (
                  <React.Fragment>
                    <div className="rotoql-downgrade-view__header">Downgrade</div>
                    <div className="rotoql-downgrade-view__bullet-container">
                      <img
                        src={DownArrowIcon.src}
                        srcSet={DownArrowIcon.srcSet}
                        style={{ width: "50px" }}
                        alt="Down Arrow"
                        className="rotoql-downgrade-view__bullet-icon"
                      />
                      You will be downgraded from {currentTier.title} {currentTier.durationTitle} to{" "}
                      {selectedTier.title} {selectedTier.durationTitle}.
                    </div>
                    {totalDue > 0 ? (
                      <div className="rotoql-downgrade-view__bullet-container">
                        <img
                          src={CreditCardIcon.src}
                          srcSet={CreditCardIcon.srcSet}
                          style={{ width: "50px" }}
                          alt="Credit Card Icon"
                          className="rotoql-downgrade-view__bullet-icon"
                        />
                        Your credit card will be billed: {totalDueLoading ? "Loading..." : `$${totalDue / 100}`}
                      </div>
                    ) : (
                      <div className="rotoql-downgrade-view__bullet-container">
                        <img
                          src={NotesIcon.src}
                          srcSet={NotesIcon.srcSet}
                          style={{ width: "50px" }}
                          alt="Notes Icon"
                          className="rotoql-downgrade-view__bullet-icon"
                        />
                        Account credit for unused {currentTier.title} {currentTier.durationTitle} subscription to be
                        applied for future charges: {totalDueLoading ? "Loading..." : `$${totalDue / -100}`}
                      </div>
                    )}
                    {error ? <div className="rotoql-downgrade-view__error-text">{error}</div> : null}
                    <div className="rotoql-downgrade-view__button-container">
                      <Button
                        className="rotoql-downgrade-view__button"
                        onClick={async () => {
                          const trackingProps = {
                            screen: "DowngradeView",
                            page: "Pricing",
                            tab: selectedTier.durationTitle,
                            subscriptionLevel: `${selectedTier.title} ${selectedTier.durationTitle}`,
                            sports: sportList.length ? sportList.toString() : "All",
                            upgradeSource: subscriptionUpgradeSource,
                          };
                          this.setState({ loading: true, error: undefined });
                          try {
                            const subRes: any = await this.props.changeUserSubscription({
                              variables: {
                                currentUserSubscriptionId: userSubscription.id,
                                targetSubscriptionName: selectedTier.key,
                                sports: selectedTier.maxSports !== "all" ? sportList : null,
                              },
                              errorPolicy: "all",
                            });
                            const { data: subData, errors: subErrors } = subRes;
                            if (subErrors || !subData?.stripeChangeUserSubscription) {
                              throw new Error("Failed to change subscription.");
                            }
                            const token = subData.stripeChangeUserSubscription.token;
                            await setSubscriptionLevel(token);
                            toggleModal();
                            this.setState({ loading: false });
                            AnalyticsService.track("Downgrade Subscription", {
                              ...trackingProps,
                              success: true,
                            });
                            GAService.sendEvent({
                              hitType: "event",
                              eventCategory: "Downgrade",
                              eventAction: window.location.href,
                              eventLabel: selectedTier.key,
                            });
                          } catch (e: any) {
                            this.setState({ loading: false, error: e.message });
                            console.log("Error downgrading subscription: ", e.message);
                            AnalyticsService.track("Downgrade Subscription", {
                              ...trackingProps,
                              success: false,
                            });
                          }
                        }}
                      >
                        {loading ? <Loader type="ball-pulse" active /> : "CONFIRM DOWNGRADE"}
                      </Button>
                      <Button
                        className="rotoql-downgrade-view__button rotoql-downgrade-view__button--cancel"
                        onClick={() => toggleModal()}
                      >
                        CANCEL
                      </Button>
                    </div>
                    <style jsx>{styles}</style>
                  </React.Fragment>
                );
              }}
            </Query>
          </div>
        )}
      </SubscriptionContext.Consumer>
    );
  }
}

export default _.flowRight<any, any, any>(
  graphql(CHANGE_USER_SUBSCRIPTION, { name: "changeUserSubscription" }),
  graphql(UPDATE_USER_SPORT, { name: "updateUserSport" })
)(DowngradeView);
