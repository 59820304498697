import React, { useContext, useCallback, useState, useEffect, useRef, useImperativeHandle } from "react";
import styles from "./styles.scss";
import { Icon } from "@mdi/react";
import { mdiCheck } from "@mdi/js";
import { useMutation, useQuery } from "@apollo/client";
import { USER_SAVE_BOOKS } from "src/gql/mutations";
import { USER_SPORTSBOOKS } from "src/gql/queries";
import { SubscriptionContext } from "src/services/SubscriptionService";
import { userSetToken } from "src/services/UserService";
import useAsyncEffect from "use-async-effect";
import { useContainerQuery } from "src/services/UtilService";
import AnalyticsService from "src/services/AnalyticsService";

export interface BookSelectorHandle {
  /**
   * Manually submit books
   */
  submit(): void;
  /**
   * Reset books to server state
   */
  reset(): void;
}

const BookSelector = (
  {
    className,
    setBookIds: setBookIdsCallback,
    includeOffers = false,
    autoSync = false,
  }: {
    className?: string;
    setBookIds?: any; // notify when user add/remove book
    includeOffers?: boolean; // preloads related data
    autoSync?: boolean;
  },
  ref: any
) => {
  const eventTarget = useRef(new EventTarget());
  const { userLocale } = useContext(SubscriptionContext);
  const userBooksRes = useQuery(USER_SPORTSBOOKS, {
    fetchPolicy: "cache-and-network",
    variables: {
      locale: userLocale?.toLowerCase(),
      includeOffers,
    },
  });

  // Get relevant books
  const stateHasBooks = userBooksRes.data?.prismicState?.sportsbooks?.[0]?.sportsbook; // prettier-ignore
  const stateBooks = stateHasBooks
    ? userBooksRes.data?.prismicState?.sportsbooks
    : userBooksRes.data?.fallbackState?.sportsbooks;

  // Book ids state
  const [bookIds, setBookIds] = useState<string[]>([]);

  // Responsiveness
  const container = useRef<any>(null);
  useContainerQuery(container, {
    small: 600,
    large: Infinity,
  });

  // Initialize books selected to server state
  const resetBooks = () => {
    setBookIds(userBooksRes.data?.me?.books?.map((x: any) => x.id) ?? []);
  };
  useEffect(resetBooks, [Boolean(userBooksRes.data)]);

  const bookNameById = (id: string) =>
    stateBooks.find((book: any) => id === book.sportsbook?.internal_book?.id)?.sportsbook?.name ?? "";

  // Sync books
  const [userSaveBooks] = useMutation(USER_SAVE_BOOKS);
  const [needSync, setNeedSync] = useState(false);
  const [syncing, setSyncing] = useState(false);
  useAsyncEffect(async () => {
    if (!syncing && needSync) {
      AnalyticsService.track("Set User Sportsbooks", {
        bookNames: bookIds.map(bookNameById),
        page: window.location.href,
        state: userLocale,
      });
      setSyncing(true);
      setNeedSync(false);
      const res = await userSaveBooks({ variables: { bookIds } }).catch(console.error); // prettier-ignore
      eventTarget.current.dispatchEvent(new CustomEvent("synced"));
      const token = res?.data?.userSaveBooks?.token;
      if (token) {
        userSetToken(token);
      }
      setSyncing(false);
    }
  }, [syncing, needSync]);

  // API
  useImperativeHandle(ref, () => ({
    submit: () =>
      new Promise((resolve) => {
        setNeedSync(true);
        eventTarget.current.addEventListener("synced", resolve);
      }),
    reset: resetBooks,
  }));

  const toggleBookId = useCallback(
    (bookId: string) => {
      const newBookIds = bookIds.includes(bookId) ? bookIds.filter((bId: any) => bId !== bookId) : [...bookIds, bookId];
      setBookIds(newBookIds);
      setBookIdsCallback?.(newBookIds);
      if (autoSync) {
        setNeedSync(true);
      }
    },
    [bookIds]
  );

  return (
    <div className={`book-selector ${className || ""}`} ref={container}>
      {!stateBooks?.length && <div style={{ textAlign: "center", padding: 16 }}>Loading...</div>}
      {stateBooks?.map((book: any) => {
        const bookId = book.sportsbook?.internal_book?.id;
        const selected = bookIds.includes(bookId);
        return (
          <button
            key={bookId}
            type="button"
            className={`book-button ${selected ? "active" : ""}`}
            onClick={() => toggleBookId(bookId)}
          >
            {selected ? (
              <div className="iconContainer">
                <Icon color="#fff" path={mdiCheck} size="20px" />
              </div>
            ) : null}
            <div
              className="book-image"
              aria-label={book?.sportsbook?.name}
              style={{
                backgroundImage: `url(${book.sportsbook?.image?.url})`,
              }}
            />
          </button>
        );
      })}
      <style jsx>{styles}</style>
    </div>
  );
};

export default React.forwardRef(BookSelector);
