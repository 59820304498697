import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

Bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || "",
  plugins: [new BugsnagPluginReact()],
  onError: (event) => {
    // Discard non-harmful events
    const filter = [
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications.",
      "Illegal invocation",
    ];
    if (filter.some((f) => event?.errors?.[0]?.errorMessage?.includes(f))) {
      return false;
    }
    return true;
  },
});

export default Bugsnag;
