import React, { useState, useEffect, useMemo, useContext, ReactPortal, ReactNode } from "react";
import { createPortal } from "react-dom";
import { useForceUpdate } from "src/services/UtilService";

interface PortalContextType {
  add: (element: ReactPortal) => void;
  delete: (element: ReactPortal) => void;
}
const PortalContext = React.createContext<PortalContextType>(undefined as any);

export const PortalProvider = ({ children }: { children: any }) => {
  const forceUpdate = useForceUpdate();
  const portals = useMemo(() => new Set<ReactPortal>(), []);

  const contextValue = {
    add: (portal: ReactPortal) => {
      portals.add(portal);
      forceUpdate();
    },
    delete: (portal: ReactPortal) => {
      portals.delete(portal);
      forceUpdate();
    },
  };

  return (
    <PortalContext.Provider value={contextValue}>
      {children}
      {[...portals]}
    </PortalContext.Provider>
  );
};

/**
 * Use a portal (e.g. overlay/modal), unmounted by default for performance
 * Deps are for top level node updates. Remounts on change until better solution found
 */
export const usePortal = (children: ReactNode): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const portals = useContext(PortalContext);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    let container: HTMLDivElement;
    let portal: React.ReactPortal;

    if (mounted) {
      container = document.createElement("div");
      portal = createPortal(children, container);
      document.body.appendChild(container);
      portals.add(portal);
    }

    return () => {
      if (portal) {
        portals.delete(portal);
      }
      container?.remove();
    };
  }, [mounted]);

  return [mounted, setMounted];
};
