import { WEB_SPORTS_MAP } from "src/constants";

/**
 * Return a url with modified search params. Delete a param by setting undefined.
 * @param urlString The url to modify
 * @param params The search params to set
 */
export const getUrlWithSearchParams = (urlString: string, params: any) => {
  let urlObj;

  // Catch invalid URL
  try {
    urlObj = new URL(urlString);
  } catch (e) {
    return "";
  }

  let urlParams = new URLSearchParams(urlObj.search);
  for (const key in params) {
    if (params[key] !== undefined) {
      urlParams.set(key, params[key]);
    } else {
      urlParams.delete(key);
    }
  }
  urlObj.search = urlParams.toString();
  return urlObj.toString();
};

export const localizeUrl = (url: string) => {
  return url.replace("://betql.co", `://${window.location.host}`);
};

// Get url for an article (for graphql api only)
export const getArticleAsUrl = (article: any) => {
  const articleInfo = article._meta
    ? {
        uid: article._meta.uid,
        league: article.league,
        type: article._meta.type,
      }
    : {
        // non-graphql (for /preview link resolver only)
        uid: article.uid,
        league: article.data?.league,
        type: article.type,
      };

  if (!articleInfo?.uid) {
    return "/";
  }
  let asUrl: string = "";
  let articleSportObj = WEB_SPORTS_MAP[articleInfo.league] || null;
  if (articleSportObj) {
    asUrl = `/${articleSportObj.url}` + asUrl;
  }
  if (articleInfo.type === "news_basic" && article.google_paid_search_only) {
    asUrl += "/exclusive";
  } else if (articleInfo.type === "news_basic" && !article.google_paid_search_only) {
    asUrl += "/news";
  } else if (articleInfo.type === "sportsbook_review") {
    asUrl += "/sportsbook-reviews";
  } else if (articleInfo.type === "games_table") {
    return `/${articleInfo.uid}/odds`;
  }
  asUrl += `/${articleInfo.uid}`;
  return asUrl;
};

export const getStringQueryParam = (params: string | string[] | undefined): string => {
  if (typeof params === "string") {
    return params;
  }
  return "";
};

export const getStringArrayQueryParam = (params: string | string[] | undefined): string[] => {
  if (Array.isArray(params)) {
    return params;
  }
  return [];
};
