import React, { Component } from "react";
import { Modal } from "reactstrap";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Mutation } from "@apollo/client/react/components";
import Loader from "react-loaders";

import { SubscriptionContext } from "../../services/SubscriptionService";
import Dropdown, { DropdownItem } from "../Dropdown";
import { UPDATE_USER_MUTATION } from "../../gql/mutations";
import styles from "./styles.scss";
import { STATES } from "../../constants";

interface StateCaptureModalState {
  toggle: boolean;
  showStateCapture: boolean;
  selectedStateIndex: number;
}

class StateCaptureModal extends Component<{}, StateCaptureModalState> {
  static contextType = SubscriptionContext;
  constructor(props: {}) {
    super(props);
    this.state = { toggle: true, showStateCapture: false, selectedStateIndex: 0 };
  }
  componentDidMount(): void {
    const stateCaptured = window.localStorage.getItem("stateCapture");
    let showStateCapture = false;
    if (stateCaptured !== "true") {
      showStateCapture = true;
    }
    this.setState({ showStateCapture });
  }

  closeModal = () => {
    this.setState({ toggle: !this.state.toggle });
  };

  onSelectState = (_: DropdownItem, selectedStateIndex: number) => {
    this.setState({ selectedStateIndex });
  };

  onOpened = () => {
    window.localStorage.setItem("stateCapture", "true");
  };

  render() {
    const { showStateCapture, selectedStateIndex } = this.state;
    if (!this.context.signedIn || this.context.userLocale || !showStateCapture) {
      return null;
    }
    return (
      <Modal
        isOpen={this.state.toggle}
        toggle={() => this.closeModal}
        className="state-capture-modal"
        onOpened={this.onOpened}
      >
        <div className="state-capture-modal__container">
          <div className="state-capture-modal__close-button" onClick={this.closeModal}>
            <Icon path={mdiClose} size="22px" color="#fff" />
          </div>
          <div className="state-capture-modal__title">
            Select your state to redeem exclusive sportsbook offers for BetQL users
          </div>
          <Dropdown
            className="state-capture-modal__state-dropdown"
            onSelectItem={this.onSelectState}
            selectedIndex={selectedStateIndex}
            items={STATES}
            toggleStyle={{
              width: "100%",
              height: "45px",
              fontSize: "17px",
            }}
            menuStyle={{
              width: "100%",
            }}
            menuItemStyle={{
              height: "45px",
              fontSize: "17px",
            }}
          />
          <Mutation mutation={UPDATE_USER_MUTATION}>
            {(updateUserMutation: any, { loading }: any) => {
              return (
                <button
                  className="state-capture-modal__button-container"
                  onClick={async () => {
                    try {
                      const res = await updateUserMutation({
                        variables: {
                          state: STATES[selectedStateIndex].value,
                        },
                      });
                      const token = res?.data?.updateUser?.token;
                      if (token) {
                        this.context.setLocale(token);
                      }
                      this.closeModal();
                    } catch (e: any) {
                      console.log(e.message);
                    }
                  }}
                >
                  {loading ? <Loader type="ball-pulse" active /> : "Submit"}
                </button>
              );
            }}
          </Mutation>
        </div>
        <style jsx global>
          {styles}
        </style>
      </Modal>
    );
  }
}

export default StateCaptureModal;
