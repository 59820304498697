import React, { PureComponent } from "react";
import { Button as BootstrapButton, ButtonGroup as BootstrapButtonGroup } from "reactstrap";
import Link from "next/link";

import styles from "./styles.scss";
import LinkWithHandler from "../LinkWithHandler";

export interface ButtonGroupItem {
  href?: string;
  asUrl?: string;
  name: string;
  key: string;
  labelSelected?: JSX.Element;
  label?: JSX.Element;
  labelSelectedInverse?: JSX.Element;
  [key: string]: any;
}

export interface ButtonGroupProps {
  style?: object;
  className?: string;
  onSelectItem(item: ButtonGroupItem, index: number): void;
  selectedIndex: number;
  // This makes all of the button same width, it must be used with fixed or 100% width to work properly
  equalWidth?: boolean;
  // Use this parameter to change the selected button from blue text with white background to blue background with white text
  inverseSelectedButton?: boolean;
  items: Readonly<ButtonGroupItem[]>;
  selectedButtonStyle?: string;
  unselectedButtonStyle?: string;
  scroll?: boolean;
}

export default class ButtonGroup extends PureComponent<ButtonGroupProps, any> {
  static defaultProps = {
    onSelectItem: () => {},
    selectedIndex: 0,
    items: [],
    scroll: false,
  };

  /**
   * Select the active
   */
  _selectActiveButtonClass = (selected: boolean) => {
    if (selected) {
      if (this.props.selectedButtonStyle) {
        return "active " + this.props.selectedButtonStyle;
      } else if (this.props.inverseSelectedButton) {
        return "active rotoql-button-button-group__button--active-inverse";
      }

      return "active rotoql-button-button-group__button--active";
    } else if (this.props.unselectedButtonStyle) {
      return this.props.unselectedButtonStyle;
    }

    return "";
  };

  _renderItem = (item: ButtonGroupItem, selected: boolean) => {
    if (item.labelSelectedInverse && selected && this.props.inverseSelectedButton) {
      return item.labelSelectedInverse;
    } else if (item.labelSelected && selected) {
      return item.labelSelected;
    } else if (item.label && !selected) {
      return item.label;
    }
    return item.name;
  };

  render() {
    const { style, className, onSelectItem, selectedIndex, items, equalWidth, scroll } = this.props;

    return (
      <BootstrapButtonGroup
        style={style}
        className={`rotoql-button-button-group ${equalWidth ? "rotoql-button-button-group__equal-width" : ""} ${
          className || ""
        }`}
      >
        {items.map((item: ButtonGroupItem, index: number) => {
          if (item.href) {
            return (
              <Link key={index} href={item.href} passHref scroll={scroll} legacyBehavior>
                <LinkWithHandler
                  onCustomClick={() => onSelectItem(item, index)}
                  className={`rotoql-button-button-group__button ${this._selectActiveButtonClass(
                    index === selectedIndex
                  )} btn btn-secondary`}
                >
                  {this._renderItem(item, index === selectedIndex)}
                </LinkWithHandler>
              </Link>
            );
          }
          return (
            <BootstrapButton
              key={index}
              active={index === selectedIndex}
              onClick={() => onSelectItem(item, index)}
              className={`rotoql-button-button-group__button ${this._selectActiveButtonClass(index === selectedIndex)}`}
            >
              {this._renderItem(item, index === selectedIndex)}
            </BootstrapButton>
          );
        })}
        <style jsx global>
          {styles}
        </style>
      </BootstrapButtonGroup>
    );
  }
}
