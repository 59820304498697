import React, { useMemo } from "react";
import styles from "./styles.scss";
import moment from "moment";
import {
  StatConfig,
  getSplitColumnLayout,
  getStandardPropertyKeys,
  getSummaryStatConfig,
  sortByConfig,
} from "src/services/PlayerCardService";
import { Player, PlayerStat, PlayerStatSide, PlayerStatSummary } from "src/models";
import Tooltip from "src/components/Tooltip";

type PlayerCardSplitsProps = {
  summary: PlayerStatSummary;
  league: string;
};

function format(number: number | string): string {
  if (number === "--") {
    return number;
  }

  return Number(number).toFixed(1);
}

function generateData(
  stats: PlayerStat[],
  player: Player,
  league: string,
  side: PlayerStatSide,
  config: StatConfig
): PlayerStat[] {
  const data = (stats ?? []).filter(
    (stat) =>
      config[stat.statProperty] && stat.statType === "SEASON" && stat.statCategory === "average" && stat.side === side
  );

  const keys = getStandardPropertyKeys(league, player.position);
  keys.forEach((property, index) => {
    const hasProperty = data.findIndex((stat) => stat.statProperty === property) !== -1;

    if (!hasProperty) {
      data.push({
        id: `${property}-${index}`,
        statProperty: property,
        statType: "SEASON",
        side,
        statCategory: "average",
        statValue: "--",
      });
    }
  });

  return data.sort((a, b) => sortByConfig(a, b, config));
}

const SplitsListHeader = ({ properties }: { properties: string[] }) => {
  return (
    <>
      <div className="roto-playercard-modal-splits__list-cell__header">
        <span className="roto-playercard-modal-splits__list-cell__header-year">{moment().format("YYYY")}</span>
      </div>
      <>
        {properties.map((property, index) => (
          <div key={index} className="roto-playercard-modal-splits__list-cell__header">
            <span>{property.toUpperCase()}</span>
          </div>
        ))}
      </>
    </>
  );
};

export function PlayerCardSplits({ summary: { player, stats }, league }: PlayerCardSplitsProps) {
  const { defaultConfig, columns, properties } = useMemo(() => {
    const config = getSummaryStatConfig(league, player.position);
    const configDefaults = config?.default ?? {};
    const configProperties = Object.values(configDefaults).map((val) => val.shortLabel);
    const col = getSplitColumnLayout(league, player.position);

    return { defaultConfig: configDefaults, properties: configProperties, columns: col };
  }, [stats, player, league]);

  const awayStats = generateData(stats, player, league, "away", defaultConfig);
  const homeStats = generateData(stats, player, league, "home", defaultConfig);
  const allStats = generateData(stats, player, league, "all", defaultConfig);

  return (
    <div className="roto-playercard-modal-splits__container">
      <div className="roto-playercard-modal-splits__content">
        <span className="roto-playercard-modal-splits__list__header">
          <span>PLAYER SPLITS</span>
          <Tooltip title="PLAYER SPLITS">
            <p className="tooltip-text">Stat values are per game. Stats include regular and postseason</p>
          </Tooltip>
        </span>
        <div className={`roto-playercard-modal-splits__list-${columns}`}>
          <SplitsListHeader properties={properties} />
          <div className="roto-playercard-modal-splits__list-cell__label">
            <span>ALL GAMES</span>
          </div>
          <>
            {allStats.map((stat) => (
              <div key={stat.id} className="roto-playercard-modal-splits__list-cell__value--all">
                {format(stat.statValue)}
              </div>
            ))}
          </>
          <div className="roto-playercard-modal-splits__list-cell__label">
            <span>HOME</span>
          </div>
          <>
            {homeStats.map((stat) => (
              <div key={stat.id} className="roto-playercard-modal-splits__list-cell__value--all">
                {format(stat.statValue)}
              </div>
            ))}
          </>
          <div className="roto-playercard-modal-splits__list-cell__label">
            <span>ROAD</span>
          </div>
          <>
            {awayStats.map((stat) => (
              <div key={stat.id} className="roto-playercard-modal-splits__list-cell__value--all">
                {format(stat.statValue)}
              </div>
            ))}
          </>
        </div>
      </div>
      <style jsx global>
        {styles}
      </style>
    </div>
  );
}
