import React, { useContext, useEffect, useState } from "react";
import { ModalHeader, Modal } from "reactstrap";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import Login from "./Login";
import RegisterFromSocial from "./RegisterFromSocial";
import LoginFromSocial from "./LoginFromSocial";
import Register from "./Register";
import SignInWithApple from "./SignInWithApple";
import SignInWithFacebook from "./SignInWithFacebook";
import { Reset, RequestReset } from "./ForgotPW";
import ButtonGroup, { ButtonGroupItem } from "../ButtonGroup";
import BloombergLogo from "public/logos/bloomberg.png";
import ForbesLogo from "public/logos/forbes.png";
import BostonGlobeLogo from "public/logos/the-boston-globe.png";
import styles from "./styles.scss";
import BetQLLogo from "src/components/BetQLLogo";
import { SubscriptionContext } from "src/services/SubscriptionService";

const tabItems = [
  { name: "Log In", key: "login" },
  { name: "Sign Up", key: "signup" },
];

const LoginModal = () => {
  const ctx = useContext(SubscriptionContext);
  const visible = ctx.loginModalVisible;
  const index = ctx.loginTabIndex;
  const [isError, setIsError] = useState(false);

  const setTabIndex = (tab: ButtonGroupItem) => {
    setIsError(false);
    const tabIndex = tabItems.indexOf(tab);
    ctx.setLoginTabIndex(tabIndex);
  };
  const renderForm = () => {
    if (index === 0) {
      return <Login />;
    } else if (index === 1) {
      return <Register />;
    } else if (index === 2) {
      return <RequestReset sentRequest={() => ctx.setLoginTabIndex(3)} />;
    } else if (index === 3) {
      return <Reset resetComplete={() => ctx.setLoginTabIndex(0)} />;
    } else if (index === 4) {
      return <RegisterFromSocial />;
    } else if (index === 5) {
      return <LoginFromSocial />;
    }
  };

  useEffect(() => {
    if (visible && !document.querySelector("applescript")) {
      const apple = document.createElement("script");
      apple.classList.add("applescript");
      apple.setAttribute("src", "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js");
      document.body.appendChild(apple);

      const fb = document.createElement("script");
      fb.setAttribute("src", "https://connect.facebook.net/en_US/sdk.js");
      document.body.appendChild(fb);
    }
  }, [visible]);

  // Modals should not be in the DOM if not visible
  if (!visible) {
    return null;
  }

  return (
    <span>
      {index === 4 || index === 5 ? (
        <Modal isOpen={visible} toggle={() => ctx.onSignIn()} className="rotoql-social-login-modal">
          <ModalHeader toggle={() => ctx.onSignIn()} className="rotoql-social-login-modal__header">
            <BetQLLogo color="white" className="rotoql-social-login-modal__logo" />
            <div className="rotoql-social-login-modal__header-text">
              Get Started with BetQL
              <br />
              for Free
            </div>
          </ModalHeader>
          <div className="rotoql-social-login-modal__body-container">
            <div className="rotoql-social-login-modal__form-container">
              {index === 4 ? (
                <div className="rotoql-social-login-modal__form-container--text">
                  Create an Account and Get Access to Today's Free Betting Content
                </div>
              ) : (
                <div className="rotoql-social-login-modal__form-container--text">
                  Log In to Get Today's Free Betting Content
                </div>
              )}
              {renderForm()}
              {index === 5 ? (
                <div className="rotoql-social-login-modal__login" onClick={() => ctx.setLoginTabIndex(4)}>
                  <span className="rotoql-social-login-modal__login--link">Don't Have an Account?</span>
                  <span className="rotoql-social-login-modal__login--link-blue">&nbsp;Register Here</span>
                </div>
              ) : null}
              {index === 4 ? (
                <div className="rotoql-social-login-modal__login" onClick={() => ctx.setLoginTabIndex(5)}>
                  <span className="rotoql-social-login-modal__login--link">Already Have a BetQL Account?</span>
                  <span className="rotoql-social-login-modal__login--link-blue">&nbsp;Log In</span>
                </div>
              ) : null}
              <div className="rotoql-social-login-modal__footer-container">
                <div className="rotoql-social-login-modal__footer-container--text">As Seen In</div>
                <div className="rotoql-social-login-modal__footer-container--logos">
                  <img
                    data-src={BloombergLogo}
                    className="rotoql-social-login-modal__footer-container--bloomberg-logo lazyload"
                  />
                  <img
                    data-src={BostonGlobeLogo}
                    className="rotoql-social-login-modal__footer-container--boston-globe-logo lazyload"
                  />
                  <img
                    data-src={ForbesLogo}
                    className="rotoql-social-login-modal__footer-container--forbes-logo lazyload"
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      {index !== 4 && index !== 5 ? (
        <Modal isOpen={visible} toggle={() => ctx.onSignIn()} className="rotoql-login-modal">
          <button onClick={() => ctx.onSignIn()} className="rotoql-login-modal__desktop-close-btn">
            <Icon path={mdiClose} size="35px" color="#FFFFFF" />
          </button>
          <div className="rotoql-login-modal__body-container">
            <div className="rotoql-login-modal__form-container">
              <ModalHeader
                toggle={() => ctx.onSignIn()}
                className="rotoql-login-modal__header"
                close={
                  <button onClick={() => ctx.onSignIn()} className="rotoql-login-modal__close-btn">
                    <Icon path={mdiClose} size="28px" color="#000" />
                  </button>
                }
              >
                <BetQLLogo className="rotoql-login-modal__logo" />
              </ModalHeader>
              <ButtonGroup
                className="rotoql-login-modal__button-group"
                onSelectItem={setTabIndex}
                selectedIndex={index}
                items={tabItems}
              />
              {isError && <div className="rotoql-login-modal__error">Oops! Something went wrong.</div>}
              {renderForm()}
              {index < 2 && (
                <div className="rotoql-login-modal__other-options">
                  <SignInWithApple toggleError={() => setIsError(true)} isLogin={index === 0} />
                  <SignInWithFacebook toggleError={() => setIsError(true)} isLogin={index === 0} />
                  <div className="rotoql-login-modal__forgot-pw" onClick={() => ctx.setLoginTabIndex(2)}>
                    Forgot Password
                  </div>
                </div>
              )}
            </div>
            <div className="rotoql-login-modal__image-container">
              <div className="rotoql-login-modal__title-container">
                <div className="rotoql-login-modal__title">Signup To Access BetQL</div>
                <div className="rotoql-login-modal__subtitle">
                  <div className="rotoql-login-modal__subtitle-item">
                    <strong>Free Games of the Day: </strong> Value and sharp betting reports for free games every day.
                  </div>
                  <div className="rotoql-login-modal__subtitle-item">
                    <strong>Real-Time Odds: </strong> Track line movement in real-time.
                  </div>
                  <div className="rotoql-login-modal__subtitle-item">
                    <strong>Community Polls: </strong> See who your peer bettors love every day.
                  </div>
                </div>
                <img
                  src="https://static.rotoql.com/login/arrow-min.png"
                  className="rotoql-login-modal__arrow-container"
                />
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      <style jsx global>
        {styles}
      </style>
    </span>
  );
};

export default LoginModal;
