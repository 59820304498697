import React, { useState } from "react";
import styles from "./styles.scss";

const DEFAULT_PLAYER_IMG = "https://static.rotoql.com/player/default.png";
const DEFAULT_SIZE_TEXT = "/250-250/";

export interface PlayerImageProps {
  url: string;
  size?: number;
  style?: object;
  containerStyle?: object;
  className?: string;
  containerClassName?: string;
}

const PlayerImage = ({ url, size = 64, style, containerStyle, className, containerClassName }: PlayerImageProps) => {
  const [error, setError] = useState(false);
  const onError = () => {
    if (!error) {
      setError(true);
    }
  };
  url = !error && url ? url.replace(DEFAULT_SIZE_TEXT, `/${size * 3}-${size * 3}/`) : DEFAULT_PLAYER_IMG;

  return (
    <div
      style={{ ...containerStyle, width: `${size}px`, height: `${size}px` }}
      className={`rotoql-player-img__container ${containerClassName}`}
    >
      <img
        style={style}
        data-src={url}
        onError={onError}
        className={`rotoql-player-img__image ${className} lazyload`}
        alt={"Player Image"}
      />
      <style jsx global>
        {styles}
      </style>
    </div>
  );
};

export default PlayerImage;
