import { ReactiveVar, useReactiveVar } from "@apollo/client";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useResizeObserver from "use-resize-observer";
import { parseCookies, setCookie } from "./SubscriptionService";
import { NextRouter } from "next/router";

/**
 * Define breakpoint classes for a container
 * NOTE: There is a bug with nested container queries so avoid those.
 * NOTE: You must always return the container from the function e.g. not null
 * @param container A React ref for the container
 * @param query Example: { small: 575, large: Infinity }
 */

export const useContainerQuery = (container: React.MutableRefObject<any>, query: any) => {
  const [breakpoint, setBreakpoint] = useState<"small" | "medium" | "large">();

  useResizeObserver({
    ref: container,
    onResize: ({ width }: any) => {
      if (!container.current) {
        return;
      }
      // Find the right className to apply
      const entries = Object.entries(query).sort((a: any, b: any) => a[1] - b[1]);
      const classNames = entries.map((e) => e[0]);
      const breakpoints = entries.map((e) => e[1]);
      const index = breakpoints.findIndex((w: any) => w >= width);
      const className = classNames[index];
      // Update the classList
      container.current.classList.remove(...classNames.filter((s) => s !== className)); // prettier-ignore
      container.current.classList.add(...classNames.filter((s) => s === className)); // prettier-ignore
      // Notify the component the breakpoint changed
      if (container.current.dataset.breakpoint !== className) {
        setBreakpoint(className as "small" | "medium" | "large");
      }
      container.current.dataset.breakpoint = className;
    },
  });

  return breakpoint;
};

/**
 * We are storing slug ids on the backend with apostrophes and parenthesis URL Encoded.
 * encodeURIComponent does not encode these characters, as they are no longer considered,
 * "Special." This method encodes those as well so that graphql queries using the slug id
 * will match the backend.
 */
export const rfc3986EncodeURIComponent = (url: string) => {
  return encodeURIComponent(url).replace(/[!'()*]/g, escape);
};

export const useReactiveVarListener = <T extends unknown>(variable: ReactiveVar<T>, onChange: (value: T) => any) => {
  const didMountRef = useRef(false);
  const value = useReactiveVar(variable);

  useEffect(() => {
    if (didMountRef.current) {
      onChange(value);
    } else {
      didMountRef.current = true;
    }
  }, [value]);
};

/**
 * Use state persisted via isomorphic cookie.
 */
export const usePeristedState = <T extends unknown>(initialValue: T, key: string): [T, (newValue: T) => void] => {
  const cookie = parseCookies()[key];

  // Recall from cookie
  const [value, rawSetValue] = useState<T>(cookie === undefined ? initialValue : JSON.parse(cookie)); // prettier-ignore

  // Set and persist
  const setValue = (newValue: T) => {
    rawSetValue(newValue);
    setCookie(key, JSON.stringify(newValue));
  };

  return [value, setValue];
};

/**
 * Use a reactive variable persisted via isomorphic cookie.
 */
export const usePeristedVar = <T extends unknown>(
  variable: ReactiveVar<T>,
  key: string,
  initialValue: T
): [T, (newValue: T) => void] => {
  const value = useReactiveVar(variable);

  // Set and persist
  const setValue = (newValue: T) => {
    variable(newValue);
    setCookie(key, JSON.stringify(newValue));
  };

  // Recall from cookie
  useEffect(() => {
    const cookie = parseCookies()[key];
    variable(cookie === undefined ? initialValue : JSON.parse(cookie));
  }, []);

  return [value, setValue];
};

export function wait(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export const useForceUpdate = () => {
  const [, setValue] = useState(0);
  return useCallback(() => setValue(Math.random()), []);
};

export const useLocalStorage = <T extends unknown>(key: string): [T | null, (newValue: T) => void] => {
  const [val, setVal] = useState<T | null>(null);

  const setValue = useCallback((value: T) => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, []);

  const handler = useCallback((e: any) => {
    if (e.detail.key === key) {
      setVal(JSON.parse(e.detail.newVlaue));
    }
  }, []);

  useEffect(() => {
    setVal(JSON.parse(window.localStorage.getItem(key) ?? "null"));
    window.addEventListener("storageUpdated", handler);
    return () => {
      window.removeEventListener("storageUpdated", handler);
    };
  }, []);

  // Return null for first render to avoid SSR-client mismatch
  return [val, setValue];
};

/**
 * If removeList is empty, the function removes all params from url.
 * @param {*} router
 * @param {*} removeList
 */
export const useQueryParamsRemove = (router: NextRouter, removeList: string[] = []) => {
  if (removeList.length > 0) {
    removeList.forEach((param) => delete router.query[param]);
  } else {
    // Remove all
    Object.keys(router.query).forEach((param) => delete router.query[param]);
  }
  router.replace(
    {
      pathname: router.pathname,
      query: router.query,
    },
    undefined,
    /**
     * Do not refresh the page
     */
    { shallow: true }
  );
};
