import ClaritasService from "./ClaritasService";
import GAService from "./GAService";
import { getProductPurchaseModel } from "./SubscriptionService";
import { analytics, logEvent, setUserId } from "../lib/firebase";

const capitalize = (s: any) => s?.[0]?.toUpperCase() + s?.slice(1);

export default {
  track: (eventName: string, properties: any) => {
    try {
      // Segment
      window.analytics.track(eventName, properties);

      // Mixpanel
      window.mixpanel.track(eventName, properties);

      // Firebase
      logEvent(analytics, eventName.toLowerCase().replace(/-/g, "").replace(/ /g, "_"), properties);

      // Web vitals are just for performance monitoring
      if (eventName.startsWith("Next.js")) {
        return;
      }

      // Braze Custom Events
      if (window.logBrazeCustomEvent) {
        if (eventName !== "Purchase") {
          window.logBrazeCustomEvent(eventName, properties);
        }
      } else {
        console.error(
          "Trying to log Braze event before SDK is initialized or ignored because it is a purchase. Event: ",
          eventName
        );
      }

      // Purchase Success
      if (eventName === "Purchase" && properties.success) {
        // Claritas
        ClaritasService.trackPurchase();

        // Google Analytics
        GAService.sendEvent({
          hitType: "event",
          eventCategory: "Purchase - Success",
          eventAction: window.location.href,
          eventLabel: properties.tierKey,
        });

        // Braze Purchase Events
        if (window.logPurchase) {
          window.logPurchase(properties.tierKey, properties.price, properties.currency, 1, {
            state: properties.state,
          });
        } else {
          console.error("Trying to log Braze purchase event before SDK is initialized.");
        }

        // Facebook Ads Attribution
        window.fbq("track", "Purchase", {
          currency: properties.currency,
          value: properties.price,
        });

        const purchaseModel = getProductPurchaseModel(properties.tierKey);

        // Subscription
        if (purchaseModel === "subscription") {
          // Twitter Ads Attribution
          window.twq("event", "o7z0l", {
            value: properties.price,
            currency: "USD",
            contents: [
              {
                content_name: properties.tierKey,
                content_price: properties.price,
                num_items: 1,
              },
            ],
          });
        }
      }

      // Purchase Failure
      if (eventName === "Purchase" && !properties.success) {
        GAService.sendEvent({
          hitType: "event",
          eventCategory: "Purchase - Fail",
          eventAction: window.location.href,
          eventLabel: properties.tierKey,
        });
      }

      // Sendinblue
      if (eventName !== "Signup") {
        // Errors until page loaded
        window.sendinblue.track(eventName, properties);
      }

      // Signup Success
      if (eventName === "Signup") {
        // Claritas
        ClaritasService.trackEmailCapture();
        // Google Analytics
        GAService.sendEvent({
          hitType: "event",
          eventCategory: "Sign Up - Success",
          eventAction: window.location.href,
          eventLabel: `Source - ${capitalize(properties.source)}`, // keep previous behavior
        });
        // Facebook Ads Attribution
        window.fbq("trackCustom", "Signup", { source: properties.source });
        // Twitter Ads Attribution
        window.twq("event", "o7z0i");
      }

      // Signup Failure
      if (eventName === "Signup Failure") {
        // Google Analytics
        GAService.sendEvent({
          hitType: "event",
          eventCategory: "Sign Up - Fail",
          eventAction: window.location.href,
          eventLabel: `Source - ${capitalize(properties.source)}`, // keep previous behavior
        });
      }

      // Claim Offer or Bet Now
      if (["Claim Offer", "Bet Now"].includes(eventName)) {
        // Facebook Ads Attribution
        window.fbq("trackCustom", eventName, properties);
      }
    } catch (e: any) {
      console.error(`Error in track: ${eventName}`, e.message);
    }
  },
  page: (url: string) => {
    try {
      window.analytics.page(url);
      window.sendinblue.page(url, {
        ma_url: url,
        ma_path: url.replace("https://betql.co", ""),
        ma_title: window.document.title,
        ma_referrer: window.document.referrer,
      });
      // Mixpanel tracks pageviews manually
    } catch (e: any) {
      console.log(e.message);
    }
  },
  identify: (userId: string, userProps: any) => {
    if (userId) {
      try {
        window.analytics.identify(userId, userProps);
        window.mixpanel.people.set(userProps);
        window.mixpanel.identify(userId);
        window.sendinblue.identify(userProps.email, {
          userId,
          SIGNUP_PAGE: userProps.page,
        });
        window.branch.setIdentity(userId);
        // Firebase
        setUserId(analytics, userId);
      } catch (e: any) {
        console.log(e.message);
      }
    }
  },
  alias: (userId: string) => {
    if (userId) {
      try {
        window.analytics.alias(userId);
        window.mixpanel.alias(userId);
      } catch (e: any) {
        console.log(e.message);
      }
    }
  },
  reset: () => {
    try {
      window.analytics.reset();
      window.mixpanel.reset();
      window.branch.logout();
    } catch (e: any) {
      console.log(e.message);
    }
  },
};
