import React, { Fragment } from "react";
import styles from "./styles.scss";

function index() {
  return (
    <Fragment>
      <a className="skip-to-content-link" href="#main">
        Skip to content
      </a>
      <style jsx>{styles}</style>
    </Fragment>
  );
}

export default index;
