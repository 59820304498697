import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import styles from "./styles.scss";
import Dropdown from "src/components/Dropdown";
import { STATES } from "src/constants";
import { REDEEM_VOUCHER } from "src/gql/mutations";
import { useContext } from "react";
import { SubscriptionContext } from "src/services/SubscriptionService";
import { betMGMPendingVar, currentSport } from "src/services/ApolloService";
import { usePeristedVar } from "src/services/UtilService";
import AnalyticsService from "src/services/AnalyticsService";

const BEGMGM_LINK = "https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1702760";

export interface OfferStepsViewProps {
  tabIndex: number;
  setTabIndex: any;
}

const OfferStepsView = ({ tabIndex, setTabIndex }: OfferStepsViewProps) => {
  const ctx = useContext(SubscriptionContext);
  const [redeemVoucher, { loading }] = useMutation(REDEEM_VOUCHER);
  const [, setBetMGMPending] = usePeristedVar(betMGMPendingVar, "betMGMPending", false); // prettier-ignore
  const [stateIndex, setStateIndex] = useState(0); // prettier-ignore
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const state = STATES[stateIndex];

  // Keep state up to date ie after sign in
  useEffect(() => {
    setStateIndex(STATES.findIndex((x) => x.value === ctx.userLocale ?? "NJ"));
  }, [ctx.userLocale]);

  // Mark as pending offer if available in state
  useEffect(() => {
    if (state.legal) {
      setBetMGMPending(true);
    }
  }, [state.legal]);

  const redeemOffer = async () => {
    try {
      const { data } = await redeemVoucher({
        variables: {
          code,
        },
      });
      await ctx.setSubscriptionLevel(data.redeemVoucher.token);
      setBetMGMPending(false);

      AnalyticsService.track("Redeem Offer BetMGM Success", {
        page: window.location.href,
        sport: currentSport()?.name,
        source: "Offer Steps Modal",
        state: ctx.userLocale,
        code,
      });

      setTabIndex(tabIndex + 1);
    } catch (e: any) {
      setErrorMessage(e?.message ?? "We don't recognize that code");

      AnalyticsService.track("Redeem Offer BetMGM Failure", {
        page: window.location.href,
        sport: currentSport()?.name,
        source: "Offer Steps Modal",
        state: ctx.userLocale,
        code,
      });
    }
  };

  const openOfferLink = () => {
    AnalyticsService.track("Deposit on BetMGM Open Link Button", {
      page: window.location.href,
      sport: currentSport()?.name,
      source: "Offer Steps Modal",
      state: ctx.userLocale,
    });
    window.open(BEGMGM_LINK);
  };

  const offerMarkup = (
    <React.Fragment>
      <div className="step step-1">
        <div className="step-left">Step 1</div>
        <div className="step-middle">
          <div className="step-title">Deposit $10 and bet at least $1.</div>
          <div>
            BetMGM will provide a voucher code within 24 hours of making your first bet. You must be a first-time
            customer to qualify.
          </div>
        </div>
        <div className="step-right" onClick={openOfferLink}>
          <div className="blue-button">Deposit on BetMGM</div>
        </div>
      </div>
      <div className="step">
        <div className="step-left">Step 2</div>
        <div className="step-middle">
          <div className="step-title">Paste your code and click "Redeem Offer".</div>
          <div className="help-text">
            Getting stuck? <a href="mailto:support@betql.co">Email us</a> for help.
          </div>
        </div>
        <div className="step-right">
          <input
            className={`code-input ${errorMessage ? "error" : ""}`}
            type="text"
            placeholder="Paste code here"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            onFocus={() => setErrorMessage("")}
          />
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <div style={{ height: 20 }} />
          <div className="blue-button" onClick={() => !loading && redeemOffer()}>
            {loading ? "Loading..." : "Redeem Offer"}
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  const fallbackMarkup = (
    <div className="offer-fallback">
      <div className="fallback-text">
        Unfortunately, BetMGM is not available in your state, but we won't let you go empty handed. Get{" "}
        <span>10% OFF</span> any BetQL subscription with the code below:
      </div>
      <div className="offer-promo">
        <div className="offer-promo-border">
          <div className="offer-promo-label">PROMO CODE</div>
          <div className="offer-promo-code">BetQL123</div>
        </div>
      </div>
      <div className="blue-button" onClick={() => setTabIndex(tabIndex - 1)}>
        Redeem Code
      </div>
      <div style={{ height: 80 }} />
      <div className="help-text">
        Getting stuck? <a href="mailto:support@betql.co">Email us</a> for help.
      </div>
    </div>
  );

  return (
    <div className="offer-steps-view">
      <div className="question">What state are you in?</div>
      <Dropdown
        className="state-dropdown"
        toggleStyle={{
          padding: "20px",
          width: "100%",
          maxWidth: "320px",
          fontSize: "18px",
          whiteSpace: "nowrap",
        }}
        menuStyle={{
          minWidth: "100%",
        }}
        onSelectItem={(_: any, idx: number) => {
          setStateIndex(idx);
        }}
        selectedIndex={stateIndex}
        items={STATES}
      />
      {state.legal ? offerMarkup : fallbackMarkup}
      <style jsx>{styles}</style>
    </div>
  );
};

export default OfferStepsView;
