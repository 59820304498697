import React from "react";
import indefinite from "indefinite";
import { PICK_TYPES, BetQLService } from "@rotoql/common-services";
import moment, { Moment } from "moment-timezone";

const NO_VALUE_MESSAGE =
  "There is little to no value on the current line right now. View another bet type to find other potential options.";
const UNDERDOG_PREDICATE = "difference from the current line. Consider taking the underdog.";
const DRAW_PREDICATE = "difference from the current line. Consider taking the draw.";
const FAVORITE_PREDICATE = "difference from the current line.";

export const TAGLINE_1 = "Our computer model analyzes every bet, every day to find mispriced lines"; // prettier-ignore
export const TAGLINE_2 = "Your one-stop shop for sports betting picks, analysis, and sportsbook offers"; // prettier-ignore
export const TAGLINE_3 = "We analyze every bet, you beat the sportsbooks";

/*
 * Capitalize a string.
 * Takes the first charachter in the string and makes it capital and lower cases the rest of the string.
 *
 * @param str {string} string to capitalize
 */
export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

/*
 * Capitalize a series of words in a string.
 * Splits the string on spaces, capitalizes every substring with the capitalize function, and then joins them back with spaces.
 *
 * @param str {string} string of words to capitalize
 */
export const capitalizeWords = (str: string) => {
  const words = str.split(" ");
  return words.map((word: string) => capitalize(word)).join(" ");
};

/**
 * Simple helper to take a number or null which returns a + or - prefixing the number of -- for a null
 *
 * @param value? {number} number to print into a string
 */
export const printPlus = (value?: number) => {
  if (value === undefined || value === null) {
    return "--";
  } else if (value >= 0) {
    return `+${value}`;
  } else if (value < 0) {
    return `${value}`;
  } else {
    return "--";
  }
};

function getPointText(game: any) {
  const leagueName = game?.league?.name;
  let pointText = "point";
  if (leagueName === "NHL") {
    pointText = "goal";
  }
  if (leagueName === "MLB") {
    pointText = "run";
  }
  return pointText;
}

const preferredTeamText = (game: any, adverb: string, periodFilter: string) => {
  const { homeTeam, awayTeam } = game;
  const isDraw = game[`value${periodFilter}PreferredMoneyline`] === "draw";
  const name = isDraw
    ? "a draw"
    : game[`value${periodFilter}PreferredTeamId`] === homeTeam.id
    ? homeTeam.fullName
    : awayTeam.fullName;
  return (
    <span>
      {"We have "}
      {periodFilter.includes("total") ? "the Total" : <span style={{ fontWeight: 700 }}>{name}</span>}
      {adverb}
    </span>
  );
};

const preferredLineText = (game: any, periodFilter: string) => {
  const isTotal = periodFilter.includes("total");
  const pointText = isTotal ? getPointText(game) : "point";
  const preferredLine = game[`value${periodFilter}PreferredLine`];
  if (preferredLine !== null && preferredLine !== undefined) {
    return (
      <span>
        <span style={{ fontWeight: 700 }}>
          {isTotal
            ? BetQLService.getTotalDisplay(preferredLine.toFixed(1))
            : BetQLService.getLineDisplay(preferredLine.toFixed(1))}
        </span>
        {periodFilter.includes("spread") || isTotal ? ` ${pointText}${Math.abs(preferredLine) === 1 ? "" : "s"}` : ""}
        {", which is "}
      </span>
    );
  }
  return "";
};

const differentialText = (game: any, predicate: string, periodFilter: string) => {
  const pointText = periodFilter.includes("total") ? getPointText(game) : "point";
  const differential = game[`value${periodFilter}Differential`];
  if (differential !== null) {
    const aOrAn: string = indefinite(`${differential}`).replace(differential, "");
    return (
      <span>
        {aOrAn}
        <span style={{ fontWeight: 700 }}>{`${differential} `}</span>
        {pointText} {predicate}
      </span>
    );
  }
  return "";
};

function totalPredicateText(game: any, periodFilter: string) {
  const preferredTotal = BetQLService.titleCase(game[`value${periodFilter}PreferredTotal`]);
  if (preferredTotal !== null && preferredTotal !== undefined) {
    return `difference from the current Total. Consider taking the ${preferredTotal}`;
  }
  return "difference from the current Total.";
}

function weirdSpreadText(game: any, periodFilter: String) {
  const { homeTeam, awayTeam } = game;
  const isHomeTeamPreferred = game[`value${periodFilter}PreferredTeamId`] === game.homeTeam.id;
  const newFilter = periodFilter.replace("spread", "moneyline");
  const preferredWins = game[`value${periodFilter}PreferredTeamId`] === game[`value${newFilter}PreferredTeamId`];
  return (
    <span>
      {preferredWins ? (
        <span>
          <span style={{ fontWeight: 700 }}>{isHomeTeamPreferred ? homeTeam.fullName : awayTeam.fullName}</span> favored
          to win at a better value than the current line. Consider taking the underdog.
        </span>
      ) : (
        <span>
          <span style={{ fontWeight: 700 }}>{isHomeTeamPreferred ? awayTeam.fullName : homeTeam.fullName}</span> favored
          to win, but the{" "}
          <span style={{ fontWeight: 700 }}>{isHomeTeamPreferred ? homeTeam.fullName : awayTeam.fullName}</span> offer
          better value than the current line. Consider taking the underdog.
        </span>
      )}
    </span>
  );
}

export const renderDrawerText = (
  game: any,
  filterType: string,
  titleTextClassName: string,
  bodyTextClassName: string
) => {
  const { homeTeam, awayTeam } = game;
  const periodFilter =
    filterType === "spread" || filterType === "moneyline" || filterType === "total" ? `FG${filterType}` : filterType;

  const isDraw = game[`value${periodFilter}PreferredMoneyline`] === "draw";

  let drawerText = [
    <div key={0} className={titleTextClassName}>
      {game[`value${periodFilter}Rating`]} Star Rating - {PICK_TYPES[filterType].shortName}
    </div>,
  ];

  const adverb =
    periodFilter.includes("spread") && parseInt(game[`value${periodFilter}PreferredLine`], 10) < 0
      ? " favored by "
      : " at ";

  if (game[`maxNew${periodFilter}Trend`].noData) {
    drawerText.push(
      <div key={1} className={bodyTextClassName}>
        {NO_VALUE_MESSAGE}
      </div>
    );
  } else if (periodFilter.includes("total")) {
    const predicate = totalPredicateText(game, periodFilter);
    drawerText.push(
      <div key={1} className={bodyTextClassName}>
        {preferredTeamText(game, adverb, periodFilter)}
        {preferredLineText(game, periodFilter)}
        {differentialText(game, predicate, periodFilter)}
      </div>
    );
  } else if (filterType.includes("spread") && (game.league.name === "MLB" || game.league.name === "NHL")) {
    if (game[`maxNew${periodFilter}Trend`].useUnderdog) {
      drawerText.push(
        <div key={1} className={bodyTextClassName}>
          We have {weirdSpreadText(game, periodFilter)}
        </div>
      );
    } else {
      drawerText.push(
        <div key={1} className={bodyTextClassName}>
          We have{" "}
          <span style={{ fontWeight: 700 }}>
            {game[`value${periodFilter}PreferredTeamId`] === homeTeam.id ? homeTeam.fullName : awayTeam.fullName}
          </span>{" "}
          favored to win at an even better value than the current line.
        </div>
      );
    }
  } else if (game[`maxNew${periodFilter}Trend`].useUnderdog) {
    drawerText.push(
      <div key={1} className={bodyTextClassName}>
        {preferredTeamText(game, adverb, periodFilter)}
        {preferredLineText(game, periodFilter)}
        {differentialText(game, isDraw ? DRAW_PREDICATE : UNDERDOG_PREDICATE, periodFilter)}
      </div>
    );
  } else {
    drawerText.push(
      <div key={1} className={bodyTextClassName}>
        {preferredTeamText(game, adverb, periodFilter)}
        {preferredLineText(game, periodFilter)}
        {differentialText(game, FAVORITE_PREDICATE, periodFilter)}
      </div>
    );
  }

  return drawerText;
};

// sort by matchup start date, default future -> past
const sortByStartDate = (schedule: Array<any>, direction = 1) =>
  schedule.sort((matchup1: any, matchup2: any): number => {
    const startDate1: Moment = moment.utc(matchup1.startDate);
    const startDate2: Moment = moment.utc(matchup2.startDate);
    return (startDate2.valueOf() - startDate1.valueOf()) * direction;
  });

// Betting Facts (ou/ats win/loss etc)
// pass in homeTeam or awayTeam and make sure it has the `schedule` property
export const getBettingFacts = (event: any, team: "home" | "away") => {
  const teamObject = event[`${team}Team`];
  const schedule = sortByStartDate(teamObject.schedule || []);

  const stats = {
    games: 0,
    wins: 0,
    losses: 0,
    atsWins: 0,
    atsLosses: 0,
    homeAtsWins: 0,
    homeAtsLosses: 0,
    awayAtsWins: 0,
    awayAtsLosses: 0,
    favAtsWins: 0,
    favAtsLosses: 0,
    dogAtsWins: 0,
    dogAtsLosses: 0,
    l5AtsWins: 0,
    l5AtsLosses: 0,
    l10AtsWins: 0,
    l10AtsLosses: 0,
  };

  for (const matchup of schedule) {
    const { homeTeam, homeScore, awayScore, line } = matchup;

    if (!line) {
      continue;
    }

    const atsWin = teamObject.id === matchup.homeTeam.id ? line.homeAtsWin : line.awayAtsWin;

    if (!line || homeScore === null || awayScore === null || atsWin === null) {
      continue;
    }

    const isHome = teamObject.id === homeTeam.id;
    const homeTeamWon = homeScore > awayScore;
    const awayTeamWon = homeScore > awayScore;
    const atsLoss = !atsWin;
    const isFav = (isHome ? line.homeSpread : line.awaySpread) < 0;
    const isDog = (isHome ? line.homeSpread : line.awaySpread) > 0;
    const isL5 = stats.games < 5;
    const isL10 = stats.games < 10;

    stats.games++;
    if (isHome ? homeTeamWon : awayTeamWon) {
      stats.wins++;
    }
    if (isHome ? awayTeamWon : homeTeamWon) {
      stats.losses++;
    }
    if (atsWin) {
      stats.atsWins++;
    }
    if (atsLoss) {
      stats.atsLosses++;
    }
    if (isHome && atsWin) {
      stats.homeAtsWins++;
    }
    if (isHome && atsLoss) {
      stats.homeAtsLosses++;
    }
    if (!isHome && atsWin) {
      stats.awayAtsWins++;
    }
    if (!isHome && atsLoss) {
      stats.awayAtsLosses++;
    }
    if (atsWin && isFav) {
      stats.favAtsWins++;
    }
    if (!atsWin && isFav) {
      stats.favAtsLosses++;
    }
    if (atsWin && isDog) {
      stats.dogAtsWins++;
    }
    if (!atsWin && isDog) {
      stats.dogAtsLosses++;
    }
    if (isL5 && atsWin) {
      stats.l5AtsWins++;
    }
    if (isL5 && atsLoss) {
      stats.l5AtsLosses++;
    }
    if (isL10 && atsWin) {
      stats.l10AtsWins++;
    }
    if (isL10 && atsLoss) {
      stats.l10AtsLosses++;
    }
  }
  return stats;
};
