import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { SOCIAL_PROVIDER } from "../../constants";
import { SOCIAL_LOGIN_MUTATION } from "../../gql/mutations";
import { SubscriptionContext } from "src/services/SubscriptionService";

export interface SignInProps {
  toggleError(): void;
  isLogin: boolean;
}

export default function SignInWithApple(props: SignInProps) {
  const ctx = useContext(SubscriptionContext);
  const { toggleError, isLogin } = props;
  const [login] = useMutation(SOCIAL_LOGIN_MUTATION);
  const { origin } = window.location;

  const signInWithApple = async () => {
    window.AppleID.auth.init({
      clientId: "com.rotoql.betql-web",
      redirectURI: origin,
      scope: "name email",
      usePopup: true,
    });
    try {
      const { authorization: appleAuth } = await window.AppleID.auth.signIn();
      const {
        data: { login: loginData },
      } = await login({
        variables: {
          socialProvider: SOCIAL_PROVIDER.APPLE,
          socialToken: appleAuth.id_token,
        },
      });
      ctx.onSignIn(loginData, isLogin, "social");
    } catch (err) {
      console.error(err);
      toggleError();
    }
  };

  return (
    <button className="btn signin-btn" onClick={signInWithApple}>
       Sign In with Apple
      <style jsx>{`
        .signin-btn {
          background: black;
          border-radius: 8px;
          color: white;
          font-size: 15px;
          margin-bottom: 8px;
          height: 46px;
          width: 100%;
        }
        .signin-btn:hover {
          background: rgba(0, 0, 0, 0.85);
        }
      `}</style>
    </button>
  );
}
