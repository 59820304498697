import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Modal } from "reactstrap";
import PlayerImage from "src/components/Player/PlayerImage";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import styles from "./styles.scss";
import { PlayerCardOverSummary } from "./PlayerCardSummary/PlayerCardOver";
import { PlayerCardSplits } from "./PlayerCardSplits";
import { PlayerCardPreform } from "./PlayerCardPreform";
import { useQuery, useReactiveVar } from "@apollo/client";
import { PLAYER_STAT_SUMMARY_QUERY } from "src/gql/queries";
import { PlayerCardContext } from "src/services/PlayerCardService";
import { Player, PlayerStatSummaryResponse, Team } from "src/models";
import Spinner from "src/components/Spinner";
import { currentSport } from "src/services/ApolloService";
import AnalyticsService from "src/services/AnalyticsService";
import { useRouter } from "next/router";
import { useQueryParamsRemove } from "src/services/UtilService";
import GAService from "src/services/GAService";

const PlayerCardModal = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const currentSportObj = useReactiveVar(currentSport);
  const context = useContext(PlayerCardContext);
  const [open, setOpen] = useState<boolean>(false);
  const [playerId, setPlayerId] = useState<string>();
  const [league, setLeague] = useState(currentSportObj?.name ?? "");

  const queryPlayerId = useMemo(() => {
    if (typeof router.query?.playerId === "string") {
      return router.query?.playerId;
    }
    return;
  }, [router.query?.playerId]);

  const toggle = useCallback(() => {
    const isOpen = !open;

    setOpen(isOpen);
    if (!isOpen && queryPlayerId) {
      useQueryParamsRemove(router, ["playerId"]);
    }
  }, [open]);
  const { data, loading } = useQuery<PlayerStatSummaryResponse>(PLAYER_STAT_SUMMARY_QUERY, {
    variables: {
      playerId,
    },
    skip: !open || !playerId,
  });

  const summary = data?.playerStatSummary;
  const player = (summary?.player ?? {}) as Player;
  const team = (summary?.team ?? {}) as Team;

  const openModal = useCallback(async (_player: Player, sport?: string) => {
    setPlayerId(_player.id);
    setOpen(true);

    if (sport) {
      setLeague(sport);
    }
  }, []);

  const contextValue = useMemo(() => ({ ...context, openModal }), [context, openModal]);

  useEffect(() => {
    if (queryPlayerId && !open) {
      openModal({ id: queryPlayerId });
      GAService.sendEvent({
        hitType: "event",
        eventCategory: "Player Card Open",
        eventAction: window.location.href,
        eventLabel: summary?.player.fullName,
      });
      AnalyticsService.track("Player Card Open", {
        screen: "PlayerCard",
        tab: "Summary",
        sport: league,
        playerId: summary?.player.id,
        player: summary?.player.fullName,
      });
    }
  }, [league, summary]);

  return (
    <PlayerCardContext.Provider value={contextValue}>
      {children}
      <Modal isOpen={open} toggle={toggle} className="roto-playercard-modal">
        <div className="modal-header">
          <div className="roto-playercard-modal__info">
            <PlayerImage
              url={player.imageUrl ?? ""}
              containerClassName="roto-playercard-modal__player-image d-sm-block"
            />
            <div>
              <div className="modal-title">
                <div>{player.fullName ?? "Loading..."}</div>
                {Boolean(player.injuryStatus) && <div className="player-injury-status">{player.injuryStatus}</div>}
              </div>
              <div className="roto-playercard-modal__team">
                <div className="roto-playercard-modal__team__player-name">{team.fullName}</div>
                <div>{player.position}</div>
              </div>
            </div>
          </div>
          <div className="roto-playercard-modal__close-button" onClick={toggle}>
            <Icon path={mdiClose} size="22px" color="#000" />
          </div>
        </div>
        <div className="roto-playercard-modal__body">
          <div className="roto-playercard-modal__body__content">
            {!summary || loading ? (
              <div className="roto-playercard-modal__body__loading">
                <Spinner size={32} color="#ebebeb" />
              </div>
            ) : (
              <>
                <PlayerCardOverSummary summary={summary} league={league} />
                <PlayerCardSplits summary={summary} league={league} />
                <PlayerCardPreform summary={summary} league={league} />
              </>
            )}
          </div>
        </div>
        <style jsx global>
          {styles}
        </style>
      </Modal>
    </PlayerCardContext.Provider>
  );
};

export default PlayerCardModal;
