import React, { Component } from "react";
import { Button as BootstrapButton } from "reactstrap";
import styles from "./styles.scss";

export class Button extends Component<any, any> {
  render() {
    const { children, className } = this.props;
    return (
      <React.Fragment>
        <BootstrapButton {...this.props} className={`rotoql-button ${className ? className : ""}`}>
          {children}
        </BootstrapButton>
        <style jsx global>
          {styles}
        </style>
      </React.Fragment>
    );
  }
}
