import React, { Component } from "react";
import { Button, Form, Input, Row, Col, Alert } from "reactstrap";
import { Mutation } from "@apollo/client/react/components";
import { ApolloError } from "@apollo/client";
import GAService from "../../../services/GAService";

import { LOGIN_MUTATION } from "../../../gql/mutations";
import { SubscriptionContext } from "src/services/SubscriptionService";

export interface LoginFromSocialState {
  email: string;
  password: string;
  submitting: boolean;
  errors: string[];
}

export default class LoginFromSocial extends Component<{}, LoginFromSocialState> {
  static contextType = SubscriptionContext;
  constructor(props: any) {
    super(props);
    this.state = { email: "", password: "", submitting: false, errors: [] };
  }

  renderErrorMessages(error: ApolloError) {
    if (!error) {
      return null;
    }
    const errorMessageList: any = [];
    const errors: any = error.graphQLErrors;
    if (!errors) {
      return null;
    }
    for (let i = 0; i < errors.length; i++) {
      errorMessageList.push(<div key={i}>{errors[i].message}</div>);
    }
    return <Alert color="danger">{errorMessageList}</Alert>;
  }

  render() {
    const { email, password } = this.state;
    return (
      <Mutation mutation={LOGIN_MUTATION}>
        {(login: any, { loading, error }: any) => (
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              try {
                const res = await login({
                  variables: {
                    email,
                    password,
                  },
                });
                await this.context.onSignIn(res.data.login, true, "social");
                GAService.sendEvent({
                  hitType: "event",
                  eventCategory: "Login - Success",
                  eventAction: window.location.href,
                  eventLabel: "Source - Social",
                });
                window.fbq("trackCustom", "Login", { source: "social" });
              } catch {
                GAService.sendEvent({
                  hitType: "event",
                  eventCategory: "Login - Fail",
                  eventAction: window.location.href,
                  eventLabel: "Source - Social",
                });
              }
            }}
          >
            {error ? (
              <Row>
                <Col md={12}>{this.renderErrorMessages(error)}</Col>
              </Row>
            ) : null}
            <div className="rotoql-social-login-modal__input-container">
              <Input
                id="email"
                type="email"
                name="email"
                value={email}
                placeholder="Email Address"
                onChange={(e) => {
                  this.setState({
                    email: e.target.value,
                    errors: [],
                  });
                }}
                className="rotoql-social-login-modal__input"
              />
            </div>
            <div className="rotoql-social-login-modal__input-container">
              <Input
                className="rotoql-social-login-modal__input"
                type="password"
                name="password"
                value={password}
                placeholder="Password"
                onChange={(e) => {
                  this.setState({
                    password: e.target.value,
                    errors: [],
                  });
                }}
              />
            </div>
            <div className="rotoql-social-login-modal__button-container">
              <Button color="primary" disabled={loading} className="rotoql-social-login-modal__login-button">
                Log In
              </Button>
            </div>
          </Form>
        )}
      </Mutation>
    );
  }
}
