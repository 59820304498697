import React, { useState } from "react";
import styles from "./styles.scss";

export type TabItem = {
  id: any;
  label: string;
};

type PlayerCardTabProps = {
  tabItems: TabItem[];
  onChange: (key: string) => void;
};

type PlayerCardTabButtonProps = {
  id: any;
  label: string;
  selectedTab?: any;
  onChange: (key: string) => void;
};

function PlayerCardTabButton({ id, label, selectedTab, onChange }: PlayerCardTabButtonProps) {
  return (
    <a
      className={`roto-playercard-modal__tab-menu-button${selectedTab === id ? "--selected" : ""}`}
      onClick={() => onChange(id)}
    >
      {label}
    </a>
  );
}

export function PlayerCardTab(props: PlayerCardTabProps) {
  const { tabItems, onChange } = props;
  const [selectedTab, setSelectedTab] = useState<string | null>(tabItems[0].id);

  const handleChange = (key: string) => {
    setSelectedTab(key);
    onChange(key);
  };

  return (
    <div className="roto-playercard-modal__tab-menu">
      {tabItems.map((item) => (
        <PlayerCardTabButton key={item.id} {...item} selectedTab={selectedTab} onChange={() => handleChange(item.id)} />
      ))}
      <style jsx global>
        {styles}
      </style>
    </div>
  );
}
