import React, { useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { variationId, variationOverride } from "./ApolloService";
import XXH from "xxhashjs";
import { v4 as uuidv4 } from "uuid";
import { parseCookies } from "nookies";
import { setCookie } from "./SubscriptionService";

const isProd = process.env.NEXT_PUBLIC_VERCEL_ENV === "production";
const AB_TEST_ID_COOKIE_NAME = "betql_ab_test_id";
const maxUnsignedInt32 = 4294967295;

let _variationId: string | null = null;

// Get variation id from existing cookies or return a new one, use in getInitialProps
export const getVariationId = () => {
  const cookies = parseCookies();
  if (!_variationId) {
    _variationId = cookies[AB_TEST_ID_COOKIE_NAME] ?? uuidv4();
  }
  return _variationId;
};

/**
 * Create a variation that is randomly distributed to users.
 *
 * @param feature A string to seed the hash.
 * @param variations The list of possible values.
 */
export const useVariation = (feature: string = "", variations: any[]) => {
  const override = useReactiveVar(variationOverride);

  // Persist variationId once in the browser
  useEffect(() => setCookie(AB_TEST_ID_COOKIE_NAME, variationId() as string), []); // prettier-ignore

  // Hash speed is 3 bytes per cycle mega cheap
  const hash = XXH.h32(feature + variationId(), 0).toNumber();

  // This fraction determines which variation is received
  let fraction = override ? override / 10 : hash / maxUnsignedInt32;

  // Get the variation
  const chosenOptionIndex = Math.floor(fraction * variations.length);

  return variations[chosenOptionIndex];
};

export const cycleVariation = () => {
  const variation = variationOverride();
  if (variation === null) {
    variationOverride(0);
  } else if (variation === 9) {
    variationOverride(null);
  } else {
    variationOverride(variation + 1);
  }
  return variationOverride();
};

export const VariationOverride = ({ className = "", style = {} }: { className?: string; style?: any }) => {
  const variation = useReactiveVar(variationOverride);

  if (isProd) {
    return null;
  }

  return (
    <span className={className} style={{ cursor: "pointer", userSelect: "none", ...style }} onClick={cycleVariation}>
      Variation override is {variation ?? "None"}. Click here to change.
    </span>
  );
};
