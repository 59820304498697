import React, { useContext, useState } from "react";
import { Modal } from "reactstrap";
import moment from "moment-timezone";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";

import AdZoneService, { AdZoneEnum } from "../../services/AdZoneService";
import styles from "./styles.scss";
import { SubscriptionContext } from "src/services/SubscriptionService";
import { DEFAULT_TIMEZONE } from "src/constants";

const todaysDate = moment.tz(DEFAULT_TIMEZONE);

const MarketingCampaignModal = ({ pageKey }: { pageKey: string | null }) => {
  const ctx = useContext(SubscriptionContext);
  const [toggle, setToggle] = useState(true);

  const closeModal = () => setToggle(!toggle);

  const onGetDealButtonClick = () => {
    AdZoneService.setInterstitialViewed();
    setToggle(false);
    ctx.redirectToPricing(
      {
        screen: "All",
        page: pageKey,
        source: "VIP40 Modal Deal",
      },
      "VIP40 Deal",
      AdZoneService.getPricingModalPage(AdZoneEnum.INTERSTITIAL_MODAL),
      AdZoneService.getPricingModalSportList(AdZoneEnum.INTERSTITIAL_MODAL)
    );
  };

  if (
    // only show it to free users
    ctx.signedIn ||
    // is the deal still going on
    !todaysDate.isBetween(
      AdZoneService.getStartDate(AdZoneEnum.INTERSTITIAL_MODAL),
      AdZoneService.getEndDate(AdZoneEnum.INTERSTITIAL_MODAL)
    ) ||
    // have they already veiwed the modal
    AdZoneService.getInterstitialViewed()
  ) {
    return null;
  }
  const interstitialConfig = AdZoneService.getInterstitialConfig();
  return (
    <Modal isOpen={toggle} toggle={closeModal} className="campaign-modal">
      <div className="campaign-modal__container">
        <div className="campaign-modal__close-button" onClick={closeModal}>
          <Icon path={mdiClose} size="22px" color="#fff" />
        </div>
        <div className="campaign-modal__title">
          <React.Fragment>
            {interstitialConfig.title}&nbsp;
            <span className="campaign-modal__strikethrough-text">{interstitialConfig.strikeText}</span>
          </React.Fragment>
        </div>
        <div className="campaign-modal__sub-title">
          USE CODE <span className="campaign-modal__sub-title--coupon-text">{interstitialConfig.coupon}</span> AT
          CHECKOUT
        </div>
        <div className="campaign-modal__image-container">
          <img
            className="campaign-modal__phone-img lazyload"
            data-src={interstitialConfig.image}
            alt="BetQL Mobile App"
          />
        </div>
        <button className="campaign-modal__button-container" onClick={onGetDealButtonClick}>
          Claim Offer
        </button>
        <div className="campaign-modal__fine-print">{interstitialConfig.finePrint}</div>
      </div>
      <style jsx global>
        {styles}
      </style>
    </Modal>
  );
};

export default MarketingCampaignModal;
