import React, { Component } from "react";

import Login from "../../../LoginModal/Login";
import Register from "../../../LoginModal/Register";
import { Reset, RequestReset } from "../../../LoginModal/ForgotPW";
import ButtonGroup, { ButtonGroupItem } from "../../../ButtonGroup";
import { SubscriptionContext } from "../../../../services/SubscriptionService";
import styles from "./styles.scss";

const tabItems = [
  { name: "Log In", key: "login" },
  { name: "Sign Up", key: "signup" },
];

export interface AuthViewProps {
  style?: any;
  className?: string;
}

export interface AuthViewState {
  authTabIndex: number;
}

/**
 * Render the authenticatino view for the CheckoutModal component
 */
export default class AuthView extends Component<AuthViewProps, AuthViewState> {
  static contextType = SubscriptionContext;
  static defaultProps = {
    visible: false,
    loginTabIndex: 0,
  };
  constructor(props: AuthViewProps) {
    super(props);
    this.state = {
      authTabIndex: 0,
    };
  }

  /**
   * Change which form we display in the AuthView
   *
   * @param tab
   */
  setAuthTabIndex = (tab: ButtonGroupItem | number) => {
    if (typeof tab === "number") {
      this.setState({ authTabIndex: tab });
    } else {
      const authTabIndex = tabItems.indexOf(tab);
      this.setState({ authTabIndex });
    }
  };

  /**
   * Determine which form to display based on the
   */
  renderForm = () => {
    const { authTabIndex } = this.state;
    if (authTabIndex === 0 || authTabIndex === 1) {
      return (
        <div className="rotoql-content-authview__auth-container">
          <div
            className={`rotoql-content-authview__form-container ${
              authTabIndex !== 0 ? "rotoql-content-authview__form-container--inactive" : ""
            }`}
          >
            <div className="rotoql-content-authview__form-subcontainer">
              <div className="rotoql-content-authview__form-title">Log In</div>
              <Login />
              <div className="rotoql-content-authview__forgot-pw" onClick={() => this.setAuthTabIndex(2)}>
                Forgot Password
              </div>
            </div>
          </div>
          <div
            className={`rotoql-content-authview__form-container ${
              authTabIndex !== 1 ? "rotoql-content-authview__form-container--inactive" : ""
            }`}
          >
            <div className="rotoql-content-authview__form-subcontainer">
              <div className="rotoql-content-authview__form-title">Sign Up</div>
              <Register hidePhone />
            </div>
          </div>
        </div>
      );
    } else if (authTabIndex === 2) {
      return (
        <div className="rotoql-content-authview__form-container">
          <div className="rotoql-content-authview__form-subcontainer">
            <RequestReset sentRequest={() => this.setAuthTabIndex(3)} />
            <div className="rotoql-content-authview__or">or</div>
            <div className="rotoql-content-authview__forgot-pw" onClick={() => this.setAuthTabIndex(1)}>
              Go back
            </div>
          </div>
        </div>
      );
    } else if (authTabIndex === 3) {
      return (
        <div className="rotoql-content-authview__form-container">
          <div className="rotoql-content-authview__form-subcontainer">
            <Reset resetComplete={() => this.setAuthTabIndex(0)} />
            <div className="rotoql-content-authview__forgot-pw" onClick={() => this.setAuthTabIndex(2)}>
              Go back
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    const { authTabIndex } = this.state;
    const { style, className } = this.props;
    return (
      <div className={`rotoql-content-authview ${className}`} style={style}>
        <ButtonGroup
          className="rotoql-content-authview__button-group"
          onSelectItem={this.setAuthTabIndex}
          selectedIndex={authTabIndex}
          items={tabItems}
        />
        {this.renderForm()}
        <style jsx>{styles}</style>
      </div>
    );
  }
}
