import React, { useContext, useEffect } from "react";
import moment from "moment-timezone";
import { DefaultSeo } from "next-seo";
import { useReactiveVar } from "@apollo/client";
import { isMobile } from "react-device-detect";
import { useRouter } from "next/router";

// Components
import NavBar from "src/components/NavBar";
import Main from "src/components/Main";
import Footer from "src/components/Footer";
import LoginModal from "src/components/LoginModal";
import PricingModal from "src/components/Pricing/PricingModal";
import MarketingCampaignModal from "src/components/MarketingCampaignModal";
import StateCaptureModal from "src/components/StateCaptureModal";
import SportMismatchModal from "src/components/SportMismatchModal";
import FreeTrialBanner from "src/components/FreeTrialBanner";
import CollectSportsbookModal from "src/components/CollectSportsbookModal";
import SkipToContent from "src/components/SkipToContent";
import CustomOfferModal from "src/components/CustomOfferModal";
import PromotionBanner from "src/components/PromotionBanner";
import PlayerCardModal from "src/components/PlayerCard/PlayerCardModal";
// Services
import { SubscriptionContext } from "src/services/SubscriptionService";
import { customOfferModalOpen, isHeadless } from "src/services/ApolloService";
import AdZoneService, { AdZoneEnum } from "src/services/AdZoneService";
// Misc
import SEO from "src/next-seo.config";
import { DEFAULT_TIMEZONE, VALID_PROMO } from "src/constants";
import styles from "src/styles/global.scss";

const todaysDate = moment.tz(DEFAULT_TIMEZONE);

export interface PageProps {
  children: React.ReactNode;
}

const Page = ({ children }: PageProps) => {
  const ctx = useContext(SubscriptionContext);
  const router = useRouter();
  const headless = useReactiveVar(isHeadless);
  const customOfferModalShow = useReactiveVar(customOfferModalOpen);
  // we use these as key so they need to be lowercase
  // vercel will match upper or lower case patterns
  let pageKey: string | null = null;
  if (typeof router.query?.pageKey === "string") {
    pageKey = router.query?.pageKey?.toLowerCase();
  }

  const noIndexObject =
    process.env.NEXT_PUBLIC_VERCEL_ENV !== "production"
      ? {
          dangerouslySetAllPagesToNoIndex: true,
          dangerouslySetAllPagesToNoFollow: true,
        }
      : {};

  useEffect(() => {
    const { gclid, couponId } = router.query;
    const betqlgclid: string = gclid as any;

    if (betqlgclid) {
      window.localStorage.setItem("betqlgclid", betqlgclid);
    }

    if (couponId && isMobile) {
      ctx.togglePricingModal({});
    }
  }, []);
  return (
    <React.Fragment>
      {/* TODO: the sport check is a one off, lets remove after promo */}
      {!ctx.hasSubscriptionAccess("CFB") &&
      todaysDate.isBetween(
        AdZoneService.getStartDate(AdZoneEnum.SITE_TOP_BANNER),
        AdZoneService.getEndDate(AdZoneEnum.SITE_TOP_BANNER)
      ) ? (
        <button
          className="site-banner"
          onClick={() =>
            ctx.togglePricingModal({
              track: {
                screen: "All",
                page: pageKey,
                source: AdZoneEnum.SITE_TOP_BANNER,
              },
              source: "Bowl Season Deal",
              defaultTierIndex: AdZoneService.getPricingModalPage(AdZoneEnum.SITE_TOP_BANNER),
              defaultSports: AdZoneService.getPricingModalSportList(AdZoneEnum.SITE_TOP_BANNER),
            })
          }
        >
          {AdZoneService.getBannerText(AdZoneEnum.CHECKOUT_MODAL_BANNER)}
        </button>
      ) : null}
      <div className="d-flex flex-column h-100 w-100" style={{ position: "relative" }}>
        <style jsx global>
          {styles}
        </style>
        <DefaultSeo {...SEO} {...noIndexObject} />
        <SkipToContent />
        <LoginModal />
        <PricingModal />
        <MarketingCampaignModal pageKey={pageKey} />
        <StateCaptureModal />
        <SportMismatchModal />
        <CollectSportsbookModal />
        {customOfferModalShow && <CustomOfferModal />}
        {/* FreeTrialBanner slots in above the NavBar */}
        {!headless && (
          <>
            {VALID_PROMO ? <PromotionBanner /> : <FreeTrialBanner />}
            <NavBar />
          </>
        )}
        <PlayerCardModal>
          <Main>{children}</Main>
        </PlayerCardModal>
        {headless ? null : <Footer />}
      </div>
    </React.Fragment>
  );
};

export default Page;
