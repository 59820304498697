import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.scss";
import { Line, getElementsAtEvent } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Filler } from "chart.js";
import { PlayerCardTab, TabItem } from "../PlayerCardTab";
import moment from "moment";
import { PlayerStatSummary } from "src/models";
import {
  StatConfig,
  getStandardPropertyKeys,
  getSummaryStatConfig,
  sortByConfig,
} from "src/services/PlayerCardService";
import { groupBy, isUndefined } from "lodash";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Filler);

const gameMarkerDataLabels = {
  id: "gameMarkerDataLabels",
  afterDatasetsDraw: (chart: any) => {
    const { ctx } = chart;
    ctx.save();

    ctx.font = "12px Object Sans";
    for (let x = 0; x < chart.config.data.datasets.length; x++) {
      const data = chart.config.data.datasets[x].data;
      for (let i = 0; i < data.length; i++) {
        const stat = data[i];
        const value = stat.hasNoData ? "0" : stat.value;
        const textWidth = ctx.measureText(value).width;
        const metadata = chart.getDatasetMeta(x).data[i];
        ctx.fillStyle = "#000000";
        ctx.fillText(value, metadata.x - textWidth / 2, metadata.y - 10);
      }
    }
    ctx.restore();
  },
};

const gameDateLabels = {
  id: "gameDateLabels",
  beforeDraw: (chart: any) => {
    const {
      ctx,
      scales: { x },
    } = chart;
    ctx.save();

    for (let item = 0; item < chart.config.data.datasets.length; item++) {
      const data = chart.config.data.datasets[item].data;
      for (let i = 0; i < data.length; i++) {
        const tick = x.ticks[i];

        if (!tick) {
          continue;
        }

        const { label, date } = data[i];
        tick.label = label;

        const textWidth = ctx.measureText(label).width;

        ctx.font = "400 12px Object Sans";
        ctx.fillStyle = "#7E8A99";
        ctx.fillText(date, x.getPixelForValue(i) - textWidth / 2, x.top + 38);
      }
    }
    ctx.restore();
  },
};

type GameData = { header: string; label?: string; value: number | string; hasNoData?: boolean }[][];
const defaultOptions = {
  sortOrder: 0,
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        color: "#1A1A1A",
        font: {
          family: "Object Sans",
          size: 12,
          weight: 700,
        },
      },
    },
    y: {
      suggestedMin: 19,
      suggestedMax: 34,
      ticks: {
        maxTicksLimit: 4,
        color: "#909CAC",
        font: {
          family: "Object Sans",
          size: 12,
          weight: 400,
        },
      },
      border: {
        display: false,
      },
    },
  },
  parsing: {
    xAxisKey: "date",
    yAxisKey: "value",
  },
  layout: {
    padding: {
      bottom: 14,
    },
  },
};

const defaultDataSet = {
  label: "Performance Chart",
  backgroundColor: "#000000" as any,
  borderColor: "#004198",
  borderWidth: 3,
  fill: true,
  pointBackgroundColor: "#FFFFFF",
  pointRadius: 5,
  pointHoverRadius: 5,
};

type PlayerCardPreformProps = {
  summary: PlayerStatSummary;
  league: string;
};

type SummaryChartOptions = typeof defaultOptions;

function generateGameData({ player, latestStats }: PlayerStatSummary, league: string, config: StatConfig): GameData {
  return latestStats
    .map((item) => {
      const { awayTeam, homeTeam, startDate, playerStats } = item;
      const homeStats = playerStats.filter(
        (stat) =>
          stat.statType === "GAME" &&
          stat.statCategory === "total" &&
          stat.side === "home" &&
          stat.player?.id === player.id
      );

      const awayStats = playerStats.filter(
        (stat) =>
          stat.statType === "GAME" &&
          stat.statCategory === "total" &&
          stat.side === "away" &&
          stat.player?.id === player.id
      );

      const values = homeStats.length ? homeStats : awayStats;
      const side = homeStats.length ? "home" : "away";
      const startHeader =
        side === "home" ? `vs ${awayTeam.preferredAbbreviation}` : `@ ${homeTeam.preferredAbbreviation}`;
      const dateValue = moment(startDate).format("MMM DD");
      const label = side === "home" ? `vs ${awayTeam.preferredAbbreviation}` : `@ ${homeTeam.preferredAbbreviation}`;

      const data: { header: string; label: string; date: string; value: number | string; hasNoData?: boolean }[] = [
        {
          header: startHeader,
          label: `${label}`,
          value: dateValue,
          date: dateValue,
        },
      ];

      const keys = getStandardPropertyKeys(league, player.position);
      keys.forEach((property, index) => {
        const hasProperty = values.findIndex((stat) => stat.statProperty === property) !== -1;

        if (!hasProperty) {
          values.push({
            id: `${property}-${index}`,
            statProperty: property,
            statType: "GAME",
            side,
            statCategory: "total",
            statValue: 0,
            hasNoData: true,
          });
        }
      });

      values
        .sort((a, b) => sortByConfig(a, b, config))
        .forEach((stat) => {
          const propertyConfig = config[stat.statProperty];
          if (propertyConfig) {
            data.push({
              header: propertyConfig.shortLabel,
              label: `${label}`,
              value: stat.statValue ?? 0,
              date: dateValue,
              hasNoData: stat.hasNoData,
            });
          }
        });

      return data;
    })
    .reverse();
}

function getChartOptions({ player, latestStats }: PlayerStatSummary, config: StatConfig): SummaryChartOptions[] {
  const newOptions: SummaryChartOptions[] = [];
  const groupedStats = groupBy(
    latestStats
      .map((game) => game.playerStats.filter((stat) => config[stat.statProperty] && stat.player?.id === player.id))
      .flat(1),
    (stat) => stat.statProperty
  );

  for (let statProperty in groupedStats) {
    const sortOrder = config[statProperty].sortOrder;
    const opts = { ...defaultOptions };
    const values = groupedStats[statProperty].map((stat) => Number(stat.statValue));
    const suggestedMin = Math.min(...values);
    const suggestedMax = Math.max(...values) + 4;

    newOptions.push({
      ...defaultOptions,
      sortOrder,
      scales: {
        ...defaultOptions.scales,
        y: { ...opts.scales.y, suggestedMin, suggestedMax },
      },
    });
  }

  const sortedOptions = newOptions.sort((a, b) => a.sortOrder - b.sortOrder);

  return sortedOptions;
}

function PreformSummaryCell({
  header,
  hasNoData,
  value,
}: {
  header: string;
  value: number | string;
  hasNoData?: boolean;
}) {
  return (
    <div key={value} className="roto-playercard-modal-preform__summary-content__list-cell">
      <div className="roto-playercard-modal-preform__summary-content__list-cell__header">{header}</div>
      <div className="roto-playercard-modal-preform__summary-content__list-cell__content">
        {hasNoData ? "--" : value}
      </div>
    </div>
  );
}

export function PlayerCardPreform({ summary, league }: PlayerCardPreformProps) {
  const lineChartRef = useRef<ChartJSOrUndefined>();
  const [init, setInit] = useState(false);

  const {
    tabItems,
    allGamesData,
    defaultChartOptions,
    defaultDataSets = [],
  } = useMemo(() => {
    const config = getSummaryStatConfig(league, summary.player.position);
    const tItems = Object.values(config.default).map<TabItem>((c, id) => ({ id, label: c.longLabel }));
    const gData = generateGameData(summary, league, config.all);
    const chartOptions = getChartOptions(summary, config.default);
    const dDataSets = tItems.slice(0).map((_item, index) => ({
      ...defaultDataSet,
      data: gData.map((data) => data[index + 1]),
    }));

    return { tabItems: tItems, allGamesData: gData, defaultChartOptions: chartOptions, defaultDataSets: dDataSets };
  }, [summary, league]);

  const [options, setOptions] = useState(defaultChartOptions[0]);
  const [selectedGame, setSelectedGame] = useState(allGamesData[allGamesData.length - 1]);
  const [dataset, setDataset] = useState(defaultDataSets[0]);
  const datasets = useMemo(() => {
    if (lineChartRef.current) {
      const gradient = lineChartRef.current.ctx.createLinearGradient(0, 0, 0, 300);
      gradient.addColorStop(0, "rgba(0, 65, 152, 0.3)");
      gradient.addColorStop(1, "rgba(0, 65, 152, 0)");
      return defaultDataSets.map((currentData) => ({ ...currentData, backgroundColor: gradient }));
    }
    return defaultDataSets;
  }, [init, lineChartRef]);

  useEffect(() => {
    setDataset({ ...datasets[0] });
  }, [datasets]);

  const data = {
    datasets: [{ ...dataset }],
  };

  const handleChange = useCallback(
    (id: string) => {
      setOptions(defaultChartOptions[Number(id)]);
      setDataset(datasets[Number(id)]);
    },
    [defaultChartOptions, datasets]
  );

  const handleMarkerClick = useCallback(
    (event: any) => {
      if (lineChartRef.current) {
        const element = getElementsAtEvent(lineChartRef.current, event);
        if (!isUndefined(element?.[0]?.index)) {
          setSelectedGame(allGamesData[element[0].index]);
        }
      }
    },
    [lineChartRef, allGamesData]
  );

  const summaryHeader = selectedGame?.[0]?.header ?? "";

  return (
    <div className="roto-playercard-modal-preform__container">
      <div className="roto-playercard-modal-preform__content">
        <span className="roto-playercard-modal-preform__header">RECENT PERFORMANCE(LAST 5 GAMES)</span>
        <PlayerCardTab tabItems={tabItems} onChange={handleChange} />
        <div className="roto-playercard-modal-preform__chart">
          <Line
            ref={(ref) => {
              // @ts-ignore
              lineChartRef.current = ref;
              setInit(true);
            }}
            // @ts-ignore
            options={options}
            plugins={[gameMarkerDataLabels, gameDateLabels]}
            data={data}
            onClick={handleMarkerClick}
          />
        </div>
        {selectedGame && (
          <div className="roto-playercard-modal-preform__summary">
            <div className="roto-playercard-modal-preform__summary-tab">
              <div>
                <span>{summaryHeader}</span>
              </div>
            </div>
            <div className="roto-playercard-modal-preform__summary-content">
              <div className="roto-playercard-modal-preform__summary-content__list">
                {selectedGame.map((item, index) => (
                  <PreformSummaryCell key={index} {...item} />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <style jsx global>
        {styles}
      </style>
    </div>
  );
}
