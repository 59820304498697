import React, { useState, useEffect, AnchorHTMLAttributes, useContext } from "react";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import XXH from "xxhashjs";
import { OFFERS_QUERY } from "src/gql/queries";
import { userGetId } from "./UserService";
import { v4 as uuidv4 } from "uuid";
import { SubscriptionContext } from "./SubscriptionService";

export interface OfferItem {
  prismic_title: string;
  welcome_offer: string; // validated
  rating: number; // validated
  staff_pick: "true" | "false";
  web_link: string; // validated (web only)
  promo_code: string | null;
  how_it_works: string | null;
  preferred_payment: string | null;
  unpreferred_payment: string | null;
  withdrawal_time: string | null;
  minimum_deposit: string | null;
  new_badge: boolean | null;
  minimum_withdrawal: string | null;
  recommended_payment: string | null;
  review_content: any[] | null;
  sportsbook_name: string;
  image: { url: string }; // validated
  _meta: { id: string };
}

export interface StateItem {
  name: string;
  abbreviation: string;
  offers_title: string;
  offers_description: string;
  offers_content: any[];
  sportsbook_offers: OfferItem[];
  casino_offers: OfferItem[];
}

export const getOfferId = (offer: OfferItem) => {
  return XXH.h32(JSON.stringify(offer.web_link), 0).toString(16);
};

/**
 * Validate and filter offers from Prismic
 */
const filterInvalidOffers = (offers: OfferItem[]) =>
  offers.filter((offer: OfferItem) => {
    if (!offer) {
      return false;
    }
    if (!offer.image?.url) {
      return false;
    }
    if (!offer.welcome_offer) {
      return false;
    }
    if (!offer.rating) {
      return false;
    }
    if (!offer.web_link) {
      return false;
    }
    return true;
  });

// Prepare prismic offer states response for frontend
export const prepareOfferStates = (response: any): { data: StateItem[]; loading: boolean } => {
  // Get list of states
  let states: StateItem[] = _.cloneDeep(
    response?.data?.prismicAllStatess?.edges?.[0]?.node?.states?.map((s: any) => s.state) ?? []
  );

  // Transform StateItem to proper data structure
  states.forEach((state) => {
    state.sportsbook_offers = state?.sportsbook_offers?.map((o: any) => o.sportsbook_offer) ?? [];
    state.casino_offers = state?.casino_offers?.map((o: any) => o.sportsbook_offer) ?? [];
  });

  // Validate prismic entries
  states.forEach((state) => {
    state.sportsbook_offers = filterInvalidOffers(state.sportsbook_offers);
    state.casino_offers = filterInvalidOffers(state.casino_offers);
  });

  return { data: states, loading: response.loading };
};

// Easy hook for getting offer states
export const useOfferStates = ({
  ssr = false,
}: {
  ssr?: boolean;
} = {}): { data: StateItem[]; loading: boolean } => {
  const response = useQuery(OFFERS_QUERY, { ssr });
  return prepareOfferStates(response);
};

// Gets the affiliate URL for mgm, draftkings, fanduel, caesars. Links straight to the game.
const _getMetabetUrl = (bookName: string, sportsdataGameId: string, userLocale: string | null) => {
  // Strip spaces and dashes from the book name to key the logo off of
  const cleanBookName = bookName.toLowerCase().replace(" ", "").replace("-", "");

  // Change affiliate link to MetaBet Express Link only for these sportsbook
  const metaBetLinkForBooks = ["mgm", "draftkings", "fanduel", "caesars"];
  const metaBetUrl = "https://betql.go.metabet.io/bet";
  const gclid = window?.localStorage?.getItem("betqlgclid") ?? null; // prettier-ignore
  if (sportsdataGameId && metaBetLinkForBooks.includes(cleanBookName)) {
    let metaBetLink = `${metaBetUrl}/${cleanBookName}/sdio_game_${sportsdataGameId}`;
    if (userLocale) {
      metaBetLink = `${metaBetUrl}/${cleanBookName}_${userLocale}/sdio_game_${sportsdataGameId}`;
    }
    if (gclid) {
      metaBetLink = `${metaBetLink}?gclid=${gclid}`;
    }
    return _addTrackingToPartnerUrl(metaBetLink);
  }
  return "";
};

// Adds referral tracking to an affiliate URL
const _addTrackingToPartnerUrl = (url?: string) => {
  if (url?.includes("mgm")) {
    const newUrl = new URL(url);
    const referrer = document?.referrer;
    const path = window?.location?.pathname?.replace(/\W/g, "");
    const gclid = window?.localStorage?.getItem("betqlgclid");
    const userId = userGetId() || "anonymous-" + uuidv4();
    newUrl.searchParams.set("tdpeh", userId);
    newUrl.searchParams.set("btag", `referrer-${referrer || "none"}..path-${path || "none"}..gclid-${gclid || "none"}`);
    return newUrl.href;
  }
  return url || "";
};

interface MetaBetLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  bookName: string;
  sportsdataGameId: string;
}

export const MetaBetLink = ({ bookName, sportsdataGameId, children, ...props }: MetaBetLinkProps) => {
  const ctx = useContext(SubscriptionContext);
  const [href, setHref] = useState(props.href ?? "");
  useEffect(() => {
    let affUrl = _getMetabetUrl(bookName, sportsdataGameId, ctx.userLocale);
    if (props.href && (affUrl === null || affUrl === undefined)) {
      affUrl = _addTrackingToPartnerUrl(props.href);
    }
    setHref(affUrl);
  }, [props.href]);
  return (
    <a {...props} href={href}>
      {children}
    </a>
  );
};

export const AffiliateLink = ({ children, ...props }: AnchorHTMLAttributes<HTMLAnchorElement>) => {
  const [href, setHref] = useState(props.href ?? "");
  useEffect(() => {
    setHref(_addTrackingToPartnerUrl(props.href));
  }, [props.href]);
  return (
    <a {...props} href={href}>
      {children}
    </a>
  );
};
