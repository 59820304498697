import React, { useContext, useMemo } from "react";
import { ModalHeader, Modal } from "reactstrap";
import Link from "next/link";
import { useQuery } from "@apollo/client";
import { customOfferModalOpen } from "src/services/ApolloService";
import { SubscriptionContext } from "src/services/SubscriptionService";
import { USER_SPORTSBOOKS } from "src/gql/queries";
import styles from "./styles.scss";
import { AffiliateLink } from "src/services/OfferService";

function CustomOfferModal() {
  const { userLocale } = useContext(SubscriptionContext);
  const userBooksRes = useQuery(USER_SPORTSBOOKS, {
    fetchPolicy: "cache-and-network",
    variables: {
      locale: userLocale,
      includeOffers: true,
    },
  });

  // Get relevant state
  const stateHasBooks = userBooksRes.data?.state?.sportsbooks?.[0]?.sportsbook; // prettier-ignore
  const state = stateHasBooks ? userBooksRes.data?.state : userBooksRes.data?.fallbackState;

  // User's book ids
  const bookIds = userBooksRes.data?.me?.books?.map((x: any) => x.id) ?? [];

  // Find unclaimed offer
  const offer = useMemo(() => {
    const byUnclaimedOffer = (x: any) => !bookIds.includes(x?.sportsbook_offer?.sportsbook?.internal_book?.id);
    return state?.sportsbook_offers?.find(byUnclaimedOffer)?.sportsbook_offer ?? null; // prettier-ignore
  }, [bookIds]);

  // Hide if no unclaimed offer
  if (userBooksRes.data && !offer) {
    customOfferModalOpen(false);
    return null;
  }

  const close = () => customOfferModalOpen(false);

  return (
    <Modal isOpen toggle={close} className="offer-modal">
      <ModalHeader toggle={close}>
        <div className="offer-header-primary">Exclusive offer!</div>
      </ModalHeader>
      <div className="offer-body">
        <div className="offer-body-info">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src={offer?.image?.url} alt={offer?.alt ?? "Offer Image"} />
          <p className="offer-welcome_offer">{offer?.welcome_offer}</p>
          <p className="offer-description">{offer?.how_it_works}</p>
          <AffiliateLink href={offer?.web_link} className="web-offer-button share-btn">
            Redeem
          </AffiliateLink>
          <Link href="/sportsbook-offers" legacyBehavior>
            <a className="see-all-button share-btn" onClick={close}>
              See All Offers
            </a>
          </Link>
        </div>
      </div>
      <style jsx>{styles}</style>
    </Modal>
  );
}

export default CustomOfferModal;
