import React, { Component } from "react";
import { Button, Form, Label, Input, Row, Col, Alert } from "reactstrap";
import { Mutation } from "@apollo/client/react/components";
import { ApolloError } from "@apollo/client";

import { REQUEST_PASSWORD_RESET } from "../../../gql/mutations";
import styles from "./styles.scss";

export interface ResetRequestProps {
  sentRequest(): void;
}

export interface ResetRequestState {
  email: string;
  errors: string[];
}

export default class ResetRequest extends Component<ResetRequestProps, ResetRequestState> {
  constructor(props: any) {
    super(props);
    this.state = { email: "", errors: [] };
  }

  renderErrorMessages(error: ApolloError) {
    if (!error) {
      return null;
    }
    const errorMessageList: any = [];
    const errors: any = error.graphQLErrors;
    if (!errors) {
      return null;
    }
    for (let i = 0; i < errors.length; i++) {
      const thisError = errors[i];
      errorMessageList.push(<div key={i}>{thisError.message}</div>);
    }
    return <Alert color="danger">{errorMessageList}</Alert>;
  }

  render() {
    const { sentRequest = () => {} } = this.props;
    const { email } = this.state;
    return (
      <Mutation mutation={REQUEST_PASSWORD_RESET}>
        {(mutate: any, { loading, error }: any) => (
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              await mutate({
                variables: { email },
              });
              await sentRequest();
            }}
          >
            <style jsx>{styles}</style>
            {error ? (
              <Row>
                <Col md={12}>{this.renderErrorMessages(error)}</Col>
              </Row>
            ) : null}
            <div className="rotoql-reset__input-container">
              <Label for="email" className="rotoql-reset__label">
                E-mail Address
              </Label>
              <Input
                id="email"
                type="email"
                name="email"
                value={email}
                placeholder="Enter e-mail address"
                onChange={(e) => {
                  this.setState({
                    email: e.target.value,
                    errors: [],
                  });
                }}
                className="rotoql-reset__input"
              />
            </div>
            <div className="rotoql-reset__button-container">
              <Button color="primary" disabled={loading} className="rotoql-reset__request-reset-button">
                Request Reset
              </Button>
            </div>
            <div className="rotoql-reset__forgot-pw" onClick={async () => sentRequest()}>
              Already have a reset code?
            </div>
          </Form>
        )}
      </Mutation>
    );
  }
}
