import React, { Component } from "react";

import { Button } from "../../../Button";
import styles from "./styles.scss";

export interface MobileSubViewProps {
  toggleModal(): void;
}

export default class MobileSubView extends Component<MobileSubViewProps, {}> {
  render() {
    const { toggleModal } = this.props;
    return (
      <div className="rotoql-mobile-sub-view">
        <div className="rotoql-mobile-sub-view__body-text">
          We have detected you have subscribed to the mobile app. In order to change your subscription, manage through
          your device’s app store.
        </div>
        <div className="rotoql-mobile-sub-view__button-container">
          <Button className="rotoql-mobile-sub-view__button" onClick={() => toggleModal()}>
            CLOSE
          </Button>
        </div>
        <style jsx>{styles}</style>
      </div>
    );
  }
}
